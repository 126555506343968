import {URL_API} from '../../env.json';
import {post} from '../base/portal/apis';
const FormData = require('form-data');
const axios = require('axios');

export function getUserInfoApi(token) {
    return new Promise(function (resolve, reject) {
        const apiURL = URL_API + '/api/v1/users/profile';
        const header = {Authorization: 'Bearer ' + token};
        const form = new FormData();
        axios({
            method: 'GET',
            url: apiURL,
            headers: header,
            data: form,
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error.response && error.response.status) {
                    resolve({
                        status: error.response.status,
                        message: error.response
                            ? error.response.data.message
                            : '',
                    });
                } else {
                    resolve({
                        status: 404,
                        message: error.response
                            ? error.response.data.message
                            : '',
                    });
                }
            });
    });
}

export function saveFilterApi(user_status, gender, age_id, areaId, token) {
    const url = '/api/v1/users/filter';
    const data = {};
    if (user_status) {
        data.user_status = user_status;
    }
    if (gender) {
        data.gender = gender;
    }
    if (age_id) {
        data.age_id = age_id;
    }
    if(areaId){
        data.area_id = areaId;
    }
    return post(url, token, data);
}
