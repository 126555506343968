export const NetInfox = 1;
export const AsyncStoragex = 1;

// only fake to skeep flow, not use
export const genCallId = () => {
    return '2a5e488d-bb64-4ad8-a633-82628c47b329';
};

export const runAfter = (handle, delay) => {
    setTimeout(handle, delay);
};
