import React, {useState, useEffect} from 'react';
import {StyleSheet, Text, View, Button, Image} from 'react-native';
import {useRouteMatch} from 'react-router-dom';
import {updateImageUri} from '../actions';
import {connect} from 'react-redux';
import {Constants} from '../../../util/Constants';
import Resizer from 'react-image-file-resizer';
import '../avatar.scss';
import {URL_WEB} from '../../../env.json';
var sizeOf = require('image-size');

const SettingAvatar = ({history, updateImageUri, imageUri}) => {
    const {url} = useRouteMatch();
    const [imageError, setImageError] = useState('');
    const avatarDefault = URL_WEB + '/images/ic_avata_big.svg';
    const [avatar, setAvatar] = useState(() => imageUri || avatarDefault);

    useEffect(() => {
        if (!imageUri) {
            const val = localStorage.getItem('userAvatarRegisterInfo');
            if (val !== null) {
                const data = JSON.parse(val);
                if (data.imageUri) {
                    setAvatar(data.imageUri);
                } else {
                    setAvatar(avatarDefault);
                }
            } else {
                setAvatar(avatarDefault);
            }
        } else {
            setAvatar(imageUri);
        }
    }, [imageUri]);

    let onChangeFile = (e) => {
        let filePer = ['image/png', 'image/jpg', 'image/jpeg', ''];
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        if (file) {
            if (filePer.findIndex((f) => f === file.type) === -1) {
                // ERROR
            } else {
                Resizer.imageFileResizer(
                    file,
                    9999999999,
                    9999999999,
                    Constants.IMG_JPEG,
                    Constants.IMG_QUANLITY,
                    0,
                    (uri) => {
                        var img = Buffer.from(uri.substr(23), 'base64');
                        var dimensions = sizeOf(img);
                        if (
                            dimensions.width < Constants.PROFILE_IMG_MAX_SIZE ||
                            dimensions.height < Constants.PROFILE_IMG_MAX_SIZE
                        ) {
                            setImageError(
                                '横110px × 縦110px以上の画像をアップロードしてください。',
                            );
                        } else {
                            setImageError('');
                            resizeImage(file);
                        }
                    },
                    Constants.IMG_BASE64,
                );
            }
        }
    };
    let resizeImage = (file) => {
        Resizer.imageFileResizer(
            file,
            110,
            110,
            Constants.IMG_JPEG,
            Constants.IMG_QUANLITY,
            0,
            (uri) => {
                localStorage.setItem(
                    'userAvatarRegisterInfo',
                    JSON.stringify({
                        imageUri: uri,
                        type_image: Constants.IMAGE_TYPE_BASE64,
                    }),
                );
                updateImageUri(uri, Constants.IMAGE_TYPE_BASE64, true);
            },
            Constants.IMG_BASE64,
        );
    };
    let handLerTakePicture = () => {
        history.push({
            pathname: `${url}/take-picture`,
        });
    };
    let handlerUpdateUser = () => {
        let userInfoStorage;
        try{
            userInfoStorage = localStorage.getItem('user_info');
        }catch(e){
            userInfoStorage = {};
        }
        let registerAvatar = userInfoStorage.userAvatarRegisterInfo;
        if (registerAvatar === null) {
            userInfoStorage.userAvatarRegisterInfo = {
                    imageUri: avatarDefault,
                    type_image: Constants.IMAGE_TYPE_LINK,
                };
            localStorage.setItem('user_info', userInfoStorage);
        }
        history.push({
            // pathname: '/registration-information/sound-setting',
            pathname: '/registration-information/blood-setting',            
        });
    };
    let handlerListAvatar = () => {
        history.push({
            pathname: '/registration-information/avatar/list',
        });
    };
    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.txt}>
                        {'つぎは あなたのアイコン画像を\n設定してみましょう！'}
                    </Text>
                    <Text style={styles.txt} />
                </View>
            </View>
            <View style={styles.main}>
                <img id="img-profile-register" src={avatar} />
                {imageError !== '' && (
                    <span className="imageError">{imageError}</span>
                )}
                <div className="button-choice">
                    <span
                        onClick={() => {
                            handLerTakePicture();
                        }}
                        className="button-choice-take-picture">
                        写真を撮る
                    </span>
                </div>
                <div className="buttonContainer">
                    <label htmlFor="upload-photo" className="uploadLabel">
                        ライブラリから選ぶ
                    </label>
                    <input
                        onChange={onChangeFile}
                        type="file"
                        id="upload-photo"
                        accept="image/png,image/jpg,image/jpeg"
                    />
                </div>
                <div className="button-choice">
                    <span
                        onClick={handlerListAvatar}
                        className="button-choice-take-picture">
                        アバター選択
                    </span>
                </div>
                <div className="button-back-setting-info">
                    <span
                        onClick={handlerUpdateUser}
                        className="button-back-setting-info-avatar">
                        決定{' '}
                    </span>
                </div>
            </View>
        </View>
    );
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#A6D837',
    },
    header: {
        alignItems: 'center',
        height: 170,
        paddingBottom: 50,
        flexDirection: 'column',
    },
    txt: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 80,
        textAlign: 'center',
        cursor: 'default',
    },
    main: {
        height: 512,
        backgroundColor: '#F4F9FA',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
});
function _mapStateToProps(state) {
    return {
        imageUri: state['cocoro/register'].imageUri,
    };
}
let _mapDispatchToProps = {
    updateImageUri,
};
export default connect(_mapStateToProps, _mapDispatchToProps)(SettingAvatar);
