import {verifyCodeApi} from '../base/api';
import {httpRequest} from '../base/api/actions';

export function verifyCode(email, code) {
    return async function (dispatch, getState) {
        const result = dispatch(
            httpRequest(verifyCodeApi({email, verify_code: code}), true),
        );
        return result;
    };
}
