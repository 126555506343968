import {db} from '../base/firebase/firebase';
import {
    REALTIME_TYPE_CHAT,
    REALTIME_TYPE_CHAT_CONNECTION,
    REALTIME_TYPE_CHAT_TOPIC,
    REALTIME_TYPE_DATA,
    REALTIME_TYPE_DATA_ACTION_READ,
    REALTIME_TYPE_MESSAGE_NOTIFICATION,
    REALTIME_TYPE_USER,
    REALTIME_TYPE_USER_ACTION_BONUS,
    REALTIME_TYPE_USER_ACTION_BONUS_DONE,
    ROOM_TYPE_CHAT_TOPIC,
    REALTIME_TYPE_MESSAGE_SYSTEM_CALL_MISS,
    REALTIME_TYPE_MESSAGE_SYSTEM_CALL_CANCEL,
} from '../call/constants';
import {
    openModalPage,
    openPopup,
    openActionPopup,
    closeModalPage,
    closeChatScreen,
    updateMessageRoomData,
    updateLatestRoomInfo,
    updateLatestRooms,
} from '../app/actions';
import {plusUnreadHistory} from '../history';
import {
    ADD_MSGS,
    UPDATE_STATUS,
    UPDATE_DATA,
    UPDATE_ROOM_INFO,
    CLEAR_ROOM_INFO,
    UPDATE_MESSAGE_IS_READ,
    REMOVE_TMP_MSG,
} from './actionTypes';
import {Keyboard} from '../call/media';
import {isNative} from '../../util/device';
import {roomTypes} from '../../util/Constants';
import {NotiServiceInstance} from '../notify/NotifService';
import clock from '../base/clock';
import {httpRequest} from '../base/api/actions';
import {
    confirmBonusChatApi,
    createHistoryApi,
    createRoomChatApi,
    createRoomChatApi1,
    getRoomDetailApi,
    pingConnectRoomChatApi,
    requestBonusChatApi,
    sendMessageApi,
    updateUnreadMessageNumberApi,
    updateRoomReplied,
    deleteMessageApi,
    getMessageRoomToMeApi,
    deleteMessagesApi,
    getSignedFileUrl,
    addFriendApi,
    updateShowBonusStatus,
    getMessageToMeApi,
    sendPresentApi,
} from '../base/api';
import * as webrtc from '../base/webrtc';
import {MODAL_BONUS_CHAT} from '../app/constants';
import {
    messageStatusTexts,
    messageTypeIds,
    replyMessageTypes,
} from './constants';
import {Constants} from '../../util/Constants';
import {genUUIDv4} from '../../util/device.web';
import chatdb from './chatdb';


//-----------------------------------------------

let MUSIC_URL = [[
    "/images/trumpet/mp3/1/1.mp3",
    "/images/trumpet/mp3/1/2.mp3",
    "/images/trumpet/mp3/1/3.mp3",
    "/images/trumpet/mp3/1/4.mp3",
    "/images/trumpet/mp3/1/5.mp3",
    "/images/trumpet/mp3/1/6.mp3",
    "/images/trumpet/mp3/1/7.mp3",
    "/images/trumpet/mp3/1/8.mp3",
],[
    "/images/trumpet/mp3/2/1.mp3",
    "/images/trumpet/mp3/2/2.mp3",
    "/images/trumpet/mp3/2/3.mp3",
    "/images/trumpet/mp3/2/4.mp3",
    "/images/trumpet/mp3/2/5.mp3",
    "/images/trumpet/mp3/2/6.mp3",
    "/images/trumpet/mp3/2/7.mp3",
    "/images/trumpet/mp3/2/8.mp3",
],[
    "/images/trumpet/mp3/3/1.mp3",
    "/images/trumpet/mp3/3/2.mp3",
    "/images/trumpet/mp3/3/3.mp3",
    "/images/trumpet/mp3/3/4.mp3",
    "/images/trumpet/mp3/3/5.mp3",
    "/images/trumpet/mp3/3/6.mp3",
    "/images/trumpet/mp3/3/7.mp3",
    "/images/trumpet/mp3/3/8.mp3",
]];

export function addMsg(msg) {
    return {
        type: 'ADD_MSG',
        msg,
    };
}

export function removeTmpMsg(msg) {
    return {
        type: REMOVE_TMP_MSG,
        msg,
    };
}

export function clearOldMsg() {
    return {
        type: 'CLEAR_OLD_MSG',
    };
}

export function addMsgs(msgs) {
    return {
        type: ADD_MSGS,
        msgs,
    };
}

export function initMsg(msgs) {
    return {
        type: 'INIT_MSG',
        msgs,
    };
}

export function updateRoomInfo(roomInfo) {
    return {
        type: UPDATE_ROOM_INFO,
        roomInfo,
    };
}

export function clearRoomInfo() {
    return {
        type: CLEAR_ROOM_INFO,
    };
}

export function updateStatus(messageId, status) {
    return {
        type: UPDATE_STATUS,
        status,
        messageId,
    };
}

export function messageIsRead(messageId) {
    return {
        type: UPDATE_MESSAGE_IS_READ,
        messageId,
    };
}

export function updateData(messageId, data, size) {
    return {
        type: UPDATE_DATA,
        data,
        messageId,
        size,
    };
}

export function addFriend({user_id, room_id, topic_id}) {
    return async function (dispatch, getState) {
        const result = await dispatch(
            httpRequest(
                addFriendApi({
                    user_id,
                    room_id,
                    topic_id,
                }),
                true,
            ),
        );
        if (result.status === true) {
            dispatch(openPopup('ともだちを追加しました。'));
        }
        return result;
    };
}

export function createRoomChat({
    id,
    user_id,
    room_type_id,
    topic_id,
    is_anonymous,
}) {
    return async function (dispatch, getState) {
        if(id === '0')
        {
            const result = await dispatch(
                httpRequest(
                    createRoomChatApi({
                        user_id,
                        room_type_id,
                        topic_id,
                        is_anonymous,
                    }),
                    true,
                ),
            );
            if (result?.id) {
                dispatch(updateRoomInfo(result));
            }
            return result;
        }
        else{
            const result = await dispatch(
                httpRequest(
                    createRoomChatApi1({
                        id,
                        user_id,
                        room_type_id,
                        topic_id,
                        is_anonymous,
                    }),
                    true,
                ),
            );
            if (result?.id) {
                dispatch(updateRoomInfo(result));
            }
            return result;
        }
    };
}

// listen on init app
export function subscribeRealtimeChatData(myId) {
    return function (dispatch, getState) {
        let ignoredFirstQuery = false;
        const handler = (doc) => {
            if (ignoredFirstQuery) {
                const data = doc.data();
                switch (data.type) {
                    case REALTIME_TYPE_CHAT_CONNECTION:
                        // ignore if pinger is blocked
                        const {userInfo: me} = getState()['cocoro/user'];
                        if (me.blocks?.[data.user_id]) {
                            console.log('chat ping is blocked');
                            return;
                        }

                        webrtc.setupChatConnection(
                            myId,
                            data.user_id,
                            data.signal_id,
                            async (message) => {
                                return dispatch(receiveChatMessage(message));
                            },
                        );

                        break;

                    case REALTIME_TYPE_MESSAGE_NOTIFICATION: {
                        // receive message notification
                        const {lastAppActive} = getState()['cocoro/app'];
                        if (data.created_at > lastAppActive) {
                            dispatch(
                                receiveChatMessageFromRealtimeAction(data),
                            );
                        }
                        break;
                    }

                    case REALTIME_TYPE_CHAT: {
                        // receive message chat friend
                        const {lastAppActive} = getState()['cocoro/app'];
                        if (data.created_at > lastAppActive) {
                            dispatch(
                                receiveChatMessageFromRealtimeAction(data),
                            );
                        }
                        break;
                    }

                    case REALTIME_TYPE_CHAT_TOPIC: {
                        // receive message chat topic
                        const {lastAppActive} = getState()['cocoro/app'];
                        if (data.created_at > lastAppActive) {
                            
                            dispatch(
                                receiveChatMessageFromRealtimeAction(data),
                            );
                        }
                        break;
                    }

                    case REALTIME_TYPE_DATA:
                        if (data.action === REALTIME_TYPE_DATA_ACTION_READ) {
                            // update status message log call '既読'
                            dispatch(
                                updateStatus(data.id, messageStatusTexts.READ),
                            );
                            chatdb.updateMessage(data.id, {
                                status: messageStatusTexts.READ,
                                is_read: true,
                            });
                        }
                        break;
                    case REALTIME_TYPE_USER:
                        const {roomInfo} = getState()['cocoro/chat'];
                        if (
                            data.action === REALTIME_TYPE_USER_ACTION_BONUS &&
                            data.room_id === roomInfo?.id
                        ) {
                            if (isNative) {
                                Keyboard.dismiss();
                            }
                            dispatch(
                                openModalPage({
                                    type: MODAL_BONUS_CHAT,
                                    ...roomInfo,
                                }),
                            );
                        }
                        if (
                            data.action ===
                                REALTIME_TYPE_USER_ACTION_BONUS_DONE &&
                            data.room_id === roomInfo?.id
                        ) {
                            let message =
                                data.status === true
                                    ? '課金を承認されました。'
                                    : '課金を拒否されました。';
                            dispatch(
                                openActionPopup(message, () =>
                                    dispatch(
                                        httpRequest(
                                            updateShowBonusStatus(data.room_id),
                                            false,
                                        ),
                                    ),
                                ),
                            );
                            dispatch(updateRoomInfo({bonus: true}));
                        }
                        break;
                    default:
                        break;
                }
            } else {
                ignoredFirstQuery = true;
            }
        };
        const unsubRealtime = db
            .collection('real_time')
            .doc(myId)            
            .onSnapshot(handler);
        return unsubRealtime;
    };
}

export function syncChatMessagesImmediatelyAppActive() {
    return async function (dispatch, getState) {
        const {userInfo: me} = getState()['cocoro/user'];
        const res = await getMessageToMeApi();
        if (Array.isArray(res?.data) && res.data.length > 0) {
            const msgIds = [];
            await Promise.all(
                res.data.map((m) => {
                    const msg = {...m, status: messageStatusTexts.RECEIVED};
                    dispatch(addMsg(msg));
                    msgIds.push(m.id);

                    if (!msg.expire_at || (msg.expire_at && !msg.topic_id)) {
                        const currentTime = clock.now();
                        const expireTime =
                            currentTime +
                            (me?.saved_message_time
                                ? me.saved_message_time
                                : 60) *
                                60 *
                                1000; // default saved_message_time is 60 minutes
                        msg.expire_at = expireTime;
                    }
                    return chatdb.addMessage(msg);
                }),
            );
            dispatch(updateLatestRooms());

            await dispatch(httpRequest(deleteMessagesApi(msgIds), false));
        }
    };
}

/**
 * Get room data, message persist on firebase -> delete message on server
 */
export function initChatData(roomId, myId, roomInfo) {
    return async function (dispatch, getState) {
        const {userInfo: me} = getState()['cocoro/user'];
        
        const [roomRes, messagesRes] = await Promise.all([
            dispatch(httpRequest(getRoomDetailApi(roomId,myId), false)),
            dispatch(httpRequest(getMessageRoomToMeApi(roomId,myId), false)),
        ]);

        
        if (roomRes?.errors) {
            return roomRes;
        }

        dispatch(updateRoomInfo(roomRes));

        console.log("roomInfo------------",roomInfo);
        const messages = await chatdb.getMessages1({
            room_id: roomId,
            offset: 0,
            limit: isNative ? 20 : 1000,
            roomInfo: roomInfo,
            userInfo: me,
        });
        dispatch(initMsg(messages));
        // check show bonus chat modal
        // if (
        //     roomRes.bonus === false &&
        //     roomRes.topic &&
        //     roomRes.topic.user_id !== myId
        // ) {
        //     dispatch(openModalPage({type: MODAL_BONUS_CHAT, ...roomRes}));
        // }
        // if (
        //     roomRes.is_show_bonus_status === true &&
        //     roomRes.topic &&
        //     roomRes.topic.user_id === myId
        // ) {
        //     dispatch(
        //         openActionPopup(roomRes.bonus_message, () =>
        //             dispatch(httpRequest(updateShowBonusStatus(roomId), false)),
        //         ),
        //     );
        // }

        if (Array.isArray(messagesRes) && messagesRes.length > 0) {
            const msgIds = [];
            await Promise.all(
                messagesRes.map((m) => {
                    const msg = {...m, status: messageStatusTexts.RECEIVED};
                    dispatch(addMsg(msg));
                    msgIds.push(m.id);

                    if (!msg.expire_at || (msg.expire_at && !msg.topic_id)) {
                        const currentTime = clock.now();
                        const expireTime =
                            currentTime +
                            (me?.saved_message_time
                                ? me.saved_message_time
                                : 60) *
                                60 *
                                1000; // default saved_message_time is 60 minutes
                        msg.expire_at = expireTime;
                    }
                    return chatdb.addMessage(msg);
                }),
            );
            await dispatch(httpRequest(deleteMessagesApi(msgIds), false));
        }
        return roomRes;
    };
}

export function loadMorePreviousMessages(roomId, offset) {
    return async function (dispatch, getState) {
        const messages = await chatdb.getMessages1({room_id: roomId, offset});
        dispatch(addMsgs(messages));
    };
}

export function subscribeChatConnection(roomId, myId, otherUserId) {
    return function (dispatch, getState) {
        dispatch(connectChatChannel(roomId, myId, otherUserId));

        const intervalTryToConnectWebrtc = setInterval(() => {
            dispatch(connectChatChannel(roomId, myId, otherUserId));
        }, 30000);

        return () => {
            clearInterval(intervalTryToConnectWebrtc);
        };
    };
}

export function connectChatChannel(roomId, myId, otherUserId) {
    return function (dispatch, getState) {
        if (!roomId || !myId || !otherUserId) {
            console.warn(
                'unable init webrtc connect',
                roomId,
                myId,
                otherUserId,
            );
            return;
        }

        // setup peer connection
        const conn = webrtc.initChatConnection(myId, otherUserId, genUUIDv4());

        if (!conn.channel) {
            conn.initDataChannel(async (message) => {
                return dispatch(receiveChatMessage(message));
            });
            conn.connectWithoutStream();
        }

        if (conn.status !== webrtc.Status.Connected) {
            console.log('ping chat connection___', conn.signalId);
            dispatch(
                httpRequest(
                    pingConnectRoomChatApi({
                        signal_id: conn.signalId,
                        room_id: roomId,
                        user_id: otherUserId,
                        id: myId,
                    }),
                    false,
                    true,
                ),
            );
        }
    };
}

/**
 * Send Present
 */


export async function sendPresent(coin, receiveUserId) {
    const res = await sendPresentApi(coin, receiveUserId);
    if(res?.data?.message === "success"){
        return true
    }else{
        return false;
    }
}

/**
 * Send chat
 * Send on webrtc connection -> fail -> save firebase -> push notification
 */
export function sendChat(
    user_id,
    user_name,
    roomId,    
    messageTypeId,
    data,
    size = {
        width: 0,
        height: 0,
    },
) {
    return async function (dispatch, getState) {
        const {userInfo: me} = getState()['cocoro/user'];
        const {roomInfo} = getState()['cocoro/chat'];
        const {messages} = getState()['cocoro/chat'];

        let userId = me.id;
        if(user_id !== "0")
            userId = user_id;
        let userName = me.user_name;
        if(user_name !== "0")
            userName  = user_name;
            
        const topicId = roomInfo.topic_id;

        const topic = roomInfo.topic;
        const receiverId = roomInfo?.participants_array?.find(
            (uid) => uid !== userId,
        );

        const currentTime = clock.now();
        const expireTime =
            parseInt(roomInfo.room_type_id) === ROOM_TYPE_CHAT_TOPIC
                ? roomInfo.topic.expire_at._seconds * 1000
                : currentTime +
                  (me.saved_message_time ? me.saved_message_time : 60) *
                      60 *
                      1000; // default saved_message_time is 60 minutes

        let message = {
            id: genUUIDv4(),
            message_type_id: messageTypeId.toString(),
            room_id: roomId,
            topic_id: roomInfo.topic_id || null,
            // user_id: me.id,
            user_id: userId,
            send_user_mail: me.email_id,
            user_name: userName,
            is_anonymous: roomInfo.anonymous?.[userId] || false,
            receiver_id: receiverId,
            data,
            size,
            is_read: false,
            status: messageStatusTexts.RECEIVED,
            topic_title: topic?.title || '',
            room_type_id: roomInfo.room_type_id,
            created_at: currentTime,
            expire_at: expireTime,
        };

        // display message on screen
        dispatch(addMsg(message));
        // message.user_id = userId;
        // must save message to local immediately
        await chatdb.addMessage(message);

        dispatch(updateMessageRoomData(roomId, message, 0, userId));

        // TODO: need move ----------------------
        //@author puffer
        // if (roomInfo.topic && messages.length === 0) {
            var friendAdd = 0;
            for(var i=0;i<messages.length;i++){
                if(messages[i].message_type_id === "1")
                {
                    if(messages[i].receiver_id === roomInfo?.userInfo?.id)
                    {
                        friendAdd++;
                    }
                }
            }
            // if(messages.length >0 && friendAdd < messages.length)
                // dispatch(httpRequest(addFriendApi({user_id: roomInfo?.userInfo?.id}, true)));
        // }
        dispatch(httpRequest(createHistoryApi(roomId, message)));
        
        if (
            parseInt(roomInfo.room_type_id) === roomTypes.CHAT_TOPIC &&
            userId === roomInfo.topic?.user_id &&
            roomInfo.is_replied !== false
        ) {
            dispatch(httpRequest(updateRoomReplied(roomId)));
        }
        //---------------------------------------

        const peerConn = webrtc.getChatAvailableConnection(
            roomInfo.userInfo?.id,
        );
        if (peerConn) {
            const result = await peerConn.sendData(message);
            console.log('send webrtc...', result);
            if (result) {
                // update message status
                dispatch(
                    updateStatus(message.id, messageStatusTexts.SENT_SUCCESS),
                );
                await chatdb.updateMessage(message.id, {
                    status: messageStatusTexts.SENT_SUCCESS,
                });

                return;
            }
        }

        // fallback push notification
        const res = await dispatch(
            httpRequest(sendMessageApi(message, userId), false, true),
        );
        console.log('fallback send message -> firebase', res?.status);
        if (res?.status === true) {
            message.status = messageStatusTexts.SENT_SUCCESS;
        } else {
            message.status = messageStatusTexts.SENT_FAIL;
        }
        if (res?.status === false && res?.message === 'blocked') {
            message.status = messageStatusTexts.REFUSED;
        }
        dispatch(updateStatus(message.id, message.status));
        await chatdb.updateMessage(message.id, {status: message.status});
    };
}

export function uploadFile(
    roomId,
    messageTypeId,
    data,
    size = {
        width: 0,
        height: 0,
    },
) {
    return async function (dispatch, getState) {
        const message = {
            message_type_id: messageTypeId,
            roomId,
            data,
            size,
        };
        if (
            message.message_type_id === messageTypeIds.IMAGE ||
            message.message_type_id === messageTypeIds.EMOJI ||
            message.message_type_id === messageTypeIds.IMAGE_BASE64
        ) {
            const signedUrl = await dispatch(
                httpRequest(getSignedFileUrl(message)),
            );
            return signedUrl;
        }
        return null;
    };
}

export function resendMessageSentFail(messages) {
    return async function (dispatch, getState) {

        const {roomInfo} = getState()['cocoro/chat'];
        if (!roomInfo?.id || !roomInfo?.userInfo?.id) {
            return;
        }

        const msgSentIds = [];
        const otherUserId = roomInfo.userInfo.id;
        const peerConn = webrtc.getChatAvailableConnection(otherUserId);
        if (peerConn) {
            messages.forEach(async (m) => {
                const result = await peerConn.sendData(m);
                console.log('send webrtc...', result);
                if (result) {
                    msgSentIds.push(m.id);

                    // update message status
                    dispatch(
                        updateStatus(m.id, messageStatusTexts.SENT_SUCCESS),
                    );
                    await chatdb.updateMessage(m.id, {
                        status: messageStatusTexts.SENT_SUCCESS,
                    });
                }
            });
        }

        const remainMsgs = messages.filter(
            (m) => msgSentIds.indexOf(m.id) === -1,
        );

        await Promise.all(
            remainMsgs.map(async (m) => {
                const res = await dispatch(
                    httpRequest(sendMessageApi(m, 'userId'), false, true),
                );
                console.log('fallback send message -> firebase', res?.status);
                if (res?.status === true) {
                    m.status = messageStatusTexts.SENT_SUCCESS;
                } else {
                    m.status = messageStatusTexts.SENT_FAIL;
                }
                dispatch(updateStatus(m.id, m.status));
                await chatdb.updateMessage(m.id, {status: m.status});
            }),
        );
    };
}

export function readMessageChat(roomId, messageId) {
    return async function (dispatch, getState) {
        await chatdb.updateMessage(messageId, {
            is_read: true,
        });
    };
}

export function replyReadMessageChat(roomId, userId, messageId) {
    return async function (dispatch, getState) {
        const peerConn = webrtc.getChatAvailableConnection(userId);
        if (peerConn) {
            const replyMessage = {
                type: replyMessageTypes.READ,
                id: messageId,
                room_id: roomId,
                status: messageStatusTexts.READ,
            };
            const result = await peerConn.sendData(replyMessage);
            if (result) {
                // update message status
                console.log("dddddddd============dddddddddddd");
                dispatch(updateStatus(messageId, messageStatusTexts.READ));
                await chatdb.updateMessage(messageId, {
                    status: messageStatusTexts.READ,
                });
                return true;
            }
        }
        return false;
    };
}

export function receiveChatMessage(message) {
    return async function (dispatch, getState) {
            dispatch(updateStatus(message.id, messageStatusTexts.READ));
            await chatdb.updateMessage(message.id, {
                status: messageStatusTexts.READ,
                is_read: true,
            });
        if (message.type === replyMessageTypes.READ) {
            return true;
        }

        //---------------------------------------
        const {userInfo: me} = getState()['cocoro/user'];
        const {roomInfo} = getState()['cocoro/chat'];
        const {appState} = getState()['cocoro/app'];

        if (me.blocks?.[message.user_id]) {
            console.warn('ignore message of blocked user');
            return false;
        }

        if (roomInfo?.id === message.room_id) {
            dispatch(addMsg({...message, status: messageStatusTexts.RECEIVED}));
        } else {
            dispatch(plusUnreadHistory(message.room_id));
        }

        // update message expire_at
        if (!message.expire_at || (message.expire_at && !message.topic_id)) {
            const currentTime = clock.now();
            const expireTime =
                currentTime +
                (me?.saved_message_time ? me.saved_message_time : 60) *
                    60 *
                    1000; // default saved_message_time is 60 minutes
            message.expire_at = expireTime;
        }

        // save local
        await chatdb.addMessage({
            ...message,
            status: messageStatusTexts.RECEIVED,
        });

        // push local notification
        if (
            (roomInfo.id !== message.room_id && me.id && isNative) ||
            appState === 'background'
        ) {
            const {messageRingtone} = getState()['cocoro/settings'];
            NotiServiceInstance.pushLocalNotify(message, messageRingtone);
        }

        dispatch(updateMessageRoomData(message.room_id, message, 1, me.id));

        return true;
    };
}

export function requestBonusChat(roomId) {
    return async function (dispatch, getState) {
        const result = await dispatch(
            httpRequest(requestBonusChatApi(roomId), true),
        );
        if (result) {
            dispatch(updateRoomInfo({bonus: false}));
            dispatch(openPopup('課金リクエストを送信しました。'));
        }
    };
}

export function confirmBonusChat(roomId, status) {
    return async function (dispatch, getState) {
        const result = await dispatch(
            httpRequest(confirmBonusChatApi(roomId, status), true),
        );
        if (result === true) {
            dispatch(updateRoomInfo({paid: status}));
            dispatch(closeModalPage());
        } else if (result === false) {
            dispatch(openPopup('ポイントが不足です。'));
        }
    };
}

export function syncUnreadMessageNumber(myId) {
    return async function (dispatch, getState) {
        // const unread = await chatdb.countAllUnreadMessages(myId);
        // await dispatch(
        //     httpRequest(updateUnreadMessageNumberApi(unread), false),
        // );
    };
}

export function receiveChatMessageFromRealtimeAction(message) {
    return async function (dispatch, getState) {
        try{
            if(message.data.search("###sound") != -1){
                message.is_read = true;
                try{
                    let tmp = message.data.split("_");
                    new Audio(MUSIC_URL[tmp[1]][tmp[2]]).play();
                }catch(e){}
            }
        }catch(e){}

        const {userInfo: me} = getState()['cocoro/user'];
        const {roomInfo} = getState()['cocoro/chat'];

        message.status = messageStatusTexts.RECEIVED;

        // update message expire_at
        if (!message.expire_at || (message.expire_at && !message.topic_id)) {
            const currentTime = clock.now();
            const expireTime =
                currentTime +
                (me?.saved_message_time ? me.saved_message_time : 60) *
                    60 *
                    1000; // default saved_message_time is 60 minutes
            message.expire_at = expireTime;
        }

        await chatdb.addMessage(message);
        dispatch(httpRequest(deleteMessageApi(message.id)));

        // update latest message & bagde
        const unreadCount =
            message.message_type_id === Constants.MESSAGE_TYPE_ID_CALL_SUCCESS
                ? 0
                : 1;
        dispatch(
            updateMessageRoomData(message.room_id, message, unreadCount, me.id),
        );
        //-----------------------------

        if (roomInfo?.id === message.room_id) {
            dispatch(addMsg(message));
        } else {
            dispatch(plusUnreadHistory(message.room_id));
            const {isShowChatScreen} = getState()['cocoro/app'];
            const {messageRingtone} = getState()['cocoro/settings'];
            if (me?.id && isNative && isShowChatScreen !== true) {
                if (
                    message.message_type_id ===
                        REALTIME_TYPE_MESSAGE_SYSTEM_CALL_MISS ||
                    message.message_type_id ===
                        REALTIME_TYPE_MESSAGE_SYSTEM_CALL_CANCEL
                ) {
                    if (message.user_id !== me.id) {
                        NotiServiceInstance.pushLocalNotify(
                            message,
                            messageRingtone,
                        );
                    }
                } else {
                    NotiServiceInstance.pushLocalNotify(
                        message,
                        messageRingtone,
                    );
                }
            }
        }
    };
}

export function getTmpMsg(roomId, messageTypeId, data, size) {
    return function (dispatch, getState) {
        const {userInfo: me} = getState()['cocoro/user'];
        const {roomInfo} = getState()['cocoro/chat'];

        const topic = roomInfo.topic;
        const receiverId = roomInfo.participants_array.find(
            (uid) => uid !== me.id,
        );

        const currentTime = Math.floor(clock.now());
        const expireTime =
            parseInt(roomInfo.room_type_id) === ROOM_TYPE_CHAT_TOPIC
                ? roomInfo.topic.expire_at._seconds * 1000
                : currentTime +
                  (me.saved_message_time ? me.saved_message_time : 60) *
                      60 *
                      1000; // default saved_message_time is 60 minutes

        const message = {
            id: genUUIDv4(),
            message_type_id: messageTypeId.toString(),
            room_id: roomId,
            topic_id: roomInfo.topic_id || null,
            user_id: me.id,
            user_name: me.user_name,
            receiver_id: receiverId,
            data,
            size,
            is_read: false,
            status: messageStatusTexts.SENDING,
            topic_title: topic?.title || '',
            created_at: currentTime,
            expire_at: expireTime,
        };
        return message;
    };
}