import React from 'react';
import './style/HistoryConversationStyle.scss';
import TimeTopic from '../../../chat/components/TimeTopic';
import {CallBtn} from '../../../call';
import {ChatBtn} from '../../../chat';
import {
    ROOM_TYPE_CHAT_TOPIC,
    ROOM_TYPE_CALL_TOPIC,
} from '../../../call/constants';
import {Constants} from '../../../../util/Constants';
import { CATEGORY_LOCAL_STORE, SUBCATEGORY_LOCAL_STORE, AREA_LOCAL_STORE } from "../../../app/constants";

const HistoryConversation = ({history, unreadCount, openDetailUser}) => {

    let areas;
    let categories;
    let subcategories;
    try{
        areas = JSON.parse(localStorage.getItem(AREA_LOCAL_STORE));
    }catch(e){
        areas = [];
    }
    try{
        categories = JSON.parse(localStorage.getItem(CATEGORY_LOCAL_STORE));
    }catch(e){
        categories = [];
    }
    try{
        subcategories = JSON.parse(localStorage.getItem(SUBCATEGORY_LOCAL_STORE));
    }catch(e){
        subcategories = [];
    }
    const getSubcategoryName = (history) => {
        for(let i = 0; i < subcategories.length; i++) {
            if(history?.topic?.category_id == subcategories[i]?.category_id && history?.topic?.sub_category_id == subcategories[i]?.id )
                return subcategories[i]?.name;
        }
    }
    const getCategoryName = (history)=> {
        for(let i = 0; i < categories.length; i++) {
            if(history?.topic?.category_id == categories[i]?.id )
                return categories[i]?.name;
        }
    }
    const getAreaName = (history)=> {
        for(let i = 0; i < areas.length; i++) {
            if(history?.topic?.area_id == areas[i]?.id )
                return areas[i]?.name;
        }
    }
    let viewData;
    try{
        viewData =<>
            <span>{ getCategoryName(history) }</span>
            <img src="/images/arrow-one.png"/>
            <span>{ getSubcategoryName(history) }</span>
            <img src="/images/arrow-one.png"/>
            <span>{ getAreaName(history) }</span>
        </>
    }catch(e){
        
    }

    return (
        <div id="history-converstation">
            <div className={'avata-container'}>
                <div id="avata"
                    //  onClick={() => openDetailUser(history)}
                >
                    <img
                        src={
                            history?.user?.profile_image
                                ? history.user.profile_image
                                : Constants.AVATAR_DEFAULT
                        }
                        alt=""
                    />
                </div>
                <p>{history?.user?.user_name.length > 7 ? history?.user?.user_name.substr(0,6) + '...' : history?.user?.user_name }</p>
            </div>
            <div className="main">
                <div className={'title'}>
                    {viewData}
                </div>
                <div className="conversation">
                    <ChatBtn
                        Component={() => (
                            <div className="historyTitle">
                                <p>{history.topic.title}</p>
                            </div>
                        )}
                        userId={history.room?.user_id}
                        roomTypeId={ROOM_TYPE_CHAT_TOPIC}
                        topicId={history.topic.id}
                        topicInfo={history.topic}
                        roomInfo={{id: history.room_id}}
                        saveFlag={true}
                    />
                    {history?.topic?.is_callable == true ? (
                        <CallBtn
                            userId={history.user.id}
                            roomId={history.room_id}
                            roomTypeId={ROOM_TYPE_CALL_TOPIC}
                            topicInfo={history.topic}
                            disabled={false}
                            timeExpire={history.topic.expire_at._seconds}
                            Component={() => (
                                <img
                                    src="/images/ic_call_active.svg"
                                    alt="icon call"
                                />
                            )}
                            name = { history?.user?.user_name }
                            gender_name = {history?.user?.gender === '1' ? '男性（M）' : history?.user?.gender === '2' ? '女性（F）' : '不特定（X）'}
                            age_name = {history?.user?.age?.name}
                            area_name = { history?.user?.area?.name }
                            profile_image = {history?.user?.profile_image}
                        />
                    ) : (
                        <CallBtn
                            userId={history.user.id}
                            roomId={history.room_id}
                            roomTypeId={ROOM_TYPE_CALL_TOPIC}
                            topicInfo={history.topic}
                            disabled={true}
                            Component={() => (
                                <img
                                    src="/images/ic_call_unactive.svg"
                                    alt="icon call"
                                />
                            )}
                            name = { history?.user?.user_name }
                            gender_name = {history?.user?.gender === '1' ? '男性（M）' : history?.user?.gender === '2' ? '女性（F）' : '不特定（X）'}
                            age_name = {history?.user?.age?.name}
                            area_name = { history?.user?.area?.name }
                            profile_image = {history?.user?.profile_image}
                        />
                    )}
                </div>
                {unreadCount != 0 && (
                    <div className="unread-msg">
                        <span className="unreadCount">
                            {unreadCount < 100 ? unreadCount : '99+'}
                        </span>
                    </div>
                )}
                <div className="countdown">
                    <TimeTopic timeExpire={history.topic.expire_at._seconds} />
                </div>
            </div>
        </div>
    );
};

export default HistoryConversation;
