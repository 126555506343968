import React, { useEffect, useState }  from 'react';
import { useDispatch } from "react-redux";
import TimeTopic from '../../../chat/components/TimeTopic';
import './style/HistoryTopicStyle.scss';
import { getSubCategorySplash, getCategoriesSplash, getAreasSplash } from "../../../user/actions";
import { CATEGORY_LOCAL_STORE, SUBCATEGORY_LOCAL_STORE, AREA_LOCAL_STORE } from "../../../app/constants";
import { connect } from "react-redux";

const HistoryTopic = ({history, openReplyList, }) => { //areas, categories, subcategories
    const dispatch = useDispatch();
    const [viewCate, setViewCate] = useState('');
    const [isInit, setIsInit] = useState(true);
    let areas;
    let categories;
    let subcategories;
    try{
        areas = JSON.parse(localStorage.getItem(AREA_LOCAL_STORE));
    }catch(e){
        areas = [];
    }
    try{
        categories = JSON.parse(localStorage.getItem(CATEGORY_LOCAL_STORE));
    }catch(e){
        categories = [];
    }
    try{
        subcategories = JSON.parse(localStorage.getItem(SUBCATEGORY_LOCAL_STORE));
    }catch(e){
        subcategories = [];
    }
    const getSubcategoryName = (history) => {
        for(let i = 0; i < subcategories.length; i++) {
            if(history?.topic?.category_id == subcategories[i]?.category_id && history?.topic?.sub_category_id == subcategories[i]?.id )
                return subcategories[i]?.name;
        }
    }
    const getCategoryName = (history)=> {
        for(let i = 0; i < categories.length; i++) {
            if(history?.topic?.category_id == categories[i]?.id )
                return categories[i]?.name;
        }
    }
    const getAreaName = (history)=> {
        for(let i = 0; i < areas.length; i++) {
            if(history?.topic?.area_id == areas[i]?.id )
                return areas[i]?.name;
        }
    }
    const viewData = <>
        <span>{ getCategoryName(history) }</span>
        <img src="/images/arrow-one.png"/>
        <span>{ getSubcategoryName(history) }</span>
        <img src="/images/arrow-one.png"/>
        <span>{ getAreaName(history) }</span>
    </>

    return (
        <div
            id="history-topic"
            onClick={() => {
                openReplyList(history);
            }}>
            <div className="main">
                <div className="child-main">
                    { viewData }            
                </div>
                <div className="topic">
                    <p>{history?.topic?.title}</p>
                    <img
                        className="img-arrow"
                        src="/images/ic_arrow_right.svg"
                        alt=""
                    />
                </div>
                <div className="wrap-count-down">
                    <TimeTopic timeExpire={history?.topic.expire_at?._seconds} />
                </div>
            </div>
        </div>
    );
};

function _mapStateToProps(state) {
    return {
        areas: state['cocoro/user'].areas,
        subcategories: state['cocoro/user'].subcategories,
        categories: state['cocoro/user'].categories,
    };
}


export default connect(_mapStateToProps)(HistoryTopic);
