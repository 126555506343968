import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {CallBtn} from '../../../call';
import {ChatBtn} from '../../../chat';
import {numberFormat} from '../../../friendlist';
import {
    ROOM_TYPE_CALL_TOPIC,
    ROOM_TYPE_CHAT_TOPIC,
} from '../../../call/constants';
import TimeTopic from '../../../chat/components/TimeTopic';
import './style/DetailHistoryStyle.scss';
import {addFriendTopic} from '../../../app/actions';
import {Constants} from '../../../../util/Constants';

function DetailHistoryUser({history, userInfo}) {
    const [isAnonymous, setIsAnonymous] = useState(true);
    const [isFriend, setFriend] = useState(false);
    const dispatch = useDispatch();
    const [canAddFriend, setCanAddFriend] = useState(false);

    useEffect(() => {
        if (history.room) {
            var anonymous = Object.values(history.room.anonymous);
            setCanAddFriend(!anonymous[0] && !anonymous[1]);
        }
    }, []);

    const handleAddFriend = async () => {
        const result = await dispatch(
            addFriendTopic(userInfo.id, topic.id, history.room_id),
        );
        if (result.status) {
            setFriend(true);
        }
    };

    let topic = history.topic;

    let user_name = topic.is_anonymous ? '匿名さん' : userInfo.user_name;
    let share = !topic.is_anonymous;

    return (
        <div className="detail-user">
            <header>
                {userInfo.profile_image !== undefined &&
                userInfo.profile_image !== null ? (
                    <img src={userInfo.profile_image} alt="" />
                ) : (
                    <img src={Constants.AVATAR_DEFAULT} alt="" />
                )}
                <div>
                    <p className="name">{user_name}</p>
                    <p className="detail">
                        {userInfo.area.name + ' | '}
                        {parseInt(userInfo.gender) == 1 ? '男性（M）' : parseInt(userInfo.gender) == 2 ? '女性（F）' : '不特定（X）'}
                        {' | ' + userInfo.age.name}
                    </p>
                </div>
                {canAddFriend && !userInfo.is_my_friend && !isFriend && (
                    <div
                        className="detail-history-add-friend"
                        onClick={handleAddFriend}>
                        <img
                            className=""
                            src="/images/ic_add_friend@2x.png"
                            alt=""
                        />
                        <span>ともだち追加</span>
                    </div>
                )}
            </header>
            {((Boolean(topic?.chat_price) && Boolean(topic.chat_price != 0)) ||
                (Boolean(topic?.call_price) &&
                    Boolean(topic.call_price != 0))) && (
                <div className="detail">
                    <div>
                        <div className="detail-price">
                            <img src="/images/icon-take-charge.svg" />
                        </div>
                        <div className="detail-price-content">
                            <p>
                                {Boolean(topic?.chat_price) &&
                                    Boolean(topic.chat_price != 0) &&
                                    'チャット課金（初回のみ）'}
                                <br />
                                {Boolean(topic?.call_price) &&
                                    Boolean(topic.call_price != 0) &&
                                    '通話課金（' +
                                        topic.time_call_price +
                                        '分ごとに）'}
                            </p>
                        </div>
                        <div className="detail-pricex">
                            <p>
                                {Boolean(topic?.chat_price) &&
                                    Boolean(topic.chat_price != 0) &&
                                    numberFormat(topic.chat_price) + 'ポイント'}
                                <br />
                                {Boolean(topic?.call_price) &&
                                    Boolean(topic.call_price != 0) &&
                                    numberFormat(topic.call_price) + 'ポイント'}
                            </p>
                        </div>
                    </div>
                </div>
            )}
            <div className="comment">
                <div>
                    <span className="x">一言コメント</span>
                    {share && userInfo.instagram && (
                        <a
                            target="_blank"
                            href={
                                userInfo.instagram.includes('https://') ||
                                userInfo.instagram.includes('http://')
                                    ? userInfo.instagram
                                    : 'https://' + userInfo.instagram
                            }>
                            <img src="/images/ic_instagram@4x.png" alt="" />
                        </a>
                    )}
                    {share && userInfo.twitter && (
                        <a
                            target="_blank"
                            href={
                                userInfo.twitter.includes('https://') ||
                                userInfo.twitter.includes('http://')
                                    ? userInfo.twitter
                                    : 'https://' + userInfo.twitter
                            }>
                            <img src="/images/ic_twitter@4x.png" alt="" />
                        </a>
                    )}
                    {share && userInfo.facebook && (
                        <a
                            target="_blank"
                            href={
                                userInfo.facebook.includes('https://') ||
                                userInfo.facebook.includes('http://')
                                    ? userInfo.facebook
                                    : 'https://' + userInfo.facebook
                            }>
                            <img src="/images/ic_facebook@4x.png" alt="" />
                        </a>
                    )}
                </div>
                <p className="commenttext">{userInfo.comment}</p>
            </div>
            <div className="comment">
                <div>
                    <span className="x">投稿内容</span>
                    <TimeTopic timeExpire={topic.expire_at._seconds} />
                </div>
                <div className="detailTopic">
                    <p className="titletext">{topic.title}</p>
                    <p className="detailtext">{topic.detail}</p>
                </div>
            </div>
            <div className="history-call">
                <div>
                    <ChatBtn
                        userId={userInfo.id}
                        roomTypeId={ROOM_TYPE_CHAT_TOPIC}
                        topicId={topic.id}
                        topicInfo={topic}
                        isAnonymous={isAnonymous}
                        timeExpire={topic.expire_at._seconds}
                    />
                </div>
                <div>
                    {topic.is_callable ? (
                        <CallBtn
                            userId={history.user.id}
                            roomId={history.room_id}
                            roomTypeId={ROOM_TYPE_CALL_TOPIC}
                            topicInfo={topic}
                            timeExpire={topic.expire_at._seconds}
                        />
                    ) : (
                        <CallBtn
                            userId={history.user.id}
                            roomId={history.room_id}
                            roomTypeId={ROOM_TYPE_CALL_TOPIC}
                            topicInfo={topic}
                            Component={() => (
                                <img
                                    src="/images/ic_call_missing@4x.png"
                                    alt=""
                                />
                            )}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default DetailHistoryUser;
