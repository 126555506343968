import ReducerRegistry from '../base/redux/ReducerRegistry';
import {
    ADD_MSGS,
    UPDATE_STATUS,
    UPDATE_DATA,
    UPDATE_ROOM_INFO,
    CLEAR_ROOM_INFO,
    UPDATE_MESSAGE_IS_READ,
    REMOVE_TMP_MSG,
} from './actionTypes';
import {messageStatusTexts} from './constants';

const initialState = {
    messages: [],
    roomInfo: {},
    isShowPresentScreen: false,
};

ReducerRegistry.register('cocoro/chat', (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DATA:
            return {
                ...state,
                messages: state.messages.map((message) =>
                    message.id == action.messageId
                        ? {...message, data: action.data, size: action.size}
                        : message,
                ),
            };
        case UPDATE_STATUS:
            return {
                ...state,
                messages: state.messages.map((message) =>
                    message.id == action.messageId
                        ? {
                              ...message,
                              status:
                                  message.status === messageStatusTexts.READ // keep READ is the latest status
                                      ? message.status
                                      : action.status,
                              is_read:
                                  message.status === messageStatusTexts.READ,
                          }
                        : message,
                ),
            };
        case UPDATE_MESSAGE_IS_READ:
            return {
                ...state,
                messages: state.messages.map((message) =>
                    message.id == action.messageId
                        ? {
                              ...message,
                              is_read: true,
                          }
                        : message,
                ),
            };
        case UPDATE_ROOM_INFO:
            return {
                ...state,
                roomInfo: {...state.roomInfo, ...action.roomInfo},
            };
        case CLEAR_ROOM_INFO:
            return {
                ...state,
                roomInfo: {},
            };
        case ADD_MSGS:
            return {
                ...state,
                messages: [...state.messages, ...action.msgs],
            };
        case 'ADD_MSG':
            const index = state.messages.findIndex(
                (m) => m.id === action.msg.id,
            );
            if (index === -1) {
                return {
                    ...state,
                    messages: [...state.messages, action.msg],
                };
            } else {
                return state;
            }

        case 'INIT_MSG':
            const msgs = {};
            state.messages.forEach((m) => {
                msgs[m.id] = m;
            });
            action.msgs.forEach((m) => {
                msgs[m.id] = m;
            });
            return {
                ...state,
                messages: [...Object.values(msgs)],
            };
        case 'CLEAR_OLD_MSG':
            return {
                ...state,
                messages: [],
            };
        case REMOVE_TMP_MSG:
            const tpmIndex = state.messages.findIndex(
                (m) => m.id === action.msg.id,
            );
            const data = state.messages;
            data.splice(tpmIndex, 1);
            return {
                ...state,
                messages: data,
            };
        case 'OPEN_PRESENT':
            return { ...state, isShowPresentScreen: action.payload }
        default:
            return state;
    }
});
