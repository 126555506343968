import React from 'react';

function Popup({closePopup, data}) {
    return (
        <div id="page-popup">
            <div className="page-popup-content">
                <p dangerouslySetInnerHTML={{__html: data}} />
                <button onClick={closePopup}>OK</button>
            </div>
        </div>
    );
}

export default Popup;
