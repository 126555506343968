import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {updateSmallStatus} from '../actions';
import {formatPrice} from '../../chat/functions';
import { openPopup, closePopup } from "../../app/actions";
import {useEffect} from 'react';
const ringBack = new Audio('/audio/incallmanager_ringback.mp3');
const CallerSmall = ({balance, callState}) => {
    const dispatch = useDispatch();

    const handleUpdateSmallStaus = () => {
        dispatch(updateSmallStatus(false));
    };

    useEffect(() => {
        if (callState === 'calling') {
            // ringBack.load();
            // ringBack.play();
        }
        return () => {
            ringBack.pause();
        };
    }, [callState]);

    return (
        <div onClick={handleUpdateSmallStaus} id="callerSmallWrap">
            <p>
                タップするとトーク画面へ戻る
                {balance != 0 && '・' + formatPrice(balance) + 'ポイント'}
            </p>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    balance: state['cocoro/call'].balance,
    callState: state['cocoro/call'].callState,
});

export default connect(_mapStateToProps)(CallerSmall);
