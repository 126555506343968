import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import '../styles.scss';
import {changeRoute, setNaviIndex} from '../../app/actions';
import {getMyInfo, getApproval, selfDeleteUser} from '../actions';
import {Link} from 'react-router-dom';
import styles from './styles';
import BudgetContainer from "./BudgetContainer.web";
import RankContainer from "./RankContainer.web";
import UnrankContainer from "./UnrankContainer.web";

const MyPage = ({userInfo,history, getMyInfo, getApproval, setNaviIndex, selfDeleteUser}) => {
    useEffect(() => {
        setNaviIndex('profile');
        // getMyInfo();
    }, [setNaviIndex]);

    let userStorage = localStorage.getItem('user_info');
    try{
        userStorage = JSON.parse(userStorage);
    }catch(e){
        userStorage = {};
    }
    let userCoin = userStorage.userCoin;
    if(userCoin == null || userCoin == undefined)
        userCoin = 0;

    let userCharacterInputWord = userStorage.userCharacterInputWord;
    if(userCharacterInputWord == null || userCharacterInputWord == undefined)
        userCharacterInputWord = '';

    let userCharacterInputDetail = userStorage.userCharacterInputDetail;
    if(userCharacterInputDetail == null || userCharacterInputDetail == undefined)
        userCharacterInputDetail = '';

    const gotoSelectImage = () => {
        window.location.href = "/changeavatar";
    }

    const registerExperts = () => {
        getApproval();
    };

    const getRating = () => {
        let rateTotal = userInfo.rate_total;
        let ratePoint = userInfo.rate_point ? userInfo.rate_point : 0;
        if (!rateTotal || rateTotal === 0) {
            rateTotal = 1;
        }
        return ratePoint / rateTotal;
    };


    return (
        <div id="poingpage">
            <header>
                <div className="back">
                    <img
                        alt=""
                        onClick={() => history.goBack()}
                        src="../../images/icon-back-grey.png"
                    />
                </div>
                <h3>マイページ</h3>
            </header>

            {userInfo && (
                <div className="mainContainer">
                    <BudgetContainer
                        budget={userCoin}
                    />
                    <button>ポイント 履歴</button>
                    <button>ポイント 払い戻し</button>
                    <div className={'row'}>
                        <div>
                            <img alt="" src={require('../../../images/point_target.png')} style={{width: 60, height: 60}}/>
                            <div>ディリール</div>
                            <div>ーレット</div>
                        </div>
                        <div>
                            <img alt="" src={require('../../../images/point_bag.png')} style={{width: 60, height: 60}}/>
                            <div>ディイリ</div>
                            <div>ミッシ引ン</div>
                        </div>
                        <div>
                            <img alt="" src={require('../../../images/point_box.png')} style={{width: 60, height: 60}}/>
                            <div>沖間ミッ</div>
                            <div>ション</div>
                        </div>
                    </div>
                    {/*{userInfo.rank !== undefined && userInfo.rank !== null ? (*/}
                    {/*    <RankContainer*/}
                    {/*        rank={userInfo.rank}*/}
                    {/*        userRating={getRating()}*/}
                    {/*    />*/}
                    {/*) : (*/}
                    {/*    userInfo.id && (*/}
                    {/*        <UnrankContainer*/}
                    {/*            request={registerExperts}*/}
                    {/*            isRegisterExpert={*/}
                    {/*                userInfo?.is_approval == undefined*/}
                    {/*                        ? true*/}
                    {/*                    : userInfo?.is_approval*/}
                    {/*            }*/}
                    {/*        />*/}
                    {/*    )*/}
                    {/*)}*/}
                </div>
            )}
        </div>
    );
};

function _mapStateToProps(state) {
    return {
        userInfo: state['cocoro/mypage'].userInfo,
        loading: state['cocoro/app'].loading,
        lastRoute: state['cocoro/app'].lastRoute,
    };
}

let _mapDispatchToProps = {
    getMyInfo,
    changeRoute,
    setNaviIndex,
    getApproval,
    selfDeleteUser,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(MyPage);
