import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import '../stylesList.scss';
import {setNaviIndex} from '../../app/actions';
import {saveFilter} from '../action';

const ThreadMyCreate = ({thread, id}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleChooseThread = async () => {
        const result = await dispatch(
            saveFilter({
                latest_joined_thread: thread.id,
            }),
        );
        if (result) {
            // dispatch(setNaviIndex('home'));
            // history.push('/');
            dispatch(setNaviIndex('search'));
            history.push('/search');
        }
    };

    return (
        <div className="item-my-create" onClick={handleChooseThread}>
            <span className="item-name">{thread.name}</span>
            <div className="item-count">
                <img
                    className="img-icon-chat"
                    src="/images/icon-count-chat.svg"
                />
                <span className="item-call"> {thread.topic.total}</span>
                <img
                    className="img-icon-call"
                    src="/images/icon-count-call.svg"
                />
                <span className="item-chat">{thread.topic.total_callable}</span>
            </div>
        </div>
    );
};

export default ThreadMyCreate;
