import React from 'react';
import './style/ReplyListItemStyle.scss';
import {Constants} from '../../../../util/Constants';
import {ChatBtn} from '../../../chat';
import {
    ROOM_TYPE_CHAT_TOPIC,
    ROOM_TYPE_CALL_TOPIC,
} from '../../../call/constants';
import {CallBtn} from '../../../call';
import {mergeLine} from '../../redux/action';

function ReplyListItem({
    reply,
    userId,
    message,
    participants,
    unreadCount,
    isCallable,
    openDetailUser,
}) {
    const getMessage = () => {
        const userName = reply.is_anonymous ? '匿名' : reply.user?.user_name;
        let content = '';
        switch (message?.message_type_id) {
            case Constants.MESSAGE_TYPE_ID_TEXT:
                const prexFix = message?.user_id === userId ? 'あなた：' : '';
                content = prexFix + mergeLine(message?.data).trim();
                break;
            case Constants.MESSAGE_TYPE_ID_IMAGE:
            case Constants.MESSAGE_TYPE_ID_BASE64:
                content =
                    message?.user_id === userId
                        ? 'あなたが画像を送信しました。'
                        : userName + 'さんが画像を送信しました。';
                break;
            case Constants.MESSAGE_TYPE_ID_EMOJI:
                content =
                    message?.user_id === userId
                        ? 'あなたがスタンプを送信しました。'
                        : userName + 'さんがスタンプを送信しました。';
                break;
            case Constants.MESSAGE_TYPE_ID_CALL_SUCCESS:
                content = userName + 'さんと通話がありました。';
                break;
            case Constants.MESSAGE_TYPE_ID_MISSED_CALL:
            case Constants.MESSAGE_TYPE_ID_SYSTEM_CALL_CANCEL:
            case Constants.MESSAGE_TYPE_ID_SYSTEM_CALL_MISS:
                content =
                    message?.user_id === userId
                        ? userName + 'さんが通話に応答しませんでした。'
                        : userName + 'さんからの不在着信がありました。';
                break;
            default:
                break;
        }
        content = (content.search("###sound") === -1 ? content : '音声メッセージ') 
        return content;
    };

    let chatComponent = (room) => () => {
        return (
            <div className="main">
                <div className="user-name">
                    {room.is_anonymous ? room.user?.user_name : room.user?.user_name}
                </div>
                {unreadCount != undefined && unreadCount !== 0 && (
                    <div className="unreadBadge">
                        <span className="unreadCount">
                            {unreadCount < 100 ? unreadCount : '99+'}
                        </span>
                    </div>
                )}
                <div className={message.is_read ? 'message' : 'unread-message'}>
                    {getMessage()}
                </div>
            </div>
        );
    };

    return (
        <div id="reply-list-item">
            <div
                id="avata"
                onClick={() =>
                    openDetailUser(
                        reply.user_id,
                        reply.is_anonymous,
                        reply.topic_id,
                        reply.id,
                        reply.anonymous,
                    )
                }>
                <img
                    alt="avata"
                    src={
                        reply.user?.profile_image
                            ? reply.user?.profile_image
                            : Constants.AVATAR_DEFAULT
                    }
                />
            </div>
           
            <ChatBtn
                
                userId={reply.user_id}
                Component={chatComponent(reply)}
                roomTypeId={ROOM_TYPE_CHAT_TOPIC}
                topicId={reply.topic_id}
                roomInfo={reply}
                participants={reply.user_id}
            />
            {isCallable && (
                <CallBtn
                    userId={reply.user_id}
                    roomId={reply.id}
                    roomTypeId={ROOM_TYPE_CALL_TOPIC}
                    topicInfo={reply.topic}
                    disabled={false}
                    isAnonymous={reply.is_anonymous || false}
                    Component={() => <i className={'fa fa-phone callable'} />}
                />
            )}
        </div>
    );
}

export default ReplyListItem;
