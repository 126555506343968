import {setLoading, openPopup, openActionPopup, openModalPage} from '../app/actions';
import {SET_MY_INFO, SET_MY_USERS_INFO, CLEAR_INFO, CLEAR_USERS_INFO, UPDATE_APPROVAL} from './actionTypes';
import {getMyProfile, getMyUsers, updateSNSApi, getApprovalApi, selfDeleteUserApi} from './apis';
import {CHECK_AUTH} from '../app/actionTypes';
import { MODAL_CHOOSE_USER } from "../app/constants";
import {updateMyInfo} from '../user';
import { logout,login } from "../user/actions";

export function setMyInfo(info) {
    return {
        type: SET_MY_INFO,
        info,
    };
}
export function setMyUsersInfo(info) {
    return {
        type: SET_MY_USERS_INFO,
        info,
    };
}

export function clearInfo() {
    return function (dispatch) {
        dispatch({type: CLEAR_INFO});
    };
}
export function clearUsersInfo() {
    return function (dispatch) {
        dispatch({type: CLEAR_USERS_INFO});
    };
}
export function openUserChanges(currentUser) {
    let id = currentUser.id;
    return function (dispatch, getState) {

        let {myUsers} = getState()['cocoro/mypage'];
        dispatch(openModalPage({type: MODAL_CHOOSE_USER, myUsers, id}));
    };
}

export function updateApproval() {
    return function (dispatch) {
        dispatch({type: UPDATE_APPROVAL});
    };
}

export function getMyInfo() {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {userInfo} = getState()['cocoro/user'];
        getMyProfile(userInfo.access_token).then((resolve) => {
            dispatch(setLoading(false));
            try {
                if (resolve.status === 200 || resolve.status === 201) {
                    dispatch(setMyInfo(resolve.data));
                    dispatch(updateMyInfo(resolve.data));
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                    return;
                }
            } catch (error) {
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}
export function getMyUsersInfo() {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {userInfo} = getState()['cocoro/user'];
        dispatch(clearUsersInfo());
        if(userInfo.email_id === undefined || userInfo.email_id === "")
        {
            dispatch(setLoading(false))
            return;
        }
        getMyUsers(userInfo.access_token, userInfo.email_id).then((resolve) => {
            dispatch(setLoading(false));
            try {
                if (resolve.status === 200 || resolve.status === 201) {
                    dispatch(setMyUsersInfo(resolve.data));
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                    return;
                }
            } catch (error) {
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}

export function updateSNSInfo(
    facebook,
    instagram,
    twitter,
    setFacebookError,
    setInstagramError,
    setTwitterError,
) {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        updateSNSApi(access_token, facebook, instagram, twitter).then(
            (resolve) => {
                dispatch(setLoading(false));
                try {
                    dispatch({type: CHECK_AUTH, status: resolve.status});
                    if (resolve.status === 200 || resolve.status === 201) {
                        dispatch(openPopup('SNS情報を保存しました。'));
                    } else if (resolve.status == 403) {
                        let errors = resolve.data.errors;
                        setFacebookError(
                            errors.facebook ? errors.facebook : '',
                        );
                        setInstagramError(
                            errors.instagram ? errors.instagram : '',
                        );
                        setTwitterError(errors.twitter ? errors.twitter : '');
                    } else {
                        dispatch(openPopup('想定しないエラーが発生しました。'));
                        return;
                    }
                } catch (error) {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            },
        );
    };
}

export function getApproval() {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        getApprovalApi(access_token).then((resolve) => {
            dispatch(setLoading(false));
            try {
                dispatch({type: CHECK_AUTH, status: resolve.status});
                if (resolve.status === 200 || resolve.status === 201) {
                    dispatch(updateApproval());
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                    return;
                }
            } catch (error) {
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}

export function selfDeleteUser() {
    return function (dispatch, getState) {

        // dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        let {email} = getState()['cocoro/user'].userInfo;
        let {email_id} = getState()['cocoro/user'].userInfo;
        let {myUsers} = getState()['cocoro/mypage'];
        if(myUsers.length>1)
        {
            if(email === email_id)
            {
                dispatch(openPopup(myUsers.length-1 + 'サブキャラクターが存在するため、削除できません。'));
                return;
            }
        }
        selfDeleteUserApi(access_token).then((resolve) => {
            dispatch(setLoading(false));
            dispatch(logout(true));
            // window.location.href = '/splash';
        }).catch((error) =>{
            // dispatch(openPopup('想定しないエラーが発生しました。'));
        })
    };
}

export function switchUser(myUsers) {
    return function (dispatch, getState) {
        dispatch(logout(true, false));
        const result = dispatch(login(myUsers.value, myUsers.nick));
        dispatch(setLoading(true));
        setTimeout(async()=>{
            window.location.href='/';
        },3000)
        
        //     const result = login('test1@gmail.com', 'qwer1234');
            // if (result?.error && result?.message) {
            //     // setErr(result.message);
            // }
            // if (result?.access_token) {
            //     // if (result.is_infomation === false) {
            //     //     history.push('/setting-information');
            //     // } else {
            //     //     history.push('/');
            //     // }
            // }
        // }, 0)
    };
}