/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useNetworkStatus} from '../../../util/hooks';
import clock from '../../base/clock';
import {ROOM_TYPE_CALL_TOPIC, ROOM_TYPE_CHAT_TOPIC, callStates} from '../../call/constants';
import TimeTopic from '../../chat/components/TimeTopic';
import {Constants} from '../../../util/Constants';
import {closeModalPage, openModalPage, openPopup} from '../../app/actions';
import {MODAL_CONFIRM_CALL} from '../../app/constants';
import {requestCall, saveRecordAudio} from '../../call/actions';
import "../detail-user-home.scss";
import { AUDIO_PRESENT_URL } from "../../app/constants";
import { saveAudioPresent } from "../../app/actions";

import {Recorder} from '../../react-voice-recorder'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';

import { SOUND_RECORD_DATA } from "../../app/constants";

const DetailUserHome = ({data, userInfo, audioPresent}) => {
    const dispatch = useDispatch();
    var timer;
    var audio = new Audio();
    audio.pause();
    audio.loop = false;

    let timeExpire = data?.data?.timeExpire;
    let remainTime = timeExpire ? timeExpire - clock.seconds() : null;
    if(timeExpire === null)
        timeExpire = 0;
    let callState = data?.data?.callState;
    let topicInfo = data?.data?.topicInfo;
    let roomTypeId = data?.data?.roomTypeId;
    let userId = data?.data?.userId;
    let roomId = data?.data?.roomId;


    const networkStatus = useNetworkStatus();

    let [isAnonymous, setIsAnonymous] = useState(true);
    const [ canAddFriend,setCanAddFriend] = useState(false);
    const [ flagStopPlay, setFlagStopPlay] = useState(true);
    // const [ showCallBtn, setShowCallBtn ] = useState(!(localStorage.getItem(SOUND_RECORD_DATA) === '' || localStorage.getItem(SOUND_RECORD_DATA) === null)); 
    const [ showCallBtn, setShowCallBtn ] = useState(false); 
    const [ playTime, setPlayTime ] = useState("00");

    useEffect(() => {
        if (data?.room) {
            var anonymous = Object.values(data?.room?.anonymous);
            setCanAddFriend(!anonymous[0] && !anonymous[1]);
        } else {
            setCanAddFriend(!data.is_anonymous);
        }
    }, []);

    useEffect(() => {
        if (data.room) {
            setIsAnonymous(data?.room?.is_anonymous);
        }
    }, []);

    const [audioData, setAudioData] = useState(null);

   const handleAudioUpload = (data)=>{
        var audio = new Audio();
        audio.pause();
        audio.src = URL.createObjectURL(data);
        audio.play();
    }
    let tempTime = 0;
    const countDown = (data)=>{
        tempTime ++;
        setPlayTime("" + (tempTime <= 9 ? "0" + tempTime : tempTime));
    }

    const handleCall = async (data) => {
        if(!flagStopPlay){
            return ;
        }
        if(data === null && (localStorage.getItem(SOUND_RECORD_DATA) === null || localStorage.getItem(SOUND_RECORD_DATA) === '')){
            await dispatch(openPopup('録画を終了しなければします。'));
            return false;
        }
        if(localStorage.getItem(SOUND_RECORD_DATA) === null || localStorage.getItem(SOUND_RECORD_DATA) === ''){
            const reader = new FileReader('');
            reader.onload = function(){
                var base64Data = reader.result.split(',')[1];
                localStorage.setItem(SOUND_RECORD_DATA, 'data:audio/x-wav;base64,' + base64Data);
                dispatch(saveRecordAudio(userInfo.id, 'data:audio/x-wav;base64,' + base64Data));
            }
            reader.readAsDataURL(data);
        }
        if (!networkStatus) {
            dispatch(openPopup('ネットワークから切断されました。'));
            return;
        }
        if (remainTime !== null && remainTime <= 0) {
            dispatch(
                openPopup(
                    '該当する声がありません。既に削除されている可能性があります。',
                ),
            );
            return;
        }

        if (callState == callStates.NONE) {
            const now = clock.now();
            if (topicInfo?.id) {
                if (topicInfo.call_price > 0) {
                    if (
                        roomTypeId == ROOM_TYPE_CALL_TOPIC &&
                        now > topicInfo.expire_at * 1000
                    ) {
                        dispatch(
                            openPopup(
                                '該当する声がありません。既に削除されている可能性があります。',
                            ),
                        );
                    } else {
                        if (userId === topicInfo.user_id) {
                            dispatch(
                                openModalPage({
                                    type: MODAL_CONFIRM_CALL,
                                    topicInfo: topicInfo,
                                    userId,
                                    roomTypeId,
                                    isAnonymous,
                                    roomId,
                                }),
                            );
                        } else {
                            dispatch(closeModalPage());
                            dispatch(
                                requestCall(
                                    userId,
                                    roomId,
                                    roomTypeId,
                                    topicInfo.id,
                                    isAnonymous,
                                ),
                            );
                        }
                    }
                } else {
                    dispatch(closeModalPage());
                    dispatch(
                        requestCall(
                            userId,
                            roomId,
                            roomTypeId,
                            topicInfo.id,
                            isAnonymous,
                        ),
                    );
                }
            } else {
                dispatch(closeModalPage());
                dispatch(
                    requestCall(userId, roomId, roomTypeId, null, isAnonymous),
                );
            }
        } else {
            dispatch(openPopup('トークできませんでした。すでにトーク中です。'));
        }
    };

    return (
        <div className="detail-user" style={{paddingTop:10}}>
            <modalheader>
                <img src={data?.data?.profile_image} alt="" />
                <div>
                    <p className="name">{data?.data?.name}</p>
                    <p className="detail">
                        {data?.data?.area_name}
                        {data?.data?.gender_name ? (' | ' + data?.data?.gender_name) : ''}
                        {data?.data?.age_name ? (' | ' + data?.data?.age_name) : ''}
                    </p>
                </div>
            </modalheader>
         
            {<Recorder
                record={true}
                flagStopPlay = {flagStopPlay}
                title={"あなたが録音したあいさつを聞いてもらいOKならばすぐに電話がかかってきます。"}
                audioURL={'voice'}
                showUIAudio = {false}
                handleAudioStop={data => {
                    try {
                        setAudioData(data);
                        setShowCallBtn(true);
                        const reader = new FileReader('');
                        reader.onload = function(){
                            var base64Data = reader.result.split(',')[1];
                            localStorage.setItem(SOUND_RECORD_DATA, 'data:audio/x-wav;base64,' + base64Data);
                            dispatch(saveRecordAudio(userInfo.id, 'data:audio/x-wav;base64,' + base64Data));
                        }
                        reader.readAsDataURL(data.blob);

                    }catch(e){

                    }
                }}
                handleAudioUpload={data => handleCall(data)}
                handleReset={() => {
                    setShowCallBtn(false);
                    if(!flagStopPlay)
                        return ;
                    localStorage.setItem(SOUND_RECORD_DATA, '');
                    dispatch(saveRecordAudio(userInfo.id, ''));
                }}
                mimeTypeToUseWhenRecording={`audio/webm`} // For specific mimetype.
            />}
            {/* { (audioPresent?.size > 0 || audioPresent?.blob?.size > 0)?  */}
            { showCallBtn && flagStopPlay? 
                <button className={'audio-play-button'} onClick = { ()=>{
                    if(flagStopPlay){
                        if(localStorage.getItem(SOUND_RECORD_DATA) === null || localStorage.getItem(SOUND_RECORD_DATA) === '' )
                            return null;
                        setFlagStopPlay(false);
                        var audio = new Audio(localStorage.getItem(SOUND_RECORD_DATA));
                        audio.pause();
                        audio.loop = false;
                        audio.play();
                        timer = setInterval(countDown, 1000);
                        audio.addEventListener("ended", function() {
                            audio.src = null;
                            setFlagStopPlay(true);
                            clearInterval(timer);
                            tempTime=0;
                        });
                    }
                    }}><span>再生</span>
                </button>
                : null
            }
            {/* <h2 className="max-time">(最大20秒)</h2>
            
            <div className="hint-text">録音が終了したら、「送信」を押して、相手にメッセージを送ってください</div>
            */}
            {
                flagStopPlay===false ?
                <div className="alter-record">
                    <img src={require('../../../images/microphone1.png')}/>
                    <div>
                        <div style={{fontSize:"30px", width:"200px"}}>
                            再生中です
                        </div>
                        <div style={{display:"flex", marginLeft:"40px"}}>
                            <div style={{fontSize:"60px"}}>
                                {playTime}
                            </div>
                            <div style={{fontSize:"30px", marginTop:"20px"}}>
                                秒
                            </div>
                        </div>
                    </div>
                </div> : null}
            {/* {isRecorded === false  && recordStatus === false ? <div className="record-text1">録音開始1</div> : null} */}
            {/* {isRecorded === false  && recordStatus === false ? <div className="record-text">録音開始</div> : null} */}
        </div>
    );
};

const _mapStateToProps = (state) => ({
    userInfo: state['cocoro/user'].userInfo,
    audioPresent: state['cocoro/app'].audioPresent,
});

export default connect(_mapStateToProps)(DetailUserHome);
