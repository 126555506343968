import React, {useState, useEffect} from 'react';
import '../styles.scss';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {getMySettings, updateSettings, openTimeSettings} from '../actions';
import {logout} from '../../user/actions';
import {changeRoute} from '../../app';
import ArrowSetting from './ArrowSetting.web';
import SwitchSetting from './SwitchSetting.web';
import ButtonAction from './ButtonAction.web';
import SpinnerSetting from './SpinnerSetting.web';
import { SETTING_CALL_VOICE } from "../../app/constants";

function Settings({
    loading,
    timeSetting,
    callRingtone,
    messageRingtone,
    getMySettings,
    updateSettings,
    openTimeSettings,
    logout,
    changeRoute,
    lastRoute,
}) {
    let history = useHistory();

    let actionLogOut = () => {
        logout(true);
    };

    let [checkVoiceState, setCheckVoiceState] = useState(localStorage.getItem(SETTING_CALL_VOICE) === 'true' ? true : false);
    let [checkMessageState, setCheckMessageState] = useState(true);

    useEffect(() => {
        if (!loading) {
            getMySettings();
        }
    }, [getMySettings]);

    let openTimeSetting = () => {
        openTimeSettings(timeSetting);
    };

    const checkClickVoice = async () =>{
        await setCheckVoiceState(!checkVoiceState);
        await updateSettings(
            callRingtone,
            messageRingtone,
            timeSetting.value,
            checkMessageState,
        ).then((result) => {
            if (result) {
                localStorage.setItem(SETTING_CALL_VOICE, !checkVoiceState);
            }
        });
    }

    const checkClickMessage = async () =>{
        await setCheckMessageState(!checkMessageState);
    }

    return (
        <div id="settings">
            <header>
                <div className="back">
                    <img
                        alt=""
                        onClick={() => history.goBack()}
                        src="/images/icon-back-grey.png"
                    />
                </div>
                <h3>設定</h3>
                <div className="right" />
            </header>
            <div className="mainContainer">
                <div className="settingContainer">
                    <ArrowSetting
                        title={'プロフィール'}
                        link={'/changeprofile'}
                    />
                </div>
                <div className="settingContainer">
                    <ArrowSetting
                        title={'パスワード変更'}
                        link={'changepassword'}
                    />
                </div>
                <div className="settingContainer">
                    <ArrowSetting title={'SNS情報の設定'} link={'/sns_info'} />
                </div>
                <div className="settingContainer">
                    <SwitchSetting title={'メッセージ着信音'} checked={checkMessageState} callBack={ checkClickMessage }/>
                </div>
                <div className="settingContainer">
                    <SwitchSetting title={'あいさつ電話設定'} checked={checkVoiceState} callBack={ checkClickVoice }/>
                </div>
                <div className="settingContainer">
                    <SpinnerSetting
                        title={'チャット最大保存時間'}
                        currentValue={timeSetting}
                        action={openTimeSetting}
                    />
                </div>
                <div className="settingContainer">
                    <ArrowSetting
                        title={'ブロックリストの編集'}
                        link={'blocklist'}
                    />
                </div>
                <div className="settingContainer">
                    <ArrowSetting title={'利用規約'} link={'term_of_use'} />
                </div>
                <div className="settingContainer">
                    <ButtonAction title={'ログアウト'} action={actionLogOut} />
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        timeSetting: state['cocoro/settings'].timeSetting,
        callRingtone: state['cocoro/settings'].callRingtone,
        messageRingtone: state['cocoro/settings'].messageRingtone,
        lastRoute: state['cocoro/app'].lastRoute,
        loading: state['cocoro/app'].loading,
    };
}

let _mapDispatchToProps = {
    changeRoute,
    getMySettings,
    updateSettings,
    openTimeSettings,
    logout,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(Settings);
