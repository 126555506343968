import React, {useState, useEffect, useMemo, useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import '../friendcallstyles.scss';
import {useHistory} from 'react-router-dom';
import {Modal, openModalPage} from '../../app';
import {setNaviIndex} from '../../app/actions';
import {ChatBtn} from '../../chat';
import {CallBtn} from '../../call';
import {
    ROOM_TYPE_CALL_FRIEND,
    ROOM_TYPE_CHAT_FRIEND,
} from '../../call/constants';
import {DEFAULT_LATEST_ROOM_DATA} from '../constants';
import {formatMessage} from '../functions';
import {
    getFriends,
    blockFriend,
    deleteFriend,
    getFriendInfo,
    detailFriend,
} from '../actions';
import {Constants} from '../../../util/Constants';
import {MODAL_DETAIL_FRIEND} from '../../app/constants';
import HistoryListHeader from "./HistoryListHeader.web";
import { timeStamp2Date, getMonthDay } from "../../chat/functions";

function FriendList({friends, latestRoomsData, userInfo}) {
    const dispatch = useDispatch();
    const ids = [];
    const [isLoadMore, setLoadMore] = useState(false);
    const [isShowDeleteDialog, showDeleteDialog] = useState(false);
    const [isShowBlockDialog, showBlockDialog] = useState(false);
    const [selectedId] = useState('');
    const history = useHistory();
    const canLoadMore = useRef(true);
    const [lastDocId, setLastDocId] = useState(null);
    const isLoading = useRef(false);

    const handleScroll = (event) => {
        const scrollToBottom =
            event.target.scrollHeight - event.target.scrollTop <=
            event.target.clientHeight;
        if (scrollToBottom && !isLoading.current) {
            onLoadMore();
        }
    };

    const onLoadMore = async () => {
        if (canLoadMore.current) {
            setLoadMore(true);
            isLoading.current = true;
            const result = await dispatch(getFriends(lastDocId, false));
            setLoadMore(false);
            if (result?.last_doc_id) {
                setLastDocId(result?.last_doc_id);
            }
            if (result?.data.length === 0) {
                canLoadMore.current = false;
            }
            isLoading.current = false;
        }
    };

    const blockFriendFunc = (friendId) => {
        dispatch(blockFriend(friendId));
    };

    const deleteFriendFunc = (friendId) => {
        dispatch(deleteFriend(friendId));
    };

    useEffect(() => {
        dispatch(setNaviIndex('friend_list_call'));
    }, []);

    useEffect(() => {
        const initFriendList = async () => {
            isLoading.current = true;
            const result = await dispatch(getFriends(lastDocId));
            if (result?.last_doc_id) {
                setLastDocId(result?.last_doc_id);
            }
            isLoading.current = false;
            canLoadMore.current = true;
        };
        initFriendList();
    }, []);

    const showUserDetailModal = async (friend) => {
        const result = await dispatch(getFriendInfo(friend.user.id));
        if (result) {
            dispatch(detailFriend(result, friend.topic));
            dispatch(
                openModalPage({
                    type: MODAL_DETAIL_FRIEND,
                    ...result,
                    topic: friend.topic,
                    isAnonymous: friend.isAnonymous,
                    topicId: friend.room.topic_id,
                    room: friend.room
                }),
            );
        }
    };

    const dataList = useMemo(() => {
        const _friends = friends.map((f) => {
            let isAnonymous = f?.room?.anonymous?.[f?.user?.id] || false;
            return {...f, isAnonymous};
        });

        // sort by latest message create at
        return _friends.sort(function (a, b) {
            const firstCreatedTime =
                latestRoomsData?.[a.room.id]?.latestMessage?.created_at || 0;
            const secondCreatedTime =
                latestRoomsData?.[b.room.id]?.latestMessage?.created_at || 0;
            return secondCreatedTime - firstCreatedTime;
        });
    }, [friends, latestRoomsData]);

    const chatComponent = (friend) => () => {
        const roomData = latestRoomsData?.[friend.room.id]
            ? latestRoomsData[friend.room.id]
            : DEFAULT_LATEST_ROOM_DATA;
        if (!roomData.latestMessage) {
            roomData = DEFAULT_LATEST_ROOM_DATA;
        }
        let latestMessage = roomData.latestMessage;
        let unreadCount = roomData.unreadCount;
        let displayMessage = formatMessage(
            latestMessage,
            userInfo.id,
            userInfo.user_name,
            friend?.user?.user_name || '',
            friend.isAnonymous,
        );
        return (
            <div className="friend_chat_content">
                {unreadCount !== undefined && unreadCount !== 0 && (
                    <div className="unreadBadge">
                        <span className="unreadCount">
                            {unreadCount < 100 ? unreadCount : '99+'}
                        </span>
                    </div>
                )}
                {latestMessage.is_read ? (
                    <p className={'read_message'}>{displayMessage != '' ? (displayMessage.search("###sound") === -1 ? displayMessage : '音声メッセージ') : 'メ ッ セ ー ジ は あ り ま せ ん'}</p>
                ) : (
                    <p className={'unread_message'}>{displayMessage != '' ? (displayMessage.search("###sound") === -1 ? displayMessage : '音声メッセージ')  : 'メ ッ セ ー ジ は あ り ま せ ん'}</p>
                )}
                {/* {displayMessage != '' ? <TimeTopic timeExpire={latestMessage.created_at} /> : null} */}
                {displayMessage != '' ? <p className={'unread_message'}> {getMonthDay(latestMessage.created_at).substr(0, getMonthDay(latestMessage.created_at).length-3)+' '+timeStamp2Date(latestMessage.created_at)} </p> : null}
            </div>
        );
    };

    const blockDialog = () => {
        return (
            <div id="confirmRemoveDialog">
                <span className="confirmTitle">
                    このともだち、本当にブロックしますか。
                </span>
                <div className="confirmContainer">
                    <div
                        className="buttonOk"
                        onClick={() => {
                            showBlockDialog(false);
                            blockFriendFunc(selectedId);
                        }}>
                        <span>はい</span>
                    </div>
                    <div
                        className="buttonCancel"
                        onClick={() => showBlockDialog(false)}>
                        <span>いいえ</span>
                    </div>
                </div>
            </div>
        );
    };

    const deleteDialog = () => {
        return (
            <div id="confirmRemoveDialog">
                <span className="confirmTitle">
                    このともだち、本当に削除しますか。
                </span>
                <div className="confirmContainer">
                    <div
                        className="buttonOk"
                        onClick={() => {
                            showDeleteDialog(false);
                            deleteFriendFunc(selectedId);
                        }}>
                        <span>はい</span>
                    </div>
                    <div
                        className="buttonCancel"
                        onClick={() => showDeleteDialog(false)}>
                        <span>いいえ</span>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div id="friendlist-call">
            {isShowBlockDialog && <Modal Component={blockDialog} />}
            {isShowDeleteDialog && <Modal Component={deleteDialog} />}
            <HistoryListHeader onFilterChange={()=>{history.push('/history/my')}} onFilterChange1={()=>{history.push('/history/other')}} />
            <div className="mainContainer">
                <div
                    id="friend-content"
                    onScroll={(event) => handleScroll(event)}>
                    {dataList === undefined ? (
                        <div className="exception" />
                    ) : dataList.length === 0 ? (
                        <div className="exception" />
                    ) : (
                        dataList &&
                        dataList.map((friend, index) => {
                            if (ids.findIndex((id) => id == friend.id) == -1) {
                                ids.push(friend.id);
                                return (
                                    <div key={index}>
                                         <div  className="item-even">
                                            <div className="child-main" />
                                            <img
                                                className="avatar"
                                                src={
                                                    friend.user?.profile_image ||
                                                    Constants.AVATAR_DEFAULT
                                                }
                                                alt=""
                                                onClick={() =>
                                                    showUserDetailModal(friend)
                                                }
                                            />
                                            <div style={{width: 300}}>{friend?.user?.user_name}</div>
                                            <CallBtn
                                                userId={friend?.user?.id}
                                                roomId={friend?.room?.id}
                                                roomTypeId={ROOM_TYPE_CALL_FRIEND}
                                                topicInfo={friend?.topic}
                                                disabled={false}
                                                isAnonymous={friend?.isAnonymous}
                                            />
                                        </div>
                                        <div key={index} className="item-odd">
                                            <div className="child-main" />
                                            <ChatBtn
                                                Component={chatComponent(friend)}
                                                userId={friend?.user?.id}
                                                roomTypeId={ROOM_TYPE_CHAT_FRIEND}
                                                topicId={friend?.room?.topic_id}
                                                roomInfo={friend?.room}
                                                isAnonymous={friend.isAnonymous}
                                            />
                                        </div>
                                    </div>
                                   );
                            }
                        })
                    )}
                    {isLoadMore && (
                        <div id="friend-load-more">
                            <div className="friend-load-more" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        friends: state['cocoro/friends'].friends,
        hasNextPage: state['cocoro/friends'].hasNextPage,
        latestRoomsData: state['cocoro/app'].latestRoomsData,
        userInfo: state['cocoro/user'].userInfo,
    };
}

export default connect(_mapStateToProps)(FriendList);
