export const ROOM_TYPE_CHAT_FRIEND = '1';
export const ROOM_TYPE_CHAT_TOPIC = '2';
export const ROOM_TYPE_CALL_FRIEND = '3';
export const ROOM_TYPE_CALL_TOPIC = '4';
export const REALTIME_TYPE_CHAT = '1';
export const REALTIME_TYPE_CALL = '2';
export const REALTIME_TYPE_USER = '3';
export const REALTIME_TYPE_USER_ACTION_BONUS = '1';
export const REALTIME_TYPE_USER_ACTION_BONUS_DONE = '2';
export const REALTIME_TYPE_DATA = '4';
export const REALTIME_TYPE_CHAT_TOPIC = '5';
export const REALTIME_TYPE_WEBRTC = '100';
export const REALTIME_TYPE_DATA_ACTION_READ = '0';
export const REALTIME_TYPE_CALL_ACTION_REFUSE = '0';
export const REALTIME_TYPE_CALL_ACTION_AUDIO = '11';
export const REALTIME_TYPE_CALL_ACTION_CALLER = '1';
export const REALTIME_TYPE_CALL_ACTION_CALLEE = '2';
export const REALTIME_TYPE_CALL_ACTION_AGREE = '3';
export const REALTIME_TYPE_CALL_ACTION_END = '4';
export const REALTIME_TYPE_CALL_BALANCE = '5';
export const REALTIME_TYPE_CALL_ACTION_END_BALANCE = '6';
export const REALTIME_TYPE_CALL_ACTION_REQUEST_PAID = '7';
export const REALTIME_TYPE_CALL_ACTION_CONFIRM_REQUEST_PAID = '8';
export const REALTIME_TYPE_CHAT_CONNECTION = '9';
export const REALTIME_TYPE_MESSAGE_NOTIFICATION = '10';
export const REALTIME_TYPE_MESSAGE_SYSTEM_CALL_MISS = '8';
export const REALTIME_TYPE_MESSAGE_SYSTEM_CALL_CANCEL = '7';
export const CANCEL_REPORT = '10';

export const THUMB_DEFAULT =
    'https://firebasestorage.googleapis.com/v0/b/coc-02.appspot.com/o/default_avatar%404x.png?alt=media&token=92025dc7-02a9-44b2-9251-9f58eab81cf1';

export const callStates = {
    NONE: 'none',
    CALLING: 'calling',
    RECEIVING: 'receiving',
    WAITING_CONNECT: 'waiting_connect',
    CONNECTING: 'connecting',
    DISCONNECT: 'disconnect',
    UNABLE_CONNECT: 'unable_connect',
    BUSY: 'busy',
    REFUSE: 'refuse',
    WAITING_REVIEW: 'waiting_review',
    END: 'end',
};
