import ReducerRegistry from '../../base/redux/ReducerRegistry';
import {Constants} from '../../../util/Constants';
import {DEFAULT_IMAGE, UPDATE_IMAGE_URI, IMAGE_CHANGE} from './actionType';

let stateDefault = {
    images: [],
    imageUri: '',
    imageType: '',
    isNew: false,
};

ReducerRegistry.register(
    Constants.STATE_REGISTRATION_INFORMATION,
    (state = stateDefault, action) => {
        switch (action.type) {
            case DEFAULT_IMAGE:
                return {
                    ...state,
                    images: [...action.images],
                };
            case UPDATE_IMAGE_URI:
                return {
                    ...state,
                    imageUri: action.imageUri,
                    imageType: action.imageType,
                    isNew: action.isNew,
                };
            case IMAGE_CHANGE:
                return {
                    ...state,
                    isNew: action.isChange,
                };
            default:
                return state;
        }
    },
);
