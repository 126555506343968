import React, {useState, useCallback, useEffect, useF, useRef} from 'react';
import './style.scss';
import ReplyListNavigationBar from '../custom/ReplyListNavigationBar.web';
import {
    getReplyListAction,
    clearDataReplyList,
    getTopicDetail,
    updateTopicIsCallable,
    getUserInfo,
    loadMoreReply,
} from '../../redux/action';
import {Constants} from '../../../../util/Constants';
import {connect} from 'react-redux';
import ReplyListItem from '../custom/ReplyListItem.web';
import CountDownReplyList from '../custom/CountDownReplyList.web';
import {useDispatch} from 'react-redux';
import {setNaviIndex} from '../../../app';
import {DEFAULT_LATEST_ROOM_DATA} from '../../../friendlist/constants';
import { useTopicExpired } from '../../../homescreen/hooks';
import { useLocation } from "react-router-dom";
import { HISTORY_PAGE_URL } from "../../../app/constants";

const ReplyList = ({history, replyList, topic, userInfo, latestRoomsData}) => {
    const dispatch = useDispatch();
    const isLoading = useRef(false);
    const [filterType, setFilterType] = useState(Constants.REPLY_LIST_TYPE_ALL);
    const { pathname } = useLocation();
    localStorage.setItem(HISTORY_PAGE_URL, pathname);
    const changeFilter = () => {
        if (filterType === Constants.REPLY_LIST_TYPE_ALL) {
            setFilterType(Constants.REPLY_LIST_TYPE_REPLIED);
        } else {
            setFilterType(Constants.REPLY_LIST_TYPE_ALL);
        }
    };

    useEffect(() => {
        dispatch(setNaviIndex('history'));
    }, [dispatch]);

    const getTopicId = useCallback(() => {
        
        const path = history.location.pathname;
        const array = path.split('/');
        return array[2];
    }, [history]);

    useEffect(() => {
        const callApi = async () => {
            if (isLoading.current) {
                return;
            }
            isLoading.current = true;
            dispatch(getTopicDetail(getTopicId()));
            await dispatch(getReplyListAction(getTopicId(), filterType, false));
            isLoading.current = false;
        };
        callApi();
    }, [filterType, getTopicId, dispatch]);

    let openDetailUser = (id, is_anonymous, topic_id, room_id, anonymous) => {
        dispatch(
            getUserInfo(id, is_anonymous, topic_id, null, room_id, anonymous),
        );
    };

    useEffect(() => {
        return () => {
            // unmount
            dispatch(clearDataReplyList());
        };
    }, [dispatch]);

    const handleLoadMore = async () => {
        if (isLoading.current) {
            return;
        }
        isLoading.current = true;
        await dispatch(loadMoreReply(getTopicId()));
        isLoading.current = false;
    };

    let handleScroll = (event) => {
        const bottom =
            event.target.scrollHeight -
                event.target.scrollTop -
                event.target.clientHeight <
            50;
        if (bottom) {
            handleLoadMore();
        }
    };

    useTopicExpired(topic, null, history);

    return (
        <div id="reply-list">
            <ReplyListNavigationBar
                history={history}
                changeFilter={changeFilter}
                topic={topic}
                filterType={filterType}
            />
            <div id="main-container">
                <div id="content">
                    <div id="top">
                        {topic.expire_at && (
                            <CountDownReplyList
                                timeExpire={topic.expire_at._seconds}
                            />
                        )}
                        <div id="wrap-checkbox">
                            <p>トークの着信を許可する</p>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={topic?.is_callable}
                                    onChange={(e) => {
                                        dispatch(updateTopicIsCallable());
                                    }}
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                    </div>
                    <div id="list" onScroll={(event) => handleScroll(event)}>
                        {!Array.isArray(replyList) || replyList.length === 0 ? (
                            <div id="no-data">まだ応答した人はいません。</div>
                        ) : (
                            replyList.map((item) => {
                                
                                const roomData = latestRoomsData?.[item.id]
                                    ? latestRoomsData[item.id]
                                    : DEFAULT_LATEST_ROOM_DATA;
                                
                                return (
                                    item.user !== undefined ?
                                    <ReplyListItem
                                        key={item.id.toString()}
                                        reply={item}
                                        message={roomData.latestMessage}
                                        participants={item.id}
                                        unreadCount={roomData.unreadCount}
                                        userId={userInfo.id}
                                        openDetailUser={openDetailUser}
                                        isCallable={topic?.is_callable}
                                    />
                                    : null
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

function _mapStateToProps(state) {
    return {
        error: state[Constants.STATE_HISTORY].error,
        isLoading: state[Constants.STATE_HISTORY].isLoading,
        topic: state[Constants.STATE_HISTORY].topic,
        replyList: state[Constants.STATE_HISTORY].replyList,
        userInfo: state[Constants.STATE_USER].userInfo,
        latestRoomsData: state['cocoro/app'].latestRoomsData,
    };
}

export default connect(_mapStateToProps)(ReplyList);
