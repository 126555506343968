import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {CallBtn} from '../../call';
import {openModalPage} from '../../app/actions';
import {getTopic} from '../../homescreen/actions';
import {
    MODAL_DETAIL_USER_CHAT,
    MODAL_RATE,
    TYPE_RATE_CHAT,
} from '../../app/constants';
import {roomTypes} from '../../../util/Constants';
import { updateRoomInfo } from "../actions";

const Header = ({roomInfo, history, userInfo, presentImage, setPresentImage, user_name, isSmall}) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');

    const checkBeforeBack = async () => {
        if(presentImage){
            setPresentImage(false);
        }else{
            history.goBack();
        }
    };

    const handleOpenDetailUser = async () => {
        if (roomInfo.topic_id != null) {
            const result = await dispatch(
                getTopic(roomInfo.topic_id, roomInfo.id),
            );
            if (result) {
                dispatch(
                    openModalPage({
                        type: MODAL_DETAIL_USER_CHAT,
                        ...roomInfo,
                        topicData: result,
                        is_friend: result.is_friend,
                    }),
                );
            }
        } else {
            dispatch(
                openModalPage({
                    type: MODAL_DETAIL_USER_CHAT,
                    ...roomInfo,
                    is_friend: true,
                }),
            );
        }
    };

    useEffect(() => {
        setTitle(user_name);
        // if (roomInfo.anonymous) {
        //     let otherId = roomInfo.participants_array.find(
        //         (item) => item !== userInfo.id,
        //     );
        //     setTitle(
        //         roomInfo.anonymous[otherId]
        //             ? roomInfo.userInfo?.user_name
        //             : roomInfo.userInfo?.user_name,
        //     );
        // } else {
        //     if (roomInfo.topic) {
        //         if (userInfo.id === roomInfo.topic?.user_id) {
        //             setTitle(
        //                 roomInfo.is_anonymous
        //                     ? roomInfo.userInfo?.user_name
        //                     : roomInfo.userInfo?.user_name,
        //             );
        //         } else {
        //             setTitle(
        //                 roomInfo.topic?.is_anonymous
        //                     ? roomInfo.userInfo?.user_name
        //                     : roomInfo.userInfo?.user_name,
        //             );
        //         }
        //     } else {
        //         setTitle(user_name);
        //     }
        // }
    }, [roomInfo]);

    return (
        <header>
            <div className="back">
                {
                    isSmall === true &&
                    presentImage === false ?
                    <img
                        alt=""
                        src=""
                    />
                    :
                    <img
                        alt=""
                        onClick={checkBeforeBack}
                        src="/images/back.png"
                        style={{width: 30, height: 30}}
                    />
                }
            </div>
            <h3
                // onClick={
                //     roomInfo.userInfo?.user_name
                //         ? () => handleOpenDetailUser()
                //         : () => {}
                // }
                >
                {title}
            </h3>
            <div className="call">
                {
                    roomInfo.userInfo &&
                        <img
                            style={{width: 30, height: 30, marginRight: 20, paddingTop: 5}}
                            src={require('../../../images/present.png')}
                            onClick={()=>{
                                dispatch(updateRoomInfo(roomInfo));
                                history.push('/present/'+roomInfo.id)
                            }}
                        />
                }
                {roomInfo.userInfo &&
                    (roomInfo.topic && roomInfo.topic.is_callable == false ? (
                        <CallBtn
                            userId={roomInfo.userInfo.id}
                            roomTypeId={
                                parseInt(roomInfo.room_type_id) ==
                                roomTypes.CHAT_FRIEND
                                    ? roomTypes.CALL_FRIEND
                                    : roomTypes.CALL_TOPIC
                            }
                            roomId={roomInfo.id}
                            topicInfo={roomInfo.topic}
                            disabled="true"
                            Component={() => (
                                <img
                                    className="request-call-disable"
                                    src="/images/ic_call_missing@4x.png"
                                    alt=""
                                />
                            )}
                        />
                    ) : (
                        <CallBtn
                            userId={roomInfo.userInfo.id}
                            isAnonymous={roomInfo.is_anonymous}
                            roomTypeId={
                                parseInt(roomInfo.room_type_id) ==
                                roomTypes.CHAT_FRIEND
                                    ? roomTypes.CALL_FRIEND
                                    : roomTypes.CALL_TOPIC
                            }
                            roomId={roomInfo.id}
                            topicInfo={roomInfo.topic}
                        />
                    ))}
            </div>
        </header>
    );
};

function _mapStateToProps(state) {
    return {
        roomInfo: state['cocoro/chat'].roomInfo,
        userInfo: state['cocoro/user'].userInfo,
        isSmall: state['cocoro/call'].isSmall,
    };
}

export default connect(_mapStateToProps)(Header);
