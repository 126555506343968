import React, {useState} from 'react';
import '../styleConfirm.scss';
import {connect, useDispatch} from 'react-redux';
import {updateUser} from '../actions';
import {updateMyProfile} from '../../user';
import {setNaviIndex} from '../../app/actions';

const ProfileConfirm = ({history}) => {
    const dispatch = useDispatch();

    const [err, setErr] = useState('');
    let userInfo;
    let userInfoStorage = JSON.parse(localStorage.getItem('user_info'));
    try{
        userInfo = userInfoStorage.userRegisterInfo;
    }catch(e){
        userInfo = {};
    }

    let avatar
    try{
        avatar = JSON.parse(userInfoStorage.userAvatarRegisterInfo);
    }catch(e){
        avatar = {};
    }

    let handleUpdateUser = () => {
        dispatch(
            updateUser(
                callbackSuccess,
                userInfo.age_id,
                1,
                userInfo.area_id,
                userInfo.gender_id,
                avatar.type_image,
                avatar.imageUri,
                userInfo.user_name,
                setErr,
            ),
        );
    };

    let callbackSuccess = (data) => {
      //  window.location.href = '/registration-information/sound-setting';
        dispatch(updateMyProfile({is_infomation: 1}));
        window.location.href = '/';
        dispatch(setNaviIndex('home'));
        // localStorage.removeItem('userAvatarRegisterInfo');
        // localStorage.removeItem('userRegisterInfo');
        // localStorage.removeItem('userBloodSettings');
    };
    let handlerSettingAvatar = () => {
        history.push('/registration-information/avatar');
    };
    let handlerSettingInfo = () => {
        history.push('/registration-information');
    };

    return (
        <div className="confirm-container">
            <div className="main-title">
                <div className="wrap-main-title">
                    <h3>これでよろしいですか？</h3>
                    <h5>
                        ユーザーネームやアイコン画像は後からいつでも変更可能です
                    </h5>
                </div>

                <div className="fill-title-confirm">
                    {avatar?.imageUri ? (
                        <p
                            className="img-profile-confirm"
                            style={{
                                background: `url(${
                                    avatar.imageUri || ''
                                }) no-repeat 50% 50% /cover`,
                                width: '100px',
                                lineHeight: '100px',
                            }}>
                            &ensp;
                        </p>
                    ) : (
                        <img
                            className="img-profile-confirm"
                            src="/images/ic_avata_big.svg"
                        />
                    )}
                    <p
                        onClick={handlerSettingAvatar}
                        className="label-change-image">
                        変更する
                    </p>
                    <hr className="confirm-hr-style" />

                    <div className="fill-label-confirm">
                        <div className="label-user-name">
                            <p className="label-title">ユーザーネーム</p>
                            <p
                                onClick={handlerSettingInfo}
                                className="label-title-right">
                                全て変更する
                            </p>
                        </div>
                        <p className="text-input-data">
                            {userInfo.user_name || ''}
                        </p>

                        <p className="label-title">性別</p>
                        <p className="text-input-data">
                            {userInfo.gender || ''}
                        </p>

                        <p className="label-title">年代</p>
                        <p className="text-input-data">{userInfo.age || ''}</p>

                        <p className="label-title">エリア</p>
                        <p className="text-input-data">{userInfo.area || ''}</p>
                    </div>

                    <div className="confirm-submit">
                        <span
                            onClick={handleUpdateUser}
                            className="button-confirm-submit">
                            はい
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
const _mapStateToProps = (state) => ({
    imageUri: state['cocoro/register'].imageUri,
});

export default connect(_mapStateToProps)(ProfileConfirm);
