import ReducerRegistry from '../base/redux/ReducerRegistry';
import {
    CLEAR_MY_DATA,
    LOGGED_IN,
    UPDATE_DEVICE_ID,
    UPDATE_FILTER_HISTORY,
    UPDATE_MY_INFO,
    SPLASH_GET_AREAS,
    SPLAH_GET_CATEGORIES,
    SPLASH_GET_SUBCATEGORIES,
    CLEAR_MY_DATA_TEMP,
} from './actionTypes';
import {UNBLOCK_USER} from '../settings/actionTypes';
import {BLOCK_USER} from '../friendlist/actionTypes';

const initialState = {
    userInfo: {},
    deviceId: null,
    filterHistory: {},
    loggedIn: false,
    detailUser: {
        avatar:
            'https://yt3.ggpht.com/a/AATXAJyxfb2XaC2Xz5aXzFOlq79cyifpfXl_iOB9vL-y=s900-c-k-c0xffffffff-no-rj-mo',
        username: 'かなこ',
        area: '関東',
        gender: '女性（F）',
        age: '20代',
        topic_id: '1',
        user_id: 's5dKiPmXin1EUe61j7xe',
        cmt:
            '下ネタ厳禁。通話中に出てきたらすぐに切ります。出会い目的の人もお断り',
        description:
            '今、暇なのよねー。一応結婚アドバイザーやってるんで結構詳しいよ。一つ上げるなら、結婚するって法律的に財産が共有されるって事になるから結婚時点でのお互いのお金の計算はしっかりしておくと良いよ。そのほかにもいろいろあるから直接話しましょう。',
    },
    categories: null,
    subcategories: null,
    areas: null,
};

ReducerRegistry.register('cocoro/user', (state = initialState, action) => {
    switch (action.type) {
        case LOGGED_IN:
            return {
                ...state,
                loggedIn: action.status,
            };
        case UPDATE_MY_INFO:
            return {
                ...state,
                userInfo: {...state.userInfo, ...action.data},
            };
        case UPDATE_DEVICE_ID:
            return {
                ...state,
                deviceId: action.deviceId,
            };
        case UPDATE_FILTER_HISTORY:
            return {
                ...state,
                filterHistory: action.data,
            };
        case CLEAR_MY_DATA:
            return {
                ...state,
                userInfo: {},
                filterHistory: {},
                loggedIn: false,
            };
        
        case CLEAR_MY_DATA_TEMP:
            return {
                ...state,
                userInfo: {},
                filterHistory: {},
                loggedIn: true,
            };

        case BLOCK_USER: {
            const _blocks = state.userInfo.blocks || {};
            _blocks[action.userId] = action.blockId;
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    blocks: {..._blocks},
                },
            };
        }

        case UNBLOCK_USER: {
            const _blocks = state.userInfo.blocks || {};
            if (_blocks[action.userId]) {
                delete _blocks[action.userId];
            }
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    blocks: {..._blocks},
                },
            };
        }

        case 'UPDATE_USER_COIN': {
            state.userInfo.userCoin = action.data;
            return { ...state };
        }

        case SPLASH_GET_AREAS: {
            return { ...state, areas: action.data }
        }

        case SPLAH_GET_CATEGORIES: {
            return { ...state, categories: action.data }
        }

        case SPLASH_GET_SUBCATEGORIES: {
            return { ...state, subcategories: action.data }
        }

        default:
            return state;
    }
});
