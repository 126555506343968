/* eslint-disable no-lone-blocks */
import {db} from '../base/firebase/firebase';
import {
    getInfo as infoUserApi,
    getAllTopic as axiosGetAllTopic,
    updateFistLogin as axiosUpdateFistLogin,
    createTopic,
    getUserInfo,
    getMyTopicApi,
    getSubCategory,
    heartClickApi,
    exploreClickApi,
    getAreas as areaApi,
} from './apis';

import {MODAL_CHOOSE_NAME, MODAL_CHOOSE_EXPIRE_TIME, MODAL_INPUT_PW} from '../app/constants';
import {
    UPDATE_USER_NAME,
    UPDATE_TIME_SELECT,
    DISPLAY_LIST_BUBBLE_STYLE,
} from '../homescreen/actionType';
import {CHECK_AUTH} from '../app/actionTypes';
import {setLoading, openModalPage, openPopup, openInputPWModalPage} from '../app/actions';
import {timeMiles, TIME_MILES_SMALLEST} from './function';
import {httpRequest} from '../base/api/actions';
import {getThreadByIdApi, getTopicApi, getTopicsApi} from '../base/api';
import {updateMyInfo} from '../user/actions';
import { UPDATE_CATEGORY_ID_NEW, UPDATE_CATEGORY_NEW  } from "./actionType";

export function heartClick(thread_id, author_id){
    return async function (dispatch, getState) {
        await dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        let result = {};
        await heartClickApi(thread_id, author_id, access_token).then((res)=>{
            dispatch(setLoading(false));
            result = res?.data;
        })
        return result;
    }
}

export function exploreClick(thread_id, author_id){
    return async function (dispatch, getState) {
        await dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        let result = {};
        await exploreClickApi(thread_id, author_id, access_token).then((res)=>{
            dispatch(setLoading(false));
            result = res?.data;
        })
        return result;
    }
}


export function updateCategory(category) {
    return {
        type: UPDATE_CATEGORY_NEW,
        category: category,
    };
}

export function updateCategoryId(id) {
    return {
        type: UPDATE_CATEGORY_ID_NEW,
        categoryId: id,
    };
}

export function setUserCreateTopic(username) {
    return {
        type: UPDATE_USER_NAME,
        username: username,
    };
}
export function setTimeCreateTopic(time) {
    return {
        type: UPDATE_TIME_SELECT,
        time,
    };
}

export function displayListBubbleStyle(status) {
    return {
        type: DISPLAY_LIST_BUBBLE_STYLE,
        status,
    };
}

export function openDialogChooseName(data, currentName) {
    return function (dispatch, _getState) {
        dispatch(
            openModalPage({
                type: MODAL_CHOOSE_NAME,
                data,
                currentName: currentName.id,
            }),
        );
    };
}

export function openDialogChooseTime(data, currentTime) {
    return function (dispatch, _getState) {
        dispatch(
            openModalPage({
                type: MODAL_CHOOSE_EXPIRE_TIME,
                data,
                currentTime: currentTime.id,
            }),
        );
    };
}

export function getSubCategoryById(id, setTime) {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        getSubCategory(access_token, id).then((resolve) => {
            dispatch(setLoading(false));
            try {
                dispatch({type: CHECK_AUTH, status: resolve.status});
                if (resolve.status === 200 || resolve.status === 201) {
                    let minutesLeft =
                        resolve.data.topic_expire_after_max_minutes;
                    let times = timeMiles.filter(
                        (item) => item.value <= minutesLeft,
                    );
                    if (minutesLeft < 60) {
                        if (times.length >= 1) {
                            let time = times[times.length - 1];
                            dispatch(setTimeCreateTopic(time));
                        } else {
                            dispatch(setTimeCreateTopic(TIME_MILES_SMALLEST));
                        }
                    } else {
                        dispatch(
                            setTimeCreateTopic({
                                name: '1時間',
                                value: 60,
                                id: '5',
                            }),
                        );
                    }
                    // setTime(times);
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            } catch (error) {
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}

export function getTopic(id, roomId) {
    return async function (dispatch, getState) {
        const result = await dispatch(
            httpRequest(getTopicApi(id, roomId), true),
        );
        return result;
    };
}

export function getTopicList(
    {
        thread_id,
        area_id,
        category_id,
        sub_category_id,
        gender,
        age_id,
        user_status,
        last_doc_id,
        per_page,
    },
    loading = true,
) {
    return async function (dispatch, getState) {
        let showLoadingInterval = setInterval(()=>{
            dispatch(setLoading(true));
        }, 50)
        const result = await dispatch(
            httpRequest(
                getTopicsApi({
                    thread_id,
                    area_id,
                    category_id,
                    sub_category_id,
                    gender,
                    age_id,
                    user_status,
                    last_doc_id,
                    per_page,
                }),
                false,
            ),
        );
        clearInterval(showLoadingInterval);
        dispatch(setLoading(false));
        return result;
    };
}
export function updateFistLogin() {
    return function (_dispatch, getState) {
        let {access_token} = getState()['cocoro/user'].userInfo;
        axiosUpdateFistLogin(access_token)
            .then((_response) => {})
            .catch((_error) => {});
    };
}

export function createTopicModal(
    thread_id,
    title,
    detail,
    sub_category_id,
    is_callable,
    is_anonymous,
    chat_price,
    call_price,
    purchase_type,
    comment,
    expire_after_minute,
    is_not_free,
    setErr,
    callback,
    _onClose,
) {
    return function (dispatch, getState) {
        let userInfo = getState()['cocoro/user'].userInfo;
        dispatch(setLoading(true));
        createTopic(
            thread_id,
            title,
            detail,
            sub_category_id,
            is_callable,
            is_anonymous,
            parseInt(chat_price),
            parseInt(call_price),
            purchase_type,
            comment,
            expire_after_minute,
            is_not_free,
            userInfo.access_token,
        )
            .then((rs) => {
                dispatch(setLoading(false));
                dispatch({type: CHECK_AUTH, status: rs.status});
                if (rs.status == 200 || rs.status == 201) {
                    callback(rs);
                    dispatch(
                        setTimeCreateTopic({
                            name: '1時間',
                            value: '60',
                            id: '5',
                        }),
                    );
                    dispatch(setUserCreateTopic({id: '1', name: '匿名さん'}));
                    dispatch(updateMyInfo({...userInfo, comment}));
                } else if (rs.status === 422 || rs.status === 403) {
                    setErr(rs.data.errors);
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            })
            .catch((_err) => {
                dispatch(setLoading(false));
                dispatch(openPopup('想定しないエラーが発生しました。'));
            });
    };
}

export function getUserInfoHome(callbackData) {
    return function (dispatch, getState) {
        let {access_token} = getState()['cocoro/user'].userInfo;
        dispatch(setLoading(true));
        getUserInfo(access_token)
            .then((rs) => {
                dispatch(setLoading(false));
                dispatch({type: CHECK_AUTH, status: rs.status});
                if (rs.status == 200 || rs.status == 201) {
                    callbackData(rs.data);
                    return;
                }
                if (rs.status == 404) {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            })
            .catch((_err) => {
                dispatch(setLoading(false));
                dispatch(openPopup('想定しないエラーが発生しました。'));
            });
    };
}

export function getThreadById(id) {
    return async function (dispatch, getState) {
        return dispatch(httpRequest(getThreadByIdApi(id)));
    };
}

export function getMyTopic(thread_id) {
    return async function (dispatch, getState) {
        let {access_token} = getState()['cocoro/user'].userInfo;
        dispatch(setLoading(true));
        const res = await getMyTopicApi(thread_id, access_token);

        dispatch(setLoading(false));
        dispatch({type: CHECK_AUTH, status: res.status});

        if (res.status == 200 || res.status == 201) {
            return res.data;
        }

        if (res?.error) {
            dispatch(openPopup('想定しないエラーが発生しました。'));
            return null;
        }
    };
}

export function getInputPW(currentOrder,handleSaveSelectedSubCategoryPW,handleCancelSelectedSubCategoryPW) {
    return async function (dispatch) {
        await dispatch(setLoading(true));
        areaApi().then((resolve) => {
            dispatch(setLoading(false));
            try {
                if (resolve.status === 200 || resolve.status === 201) {
                    dispatch(
                        openModalPage({
                            type: MODAL_INPUT_PW,
                            data: resolve.data.filter((a) => a.name != '四国'),
                            currentOrder,
                            handleSaveSelectedSubCategoryPW,
                            handleCancelSelectedSubCategoryPW,
                        }),
                    );
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            } catch (error) {
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}