import React, {useState, useEffect} from 'react';
import {
    View,
} from 'react-native';
import {openPopup} from '../../app/actions';
import {connect} from 'react-redux';
// import Input from "../../signup/native/input/Input";
// import auth from '@react-native-firebase/auth';

function OTPResult({navigation, route, openPopup}) {
    const [code, setCode] = useState('');

    let handleClick = async () => {
        window.location.href = "/SignUp";
    }

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <img
                    style={{width: 200, height: 200, borderRadius: 10, marginBottom: 20}}
                    src={require('../../../images/yubaba.png')}
                />
                <span style={styles.txt}>
                    COCOROでは 3 入のなりたい自
                </span>
                <span style={styles.txt}>
                    分を作ることが出来ます。
                </span>
                <span style={styles.txt}>
                    心の準備は出来ましたか ?
                </span>
                <span style={styles.txt}>
                    それでは1人目のなりたい自分
                </span>
                <span style={styles.txt}>
                    を作ってください。
                </span>
            </View>

            <View style={styles.warpButton}>
                <button
                    onClick={handleClick}
                    style={styles.ButtonSubmit}>
                    <span style={styles.textButton}>つぎへ</span>
                </button>
            </View>
        </View>
    );
}

const styles = {
    container: {
        flex: 1,
        backgroundColor: '#A6D837',
        padding: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        alignItems: 'center',
        // height: 180,
        // paddingBottom: 30,
        flexDirection: 'column',
        paddingTop: 70
    },
    txt: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'center',
        marginTop: 5
    },
    textInput: {
        fontWeight: 'bold',
        width: '100%',
        padding: 10,
        color: 'black',
        height: 50
    },
    wrapInput: {
        marginTop: 50,
        backgroundColor: '#F4F9FA',
        width: '100%',
        height: 50,
        borderColor: '#DFE9EB',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 5,
        marginBottom: 5,
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: 20
    },
    warpButton: {
        width: '100%',
        marginTop: 70,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 35,
    },
    ButtonSubmit: {
        width: '80%',
        padding: 5,
        fontWeight: 'bold',
        backgroundColor: '#FFE033',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#DBBD16',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 4,
        borderBottomColor: '#DBBD16',
        borderRadius: 50,
        height: 70,
    },
    textButton: {
        fontSize: 18,
        color: '#36383B',
        fontWeight: 'bold',
    },
};

function _mapStateToProps(state) {
    return {};
}

let _mapDispatchToProps = {
    openPopup,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(OTPResult);
