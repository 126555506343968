export const deviceTypes = {
    WEB: 'web',
    IOS: 'ios',
    ANDROID: 'android',
};

export const userStatus = {
    ONLINE: 'online',
    OFFLINE: 'offline',
};
