import ReducerRegistry from '../base/redux/ReducerRegistry';
import {
    UPDATE_ACTIVE_FILTER_TOPIC,
    UPDATE_GENDER_FILTER_TOPIC,
    UPDATE_AGE_FILTER_TOPIC,
    UPDATE_AREA_FILTER_TOPIC,
} from './actionType';

let stateDefault = {
    activeFilter: {id: '0', name: '全て'},
    ageFilter: {id: '0', name: '全て'},
    genderFilter: {id: '0', name: '全て'},
    filterHistory: {id: '0', name: '四国'},
};
ReducerRegistry.register(
    'cocoro/filtertopic',
    (state = stateDefault, action) => {
        switch (action.type) {
            case UPDATE_AREA_FILTER_TOPIC:
                return {
                    ...state,
                    filterHistory: action.area,
                };
            case UPDATE_ACTIVE_FILTER_TOPIC:
                return {
                    ...state,
                    activeFilter: action.active,
                };
            case UPDATE_AGE_FILTER_TOPIC:
                return {
                    ...state,
                    ageFilter: action.age,
                };
            case UPDATE_GENDER_FILTER_TOPIC:
                return {
                    ...state,
                    genderFilter: action.gender,
                };
            default:
                return state;
        }
    },
);
