import ReducerRegistry from '../base/redux/ReducerRegistry';
import {callStates} from './constants';
let stateDefault = {
    localStream: null,
    remoteStream: null,
    roomDetail: {},
    userInfo: {},
    isSmall: false,
    balance: 0,
    paidCall: false,
    callState: callStates.NONE,
    roomChatId: null,
};

ReducerRegistry.register('cocoro/call', (state = stateDefault, action) => {
    switch (action.type) {
        case 'UPDATE_PAID_CALL':
            return {
                ...state,
                paidCall: action.paidCall,
            };
        case 'UPDATE_SMALL_STATUS':
            return {
                ...state,
                isSmall: action.isSmall,
            };
        case 'UPDATE_MYSELF_STATUS':
            return {
                ...state,
                isMyself: action.isMyself,
            };
        case 'UPDATE_USERINFO':
            return {
                ...state,
                userInfo: action.userInfo,
            };
        case 'UPDATE_BALANCE':
            return {
                ...state,
                balance: action.balance,
            };
        case 'UPDATE_ROOM_DETAIL':
            return {
                ...state,
                roomDetail: action.roomDetail,
            };
        case 'SET_LOCAL_STREAM':
            return {
                ...state,
                localStream: action.stream,
            };
        case 'SET_REMOTE_STREAM':
            return {
                ...state,
                remoteStream: action.stream,
            };
        case 'UPDATE_CALL_STATE':
            return {
                ...state,
                callState: action.payload,
                roomChatId: action.room_id,
            };
        default:
            return state;
    }
});
