export const Views = {
    WelcomeSearch: 'welcome_search_view',
    SelectArea: 'select_area_view',
    SelectCategory: 'select_category_view',
    SelectSubCategory: 'select_sub_category_view',
    CreateThread: 'create_thread_view',
    CreateThreadSuccess: 'create_thread_success_view',
    ShowThread: 'show_thread_view',
};

export const Tabs = {
    SelectArea: 'select_area_tab',
    SelectCategory: 'select_category_tab',
    SelectSubCategory: 'select_sub_category_tab',
};

export const filterTextDefault = {
    // Area: 'エリア',
    // Category: 'カテゴリー',
    // SubCategory: 'サブカテゴリー',
    Area: '全地域',
    Category: 'すべて',
    SubCategory: 'サブカテゴリ',
};
