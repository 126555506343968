import {
    requestChangePasswordApi,
    resetPasswordApi,
    resetVerifyCodeApi,
} from './apis';
import {setLoading, openActionPopup, openPopup} from '../app/actions';
import {RESET_INFO} from './actionTypes';
import {isNative} from '../call/media';

export function saveResetInfo(email, code) {
    return {
        type: RESET_INFO,
        email,
        code,
    };
}

export function requestChangePassword(email, setError, nextStep) {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        requestChangePasswordApi(email).then((resolve) => {
            dispatch(setLoading(false));
            try {
                if (resolve.status === 200 || resolve.status === 201) {
                    dispatch(saveResetInfo(email, null));
                    if (!isNative) {
                        localStorage.setItem(
                            'resetInfo',
                            JSON.stringify({
                                reset_email: email,
                                verify_code: null,
                            }),
                        );
                    }
                    nextStep();
                } else if (resolve.status === 403) {
                    let errors = resolve.data.errors;
                    setError(errors.email);
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            } catch (error) {
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}

export function resetVerifyCode(code, setError, nextStep) {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let email = '';
        if (isNative) {
            email = getState()['cocoro/reset_password'].email;
        } else {
            let resetInfo = localStorage.getItem('resetInfo');
            if (resetInfo) {
                resetInfo = JSON.parse(resetInfo);
                email = resetInfo.reset_email;
            }
        }
        resetVerifyCodeApi(email, code).then((resolve) => {
            dispatch(setLoading(false));
            try {
                if (resolve.status === 200 || resolve.status === 201) {
                    dispatch(saveResetInfo(email, code));
                    if (!isNative) {
                        localStorage.setItem(
                            'resetInfo',
                            JSON.stringify({
                                reset_email: email,
                                verify_code: code,
                            }),
                        );
                    }
                    nextStep();
                } else if (resolve.status === 403) {
                    let errors = resolve.data.errors;
                    setError(errors.verify_code);
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            } catch (error) {
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}

export function resetPassword(
    password,
    confirmPassword,
    setNewPasswordError,
    setConfirmPasswordError,
    nextStep,
) {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let email = '';
        let verifyCode = '';
        if (isNative) {
            email = getState()['cocoro/reset_password'].email;
            verifyCode = getState()['cocoro/reset_password'].verifyCode;
        } else {
            let resetInfo = localStorage.getItem('resetInfo');
            if (resetInfo) {
                resetInfo = JSON.parse(resetInfo);
                email = resetInfo.reset_email;
                verifyCode = resetInfo.verify_code;
            }
        }
        resetPasswordApi(email, verifyCode, password, confirmPassword).then(
            (resolve) => {
                dispatch(setLoading(false));
                try {
                    if (resolve.status === 200 || resolve.status === 201) {
                        if (!isNative) {
                            localStorage.removeItem('resetInfo');
                        }
                        dispatch(
                            openActionPopup(
                                '新パスワードを設定完了しました。',
                                nextStep,
                            ),
                        );
                    } else if (resolve.status === 403) {
                        let errors = resolve.data.errors;
                        setNewPasswordError(
                            errors.password ? errors.password : '',
                        );
                        setConfirmPasswordError(
                            errors.confirm_password
                                ? errors.confirm_password
                                : '',
                        );
                        if (
                            errors.verify_code !== undefined &&
                            errors.verify_code !== null
                        ) {
                            dispatch(openPopup(errors.verify_code));
                        }
                    } else {
                        dispatch(openPopup('想定しないエラーが発生しました。'));
                    }
                } catch (error) {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            },
        );
    };
}
