import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {changeRoute, openPopup} from '../../app/actions';
import {useHistory} from 'react-router-dom';
import {resetPassword} from '../actions';
import '../styles.scss';
import {InputField} from '../../changepassword';

function ResetPassword({
    navigation,
    loading,
    changeRoute,
    lastRoute,
    openPopup,
    resetPassword,
}) {
    let history = useHistory();
    let [newPassword, setNewPassword] = useState('');
    let [confirmPassword, setConfirmPassword] = useState('');
    let [newPasswordError, setNewPasswordError] = useState('');
    let [confirmPasswordError, setConfirmPasswordError] = useState('');

    let onBack = () => {
        history.goBack();
    };

    let toLogin = () => {
        history.replace('/login');
    };

    let resetPasswordFunc = () => {
        setNewPasswordError('');
        setConfirmPasswordError('');
        resetPassword(
            newPassword,
            confirmPassword,
            setNewPasswordError,
            setConfirmPasswordError,
            toLogin,
        );
    };

    return (
        <div id="resetPassword">
            <header>
                <div className="back">
                    <img
                        alt=""
                        onClick={onBack}
                        src="/images/icon-back-grey.png"
                    />
                </div>
                <h3>新パスワード設定</h3>
                <div className="none" />
            </header>
            <div className="mainContainer">
                <InputField
                    title={'新パスワード'}
                    data={newPassword}
                    setData={setNewPassword}
                    error={newPasswordError}
                />
                <InputField
                    title={'新パスワード（確認用）'}
                    data={confirmPassword}
                    setData={setConfirmPassword}
                    error={confirmPasswordError}
                />

                <div className="buttonContainer">
                    <div className="button" onClick={resetPasswordFunc}>
                        <span>送信</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        lastRoute: state['cocoro/app'].lastRoute,
        loading: state['cocoro/app'].loading,
    };
}

let _mapDispatchToProps = {
    changeRoute,
    openPopup,
    resetPassword,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ResetPassword);
