import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import '../styles.scss';
import {
    toogleSearchScreen,
    setIsFocusHomeFalse,
    setNaviIndex,
    setIsFocusSearchFalse,
} from '../actions';
import '../styles.scss';
import {getFriends} from '../../friendlist';
import {getMyInfo} from '../../mypage';
import {displayListBubbleStyle} from '../../homescreen/actions';
import {HISTORY_PAGE_URL} from '../../app/constants';

const BottomTabsLayout = ({naviIndex, isBubbleStyle, classes, children}) => {
    const dispatch = useDispatch();
    return (
        <>
            {children}
            {/* <div style={{width: 374, height: 1, backgroundColor:'#615d5d', position: 'absolute', left: 0, bottom: 62}}/>
            <div style={{width: 374, height: 1, backgroundColor:'#615d5d', position: 'absolute', left: 0, bottom: 0}}/>
            <div style={{width: 1, height: 62, backgroundColor:'#615d5d', position: 'absolute', left: 0, bottom: 0}}/>
            <div style={{width: 1, height: 62, backgroundColor:'#615d5d', position: 'absolute', left: 373, bottom: 0}}/> */}
            <BottomNavigation
                showLabels={true}
                value={naviIndex}
                onChange={(event, newValue) => {
                    dispatch(setNaviIndex(newValue));
                }}
                style={{
                    margin: '2px auto',
                    color: '#ffff',
                    fontSize: 5,
                    width: 372,
                    position: 'absolute',
                    left: 1,
                    bottom: -7,
                }}>
                <BottomNavigationAction
                    label="マイページ"
                    onClick={() => {
                        dispatch(setIsFocusHomeFalse());
                        dispatch(setIsFocusSearchFalse());
                    }}
                    value="profile"
                    classes={classes}
                    icon={
                        <img
                            src={
                                naviIndex === 'profile'
                                    ? '/images/icon-profile-active.svg'
                                    : '/images/icon-profile-unactive.svg'
                            }
                        />
                    }
                    component={Link}
                    to="/mypage"
                />

                <BottomNavigationAction
                    label="最新投稿"
                    onClick={() => {
                        // dispatch(displayListBubbleStyle(!isBubbleStyle));
                        dispatch(displayListBubbleStyle(true));
                        dispatch(setIsFocusHomeFalse());
                    }}
                    value="home"
                    classes={classes}
                    icon={
                        <img
                            src={
                                naviIndex === 'home'
                                    ? require('../../../images/ic_search_active.png')
                                    : require('../../../images/ic_search_unactive.png')
                            }
                        />
                    }
                    component={Link}
                    to="/search"
                />

                <BottomNavigationAction
                    value="search"
                    classes={classes}
                    label="          "
                    onClick={() => {
                        dispatch(toogleSearchScreen());
                        dispatch(setIsFocusSearchFalse());
                    }}
                    icon={
                        <img
                            src={
                                naviIndex === 'search'
                                    ? '/images/icon-home-select.svg'
                                    : '/images/icon-home.svg'
                            }
                        />
                    }
                    component={Link}
                    to="/"
                />

                <BottomNavigationAction
                    label="履歴"
                    onClick={() => {
                        dispatch(setIsFocusHomeFalse());
                        dispatch(setIsFocusSearchFalse());
                    }}
                    value="history"
                    classes={classes}
                    icon={
                        <img
                            src={
                                naviIndex === 'history'
                                    ? '/images/icon-history-active.svg'
                                    : '/images/icon-history-unactive.svg'
                            }
                        />
                    }
                    component={Link}
                    to={ localStorage.getItem(HISTORY_PAGE_URL) !== '' && localStorage.getItem(HISTORY_PAGE_URL) !== null ? localStorage.getItem(HISTORY_PAGE_URL) : '/history' }
                />

                <BottomNavigationAction
                    label="ともだち"
                    onClick={() => {
                        dispatch(setIsFocusHomeFalse());
                        dispatch(setIsFocusSearchFalse());
                        dispatch(getFriends({last_doc_id: null}));
                    }}
                    value="friends"
                    classes={classes}
                    icon={
                        <img
                            src={
                                naviIndex === 'friends'
                                    ? '/images/icon-friends-active.svg'
                                    : '/images/icon-friends-unactive.svg'
                            }
                        />
                    }
                    component={Link}
                    to="/friendlist"
                />
            </BottomNavigation>
        </>
    );
};

const _mapStateToProps = (state) => ({
    naviIndex: state['cocoro/app'].naviIndex,
    isBubbleStyle: state['cocoro/homescreen'].isBubbleStyle,
});

export default connect(_mapStateToProps)(BottomTabsLayout);
