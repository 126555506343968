import React from 'react';
import '../styles.scss';
import {Link} from 'react-router-dom';

export default function ArrowSetting({title, link}) {
    return (
        <Link to={link}>
            <div id="arrowSetting">
                <span>{title}</span>
                <img src="/images/ic_arrow.svg" />
            </div>
        </Link>
    );
}
