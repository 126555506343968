export const GET_HISTORY = 'GET_HISTORY';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAIL = 'GET_HISTORY_FAIL';
export const SET_HISTORY = 'SET_HISTORY';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const CLEAR_DATA_HISTORY = 'CLEAR_DATA_HISTORY';
export const CLEAR_REPLY_LIST = 'CLEAR_REPLY_LIST';
export const GET_REPLY_LIST_SUCCESS = 'GET_REPLY_LIST_SUCCESS';
export const GET_REPLY_LIST_FAIL = 'GET_REPLY_LIST_FAIL';
export const GET_REPLY_LIST_LOAD_MORE = 'GET_REPLY_LIST_LOAD_MORE';
export const LOAD_MORE_REPLY_LIST_SUCCESS = 'LOAD_MORE_REPLY_LIST_SUCCESS';
export const GET_TOPIC_SUCCESS = 'GET_TOPIC_SUCCESS';
export const UPDATE_TOPIC_IS_CALLABLE_SUCCESS =
    'UPDATE_TOPIC_IS_CALLABLE_SUCCESS';
export const IS_LOADING = 'IS_LOADING';
export const CONFIRM_ERROR = 'CONFIRM_ERROR';
export const UPDATE_REPLY = Symbol('UPDATE_REPLY');
export const ADD_REPLY = Symbol('ADD_REPLY');
export const IS_BLOCK_USER = Symbol('IS_BLOCK_USER');
export const REPLY_TOPIC = Symbol('REPLY_TOPIC');
export const DELETE_HISTORY_ITEM = Symbol('DELETE_HISTORY_ITEM');
export const DELETE_REPLY_ITEM = Symbol('DELETE_REPLY_ITEM');
