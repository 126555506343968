import React, {useEffect, useRef} from 'react';
import Main from './Main';
import {useDispatch} from 'react-redux';
import {connect} from 'react-redux';
import {initApp, subscribeRealtimeData} from '../actions';
import {
    checkLoggedInOtherDevice,
    listenLoggedInOtherDevice,
    subcribeUserStatus,
} from '../../user';
import {
    subscribeRealtimeChatData,
    syncUnreadMessageNumber,
    syncChatMessagesImmediatelyAppActive,
} from '../../chat';
import {updateLatestRooms} from '../actions';
import chatdb from '../../chat/chatdb';

function App({loggedIn, myInfo, deviceId}) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(initApp());

        const itvClearMessageExpired = setInterval(() => {
            chatdb.removeAllMessageIsExpired();
        }, 60 * 1000);
        chatdb.removeAllMessageIsExpired();

        return () => {
            clearInterval(itvClearMessageExpired);
        };
    }, []);

    const synced = useRef(false);
    useEffect(() => {
        if (loggedIn && myInfo.id && synced.current === false) {
            synced.current = true;
            dispatch(syncChatMessagesImmediatelyAppActive());
        }
    }, [loggedIn, myInfo.id]);

    useEffect(() => {
        if (!loggedIn) {
            synced.current = false;
        }
    }, [loggedIn]);

    useEffect(() => {
        const itvUpdateRooms = setInterval(() => {
            dispatch(updateLatestRooms());
        }, 60 * 1000);
        return () => clearInterval(itvUpdateRooms);
    }, []);

    useEffect(() => {
        if (loggedIn && myInfo.id) {
            const unsubChatRealtimeData = dispatch(
                subscribeRealtimeChatData(myInfo.id),
            );
            const unsubUserStatus = dispatch(subcribeUserStatus());

            dispatch(syncUnreadMessageNumber(myInfo.id));

            return () => {
                unsubChatRealtimeData();
                unsubUserStatus();
            };
        }
    }, [loggedIn, myInfo.id]);

    // subcribe realtime data
    useEffect(() => {
        if (loggedIn && myInfo.id) {
            const unsub = dispatch(subscribeRealtimeData(myInfo.id));
            return () => unsub();
        }
    }, [loggedIn, myInfo.id]);

    useEffect(() => {
        if (loggedIn && myInfo.id) {
            dispatch(updateLatestRooms());
        }
    }, [loggedIn, myInfo.id]);

    // listen logged in
    useEffect(() => {
        if (loggedIn && myInfo.id && deviceId) {
            const unsubListenLoggedInOtherDevice = dispatch(
                listenLoggedInOtherDevice(),
            );
            dispatch(checkLoggedInOtherDevice());

            return () => unsubListenLoggedInOtherDevice();
        }
    }, [loggedIn, myInfo.id, deviceId]);
    

    return <Main />;
}

const _mapStateToProps = (state) => ({
    loggedIn: state['cocoro/user'].loggedIn,
    myInfo: state['cocoro/user'].userInfo,
    deviceId: state['cocoro/user'].deviceId,
});

export default connect(_mapStateToProps)(App);
