import React, {useEffect, useState} from 'react';
import '../detailstyles.scss';
import SocialButton, {FACEBOOK, TWITTER, INSTAGRAM} from './SocialButton.web';
import {Constants} from '../../../util/Constants';
import {numberFormat} from '../functions';
import { ChatBtn } from "../../chat";
import { CallBtn } from "../../call";
import { useDispatch } from "react-redux";
import {
    ROOM_TYPE_CALL_FRIEND,
    ROOM_TYPE_CHAT_FRIEND,
} from '../../call/constants';
import {Modal, openModalPage} from '../../app';
import { addFriend } from "../../addfriend/actions";
import { getFriendInfo } from "../../friendlist/actions";
import {saveRecordAudio} from '../../call/actions';

var audio = new Audio();
var audio1 = new Audio();

export default function Callee({
    friend,
    blockFriend,
    deleteFriend,
    myInfo,
    onClose,
    roomDetail,
    handleRefuseCall,
    handleAudioCall,
    handleAnswerCall,
}) {
    let [userCharacter, setUserCharacter] = useState('');
    let [userBloodSettings, setUserBloodSettings] = useState({});
    let [isInit, setIsInit] = useState(true);
    let [listenAudio, setListenAudio] = useState(false);
    let dispatch = useDispatch();
    let soundData = '';

    

    useEffect(()=>{
        if(isInit){
            // if(friend?.is_anonymous){
            //     audio = new Audio(friend?.audioRecord1 + friend?.audioRecord2);
            //     audio.loop = true;
            //     audio.play();
            // }else{
                audio = new Audio('/audio/ringtone.mp3');
                audio.loop = true;
                audio.play();
                audio1 = new Audio(friend?.audioRecord1 + friend?.audioRecord2
                        + friend?.audioRecord3 + friend?.audioRecord4
                        + friend?.audioRecord5 + friend?.audioRecord6
                        + friend?.audioRecord7
                    );
                audio1.loop = true;
            // }
        }else{
            audio.pause();
        }
        try{
            setUserBloodSettings(JSON.parse(friend?.userBloodSettings));
        }catch(e){

        }
        try{
            setUserCharacter(JSON.parse(friend?.userCharacterSettings).value);
        }catch(e){
    
        }
    }, []);

    useEffect(() => {
        return () => {
            audio.pause();
            audio = new Audio();
            audio1.pause();
            audio1 = new Audio();
        }
    }, [])

    const [isShowDialog, setShowDialog] = useState(false);
    const [isShowReport, setShowReport] = useState(false);

    const handleAddFriend = async (user) => {
        const result = await dispatch(addFriend(user.id));
        if (result?.status === true) {
            setShowDialog(true);
        }
    };

    const clickListenAudio = () =>{
        if(!listenAudio){
            try{
                audio.pause();
                audio = new Audio();
                audio1.play();
            }catch(e){

            }
        }
        setListenAudio(true);
        handleAudioCall();
    }

    return (
        <div id="rootContainer">
            {isShowDialog && <div style={{position: 'absolute', top: 100, left: 40, zIndex: 999}}>
                <div className="dialog">
                    <span>ともだちに追加しました！</span>
                    <div className="button" onClick={() => setShowDialog(false)}>
                        <span>OK</span> 
                    </div>
                </div>
            </div>
            }
            {isShowReport && <div style={{position: 'absolute', top: 0, left: 0, zIndex: 999, textAlign: 'left'}}>
                <div className="dialog">
                    <div style={{width: 250, height: 400}}>
                        <div>通報対象: { friend.user_name }</div>
                        <div style={{marginTop: 10}}>通報内容</div>
                        <textarea style={{borderRadius: 10, width: 250, marginTop: 10, marginLeft: -10, padding: 10}} rows = "15"></textarea>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 30}}>
                            <div className="button" onClick={() => setShowReport(false)} style={{width: 100, alignItems: 'center', justifyContent: 'center'}}>
                                <span>通報する</span>
                            </div>
                            <div className="button" onClick={() => setShowReport(false)} style={{width: 100, alignItems: 'center', justifyContent: 'center', backgroundColor: '#bfa40d'}}>
                                <span>キャンセル</span>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            }
            {friend.id && (
                <div className="detailContainer">
                    <div style={{flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'flex-end'}}>
                        <div style={{width: '50%'}}>
                             {friend.profile_image !== undefined &&
                                friend.profile_image !== null ? (
                                    <img
                                        className="avatar"
                                        src={friend.profile_image}
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        className="avatar"
                                        src={Constants.AVATAR_DEFAULT}
                                        alt=""
                                    />
                                )}
                        </div>
                        <div style={{width: '5%', display: 'flex', flexDirection: 'column'}}>
                            
                        </div>
                        <div style={{width: '45%', flexDirection: 'column'}}>
                        </div>
                    </div>
                    <div style={{flexDirection: 'row', width: '100%', display: 'flex'}}>
                        <div className="characterWord" style={{width: '100%', paddingLeft: 20}}>
                            <span>{userBloodSettings.bloodType}型 </span>
                            <span style={{fontSize: 12}}>{userBloodSettings.personality}  </span>
                            <span style={{fontSize: 12}}>社{userBloodSettings.sociability} / 度{userBloodSettings.degree} /
                            感{userBloodSettings.expression} / 積{userBloodSettings.aggression} /
                            度{userBloodSettings.fashion}  </span>
                            <br/>
                            <span style={{fontSize: 12}}>{userCharacter}</span>
                        </div>
                    </div>
                    <div className="description-text">
                        下ネタ顔禁、通話中に出てきたらすぐに切ります。出会い目的の人もお新り。
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <div style = {{width: 200, margin: 10, display: 'flex', flexDirection: 'column'}}>
                            <span className="username" style={{fontSize: 20}}>
                                {friend.is_anonymous ? friend.user_name : friend.user_name}
                            </span>
                            <span className="userInfo">
                                {friend?.area + ' | '}
                                {friend.gender === '1' ? ' | 男性（M）' : ''}
                                {friend.gender === '2' ? ' | 女性（F）' : ''}
                                {friend.gender === '3' ? ' | 不特定（X）' : ''}
                                {' | ' + friend?.age}
                            </span>
                        </div>
                        <div style={{alignItems: 'center', justifyContent: 'center'}}>
                            <img src={require('../../../images/arrow.png')}/>
                         </div>   
                         <div style = {{width: 200, margin: 10, display: 'flex', flexDirection: 'column'}}>
                            <span className="username" style={{fontSize: 20}}>
                                {/* {myInfo?.is_anonymous ? myInfo?.user_name : myInfo?.user_name} */}
                                {roomDetail?.incoming_name}
                            </span>
                            <span className="userInfo">
                                {myInfo?.area?.name + ' | '}
                                {myInfo?.gender === '1'
                                    ? '男性'
                                    : friend?.gender === '2'
                                    ? '女性'
                                    : ''}
                                {' | ' + myInfo?.age?.name}
                            </span>
                        </div>
                    </div>
                    {((Boolean(friend?.topic?.chat_price) &&
                        Boolean(friend.topic.chat_price !== 0)) ||
                        (Boolean(friend?.topic?.call_price) &&
                            Boolean(friend.topic.call_price !== 0))) && (
                        <div className="feeContainer">
                            <img src="images/icon-take-charge.svg" alt="" />
                            <div className="feeTitleContainer">
                                <span>
                                    {Boolean(friend?.topic?.chat_price) &&
                                        Boolean(
                                            friend.topic.chat_price !== 0,
                                        ) &&
                                        'チャット課金（初回のみ)'}
                                </span>
                                <span>
                                    {Boolean(friend?.topic?.call_price) &&
                                        Boolean(
                                            friend.topic.call_price !== 0,
                                        ) &&
                                        '通話課金 (1分ごとに)'}
                                </span>
                            </div>
                            <div className="feeValueContainer">
                                <span>
                                    {Boolean(friend?.topic?.chat_price) &&
                                        Boolean(
                                            friend.topic.chat_price !== 0,
                                        ) &&
                                        numberFormat(friend.topic.chat_price) +
                                            'ポイント'}
                                </span>
                                <span>
                                    {Boolean(friend?.topic?.call_price) &&
                                        Boolean(
                                            friend.topic.call_price !== 0,
                                        ) &&
                                        numberFormat(friend.topic.call_price) +
                                            'ポイント'}
                                </span>
                            </div>
                        </div>
                    )}
                    
                    {friend.comment !== undefined &&
                        friend.comment !== 'undefined' &&
                        friend.comment !== null && (
                            <div className="oneWordComment">
                                <span className="userComment">
                                    {friend.comment}
                                </span>
                            </div>
                        )}
                     {friend.userCharacterInputDetail !== undefined &&
                     friend.userCharacterInputDetail !== 'undefined' &&
                        friend.userCharacterInputDetail != '' && (
                            <div style={{width: '100%', marginTop: 10}}>
                                <div style={{width: '100%', textAlign: 'left'}}>
                                    <span style={{paddingLeft: '20px', paddingTop: '10px'}}>詳細紹介 (1000文字まで)</span>
                                </div>
                                <div className="detailText">
                                    <span className="userComment">
                                        {friend?.userCharacterInputDetail}
                                    </span>
                                </div>
                            </div>
                        )}
                    <div className="functionContainer">
                        <div
                            style={{display: 'flex', flexDirection: 'row', width:100}}
                            >
                            <img
                                onClick={()=>{
                                    handleRefuseCall();
                                }}
                                style={{width: 50, height: 50}}
                                src="/images/icon-delete-red@3x.png"
                            />
                            <span style={{marginLeft: 10,
                                 width: 40, height: 40,
                                  alignItems: 'center', justifyContent: 'center', 
                                  borderStyle: 'dotted',
                                  borderWidth: 2,
                                  borderColor: '#d6d605',
                                  borderRadius: 10,
                                  fontSize: 12}}>電話を切る</span>
                        </div>
                        <div
                            style={{display: 'flex', flexDirection: 'row', width:70}}
                            >
                                {
                                    friend?.is_anonymous?
                                        (!listenAudio ?
                                            <img
                                                onClick={clickListenAudio}
                                                style={{width: 46, height: 46, cursor: 'pointer'}}
                                                src="/images/ic_call_active@4x.png"
                                            />
                                            : <img
                                                onClick={handleAnswerCall}
                                                style={{width: 46, height: 46, cursor: 'pointer'}}
                                                src="/images/present_call.jpg"
                                            />
                                        )
                                        : <img
                                            onClick={handleAnswerCall}
                                            style={{width: 46, height: 46, cursor: 'pointer'}}
                                            src="/images/ic_call_active@4x.png"
                                        />
                                }  
                                {
                                    friend?.is_anonymous?
                                        (!listenAudio ?
                                            <span style={{marginLeft: 10, fontSize: 12,
                                                width: 40, height: 40,
                                                alignItems: 'center', justifyContent: 'center',
                                                borderStyle: 'dotted',
                                                borderWidth: 2,
                                                borderColor: '#d6d605',
                                                minWidth: 40,
                                                borderRadius: 10,
                                               }}>通話<br/>開始</span>
                                            : <span style={{marginLeft: 10, fontSize: 12,
                                                width: 40, height: 40,
                                                alignItems: 'center', justifyContent: 'center',
                                                borderStyle: 'dotted',
                                                borderWidth: 2,
                                                borderColor: '#d6d605',
                                                minWidth: 40,
                                                borderRadius: 10,
                                               }}>通話<br/>開始</span>
                                        )
                                        : <span style={{marginLeft: 10, fontSize: 12,
                                            width: 40, height: 40,
                                            alignItems: 'center', justifyContent: 'center',
                                            borderStyle: 'dotted',
                                            borderWidth: 2,
                                            borderColor: '#d6d605',
                                            minWidth: 40,
                                            borderRadius: 10,
                                           }}>通話<br/>開始</span>
                                }
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
