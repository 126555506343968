import React, {useState} from 'react';
import inputStyle from './baseInputStyle';

export default function BaseInput(props) {
    const [inputTxt, setInputTxt] = useState(
        props.initVal ? props.initVal : '',
    );

    function onChangeText(e) {
        let txt = e.target.value;
        setInputTxt(txt);
        props.onChangeText(txt);
    }

    return (
        <input
            style={inputStyle}
            type={props.secureTextEntry ? 'password' : 'text'}
            onChange={onChangeText}
            value={inputTxt}
        />
    );
}
