import React, { useState } from 'react';
import {connect, useDispatch} from 'react-redux';
import {requestCall} from '../actions';
import {closeModalPage, openModalPage, openPopup} from '../../app/actions';
import {ROOM_TYPE_CALL_TOPIC, callStates} from '../constants';
import {useNetworkStatus} from '../../../util/hooks';
import clock from '../../base/clock';
import {MODAL_CONFIRM_CALL} from '../../app/constants';

import { SETTING_CALL_VOICE, MODAL_DETAIL_USER_HOME } from "../../app/constants";
import { useHistory } from "react-router-dom";

function CallBtn({
    userId,
    roomId,
    roomTypeId,
    topicInfo = null,
    Component = null,
    disabled = true,
    callState,
    isAnonymous,
    timeExpire,
    name,
    gender_name,
    area_name,
    age_name,
    profile_image,
    audioPresent,
    soundRecordNo,
}) {
    const dispatch = useDispatch();

    const remainTime = timeExpire ? timeExpire - clock.seconds() : null;

    const networkStatus = useNetworkStatus();

    const history = useHistory();

    const handleCall = () => {
        if (!networkStatus) {
            dispatch(openPopup('ネットワークから切断されました。'));
            return;
        }
        if (remainTime !== null && remainTime <= 0) {
            dispatch(
                openPopup(
                    '該当する声がありません。既に削除されている可能性があります。',
                ),
            );
            return;
        }

        if (callState == callStates.NONE) {
            let callFlag = localStorage.getItem(SETTING_CALL_VOICE);
            if(!soundRecordNo){
                dispatch(
                    openModalPage({
                        type: MODAL_DETAIL_USER_HOME,
                        data: {
                            userId: userId,
                            roomId: roomId,
                            roomTypeId: roomTypeId,
                            topicInfo: topicInfo,
                            Component: Component,
                            disabled: disabled,
                            callState: callState,
                            isAnonymous:isAnonymous,
                            timeExpire:timeExpire,
                            name,
                            gender_name,
                            area_name,
                            age_name,
                            profile_image,
                        }
                    })
                );
                return ;
            }
            const now = clock.now();
            if (topicInfo?.id) {
                if (topicInfo.call_price > 0) {
                    if (
                        roomTypeId == ROOM_TYPE_CALL_TOPIC &&
                        now > topicInfo.expire_at * 1000
                    ) {
                        dispatch(
                            openPopup(
                                '該当する声がありません。既に削除されている可能性があります。',
                            ),
                        );
                    } else {
                        if (userId === topicInfo.user_id) {
                            dispatch(
                                openModalPage({
                                    type: MODAL_CONFIRM_CALL,
                                    topicInfo: topicInfo,
                                    userId,
                                    roomTypeId,
                                    isAnonymous,
                                    roomId,
                                }),
                            );
                        } else {
                            dispatch(closeModalPage());
                            dispatch(
                                requestCall(
                                    userId,
                                    roomId,
                                    roomTypeId,
                                    topicInfo.id,
                                    isAnonymous,
                                    soundRecordNo
                                ),
                            );
                        }
                    }
                } else {
                    dispatch(closeModalPage());
                    dispatch(
                        requestCall(
                            userId,
                            roomId,
                            roomTypeId,
                            topicInfo.id,
                            isAnonymous,
                            soundRecordNo
                        ),
                    );
                }
            } else {
                dispatch(closeModalPage());
                dispatch(
                    requestCall(userId, roomId, roomTypeId, null, isAnonymous, soundRecordNo),
                );
            }
        } else {
            dispatch(openPopup('トークできませんでした。すでにトーク中です。'));
        }
    };

    return (
        <>
            {Component ? (
                <span
                    className={
                        disabled ? 'request-call-disable' : 'request-call'
                    }
                    onClick={disabled ? () => {} : handleCall}>
                    <Component />
                </span>
            ) : (
                <img
                    className="request-call"
                    onClick={handleCall}
                    src="/images/ic_call_active@2x.png"
                    alt=""
                />
            )}
        </>
    );
}

function _mapStateToProps(state) {
    return {
        callState: state['cocoro/call'].callState,
        audioPresent: state['cocoro/app'].audioPresent,
    };
}

export default connect(_mapStateToProps)(CallBtn);
