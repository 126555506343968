import React, {useState, useEffect} from 'react';
import {updateImageUri} from '../actions';
import {connect} from 'react-redux';
import {Constants} from '../../../util/Constants';
import {useHistory} from 'react-router-dom';
import '../defaultimages.scss';
import {getDefaultImages} from '../../app/functions';

const IMAGE_IN_A_ROW = 3;

function ListPicture({images, updateImageUri}) {
    const [selectedAvatar, setSelectedAvatar] = useState('');
    const [defaultAvatars, setDefaultAvatars] = useState(null);

    let userInfoStorage = localStorage.getItem('user_info');
    try{
        userInfoStorage = JSON.parse(userInfoStorage);
    }catch(e){
        userInfoStorage = {}
    }

    const userInfo = userInfoStorage.userRegisterInfo;
    let history = useHistory();
    let onBack = () => {
        history.goBack();
    };

    let changeAvatar = () => {
        if (selectedAvatar !== '') {
            userInfoStorage.userAvatarRegisterInfo  = {
                imageUri: defaultAvatars[selectedAvatar].url,
                type_image: Constants.IMAGE_TYPE_LINK,
            }
            localStorage.setItem('user_info', JSON.stringify(userInfoStorage));
            updateImageUri(
                defaultAvatars[selectedAvatar].url,
                Constants.IMAGE_TYPE_LINK,
                true,
            );
            onBack();
        }
    };

    useEffect(() => {
        let avatars = getDefaultImages(userInfo.age_id, userInfo.gender_id);
        setDefaultAvatars(avatars);
    }, [userInfo.age_id, userInfo.gender_id]);

    let onAvatarClick = (index) => {
        if (selectedAvatar === index) {
            setSelectedAvatar('');
        } else {
            setSelectedAvatar(index);
        }
    };

    return (
        <div id="images">
            <header>
                <div className="back">
                    <span onClick={() => onBack()}>キャンセル</span>
                </div>
                <h3>アバター選択</h3>
                <div className="right"></div>
            </header>
            <div className="mainContainer">
                <div className="imagesContainer">
                    {defaultAvatars &&
                        defaultAvatars.map((item, index, array) => {
                            let position1 = index * IMAGE_IN_A_ROW + 0;
                            let position2 = index * IMAGE_IN_A_ROW + 1;
                            let position3 = index * IMAGE_IN_A_ROW + 2;
                            let item1 = array[position1];
                            let item2 = array[position2];
                            let item3 = array[position3];
                            return (
                                <div>
                                    {item1 && (
                                        <div className="rowContainer">
                                            {item1 && (
                                                <div
                                                    className="item"
                                                    onClick={() =>
                                                        onAvatarClick(position1)
                                                    }>
                                                    <img
                                                        className={
                                                            position1 ===
                                                            selectedAvatar
                                                                ? 'selectedImage'
                                                                : 'unselectedImage'
                                                        }
                                                        src={item1.url}
                                                        alt=""
                                                    />
                                                </div>
                                            )}
                                            {item2 && (
                                                <div
                                                    className="item"
                                                    onClick={() =>
                                                        onAvatarClick(position2)
                                                    }>
                                                    <img
                                                        className={
                                                            position2 ===
                                                            selectedAvatar
                                                                ? 'selectedImage'
                                                                : 'unselectedImage'
                                                        }
                                                        src={item2.url}
                                                        alt=""
                                                    />
                                                </div>
                                            )}
                                            {item3 && (
                                                <div
                                                    className="item"
                                                    onClick={() =>
                                                        onAvatarClick(position3)
                                                    }>
                                                    <img
                                                        className={
                                                            position3 ===
                                                            selectedAvatar
                                                                ? 'selectedImage'
                                                                : 'unselectedImage'
                                                        }
                                                        src={item3.url}
                                                        alt=""
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
                <div className="buttonContainer">
                    {selectedAvatar !== '' ? (
                        <div className="button" onClick={changeAvatar}>
                            <span>決定</span>
                        </div>
                    ) : (
                        <div className="buttonDisable">
                            <span>決定</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        images: state['cocoro/register'].images,
        lastRoute: state['cocoro/app'].lastRoute,
        loading: state['cocoro/app'].loading,
    };
}

let _mapDispatchToProps = {
    updateImageUri,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ListPicture);
