import {httpRequest} from '../base/api/actions';
import {addFriendApi, searchUserApi} from '../base/api';

export function searchUsers(
    {keyword, last_doc_id, per_page},
    loadMore = false,
) {
    return async function (dispatch, getState) {
        const result = await dispatch(
            httpRequest(
                searchUserApi({keyword, last_doc_id, per_page}),
                !loadMore,
            ),
        );
        return result;
    };
}

export function addFriend(userId) {
    return async function (dispatch, getState) {
        const result = await dispatch(
            httpRequest(addFriendApi({user_id: userId}), true),
        );
        return result;
    };
}
