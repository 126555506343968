import {CHAT_TYPE_TEXT} from '../chat/constants';

export const MODAL_CONFIRM_CHAT = 1;
export const MODAL_DETAIL_USER_HOME = 2;
export const MODAL_DETAIL_FRIEND = 3;
export const MODAL_DETAIL_USER_CHAT = 4;
export const MODAL_BONUS_CHAT = 5;
export const MODAL_CONFIRM_CALL = 6;
export const MODAL_CHOOSE_GENDER = 7;
export const MODAL_CHOOSE_AGE = 8;
export const MODAL_CHOOSE_AREA = 9;
export const MODAL_CHOOSE_TIME = 10;
export const MODAL_CHOOSE_GENDER_REGISTER = 11;
export const MODAL_CHOOSE_AGE_REGISTER = 12;
export const MODAL_CHOOSE_AREA_REGISTER = 13;
export const MODAL_CHOOSE_GENDER_FILTER_TOPIC = 14;
export const MODAL_CHOOSE_AGE_FILTER_TOPIC = 15;
export const MODAL_CHOOSE_ACTIVE_FILTER_TOPIC = 16;
export const MODAL_PAID_CALL = 17;
export const MODAL_CHOOSE_NAME = 18;
export const MODAL_CHOOSE_EXPIRE_TIME = 19;
export const MODAL_HISTORY_USER = 20;
export const MODAL_REPLY_USER = 21;
export const MODAL_CREATE_TOPIC = 22;
export const MODAL_RATE = 23;
export const MODAL_REPORT = 24;
export const MODAL_CHOOSE_ACTIVE_FILTER_AREA = 25;
export const MODAL_CHOOSE_ACTIVE_FILTER_TOPIC_AREA = 27;
export const MODAL_CHOOSE_USER = 28;
export const MODAL_INPUT_PW = 29;

export const TYPE_RATE_CHAT = 1;
export const TYPE_RATE_CALL = 2;

export const HttpCode = {
    NOT_FOUND: 404,
};

export const INIT_ROOM_DATA_DEFAULT = {
    unreadCount: 0,
    messageIds: [],
    latestMessage: {
        data: '',
        message_type_id: CHAT_TYPE_TEXT,
        username: '',
        user_id: '',
        room_id: '',
        is_read: false,
    },
};

export const dbCollections = {
    REALTIME: 'real_time',
};

// TODO: need collect realtime type def to here
// migrate to realtimeTypes
export const REALTIME_TYPE_CHAT = '1';
export const REALTIME_TYPE_CALL = '2';
export const REALTIME_TYPE_USER = '3';
export const REALTIME_TYPE_USER_ACTION_BONUS = '1';
export const REALTIME_TYPE_USER_ACTION_BONUS_DONE = '2';
export const REALTIME_TYPE_DATA = '4';
export const REALTIME_TYPE_CHAT_TOPIC = '5';
export const REALTIME_TYPE_WEBRTC = '100';
export const REALTIME_TYPE_DATA_ACTION_READ = '0';
export const REALTIME_TYPE_CALL_ACTION_REFUSE = '0';
export const REALTIME_TYPE_CALL_ACTION_CALLER = '1';
export const REALTIME_TYPE_CALL_ACTION_CALLEE = '2';
export const REALTIME_TYPE_CALL_ACTION_AGREE = '3';
export const REALTIME_TYPE_CALL_ACTION_END = '4';
export const REALTIME_TYPE_CALL_BALANCE = '5';
export const REALTIME_TYPE_CALL_ACTION_END_BALANCE = '6';
export const REALTIME_TYPE_CALL_ACTION_REQUEST_PAID = '7';
export const REALTIME_TYPE_CALL_ACTION_CONFIRM_REQUEST_PAID = '8';
export const REALTIME_TYPE_CHAT_CONNECTION = '9';
export const REALTIME_TYPE_MESSAGE_NOTIFICATION = '10';
export const REALTIME_TYPE_MESSAGE_SYSTEM_CALL_MISS = '8';
export const REALTIME_TYPE_MESSAGE_SYSTEM_CALL_CANCEL = '7';


export const realtimeTypes = {
    ADD_FRIEND: '11',
    DELETE_FRIEND: '12',
    DELETE_HISTORY: '13',
    DELETE_ROOM_TOPIC: '14',
    DELETE_ROOM_FRIEND: '15',
};


export const DEFAULT_THEMA_ID = "rLhDPHfAZpFUayce1wxz";

export const DEFAULT_TEMA_TITLE = "DEFAULT";

export const SETTING_CALL_VOICE = "setting_call_voice";

export const AREA_LOCAL_STORE = "cocoro2.0/areas";
export const CATEGORY_LOCAL_STORE = "cocoro2.0/category";
export const SUBCATEGORY_LOCAL_STORE = "cocoro2.0/subcategory";
export const CREATE_NEW_ROOM_ID = "cocoro2.0/create_new_room_id";
export const HISTORY_PAGE_URL = "cocoro2.0/history_page_url";
export const HISTORY_PAGE_USER_ID = "cocoro2.0/history_page_user_id";
export const AUDIO_PRESENT_URL = "cocoro2.0/audio_present_url";
export const CALL_ID_TEMP = 'cocoro2.0/call_id_temp';

export const SOUND_RECORD_DATA = 'cocoro2.0/sound_record_data';