import React from 'react';
import '../styles.scss';

export default function SpinnerTopic({title, currentValue, action}) {
    return (
        <div id="SpinnerTopic">
            <span>{title}</span>
            <div className="spinnerTopicContainer" onClick={action}>
                <span className="span-value">{currentValue.name}</span>
                <img src="/images/ic_dropdown.svg" />
            </div>
        </div>
    );
}
