import {
    GET_MY_SETTINGS,
    GET_BLOCK_LIST,
    LOAD_MORE_BLOCK_LIST,
    UNBLOCK_USER,
} from './actionTypes';
import {
    setLoading,
    openPopup,
    openModalPage,
    setNaviIndex,
    changeRoute,
} from '../app/actions';
import {clearFriends} from '../friendlist';
import {clearInfo} from '../mypage';
import {CHECK_AUTH} from '../app/actionTypes';
import {timeSettings} from './functions';
import {clearMyData, updateMyProfile} from '../user';
import {
    getMySettings as settingsApi,
    updateSettings as updateApi,
    logout as logOutApi,
} from './apis';
import {MODAL_CHOOSE_TIME} from '../app/constants';
import {isNative} from '../../util/device';
import {httpRequest} from '../base/api/actions';
import {getBlockApi, unblockApi} from '../base/api';
import {UPDATE_CALL_RINGTONE_SETTING} from './actionTypes';

export function getSettings(timeSetting, callRingtone, messageRingtone) {
    return {
        type: GET_MY_SETTINGS,
        timeSetting,
        callRingtone,
        messageRingtone,
    };
}

export function updateCallRingtoneSetting(enable) {
    return {
        type: UPDATE_CALL_RINGTONE_SETTING,
        enable,
    };
}

export function getBlocks(blocks, hasNextPage) {
    return {
        type: GET_BLOCK_LIST,
        blocks,
        hasNextPage,
    };
}

export function removeBlockUser(userId) {
    return {
        type: UNBLOCK_USER,
        userId,
    };
}

export function loadMoreBlocks(blocks, hasNextPage) {
    return {
        type: LOAD_MORE_BLOCK_LIST,
        blocks,
        hasNextPage,
    };
}

export function openTimeSettings(currentTime) {
    let id = currentTime.id;
    return function (dispatch) {
        console.log( timeSettings, id);
        dispatch(openModalPage({type: MODAL_CHOOSE_TIME, timeSettings, id}));
    };
}

export function updateTimeSettings(timeSetting) {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        let {callRingtone, messageRingtone} = getState()['cocoro/settings'];
        updateApi(
            access_token,
            callRingtone,
            messageRingtone,
            timeSetting.value,
        ).then((resolve) => {
            dispatch(setLoading(false));
            try {
                dispatch({type: CHECK_AUTH, status: resolve.status});
                if (resolve.status === 200 || resolve.status === 201) {
                    dispatch(
                        updateMyProfile({
                            saved_message_time: timeSetting.value,
                        }),
                    );
                    let settings = resolve.data;
                    let messageTime = getMessageTime(
                        settings.saved_message_time,
                    );
                    dispatch(
                        getSettings(
                            messageTime,
                            settings.call_volume,
                            settings.chat_volume,
                        ),
                    );
                } else {
                    openPopup('想定しないエラーが発生しました。');
                    return;
                }
            } catch (error) {
                openPopup('想定しないエラーが発生しました。');
            }
        });
    };
}

export function updateSettings(callRingtone, messageRingtone, timeSetting, voiceCall) {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        return new Promise((resolve) => {
            updateApi(
                access_token,
                callRingtone,
                messageRingtone,
                timeSetting,
                voiceCall,
            ).then((res) => {
                dispatch(setLoading(false));
                try {
                    dispatch({type: CHECK_AUTH, status: res.status});
                    if (res.status === 200 || res.status === 201) {
                        let settings = res.data;
                        let messageTime = getMessageTime(
                            settings.saved_message_time,
                        );
                        dispatch(
                            getSettings(
                                messageTime,
                                settings.call_volume,
                                settings.chat_volume,
                            ),
                        );
                        resolve(true);
                    } else {
                        openPopup('想定しないエラーが発生しました。');
                        resolve(false);
                        return;
                    }
                } catch (error) {
                    openPopup('想定しないエラーが発生しました。');
                }
                resolve(false);
            });
        });
    };
}

export function getMySettings() {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        settingsApi(access_token).then((resolve) => {
            dispatch(setLoading(false));
            try {
                dispatch({type: CHECK_AUTH, status: resolve.status});
                if (resolve.status === 200 || resolve.status === 201) {
                    let settings = resolve.data;
                    let timeSetting = getMessageTime(
                        settings.saved_message_time,
                    );
                    dispatch(
                        getSettings(
                            timeSetting,
                            settings.call_volume,
                            settings.chat_volume,
                        ),
                    );
                } else {
                    openPopup('想定しないエラーが発生しました。');
                    return;
                }
            } catch (error) {
                openPopup('想定しないエラーが発生しました。');
            }
        });
    };
}

export function getBlockList({last_doc_id, per_page}) {
    return async function (dispatch, getState) {
        const result = await dispatch(
            httpRequest(getBlockApi({last_doc_id, per_page}), true),
        );
        return result;
    };
}

export function unBlock(userId) {
    return async function (dispatch, getState) {
        const result = await dispatch(httpRequest(unblockApi(userId)));
        if (result) {
            dispatch(removeBlockUser(userId));
        }
        return result;
    };
}

export function getMessageTime(value) {
    try {
        let timeSetting = timeSettings.find((item) => item.value === value);
        if (timeSetting === undefined) {
            return {name: '1時間', value: 60, id: '5'};
        } else {
            return timeSetting;
        }
    } catch (ex) {
        return {name: '1時間', value: 60, id: '5'};
    }
}
