import {get, put, post, deleteApi} from '../base/portal/apis';

export function updateSettings(
    token,
    call_volume,
    chat_volume,
    saved_message_time,
    voiceCall,
) {
    let url = '/api/v1/users/setting';
    let data = {
        call_volume,
        chat_volume,
        saved_message_time,
        voiceCall,
    };
    return put(url, token, data);
}

export function getMySettings(token) {
    let url = '/api/v1/users/setting';
    return get(url, token);
}

export function logout(token, deviceId) {
    let url = '/api/v1/logout';
    let data = {device_id: deviceId};
    return post(url, token, data);
}

export function getBlockList(token, index, per_page) {
    let url = '/api/v1/users/blocks?';
    if (index !== null) {
        url = url + 'current_index=' + index + '&';
    }
    url = url + 'per_page=' + per_page;
    return get(url, token);
}

export function unBlockFriend(token, userID) {
    let url = '/api/v1/users/unblock';
    let data = {id: userID};
    return deleteApi(url, token, data);
}
