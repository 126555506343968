import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {updateSNSInfo, getMyInfo} from '../actions';
import {InputField} from '../../changepassword';
import '../styles.scss';

function SNSInfo({userInfo}) {
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [facebookError, setFacebookError] = useState('');
    const [instagramError, setInstagramError] = useState('');
    const [twitterError, setTwitterError] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();

    const onBack = () => {
        history.goBack();
    };

    useEffect(() => {
        dispatch(getMyInfo());
    }, []);

    useEffect(() => {
        setFacebook(userInfo?.facebook || '');
        setInstagram(userInfo?.instagram || '');
        setTwitter(userInfo?.twitter || '');
    }, [userInfo]);

    const setSNSInfo = () => {
        setFacebookError('');
        setInstagramError('');
        setTwitterError('');
        dispatch(
            updateSNSInfo(
                facebook,
                instagram,
                twitter,
                setFacebookError,
                setInstagramError,
                setTwitterError,
            ),
        );
    };

    return (
        <div id="snsinfo">
            <header>
                <div className="back">
                    <img
                        alt=""
                        onClick={onBack}
                        src="/images/icon-back-grey.png"
                    />
                </div>
                <h3>SNS情報の設定</h3>
                <div className="none" />
            </header>
            <div className="mainContainer">
                <InputField
                    title={'Facebook個人のリンク'}
                    data={facebook}
                    setData={setFacebook}
                    error={facebookError}
                    inputType={'text'}
                />
                <InputField
                    title={'Instagram個人のリンク'}
                    data={instagram}
                    setData={setInstagram}
                    error={instagramError}
                    inputType={'text'}
                />
                <InputField
                    title={'Twitter個人のリンク'}
                    data={twitter}
                    setData={setTwitter}
                    error={twitterError}
                    inputType={'text'}
                />

                <div className="buttonContainer">
                    <div className={'button'} onClick={setSNSInfo}>
                        <span>保存</span>
                    </div>
                    <span className="note">
                        これらの入力は任意ですが、SNSアカウントを通じて
                        <br />
                        あなたのことを知ってもらうことで、よりいっそう
                        <br />
                        あなたの魅力をアピールすることができます。
                    </span>
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        lastRoute: state['cocoro/app'].lastRoute,
        loading: state['cocoro/app'].loading,
        userInfo: state['cocoro/mypage'].userInfo,
    };
}

export default connect(_mapStateToProps)(SNSInfo);
