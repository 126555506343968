import ReducerRegistry from '../base/redux/ReducerRegistry';
import {SET_MY_INFO, SET_MY_USERS_INFO, CLEAR_INFO, CLEAR_USERS_INFO, UPDATE_APPROVAL} from './actionTypes';

let stateDefault = {
    userInfo: null,
};

ReducerRegistry.register('cocoro/mypage', (state = stateDefault, action) => {
    switch (action.type) {
        case SET_MY_INFO:
            return {
                ...state,
                userInfo: action.info,
            };
        case SET_MY_USERS_INFO:
            return {
                ...state,
                myUsers: action.info,
            };
        case CLEAR_INFO:
            return {
                ...state,
                userInfo: null,
            };
        case CLEAR_USERS_INFO:
            return {
                ...state,
                myUsers: null,
            };
        case UPDATE_APPROVAL: {
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    is_approval: true,
                },
            };
        }
        default:
            return state;
    }
});
