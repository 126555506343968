import {
    GET_HISTORY_SUCCESS,
    GET_HISTORY_FAIL,
    IS_LOADING,
    CLEAR_DATA_HISTORY,
    CLEAR_REPLY_LIST,
    GET_REPLY_LIST_SUCCESS,
    LOAD_MORE_REPLY_LIST_SUCCESS,
    GET_TOPIC_SUCCESS,
    UPDATE_TOPIC_IS_CALLABLE_SUCCESS,
    CONFIRM_ERROR,
    GET_REPLY_LIST_FAIL,
    UPDATE_REPLY,
    ADD_REPLY,
    IS_BLOCK_USER,
    SET_HISTORY,
    REPLY_TOPIC,
    DELETE_HISTORY_ITEM,
    DELETE_REPLY_ITEM,
} from './actionType';
import {updateTopicCallable} from './api';
import {Constants} from '../../../util/Constants';
import {setLoading, openModalPage, openPopup} from '../../app/actions';
import {getFriendInfo as userApi} from '../../friendlist/apis';
import {CHANGE_ROUTE, CHECK_AUTH} from '../../app/actionTypes';
import {httpRequest} from '../../base/api/actions';
import {getHistoryApi, getReplyListApi, getTopicApi} from '../../base/api';
import {messageStatusTexts} from '../../chat/constants';
import chatdb from '../../chat/chatdb';
import {MODAL_HISTORY_USER, MODAL_REPLY_USER} from '../../app/constants';

export function setIsBlockUser(isBlock) {
    return {
        type: IS_BLOCK_USER,
        isBlock,
    };
}

export function getHistorySuccess(data, filterType) {
    return {
        type: GET_HISTORY_SUCCESS,
        lastDocHistory: data.lastDocId,
        histories: data.histories,
        filterType,
    };
}

export function getHistoryFail(error) {
    return {
        type: GET_HISTORY_FAIL,
        error,
    };
}

export function setHistories(histories, lastDocHistory) {
    return {
        type: SET_HISTORY,
        histories,
        lastDocHistory,
    };
}

export function deleteHistoryItem(historyId) {
    return {
        type: DELETE_HISTORY_ITEM,
        historyId,
    };
}

export function deleteReplyItem(replyId) {
    return {
        type: DELETE_REPLY_ITEM,
        replyId,
    };
}

export function clearDataHistory() {
    return {
        type: CLEAR_DATA_HISTORY,
    };
}

export function logout() {
    return {
        type: CHANGE_ROUTE,
        route: 'LOGIN',
    };
}

export function confirmError() {
    return {
        type: CONFIRM_ERROR,
        error: null,
    };
}

export function updateReply(reply) {
    return {
        type: UPDATE_REPLY,
        reply,
    };
}

export function addReply(reply) {
    return {
        type: ADD_REPLY,
        reply,
    };
}

export function updateReplyTopic(topic) {
    return {
        type: REPLY_TOPIC,
        topic,
    };
}

export function mergeLine(message) {
    return message.replace(/(\r\n|\n|\r)/gm, ' ');
}

export function showMessage(dispatch, id, replies) {
    replies.forEach(async (reply) => {
        const messages = await chatdb.getMessages({room_id: reply.id});
        let unreadCount = 0;
        if (messages && messages.length > 0) {
            const latestMessage = messages[messages.length - 1];
            reply.is_read =
                latestMessage.status === messageStatusTexts.READ ? true : false;

            messages.forEach((message) => {
                if (
                    message.user_id !== id &&
                    message.status !== messageStatusTexts.READ
                ) {
                    unreadCount = unreadCount + 1;
                }
            });
            reply.latest_message = latestMessage;
        } else {
            reply.latest_message = null;
        }
        reply.unreadCount = unreadCount;
        dispatch(updateReply(reply));
    });
}

export const setUnreadHistory = (roomId, totalUnRead) => {
    return {
        type: 'SET_UNREAD_HISTORY',
        roomId,
        totalUnRead,
    };
};

export const plusUnreadHistory = (roomId) => {
    return {
        type: 'PLUS_UNREAD_HISTORY',
        roomId,
    };
};

export function processUnread(histories) {
    return async function (dispatch, getState) {
        histories.forEach(async (h) => {
            const unread = await chatdb.countUnreadMessageByRoomId(h.room_id);
            dispatch(setUnreadHistory(h.room_id, unread));
        });
    };
}

export function getHistoryList(filterType) {
    return async function (dispatch, getState) {
        dispatch(setHistories([], null));
        const result = await dispatch(
            httpRequest(getHistoryApi(filterType, null), true),
        );
        if (result) {
            // dispatch(clearDataHistory());
            dispatch(getHistorySuccess(result, filterType));
            dispatch(processUnread(result.histories));
        } else {
            dispatch(getHistoryFail(Constants.EXCEPTION));
        }
        return result;
    };
}

export function loadMore(filterType) {
    return async function (dispatch, getState) {
        const {histories, lastDocHistory} = getState()[Constants.STATE_HISTORY];
        if (!lastDocHistory) {
            return null;
        }
        const result = await dispatch(
            httpRequest(getHistoryApi(filterType, lastDocHistory), false),
        );
        result.histories = histories.concat(result.histories);
        if (result) {
            dispatch(getHistorySuccess(result, filterType));
            dispatch(processUnread(result.histories));
        } else {
            dispatch(getHistoryFail(Constants.EXCEPTION));
        }
        return result;
    };
}

export function clearDataReplyList() {
    return {
        type: CLEAR_REPLY_LIST,
    };
}

export function getReplyListSuccess(data) {
    return {
        type: GET_REPLY_LIST_SUCCESS,
        replyList: data.replies,
        replyLastDocId: data.lastDocId,
        replyFilter: data.replyFilter,
    };
}

export function getReplyListFail(error) {
    return {
        type: GET_REPLY_LIST_FAIL,
        error,
    };
}

export function getReplyListAction(topicId, filterType) {
    return async function (dispatch, getState) {
        const result = await dispatch(
            httpRequest(getReplyListApi(topicId, filterType, null), true),
        );
        if (!result) {
            return null;
        }
        result.replyFilter = filterType;
        await dispatch(getReplyListSuccess(result));
        return result;
    };
}

export function loadMoreReply(topic_id) {
    return async function (dispatch, getState) {
        const {userInfo} = getState()['cocoro/user'];
        let {replyLastDocId, replyList, replyFilter} = getState()[
            Constants.STATE_HISTORY
        ];
        if (!replyLastDocId) {
            return;
        }
        const result = await dispatch(
            httpRequest(
                getReplyListApi(topic_id, replyFilter, replyLastDocId),
                false,
            ),
        );

        if (!result) {
            return null;
        }
        result.replyFilter = replyFilter;
        result.replies = replyList.concat(result.replies);
        dispatch(getReplyListSuccess(result, userInfo.id));
    };
}

export function getTopicDetail(topicId) {
    return async function (dispatch, getState) {
        const result = await dispatch(httpRequest(getTopicApi(topicId)));
        if (!result) {
            return null;
        }
        dispatch(getTopicSuccess(result));
        return result;
    };
}

export function getTopicSuccess(topic) {
    return {
        type: GET_TOPIC_SUCCESS,
        topic,
    };
}

export function observeRealTimeCollection(data) {
    return function (dispatch, getState) {
        const {userInfo} = getState()['cocoro/user'];
        if (data.type === Constants.REAL_TIME_TYPE_TOPIC_MSG) {
            let replyList = getState()[Constants.STATE_HISTORY].replyList;
            const filter = replyList.filter((item) => {
                if (item.room_id !== item.room_id) {
                    return item;
                }
            });
            data.is_read = false;
            const result = [data, ...filter];
            dispatch(getReplyListSuccess(result, userInfo.id));
        }
    };
}

export function updateTopicSuccess(is_callable) {
    return {
        type: UPDATE_TOPIC_IS_CALLABLE_SUCCESS,
        is_callable,
    };
}

export function updateTopicIsCallable() {
    return function (dispatch, getState) {
        let {access_token} = getState()[Constants.STATE_USER].userInfo;
        let topic = getState()[Constants.STATE_HISTORY].topic;
        updateTopicCallable(access_token, topic.id, !topic.is_callable)
            .then((data) => {
                dispatch(setLoading(false));
                dispatch({type: CHECK_AUTH, status: data.status});
                if (data.status == 200 || data.status == 201) {
                    dispatch(updateTopicSuccess(!topic.is_callable));
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            })
            .catch((error) => {
                dispatch(setLoading(false));
                dispatch(openPopup('想定しないエラーが発生しました。'));
            });
    };
}

export function getUserInfo(
    id,
    is_anonymous,
    topic_id,
    history = null,
    room_id,
    anonymous,
) {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        userApi(access_token, id).then((resolve) => {
            dispatch(setLoading(false));
            try {
                dispatch({type: CHECK_AUTH, status: resolve.status});
                if (resolve.status === 200 || resolve.status === 201) {
                    if (history === null) {
                        dispatch(
                            openModalPage({
                                type: MODAL_REPLY_USER,
                                ...resolve.data,
                                is_anonymous,
                                topic_id,
                                room_id,
                                anonymous,
                            }),
                        );
                    } else {
                        dispatch(
                            openModalPage({
                                type: MODAL_HISTORY_USER,
                                history,
                                userInfo: resolve.data,
                            }),
                        );
                    }
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                    return;
                }
            } catch (error) {
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}
