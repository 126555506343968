import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import Dot  from "./Dot";
import { CSSTransition } from "react-transition-group";

import '../styles.scss';

const Tutorial = ({loggedIn, userInfo}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [pageIndex, setPageIndex]  = useState(0);

    const nextButtonClick = ()=>{
        if(pageIndex  < 3){
            setPageIndex(pageIndex+1);
        }else{
            goToLogin();
        }
    }

    const goToLogin = ()=>{
        history.push('/signup');
        // window.location.href = "/signup";
    }

    return (
        <div id="tutorial">
            <div className="header"></div>
            <CSSTransition in={false} timeout={200} classNames="my-node" onEnter={() => { console.log('------start----------') }} onExited={() => {console.log('------end----------')}}>
                <div className="content">
                    { pageIndex === 0 ?
                        <span>
                    ようこそいらっしゃいました。<br/>
                    なりたい自分になる為の工夫<br/>
                    ①&nbsp;&nbsp;完全署名で3つのキャラを<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;使い分で来ます。<br/>
                    ②&nbsp;&nbsp;キャラが不要になったら<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;作り替えできます。<br/>
                    ③&nbsp;&nbsp;友達何人できるかな？<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;3つのキャラ単位で<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;いっぱい友達が作くれます<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;録音イメージ交換でお<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;互いが承諾すると自動で<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;友達登録されます。
                </span>
                        : null
                    }
                    {
                        pageIndex === 1 ?
                            <>
                                <span>
                                    もう一つの大きな特徴一切の履歴がのこりません投稿もチャト内容も写真もぜーんぶ決められた時間で消えてなくなります
                                </span>
                                <img alt = "" src = {require('../../../images/munosekai.png')}/>
                            </>
                            : null
                    }
                    {
                        pageIndex === 2 ?
                            <>
                                <span>
                                    通話時の最大の特徴初めての人との初めての通話時に「あいさつ電話機能」が働きます。
                                </span>
                                <img src = {require('../../../images/Screenshot_8.png')} alt = ""/>
                            </>
                            : null
                    }
                    {
                        pageIndex === 3 ?
                            <>
                                <div style = {{ width: '100%', textAlign: 'center' }}>
                                    <span>
                                        3人の独立キャラ
                                    </span>
                                </div>
                                <img src = {require('../../../images/syokusyu.png')} alt = ""/>
                            </>
                            : null
                    }
            </div>
            </CSSTransition>
            <div className="footer">
                <div>
                    <button onClick = { goToLogin } style={{fontWeight: 'bold'}}>スキップ</button>
                </div>
                <div>
                    <Dot setPageIndex = { setPageIndex.bind(this, 0) } pressStatus = { pageIndex === 0 ? true : false }/>
                    <Dot setPageIndex = { setPageIndex.bind(this, 1) } pressStatus = { pageIndex === 1 ? true : false }/>
                    <Dot setPageIndex = { setPageIndex.bind(this, 2) } pressStatus = { pageIndex === 2 ? true : false }/>
                    <Dot setPageIndex = { setPageIndex.bind(this, 3) } pressStatus = { pageIndex === 3 ? true : false }/>
                </div>
                <div>
                    <button onClick = { nextButtonClick } style={{fontWeight: 'bold'}}>{pageIndex === 3 ? '行われ' : 'つぎへ' }</button>
                </div>
            </div>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    loggedIn: state['cocoro/user'].loggedIn,
    userInfo: state['cocoro/user'].userInfo,
});

export default connect(_mapStateToProps)(Tutorial);
