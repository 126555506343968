import React, {useState, useEffect} from 'react';
import {
    View,
} from 'react-native';
import {openPopup} from '../../app/actions';
import {connect} from 'react-redux';
// import Input from "../../signup/native/input/Input";

function OTPConfirm({navigation, route, openPopup}) {
    const [code, setCode] = useState('');

    let handleClick = async () => {
        // navigation.push('SettingCharacter', {
        //     ...route.params,
        //     bloodType: bloodType,
        //     personality: personality,
        //     sociability: sociability,
        //     degree: degree,
        //     expression: expression,
        //     aggression: aggression,
        //     fashion: fashion
        // });
        window.location.href = "/OTPResult";
        try {
            let res = await route.params.confirm.confirm(code);
            // setConfrim(null);
            console.log(res);
            window.location.href = "/OTPResult";
        } catch (error) {
            alert('Invalid code');
            // console.log(error)
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <span style={styles.txt}>
                    携帯メールに書いてある
                </span>
                <span style={styles.txt}>
                    確認コード (数字6桁)
                </span>
                <span style={styles.txt}>
                    を入力してください
                </span>
            </View>

            <View style={styles.wrapInput}>
                <input type = "text"
                    style={styles.input}
                    onChange={(e) => setCode(e.target.value)}
                    value={code}
                />
            </View>


            <View style={styles.warpButton}>
                <button
                    onClick={handleClick}
                    style={styles.ButtonSubmit}>
                    <span style={styles.spanButton}>つぎへ</span>
                </button>
            </View>
        </View>
    );
}

const styles = {
    container: {
        flex: 1,
        backgroundColor: '#A6D837',
        padding: 20,
        justifyContent: 'center',
        alignItems: 'center',
        width: 375,
        height: 667,
    },
    header: {
        alignItems: 'center',
        // height: 180,
        // paddingBottom: 30,
        flexDirection: 'column',
        paddingTop: 70
    },
    txt: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'center',
        marginTop: 5
    },
    input: {
        fontWeight: 'bold',
        width: 270,
        padding: 10,
        color: 'black',
        height: 50
    },
    wrapInput: {
        marginTop: 50,
        backgroundColor: '#F4F9FA',
        width: 300,
        height: 50,
        borderColor: '#DFE9EB',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 5,
        marginBottom: 5,
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: 2
    },
    warpButton: {
        width: '100%',
        marginTop: 70,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 35,
    },
    ButtonSubmit: {
        width: '80%',
        padding: 5,
        fontWeight: 'bold',
        backgroundColor: '#FFE033',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#DBBD16',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 4,
        borderBottomColor: '#DBBD16',
        borderRadius: 50,
        height: 70,
    },
    spanButton: {
        fontSize: 18,
        color: '#36383B',
        fontWeight: 'bold',
    },
};

function _mapStateToProps(state) {
    return {};
}

let _mapDispatchToProps = {
    openPopup,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(OTPConfirm);
