import React, {useState, useEffect} from 'react';
import {updateImageUri} from '../redux/action';
import {connect} from 'react-redux';
import {Constants} from '../../../util/Constants';
import {useHistory} from 'react-router-dom';
import './defaultimages.scss';
import {getMyProfile} from '../../changeprofile';
import {getDefaultImages} from '../../app';

let flags = true;
const IMAGE_IN_A_ROW = 3;

function DefaultImages({profile, getMyProfile, updateImageUri}) {
    let [selectedAvatar, setSelectedAvatar] = useState('');
    const [defaultAvatars, setDefaultAvatars] = useState('');
    let history = useHistory();

    let onBack = () => {
        flags = true;
        history.goBack();
    };

    let changeAvatar = () => {
        if (selectedAvatar !== '') {
            updateImageUri(
                defaultAvatars[selectedAvatar].url,
                Constants.IMAGE_TYPE_LINK,
                true,
            );
            localStorage.setItem(
                'profile_image',
                defaultAvatars[selectedAvatar].url,
            );
            flags = true;
            onBack();
        }
    };

    useEffect(() => {
        // getMyProfile();
    }, [getMyProfile]);

    useEffect(() => {
        if (profile?.age_id && profile?.gender) {
            let avatars = getDefaultImages(profile.age_id, profile.gender === 'undefined' ? '2' : profile.gender);
            setDefaultAvatars(avatars);
            if (profile?.profile_image) {
                let index = avatars.findIndex(
                    (item) => item.url === profile.profile_image,
                );
                if (index !== -1) {
                    setSelectedAvatar(index);
                }
            }
        }
    }, [profile?.age_id, profile?.gender, profile]);

    let onAvatarClick = (index) => {
        if (selectedAvatar === index) {
            setSelectedAvatar('');
        } else {
            setSelectedAvatar(index);
        }
    };

    return (
        <div id="images">
            <header>
                <div className="back">
                    {/* <span onClick={() => onBack()}>キャンセル</span> */}
                    <img src={"/images/icon-back-grey.png"} onClick={() => onBack()} />
                </div>
                <h3 style={{fontWeight: 'bold'}}>アバター選択</h3>
                <div className="right"></div>
            </header>
            <div className="mainContainer">
                <div className="imagesContainer">
                    {defaultAvatars &&
                        defaultAvatars.map((item, index, array) => {
                            let position1 = index * IMAGE_IN_A_ROW + 0;
                            let position2 = index * IMAGE_IN_A_ROW + 1;
                            let position3 = index * IMAGE_IN_A_ROW + 2;
                            let item1 = array[position1];
                            let item2 = array[position2];
                            let item3 = array[position3];
                            return (
                                <div key={index}>
                                    {item1 && (
                                        <div className="rowContainer">
                                            {item1 && (
                                                <div
                                                    className="item"
                                                    onClick={() =>
                                                        onAvatarClick(position1)
                                                    }>
                                                    <img
                                                        className={
                                                            position1 ===
                                                            selectedAvatar
                                                                ? 'selectedImage'
                                                                : 'unselectedImage'
                                                        }
                                                        src={item1.url}
                                                        alt=""
                                                    />
                                                </div>
                                            )}
                                            {item2 && (
                                                <div
                                                    className="item"
                                                    onClick={() =>
                                                        onAvatarClick(position2)
                                                    }>
                                                    <img
                                                        className={
                                                            position2 ===
                                                            selectedAvatar
                                                                ? 'selectedImage'
                                                                : 'unselectedImage'
                                                        }
                                                        src={item2.url}
                                                        alt=""
                                                    />
                                                </div>
                                            )}
                                            {item3 && (
                                                <div
                                                    className="item"
                                                    onClick={() =>
                                                        onAvatarClick(position3)
                                                    }>
                                                    <img
                                                        className={
                                                            position3 ===
                                                            selectedAvatar
                                                                ? 'selectedImage'
                                                                : 'unselectedImage'
                                                        }
                                                        src={item3.url}
                                                        alt=""
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>

                <div className="buttonContainer">
                    {selectedAvatar !== '' ? (
                        <div className="button" onClick={changeAvatar}>
                            <span className="button">決定</span>
                        </div>
                    ) : (
                        <div className="buttonDisable">
                            <span className="buttonDisable">決定</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        lastRoute: state['cocoro/app'].lastRoute,
        loading: state['cocoro/app'].loading,
        profile: state['cocoro/profile'].userProfile,
    };
}

let _mapDispatchToProps = {
    getMyProfile,
    updateImageUri,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(DefaultImages);
