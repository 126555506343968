import React from 'react';
import {filterTextDefault, Views} from '../constants';
import '../styles.scss';

const FilterTopic = ({
    currentView,
    filterHistory,
    onClickArea,
    onClickCategory,
    onClickSubCategory,
}) => {
    const filterArea = filterHistory.area?.name || filterTextDefault.Area;
    const filterCategory =
        filterHistory.category?.name || filterTextDefault.Category;
    const filterSubCategory =
        filterHistory.sub_category?.name || filterTextDefault.SubCategory;

    return (
        <div id="selection-option">
            <header>

                {filterCategory !== filterTextDefault.Category ? (
                    <div
                        className={
                            currentView === Views.SelectCategory
                                ? 'category-select'
                                : 'category'
                        }
                        onClick={onClickCategory}>
                        <img
                            className="imgCategory"
                            src="/images/smile-icon-solid.svg"
                        />
                        <h3 className="h3-active">{filterCategory}</h3>
                    </div>
                ) : (
                    <div
                        className={
                            currentView === Views.SelectCategory
                                ? 'category-select'
                                : 'category'
                        }
                        onClick={onClickCategory}>
                        <img
                            className="imgCategory"
                            src={
                            currentView === Views.SelectCategory
                            ? '/images/smile-icon-solid.svg'
                            : '/images/smile-icon-solid-unactive.svg'
                            }
                        />
                        <h3 className="h3-unactive">
                            {filterTextDefault.Category}
                        </h3>
                    </div>
                )}

                {filterSubCategory !== filterTextDefault.SubCategory ? (
                    <div
                        className={
                            currentView === Views.SelectSubCategory
                                ? 'subCategory-select'
                                : 'subCategory'
                        }
                        onClick={onClickSubCategory}>
                        <img
                            className="imgSubCategory"
                            src="/images/square-icon-solid.svg"
                        />
                        <h3>{filterSubCategory}</h3>
                    </div>
                ) : (
                    <div
                        className={
                            currentView === Views.SelectSubCategory
                                ? 'subCategory-select'
                                : 'subCategory'
                        }
                        onClick={onClickSubCategory}>
                        <img
                            className="imgSubCategory"
                            src={
                                currentView === Views.SelectSubCategory
                                    ? '/images/square-icon-solid.svg'
                                    : '/images/square-icon-solid-unactive.svg'
                            }
                        />
                        <h3 className="h3-unactive">
                            {filterTextDefault.SubCategory}
                        </h3>
                    </div>
                )}

                {filterArea !== filterTextDefault.Area ? (
                    <div
                        className={
                            currentView === Views.SelectArea
                                ? 'area-select'
                                : 'area'
                        }
                        onClick={onClickArea}>
                        <img
                            className="imgArea"
                            src={'/images/location-icon-solid.svg'}
                        />
                        <h3 className="h3-active">
                            {/* {filterArea} */}
                            絞り込み
                        </h3>
                    </div>
                ) : (
                    <div
                        className={
                            currentView === Views.SelectArea
                                ? 'area-select'
                                : 'area'
                        }
                        onClick={onClickArea}>
                        <img
                            className="imgArea-unactive"
                            src={
                                currentView === Views.SelectArea
                                    ? '/images/location-icon-solid.svg'
                                    : '/images/location-icon-solid-unactive.svg'
                            }
                        />
                        <h3 className="h3-unactive">
                            {/* {filterTextDefault.Area} */}
                            絞り込み
                        </h3>
                    </div>
                )}
            </header>
        </div>
    );
};

export default FilterTopic;
