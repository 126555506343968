import React, {useEffect, useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import {listenEndEvent, endCall, listener} from '../actions';
import Caller from './Caller';
import CallerSmall from './CallerSmall';
import Callee from './Callee';
import Calling from './Calling';
import {callStates} from '../constants';
import * as webrtc from '../../base/webrtc';
import '../styles.scss';
import {openPopup} from '../../app';
import {useNetworkStatus} from '../../../util/hooks';
import {pingOnPaidCallApi} from '../../base/api';
import { Call } from '@material-ui/icons';

function CallWrap({callState, myInfo, userInfo, roomDetail, isSmall, balance}) {
    const dispatch = useDispatch();
    const networkStatus = useNetworkStatus();

    useEffect(() => {
        const unsubscribe = dispatch(listener());
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        let unsub = null;
        if (roomDetail?.room_id) {
            unsub = dispatch(listenEndEvent(myInfo.id, roomDetail.room_id));
        }
        return () => unsub && unsub();
    }, [roomDetail?.room_id]);

    // ping to paid call only is connected
    useEffect(() => {
        if (
            balance &&
            roomDetail?.topicInfo?.call_price &&
            roomDetail.user_id !== roomDetail.topicInfo.user_id &&
            callState === callStates.CONNECTING
        ) {
            const itv = setInterval(async () => {
                await pingOnPaidCallApi(roomDetail.room_id);
            }, 5000);
            return () => clearInterval(itv);
        }
    }, [callState, roomDetail?.topicInfo, balance]);

    useEffect(() => {
        const isCalling =
            [
                callStates.CONNECTING,
                callStates.WAITING_CONNECT,
                callStates.CALLING,
            ].indexOf(callState) !== -1;

        if (!networkStatus && isCalling) {
            dispatch(openPopup('ネットワークから切断されました。'));
            dispatch(endCall(userInfo.user_id, roomDetail.room_id));
        }
    }, [callState, userInfo, roomDetail, networkStatus]);

    const audioRef = useRef(null);
    useEffect(() => {
        if (
            callState === callStates.CONNECTING &&
            roomDetail?.room_id &&
            userInfo?.user_id
        ) {
            const conn = webrtc.getConnection(
                userInfo.user_id,
                roomDetail.room_id,
            );
            if (conn) {
                audioRef.current.srcObject = conn.remoteStream;
            }
        }
    }, [callState, roomDetail?.room_id, userInfo?.user_id]);
    return (
        <>
            <audio ref={audioRef} autoPlay></audio>
            {(callState === callStates.CALLING ||
                callState === callStates.BUSY ||
                callState === callStates.REFUSE) &&
                !isSmall && <Caller />}
            {(callState === callStates.CALLING ||
                callState === callStates.CONNECTING) &&
                isSmall && <CallerSmall />}
            {callState === callStates.RECEIVING && <Callee />}
            {(callState === callStates.WAITING_CONNECT ||
                callState === callStates.CONNECTING ||
                callState === callStates.DISCONNECT ||
                callState === callStates.UNABLE_CONNECT ||
                callState === callStates.WAITING_REVIEW) &&
                !isSmall && <Calling />}
        </>
    );
}

const _mapStateToProps = (state) => {
    return {
        myInfo: state['cocoro/user'].userInfo,
        userInfo: state['cocoro/call'].userInfo,
        roomDetail: state['cocoro/call'].roomDetail,
        callState: state['cocoro/call'].callState,
        balance: state['cocoro/call'].balance,
        isSmall: state['cocoro/call'].isSmall,
    };
};

export default connect(_mapStateToProps)(CallWrap);
