import React, {useCallback, useEffect, useRef, useState} from 'react';
import './style.scss';
import HistoryListHeader from '../custom/HistoryListHeader.web';
import HistoryConversation from '../custom/HistoryConversation.web';
import HistoryTopic from '../custom/HistoryTopic.web';
import {Constants} from '../../../../util/Constants';
import {connect, useDispatch} from 'react-redux';
import {
    getHistoryList,
    loadMore,
    clearDataHistory,
    confirmError,
    getUserInfo,
    setHistories,
} from '../../redux/action';
import {useRouteMatch} from 'react-router-dom';
import {confirmAlert} from 'react-confirm-alert';
import {setNaviIndex} from '../../../app/actions';
import {useWatchExpire} from '../../../homescreen/hooks';
import {DEFAULT_LATEST_ROOM_DATA} from '../../../friendlist/constants';
import { HISTORY_PAGE_URL } from "../../../app/constants";

const HistoryList = ({
    history,
    histories,
    userInfo,
    currentFilterType,
    error,
    latestRoomsData,
}) => {
    const dispatch = useDispatch();
    const firstMount = useRef(true);
    const isLoading = useRef(false);
    let {url} = useRouteMatch();

    const onFilterChange = useCallback(
        async (filterValue) => {
            if (isLoading.current) {
                return;
            }
            isLoading.current = true;
            if (filterValue === Constants.HISTORY_FILTER_ALL || filterValue === Constants.HISTORY_FILTER_OTHERS_TOPIC) {
                await dispatch(getHistoryList(Constants.HISTORY_FILTER_ALL));
            } else if (filterValue === Constants.HISTORY_FILTER_MY_TOPIC) {
                await dispatch(
                    getHistoryList(Constants.HISTORY_FILTER_MY_TOPIC),
                );
            } 
            // else if (filterValue === Constants.HISTORY_FILTER_OTHERS_TOPIC) {
            //     await dispatch(
            //         getHistoryList(Constants.HISTORY_FILTER_OTHERS_TOPIC),
            //     );
            // }
            isLoading.current = false;
        },
        [dispatch],
    );

    const expiredId = useWatchExpire(
        histories
            ? histories.map((history) => ({
                  id: history.id,
                  timeExpire: history.expire_at._seconds,
              }))
            : [],
    );

    useEffect(() => {
        if (expiredId) {
            dispatch(
                setHistories(histories.filter((h) => h.id !== expiredId)),
                null,
            );
        }
    }, [expiredId]);

    useEffect(() => {
        if (firstMount.current) {
            dispatch(getHistoryList(currentFilterType));
            dispatch(setNaviIndex('history'));
        }
        firstMount.current = false;
    }, [currentFilterType, dispatch]);

    useEffect(() => {
        return () => {
            // unmount
            dispatch(clearDataHistory());
        };
    }, []);

    const handleLoadMore = async () => {
        if (!isLoading.current) {
            isLoading.current = true;
            await dispatch(loadMore());
            isLoading.current = false;
        }
    };

    let handleScroll = (event) => {
        const bottom =
            event.target.scrollHeight -
                event.target.scrollTop -
                event.target.clientHeight <
            50;
        if (bottom) {
            handleLoadMore();
        }
    };

    const openReplyList = (item) => {
        localStorage.setItem(HISTORY_PAGE_URL, `${url}/${item.topic.id}/replies`);
        history.push({pathname: `${url}/${item.topic.id}/replies`});
    };

    let openDetailUser = (history) => {
        dispatch(
            getUserInfo(
                history.user.id,
                history.topic.is_anonymous,
                history.topic.id,
                history,
            ),
        );
    };

    useEffect(() => {
        if (error != null) {
            confirmAlert({
                customUI: ({onClose}) => {
                    return (
                        <div className="custom-ui">
                            <h1>{error}</h1>
                            <button
                                onClick={() => {
                                    onClose();
                                    dispatch(confirmError());
                                }}>
                                OK
                            </button>
                        </div>
                    );
                },
            });
        }
    }, [error]);

    return (
        <div id="history-list">
            <HistoryListHeader onFilterChange={onFilterChange} />
            <div id="main-container">
                <div id="content" onScroll={(event) => handleScroll(event)}>
                    {!Array.isArray(histories) || histories.length === 0 ? (
                        <div id="no-data">履歴がありません。</div>
                    ) : (
                        <div>
                            {histories.map((item) => {
                                if (item.topic_creator === userInfo.id) {
                                    return (
                                        <HistoryTopic
                                            key={item.id.toString()}
                                            history={item}
                                            openReplyList={openReplyList}
                                        />
                                    );
                                } else {
                                    const roomData = latestRoomsData?.[
                                        item.room_id
                                    ]
                                        ? latestRoomsData[item.room_id]
                                        : DEFAULT_LATEST_ROOM_DATA;
                                    return (
                                        <HistoryConversation
                                            key={item.id.toString()}
                                            history={item}
                                            unreadCount={roomData.unreadCount}
                                            openDetailUser={openDetailUser}
                                        />
                                    );
                                }
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

function _mapStateToProps(state) {
    return {
        histories: state[Constants.STATE_HISTORY].histories,
        currentFilterType: state[Constants.STATE_HISTORY].currentFilterType,
        userInfo: state[Constants.STATE_USER].userInfo,
        error: state[Constants.STATE_HISTORY].error,
        latestRoomsData: state['cocoro/app'].latestRoomsData,
    };
}

export default connect(_mapStateToProps)(HistoryList);
