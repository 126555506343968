import mitt from 'mitt';
import {TURN_URLS, TURN_CREDENTIAL, TURN_USERNAME} from '../../../env.json';
import {genUUIDv4} from '../../../util/device.web';
import {db} from '../firebase/firebase';
import clock from '../clock';
import {
    createRTCPeerConnection,
    createRTCIceCandidate,
    createRTCSessionDescription,
    getUserMedia,
} from './webrtc';
//-----------------------------------------------

export const iceConfig = {
    iceServers: [
        {urls: ['stun:ss-turn1.xirsys.com']},
        {
            urls: TURN_URLS.split(','),
            credential: TURN_CREDENTIAL,
            username: TURN_USERNAME,
        },
    ],
    iceCandidatePoolSize: 10,
    // iceTransportPolicy: 'relay',
};

export const mediaConfig = {
    video: false,
    audio: true,
};

export const Status = {
    None: 'none',
    Connecting: 'connecting',
    Connected: 'connected',
    Disconnected: 'disconnected',
    Fail: 'fail',
    Closed: 'closed',
    Refused: 'refused',
    Audio: 'audio',
    Busy: 'busy',
    StartCall: 'startcall',
    StartAnswer: 'startanswer',
    EndCall: 'endcall',
};

export const Event = {
    SendDataSuccess: 'send_data_success',
    ReceiveDataSuccess: 'receive_data_success',
};

export const SignalCollection = 'signals';
export const EventCollection = 'events'; // EventCollection is sub collection into SignalCollection

export let conns = new Map();

export const checkHasUserMedia = async () => {
    const stream = await getUserMedia(mediaConfig);
    if (stream) {
        stream.getTracks().forEach((track) => track.stop());
        if (stream.stop) {
            stream.stop();
        }
        return true;
    }
    return false;
};

export const initConnection = ({
    localPeerId,
    remotePeerId,
    signalId,
    callId,
    callerId,
}) => {
    console.log(
        'webrtc:initConnection',
        localPeerId,
        remotePeerId,
        signalId,
        `callerId: ${callerId}`,
    );
    const pc = createRTCPeerConnection(iceConfig);
    const ref = db.collection(SignalCollection).doc(signalId);
    const conn = new Conn({
        pc,
        ref,
        localPeerId,
        remotePeerId,
        signalId,
        callId,
        callerId,
    });
    const key = `${remotePeerId}.${signalId}`;
    conns.set(key, conn);
    conn.connect();

    return conn;
};

export const setConnection = ({
    localPeerId,
    remotePeerId,
    signalId,
    callId,
    callerId,
}) => {
    console.log(
        'webrtc:setConnection',
        localPeerId,
        remotePeerId,
        signalId,
        `callerId: ${callerId}`,
    );
    const pc = createRTCPeerConnection(iceConfig);
    const ref = db.collection(SignalCollection).doc(signalId);
    const conn = new Conn({
        pc,
        ref,
        localPeerId,
        remotePeerId,
        signalId,
        callId,
        callerId,
    });
    const key = `${remotePeerId}.${signalId}`;
    conns.set(key, conn);

    return conn;
};

export const getConnection = (peerId, signalId) => {
    const key = `${peerId}.${signalId}`;
    return conns.has(key) ? conns.get(key) : null;
};

export const initChatConnection = (localPeerId, remotePeerId, signalId) => {
    let connection = null;
    const connConnecteds = [];

    conns.forEach((conn, key) => {
        if (
            connection === null &&
            remotePeerId &&
            key.indexOf(remotePeerId) >= 0 &&
            conn.status === Status.Connected &&
            conn.channel
        ) {
            connConnecteds.push(conn);
        }

        if (
            conn &&
            conn.status !== Status.Connecting &&
            conn.status !== Status.Connected
        ) {
            destroy(conn.remotePeerId, conn.signalId);
        }
    });

    // only keep 1 connection alive
    if (connConnecteds.length > 1) {
        connection = connConnecteds.shift();
        connConnecteds.forEach((conn) => {
            // destroy(conn.remotePeerId, conn.signalId);
        });
    }

    conns.forEach((conn, key) => {
        if (
            connection === null &&
            remotePeerId &&
            key.indexOf(remotePeerId) >= 0 &&
            conn.status === Status.Connected &&
            conn.channel
        ) {
            connection = conn;
        }
    });

    if (connection === null) {
        connection = setConnection({
            localPeerId,
            remotePeerId,
            signalId,
            callId: null,
            callerId: localPeerId,
        });
    }

    return connection;
};

export const getChatAvailableConnection = (remotePeerId) => {
    if (!remotePeerId) {
        return null;
    }

    let connection = null;
    conns.forEach((conn, key) => {
        if (
            connection === null &&
            remotePeerId &&
            key.indexOf(remotePeerId) >= 0 &&
            conn.status === Status.Connected &&
            conn.channel
        ) {
            connection = conn;
        }
    });
    return connection;
};

export const setupChatConnection = (
    localPeerId,
    remotePeerId,
    signalId,
    receiveMessageHandler,
) => {
    const conn = setConnection({
        localPeerId,
        remotePeerId,
        signalId,
        callId: null,
        callerId: remotePeerId,
    });
    conn.listenDataChannel(receiveMessageHandler);
    conn.connectWithoutStream();
};

export const closeAllChannel = () => {
    conns.forEach((conn) => {
        if (conn.channel) {
            conn.channel.close();
        }
    });
};

export const destroy = (peerId, signalId) => {
    const key = `${peerId}.${signalId}`;
    if (conns.has(key)) {
        console.log('webrtc:destroy -> disconnect...', key);
        conns.get(key).disconnect();
        conns.delete(key);
    }
};

export const destroyAllByPeerId = (peerId) => {
    const delkeys = [];
    conns.forEach((conn, key) => {
        if (key.indexOf(peerId) >= 0) {
            console.log('webrtc:destroy -> disconnect...', key);
            conn.disconnect();
            delkeys.push(key);
        }
    });
    delkeys.forEach((key) => conns.delete(key));
};

export const destroyAll = () => {
    conns.forEach((conn, key) => {
        console.log('webrtc:destroy -> disconnect...', key);
        conn.disconnect();
    });
    conns.clear();
};

/**
 * webrtc has self emitter
 */
const emitter = mitt();

export const emit = (event, payload) => {
    emitter.emit(event, payload);
};

export const on = (event, cb) => {
    if (event === '*') {
        emitter.on('*', (type, e) => {
            cb(type, e);
        });
    } else {
        emitter.on(event, (data) => {
            cb(event, data);
        });
    }
    return () => emitter.off(event, cb);
};

/**
 * Notify action to peerId (remote peer)
 */
export const notifyEnd = async (signalId, peerId) => {
    return sendEvent(peerId, Status.EndCall, signalId);
};

export const notifyRefuse = async (signalId, peerId) => {
    return sendEvent(peerId, Status.Refused, signalId);
};

export const notifyAudio = async (signalId, peerId) => {
    return sendEvent(peerId, Status.Audio, signalId);
};

export const notifyBusy = async (signalId, peerId) => {
    return sendEvent(peerId, Status.Busy, signalId);
};

export const sendEvent = async (peerId, event, signalId) => {
    try {
        await db
            .collection(SignalCollection)
            .doc(signalId)
            .collection(EventCollection)
            .doc(peerId)
            .set({event});
        return true;
    } catch (e) {
        console.warn('sendEvent:', e.message);
        return false;
    }
};

/**
 * Status listener
 */
export const statusListener = (signalId, myId, cb) => {
    if (!signalId && !myId) {
        console.warn('statusListener fail');
        return;
    }

    const handler = (docSnapshot) => {
        const data = docSnapshot.data();
        console.log('webrtc:statusListener', data);
        if (data) {
            cb(data);
        }
    };

    const ref = db
        .collection(SignalCollection)
        .doc(signalId)
        .collection(EventCollection)
        .doc(myId);

    const unsubscribe = ref.onSnapshot(handler);
    return unsubscribe;
};

/**
 * Manage peer connection
 */
class Conn {
    constructor({
        pc,
        ref,
        localPeerId,
        remotePeerId,
        signalId,
        callId,
        callerId,
    }) {
        this.pc = pc;
        this.localStream = null;
        this.remoteStream = null;
        this.callerIceCandidates = [];

        this.senders = null; // pc.addTrack: [], pc.addStream: null

        this.ref = ref;
        this.emitter = mitt();

        this.localPeerId = localPeerId;
        this.remotePeerId = remotePeerId;
        this.signalId = signalId;

        this.isCaller = Boolean(callerId === localPeerId);
        this.isCallee = !this.isCaller;

        this.callerId = callerId;
        this.calleeId = this.isCaller ? remotePeerId : localPeerId;
        this.callId = callId;
        this.setRemote = false;

        this.channel = null;
        this.status = Status.None;
        this.subs = new Map();
        this.timeStartConnect = null;
    }

    async connect() {
        console.log('webrtc:connect...');
        this.timeStartConnect = clock.now();
        this.status = Status.Connecting;

        await this.initLocalStream();
        this.handlePeerConnectionStateChange();
        this.handleSubscribeSignaling();
        this.receiveEventListener();

        if (this.isCallee) {
            this.stopAfter30sIfCanNotConnected();
        }
    }

    async connectWithoutStream() {
        console.log('webrtc:connectWithoutStream...');
        this.timeStartConnect = clock.now();
        this.handlePeerConnectionStateChange();
        this.receiveEventListener();
        this.handleSubscribeSignaling();
    }

    stopAfter30sIfCanNotConnected() {
        this.timeout = setTimeout(() => {
            if (this.status !== Status.Connected) {
                this.emitter.emit(Status.Fail);
                clearTimeout(this.timeout);
            }
        }, 30000);
    }

    async initLocalStream() {
        this.localStream = await getUserMedia(mediaConfig);
        if (this.pc.addTrack) {
            this.senders = this.localStream
                .getTracks()
                .map((track) => this.pc.addTrack(track, this.localStream));
        } else {
            this.pc.addStream(this.localStream);
        }
    }

    removeStream() {
        try {
            if (!this.localStream) {
                return;
            }
            if (this.senders) {
                this.senders.forEach((sender) => this.pc.removeTrack(sender));
            } else {
                this.pc.removeStream(this.localStream);
            }
        } catch (e) {
            console.warn('removeStream', e.message);
        }
    }

    handlePeerConnectionStateChange() {
        this.pc.onconnectionstatechange = (e) => {
            
            console.log(`peer connection state: ---------------${this.pc.connectionState}`);
            switch (this.pc.connectionState) {
                case 'connecting':
                    console.log(
                        `___time wait connecting ${
                            clock.now() - this.timeStartConnect
                        } ms`,
                    );
                    break;
                case 'connected':
                    const timeWaitConnected =
                        clock.now() - this.timeStartConnect;
                    console.log(
                        `___time wait connected ${timeWaitConnected} ms`,
                    );
                    if (this.isCallee) {
                        this.ref.update({
                            time_wait_connected: timeWaitConnected,
                            connected_at: clock.date(),
                        });
                    }

                    this.status = Status.Connected;
                    this.emitter.emit(Status.Connected);
                    break;
                case 'disconnected':
                    this.status = Status.Disconnected;
                    this.emitter.emit(Status.Disconnected);
                    break;
                case 'failed':
                    this.status = Status.Fail;
                    this.emitter.emit(Status.Fail);
                    break;
                case 'closed':
                    this.status = Status.Closed;
                    this.emitter.emit(Status.Closed);
                    break;
            }
        };

        this.pc.onicecandidate = (event) => {
            if (!event.candidate) {
                return;
            }
            if (this.isCaller) {
                this.ref
                    .collection('callerCandidates')
                    .add(event.candidate.toJSON());
            }
            if (this.isCallee) {
                this.ref
                    .collection('calleeCandidates')
                    .add(event.candidate.toJSON());
            }
        };

        this.pc.onaddstream = (event) => {
            if (event.stream) {
                this.remoteStream = event.stream;
            }
        };
    }

    async handleSubscribeSignaling() {
        if (this.isCaller) {
            // subscribe sdp
            const unsubSDPListener = this.ref.onSnapshot((snapshot) => {
                const data = snapshot.data();
                if (!this.setRemote && data?.answer) {
                    this.setRemote = true;
                    const sd = createRTCSessionDescription(data.answer);
                    this.pc.setRemoteDescription(sd);
                }
            });
            this.subs.set('sdp-onsnapshot', () => unsubSDPListener());

            // subscribe candidate
            const calleeCandidateListener = this.ref
                .collection('calleeCandidates')
                .onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === 'added') {
                            const data = change.doc.data();
                            const iceCandidate = createRTCIceCandidate(data);
                            this.pc.addIceCandidate(iceCandidate);
                        }
                    });
                });
            this.subs.set('callee-candidate-onsnapshot', () =>
                calleeCandidateListener(),
            );

            // send offer
            const offer = await this.pc.createOffer();
            this.pc.setLocalDescription(offer);
            const roomWithOffer = {
                offer: {
                    type: offer.type,
                    sdp: offer.sdp,
                },
            };
            this.ref.set(roomWithOffer);
        }
        //------------------------------
        else if (this.isCallee) {
            // subscribe candidate
            const callerCandidateListener = this.ref
                .collection('callerCandidates')
                .onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === 'added') {
                            const data = change.doc.data();
                            this.callerIceCandidates.push(data);
                        }
                    });
                });
            this.subs.set('caller-candidate-onsnapshot', () =>
                callerCandidateListener(),
            );

            // get offer & send answer
            this.ref.get().then(async (doc) => {
                const data = doc.data();
                if (data?.offer) {
                    const sd = createRTCSessionDescription(data.offer);
                    await this.pc.setRemoteDescription(sd);
                    const answer = await this.pc.createAnswer();

                    const dataWithAnswer = {
                        answer: {
                            type: answer.type,
                            sdp: answer.sdp,
                        },
                    };
                    await Promise.all([
                        this.pc.setLocalDescription(answer),
                        this.ref.update(dataWithAnswer),
                    ]);

                    // add icecandidate receive from caller
                    if (this.callerIceCandidates.length > 0) {
                        this.callerIceCandidates.forEach((data) => {
                            const iceCandidate = createRTCIceCandidate(data);
                            this.pc.addIceCandidate(iceCandidate);
                        });
                    } else {
                        console.warn('___missing caller icecandidate');
                    }
                }
            });
        }
    }

    receiveEventListener() {
        const handler = (docSnapshot) => {
            const data = docSnapshot.data();
            console.log(
                `webrtc:local - ${this.localPeerId} receive:`,
                data || null,
            );
            if (data?.event) {
                this.emitter.emit(data.event);
            }
        };

        const eventRef = this.ref
            .collection(EventCollection)
            .doc(this.localPeerId);

        const unsubEventListener = eventRef.onSnapshot(handler);
        this.subs.set('event-onsnapshot', () => unsubEventListener());
    }

    on(event, cb) {
        const subs = [];
        if (event === 'all') {
            Object.values(Status).forEach((status) => {
                const handler = () => cb(status);
                console.log(`webrtc:listener:all:${status}`);
                this.emitter.on(status, handler);
                subs.push(() => this.emitter.off(status, handler));
            });
        } else if (Object.values(Status).indexOf(event) !== -1) {
            const handler = () => cb(event);
            console.log(`webrtc:listener:${event}`);
            this.emitter.on(event, handler);
            subs.push(() => this.emitter.off(event, handler));
        }
        return () => subs.forEach((unsub) => unsub());
    }

    initDataChannel(receiveHandler) {
        const channel = this.pc.createDataChannel(genUUIDv4());
        this.channel = channel;

        channel.onmessage = async (event) => {
            const message = JSON.parse(event.data);

            if (message.event === Event.ReceiveDataSuccess) {
                this.emitter.emit(`${Event.SendDataSuccess}:${message.id}`, {
                    id: message.id,
                });
            } else {
                const result = await receiveHandler(message);
                if (result) {
                    channel.send(
                        JSON.stringify({
                            event: Event.ReceiveDataSuccess,
                            id: message.id,
                        }),
                    );
                }
            }
        };

        channel.onopen = () => {
            console.log('channel open');
        };

        channel.onclose = () => {
            console.log('channel close');
            this.channel = null;
        };
    }

    listenDataChannel(receiveHandler) {
        this.pc.ondatachannel = (event) => {
            const channel = event.channel;
            this.channel = channel;

            channel.onmessage = async (event) => {
                const message = JSON.parse(event.data);
                if (message.event === Event.ReceiveDataSuccess) {
                    this.emitter.emit(
                        `${Event.SendDataSuccess}:${message.id}`,
                        {
                            id: message.id,
                        },
                    );
                } else {
                    const result = await receiveHandler(message);
                    if (result) {
                        channel.send(
                            JSON.stringify({
                                event: Event.ReceiveDataSuccess,
                                id: message.id,
                            }),
                        );
                    }
                }
            };

            channel.onopen = () => {
                console.log('channel open');
            };

            channel.onclose = () => {
                console.log('channel close');
                this.channel = null;
            };
        };
    }

    async sendData(message) {
        const result = await new Promise((resolve) => {
            const wait1s = setTimeout(() => resolve(false), 1000);
            // TODO: clear timeout
            this.emitter.on(
                `${Event.SendDataSuccess}:${message.id}`,
                (data) => {
                    if (message.id === data.id) {
                        // TODO: off event
                        resolve(true);
                    }
                },
            );
            if (this.channel) {
                this.channel.send(JSON.stringify(message));
            } else {
                resolve(false);
            }
        });
        return result;
    }

    getLocalAudioTrack() {
        if (this.localStream) {
            return null;
        }
        const tracks = this.localStream.getTracks();
        if (tracks.length > 0) {
            const audioTrack = tracks.find((track) => track.kind === 'audio');
            return audioTrack;
        }
    }

    toggleAudioTrack() {
        if (!this.localStream) {
            return;
        }

        let status = null;
        const tracks = this.localStream.getTracks();
        if (tracks.length > 0) {
            tracks.forEach((track, i) => {
                if (track.kind === 'audio') {
                    this.localStream.getTracks()[
                        i
                    ].enabled = !this.localStream.getTracks()[i].enabled;

                    if (status === null) {
                        status = track.enabled;
                    }
                }
            });
        }
        return status;
    }

    stopTracks() {
        if (!this.localStream) {
            return;
        }

        const tracks = this.localStream.getTracks();
        tracks.forEach((track, i) => {
            track.stop();
        });

        if (this.localStream.stop) {
            this.localStream.stop();
        }
    }

    createDataChannel(label, opts) {
        this.pc.createDataChannel(label, opts);
    }

    disconnect() {
        if (this.pc.signalingState === 'closed') {
            return;
        }

        if (this.localStream) {
            this.removeStream();
        }

        // unsub all ref listener
        this.subs.forEach((unsub, key) => {
            console.log('webrtc:unsub ->', key);
            unsub();
        });
        this.subs.clear();

        this.pc.close();
    }
}
