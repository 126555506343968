import {post, get} from '../base/portal/apis';

export function requestPaidCallApi(roomId, token) {
    let url = '/api/v1/rooms/' + roomId + '/requestpaidcall';
    let data = {};
    return post(url, token, data);
}

export function confirmRequestPaidCallApi(roomId, token, status) {
    let url = '/api/v1/rooms/' + roomId + '/confirmrequestpaidcall';
    let data = {status};
    return post(url, token, data);
}
