import {post} from '../base/portal/apis';

export function requestChangePasswordApi(email) {
    let url = '/api/v1/users/request_reset_password';
    let data = {email};
    return post(url, '', data);
}

export function resetVerifyCodeApi(email, verify_code) {
    let url = '/api/v1/users/reset_password/verify_code';
    let data = {email, verify_code};
    return post(url, '', data);
}

export function resetPasswordApi(
    email,
    verify_code,
    password,
    confirm_password,
) {
    let url = '/api/v1/users/reset_password';
    let data = {email, verify_code, password, confirm_password};
    return post(url, '', data);
}
