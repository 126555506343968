import ReducerRegistry from '../base/redux/ReducerRegistry';
import {
    SET_LOADING,
    SET_POPUP,
    SET_ACTION_POPUP,
    SET_CONNECT,
    SET_MODAL,
    SET_INPUT_PW_MODAL,
    SET_FCM_TOKEN,
    SET_VOIP_TOKEN,
    SET_MODAL_SPECIAL,
    SET_TOPIC_MODAL,
    SET_CREATE_TOPIC_MODAL,
    SET_FRIEND_IDS,
    ADD_FRIEND_ID,
    CURRENT_APP_STATE,
    UPDATE_ROOM_DATA,
    UPDATE_LATEST_ROOMS,
    SET_NAVI_INDEX,
    CHANGE_ROUTE,
    OPEN_CHAT_SCREEN,
    CLOSE_CHAT_SCREEN,
    OPEN_PRESENT_SCREEN,
    CLOSE_PRESENT_SCREEN,
    SET_IS_FOCUS_HOME_FALSE,
    SET_IS_FOCUS_SEARCH_FALSE,
    TOGGLE_STYLE_LIST,
    TOGGLE_SEARCH_SCREEN,
    SET_IMG_URI,
    CHANGE_HISTORY_TAB,
    CLEAR_UNREAD_COUNT,
    SET_FROM_HOME,
    SET_AUDIO_PRESENT
} from './actionTypes';
import {Constants} from '../../util/Constants';
import {REPLY_TOPIC} from '../history/redux/actionType';
import clock from '../base/clock';

const initialState = {
    appState: 'active',
    lastAppActive: clock.now(),
    route: 'SPLASH',
    lastRoute: 'SPLASH',
    isHomeFocus: true,
    isSearchFocus: false,
    isBubbleStyle: true,
    deviceId: null,
    loading: false,
    connect: true,
    popup: {status: false, data: ''},
    actionPopup: {status: false, data: '', action: () => {}},
    topicModal: {status: false, data: '', action: () => {}},
    naviIndex: 'home',
    modal: {status: false, data: {type: 0}},
    inputPWModal: {status: false, data: {type: 0}},
    createTopicModal: {status: false, data: {type: 0}},
    modalSpecial: {status: false, data: {type: 0}},
    fcmToken: null,
    voipToken: null,
    friendIds: [],
    historyTab: Constants.NAVIGATION_HISTORY_LIST,
    replyTopic: null,
    img_uri: null,
    isShowChatScreen: false,
    isShowPresentScreen: false,
    isFromHome: false,
    latestRoomsData: {},
    audioPresent: '',
};

ReducerRegistry.register('cocoro/app', (state = initialState, action) => {
    switch (action.type) {
        case SET_AUDIO_PRESENT:
            return {
                ...state,
              audioPresent: action.data
            };
        case CURRENT_APP_STATE:
            return {
                ...state,
                appState: action.currentState,
                lastAppActive:
                    action.currentState === 'active'
                        ? clock.now()
                        : state.lastAppActive,
            };
        case REPLY_TOPIC:
            return {
                ...state,
                replyTopic: action.topic,
            };
        case SET_IMG_URI:
            return {
                ...state,
                img_uri: action.img_uri,
            };
        case CHANGE_HISTORY_TAB:
            return {
                ...state,
                historyTab: action.historyTab,
            };
        case ADD_FRIEND_ID:
            return {
                ...state,
                friendIds: [state.friendIds, action.id],
            };

        case SET_FRIEND_IDS:
            return {
                ...state,
                friendIds: action.friendIds,
            };

        case SET_FCM_TOKEN:
            return {
                ...state,
                fcmToken: action.token,
            };

        case SET_VOIP_TOKEN:
            return {
                ...state,
                voipToken: action.token,
            };

        case SET_CONNECT:
            return {
                ...state,
                connect: action.connect,
            };

        case SET_POPUP:
            return {
                ...state,
                popup: action.popup,
            };

        case SET_ACTION_POPUP:
            return {
                ...state,
                actionPopup: action.popup,
            };

        case SET_TOPIC_MODAL:
            return {
                ...state,
                topicModal: action.topicModal,
            };

        case SET_MODAL_SPECIAL:
            return {
                ...state,
                modalSpecial: action.modalSpecial,
            };

        case SET_MODAL:
            return {
                ...state,
                modal: action.modal,
            };
        case SET_INPUT_PW_MODAL:
            return {
                ...state,
                inputPWModal: action.inputPWModal,
            };
        case SET_CREATE_TOPIC_MODAL:
            return {
                ...state,
                createTopicModal: action.modal,
            };

        case SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };

        case CHANGE_ROUTE:
            return {
                ...state,
                route: action.route,
                lastRoute: state.route,
            };

        case TOGGLE_STYLE_LIST:
            return {
                ...state,
                isBubbleStyle: state.isHomeFocus
                    ? !state.isBubbleStyle
                    : state.isBubbleStyle,
                isHomeFocus: true,
            };

        case TOGGLE_SEARCH_SCREEN:
            return {
                ...state,
                isRefresh: state.isSearchFocus
                    ? !state.isRefresh
                    : state.isRefresh,
                isSearchFocus: true,
            };

        case SET_IS_FOCUS_SEARCH_FALSE:
            return {
                ...state,
                isSearchFocus: false,
            };

        case SET_IS_FOCUS_HOME_FALSE:
            return {
                ...state,
                isHomeFocus: false,
            };

        case SET_NAVI_INDEX:
            return {
                ...state,
                naviIndex: action.naviIndex,
            };

        case OPEN_CHAT_SCREEN:
            return {
                ...state,
                isShowChatScreen: true,
            };

        case CLOSE_CHAT_SCREEN:
            return {
                ...state,
                isShowChatScreen: false,
            };

        case OPEN_PRESENT_SCREEN:
            return {
                ...state,
                isShowPresentScreen: true,
            };

        case CLOSE_PRESENT_SCREEN:
            return {
                ...state,
                isShowPresentScreen: false,
            };

        case UPDATE_LATEST_ROOMS:
            return {
                ...state,
                latestRoomsData: {...action.latestRooms},
            };

        case SET_FROM_HOME:
            return {
                ...state,
                isFromHome: action.status,
            };

        case UPDATE_ROOM_DATA: {
            let newData = state.latestRoomsData;
            const count =
                action.latestMessage.user_id === action.myId ? 0 : action.count;
            let is_read = action.count === 0;

            if (newData?.[action.roomId]) {
                const msgIds = newData[action.roomId].messageIds || [];
                if (msgIds.indexOf(action.latestMessage.id) === -1) {
                    newData[action.roomId].unreadCount =
                        parseInt(newData[action.roomId].unreadCount) +
                        parseInt(count);
                    if (count > 0) {
                        msgIds.push(action.latestMessage.id);
                    }
                }
                newData[action.roomId].messageIds = msgIds;
                newData[action.roomId].latestMessage = {
                    ...action.latestMessage,
                    is_read,
                };
                newData[action.roomId].updated_at =
                    action.latestMessage.created_at;
            } else {
                newData[action.roomId] = {
                    latestMessage: {...action.latestMessage, is_read},
                    unreadCount: count,
                    messageIds: count > 0 ? [action.latestMessage.id] : [],
                    updated_at: action.latestMessage.created_at,
                };
            }
            return {
                ...state,
                latestRoomsData: {...newData},
            };
        }

        case CLEAR_UNREAD_COUNT: {
            const newData = state.latestRoomsData;
            if (newData[action.roomId]) {
                newData[action.roomId].unreadCount = 0;
                newData[action.roomId].messageIds = [];
                newData[action.roomId].latestMessage.is_read = true;
            }
            return {
                ...state,
                latestRoomsData: {...newData},
            };
        } 

        default:
            return state;
    }
});
