import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Resizer from 'react-image-file-resizer';
import {Constants} from '../../../util/Constants';
import {useHistory} from 'react-router-dom';
import {getMyProfile} from '../../changeprofile';
import {updateImageUri, updateImages, setImageChange} from '../redux/action';
import './changeAvatar.scss';
import {openPopup} from '../../app/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


var sizeOf = require('image-size');

let flag = false;

function ChangeAvatar({
    profile,
    getMyProfile,
    updateImageUri,
    updateImages,
    setImageChange,
    openPopup,
}) {
    let [imageData, setImageData] = useState('');
    let [imageError, setImageError] = useState('');
    const [crop, setCrop] = useState({ 
                                aspect: 1 / 1,
                                // unit: 'px',
                                // x: 0,
                                // y: 0,
                                // width: 100,
                                // height: 100
                            });
    let [cropData, setCropData] = useState('');
    const [isCrop, setIsCrop] = useState(false);
    const [ cropResult, setCropResult ] = useState('');

    let localImage = localStorage.getItem('profile_image');
    let history = useHistory();
    const onTakePicture = (path) => {
        history.push('/takepicture');
        // setImageData({uri: path});
    };

    let onBack = () => {
        localStorage.removeItem('profile_image');
        window.location.href = "/mypage"
        flag = false;
    };

    useEffect(() => {
        getMyProfile();
    }, [getMyProfile]);

    useEffect(() => {
        if (localImage !== null && localImage !== '') {
            setImageData(localImage);
        } else {
            setImageData(profile.profile_image);
        }
    }, [profile, localImage]);

    let clickSave = () => {
        getCroppedImg(cropData, crop);
    }

    let resizeImage = (file) => {
        updateImageUri(file, Constants.IMAGE_TYPE_BASE64, true);
        localStorage.setItem('profile_image', file);
        setImageData(file);
        setIsCrop(false);
        return ;
    };

    let resizeImageCrop = (file) => {
        Resizer.imageFileResizer(
            file,
            375,
            375,
            'JPEG',
            Constants.IMG_QUALITY,
            0,
            (uri) => {
                setIsCrop(true)
                setCropData(uri)
            },
            'base64',
        );
    };

    function getCroppedImg(image, pixelCrop) {
        // pixelCrop.width,
        //         pixelCrop.height,
        if(pixelCrop.width < 110){
            openPopup(
                '横110px × 縦110px以上の画像をアップロードしてください。',
            );
            return ;
        }
                
        var image1 = new Image();
        image1.src = image;
        image1.addEventListener('load', function() {
            let pixelCrop = crop;
            const canvas = document.createElement('canvas');
            canvas.width = pixelCrop.width;
            canvas.height = pixelCrop.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(
                image1,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                pixelCrop.width,
                pixelCrop.height
              );
      
      
             
              // As Base64 string
              const base64Image = canvas.toDataURL('image/jpeg');
            //   setCropResult(base64Image);
                resizeImage(base64Image);
            }, false);
      }

    let onChangeFile = (e) => {
        let filePer = ['image/png', 'image/jpg', 'image/jpeg', ''];
        setCrop({ aspect: 1 / 1 });
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        if (filePer.findIndex((f) => f === file.type) === -1) {
            openPopup(
                'JPG・JPEG・PNGの画像ファイルをアップロードしてください。',
            );
        } else {
            if (file) {
                if (navigator.onLine) {
                    Resizer.imageFileResizer(
                        file,
                        9999999999,
                        9999999999,
                        'JPEG',
                        100,
                        0,
                        (uri) => {
                            var img = Buffer.from(uri.substr(23), 'base64');
                            var dimensions = sizeOf(img);
                            if (
                                dimensions.width <
                                    Constants.PROFILE_IMG_MAX_SIZE ||
                                dimensions.height <
                                    Constants.PROFILE_IMG_MAX_SIZE
                            ) {
                                setImageChange(false);
                                setImageError(
                                    '横110px × 縦110px以上の画像をアップロードしてください。',
                                );
                            } else {
                                setImageError('');

                                resizeImageCrop(file);
                            }
                        },
                        'base64',
                    );
                } else {
                    openPopup('ネットワークに接続されていません。');
                }
            }
        }
    };

    let chooseDefaultAvatar = () => {
        localStorage.setItem('profile_image', profile.profile_image);
        history.push({pathname: '/defaultimages'});
    };

    let changeProfileImage = () => {
        let imageType = Constants.IMAGE_TYPE_LINK;
        if (localImage.includes('base64')) {
            imageType = Constants.IMAGE_TYPE_BASE64;
        }
        if (localImage.includes('https') || localImage.includes('http')) {
            imageType = Constants.IMAGE_TYPE_LINK;
        }
        updateImages(
            profile.age_id,
            profile.area_id,
            profile.gender,
            imageType,
            localImage,
            profile.user_name,
            onBack,
        );
    };

    return (
        <div id="changeAvatar">
            <header>
                <div className="back">
                    {/* <span onClick={onBack}>キャンセル</span> */}
                    <img src={"/images/icon-back-grey.png"} onClick={() => onBack()} />
                </div>
                <h3 style={{fontWeight: 'bold'}}>プロフィール</h3>
                <div className="right"></div>
            </header>
            <div className="mainContainer">
                { 
                    isCrop ?
                        <div className={'modal-container'}>
                            <div className={'modal-body'}>
                                <div className={'modal-header'}>
                                    <FontAwesomeIcon icon={faTimes} onClick={ ()=> { setIsCrop(false); setCropData('');  } }/>
                                    <FontAwesomeIcon icon={faSave} onClick={()=>{ clickSave(); }} />
                                </div>
                                <ReactCrop src={cropData} crop={crop} onChange={newCrop => setCrop(newCrop)} />
                            </div>
                        </div>
                        : null
                }
                    {
                        imageData === '' ? null :
                            <div className="avatarContainer">
                                <div className="imageContainer">
                                    <img src={imageData} alt="" />
                                </div>
                            </div>
                    }
                    {imageError !== '' && <span>{imageError}</span>}
                <div className="buttonContainer">
                    <div className="button" onClick={onTakePicture}>
                        <span>写真を撮る</span>
                    </div>
                </div>
                <div className="buttonContainer">
                    <label htmlFor="upload-photo" className="uploadLabel">
                        ライブラリから選ぶ
                    </label>
                    <input
                        onChange={onChangeFile}
                        type="file"
                        id="upload-photo"
                        accept="image/png,image/jpg,image/jpeg"
                    />
                </div>

                <div className="buttonContainer">
                    <div className="button" onClick={chooseDefaultAvatar}>
                        <span>アバター選択</span>
                    </div>
                </div>

                {localImage !== null && localImage !== '' ? (
                    <div className="buttonContainer">
                        <div
                            className="buttonConfirm"
                            onClick={changeProfileImage}>
                            <span>OK</span>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        profile: state['cocoro/profile'].userProfile,
        isNew: state[Constants.STATE_REGISTRATION_INFORMATION].isNew,
        imageUri: state[Constants.STATE_REGISTRATION_INFORMATION].imageUri,
        lastRoute: state['cocoro/app'].lastRoute,
        loading: state['cocoro/app'].loading,
    };
}

let _mapDispatchToProps = {
    getMyProfile,
    updateImageUri,
    updateImages,
    setImageChange,
    openPopup,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ChangeAvatar);
