import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {clearOldMsg, createRoomChat} from '../actions';
import {closeModalPage, openModalPage, openPopup} from '../../app';
import clock from '../../base/clock';
import {updateRoomInfo} from '..';
import {MODAL_CONFIRM_CHAT, HISTORY_PAGE_URL, HISTORY_PAGE_USER_ID} from '../../app/constants';

const ChatBtn = ({
    userId,
    roomTypeId,
    topicId,
    topicInfo,
    Component = null,
    isAnonymous,
    timeExpire,
    roomInfo,
    participants,
    saveFlag
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const remainTime = timeExpire ? timeExpire - clock.seconds() : null;

    const handleAgreeChat = async () => {
        const result = await dispatch(
            createRoomChat({
                id: '0',
                user_id: userId,
                room_type_id: roomTypeId,
                topic_id: topicId,
                is_anonymous: isAnonymous,
            }),
        );
        if (result?.id) {
            dispatch(updateRoomInfo(result));
            dispatch(closeModalPage());
            dispatch(clearOldMsg());
            history.push('/chat/' + result.id);
        }
    };

    const gotoChat = () => {
        if (remainTime !== null && remainTime <= 0) {
            dispatch(
                openPopup(
                    '該当する声がありません。既に削除されている可能性があります。',
                ),
            );
        } else {
            if (!roomInfo && !topicInfo?.room) {
                if (topicInfo?.chat_price && topicInfo.chat_price !== 0) {
                    dispatch(
                        openModalPage({
                            type: MODAL_CONFIRM_CHAT,
                            topic: topicInfo,
                            onAgree: handleAgreeChat,
                        }),
                    );
                } else {
                    handleAgreeChat();
                }
                return;
            }

            if (topicInfo?.room) {
                dispatch(updateRoomInfo(topicInfo.room));
                dispatch(closeModalPage());
                dispatch(clearOldMsg());
                if(saveFlag) localStorage.setItem(HISTORY_PAGE_URL, '/chat/' + topicInfo?.room.id);
                history.push('/chat/' + topicInfo?.room.id);
                return;
            }

            if (roomInfo) {
                dispatch(updateRoomInfo(roomInfo));
                dispatch(closeModalPage());
                dispatch(clearOldMsg());
                if(saveFlag) localStorage.setItem(HISTORY_PAGE_URL, '/chat/' + roomInfo.id);
                
                localStorage.setItem(HISTORY_PAGE_USER_ID, userId);
                history.push('/chat/' + roomInfo.id);
                return;
            }
        }
    };

    return (
        <>
            {Component ? (
                <div onClick={gotoChat} className="request-chat">
                    <Component />
                </div>
            ) : (
                <img
                    className="request-chat"
                    onClick={gotoChat}
                    src="/images/ic_status_message@4x.png"
                    alt=""
                />
            )}
        </>
    );
};

export default ChatBtn;
