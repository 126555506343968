const inputStyle = {
    backgroundColor: '#F4F9FA',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DFE9EB',
    height: 42,
    borderRadius: 5,
    outline: 'none',
    paddingLeft: 10,
    paddingRight: 10,
};

export default inputStyle;
