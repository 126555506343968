import ReducerRegistry from '../base/redux/ReducerRegistry';

let stateDefault = {
    userProfile: {},
};

ReducerRegistry.register(
    'cocoro/changepassword',
    (state = stateDefault, action) => {
        switch (action.type) {
            default:
                return state;
        }
    },
);
