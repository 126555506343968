import {useState, useEffect} from 'react';
//-----------------------------------------------

export const useAsyncMemo = (factory, deps, initialValue) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        let cancel = false;
        const promise = factory();
        if (promise === undefined || promise === null) return;
        promise.then((val) => {
            if (!cancel) {
                setValue(val);
            }
        });
        return () => {
            cancel = true;
        };
    }, deps);

    return value;
};

export const useNetworkStatus = () => {
    const [isOnline, setNetwork] = useState(window.navigator.onLine);
    const handleNetworkChange = () => {
        setNetwork(window.navigator.onLine);
    };
    useEffect(() => {
        window.addEventListener('offline', handleNetworkChange);
        window.addEventListener('online', handleNetworkChange);
        return () => {
            window.removeEventListener('offline', handleNetworkChange);
            window.removeEventListener('online', handleNetworkChange);
        };
    });
    return isOnline;
};
