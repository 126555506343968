export const actives = [
    {id: '0', name: '全て'},
    {id: '1', name: '在籍中'},
    {id: '2', name: '在籍していない'},
];
export const ages = [
    {id: '0', name: '全て'},
    {id: '1', name: '10代'},
    {id: '2', name: '20代'},
    {id: '3', name: '30代'},
    {id: '4', name: '40代'},
    {id: '5', name: '50代'},
    {id: '6', name: '60代以上'},
];
export const genders = [
    {id: '0', name: '全て'},
    {id: '2', name: '女性（F）'},
    {id: '1', name: '男性（M）'},
    {id: '3', name: '不特定（X）'},
];
