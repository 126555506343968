import {
    ADD_FRIEND,
    NEW_FRIEND,
    DETAIL_FRIEND,
    UPDATE_FRIEND,
    REMOVE_FRIEND,
    LOAD_MORE_FRIENDS,
    CLEAR_FRIENDS,
    BLOCK_USER,
} from './actionTypes';
import {httpRequest} from '../base/api/actions';
import {
    blockApi,
    deleteFriendApi,
    getFriendApi,
    getFriendsApi,
    getUserApi,
    sendPresentApi,
} from '../base/api';
import { reportUserApi } from "./apis";
import * as webrtc from '../base/webrtc';

export function addFriends(friends, hasNextPage) {
    return {
        type: ADD_FRIEND,
        friends,
        hasNextPage,
    };
}

export function loadMoreFriends(friends, hasNextPage) {
    return {
        type: LOAD_MORE_FRIENDS,
        friends,
        hasNextPage,
    };
}

export function newFriend(friend) {
    return {
        type: NEW_FRIEND,
        friend,
    };
}

export function detailFriend(friend, topic) {
    return {
        type: DETAIL_FRIEND,
        friend,
        topic,
    };
}

export function removeFriend(id) {
    return {
        type: REMOVE_FRIEND,
        id,
    };
}

export function clearFriends() {
    return function (dispatch) {
        dispatch({type: CLEAR_FRIENDS});
    };
}

export function updateMessage(id, message, created_at, is_read, unreadCount) {
    return {
        type: UPDATE_FRIEND,
        id,
        message,
        created_at,
        is_read,
        unreadCount,
    };
}

export function blockUser(userId, blockId) {
    return {
        type: BLOCK_USER,
        userId,
        blockId,
    };
}

//-----------------------------------------------

export function getFriendInfo(friendId, topic) {
    return async function (dispatch, getState) {
        const result = await dispatch(httpRequest(getUserApi(friendId), true));
        return result;
    };
}

export function getFriends(lastDocId, loading = true) {
    return async function (dispatch, getState) {
        const result = await dispatch(
            httpRequest(getFriendsApi({last_doc_id: lastDocId}), loading),
        );
        if (result?.data) {
            if (lastDocId) {
                dispatch(loadMoreFriends(result.data, false));
            } else {
                dispatch(addFriends(result.data, false));
            }
        }
        return result;
    };
}

export function receiveNewFriendFromRealtime(friendId) {
    return async function (dispatch, getState) {
        const result = await dispatch(httpRequest(getFriendApi(friendId)));
        if (result?.id) {
            dispatch(newFriend(result));
        }
    };
}

export function blockFriend(userId) {
    return async function (dispatch, getState) {
        const result = await dispatch(httpRequest(blockApi(userId), true));
        dispatch(removeFriend(userId));

        if (result?.status === true) {
            dispatch(blockUser(userId, result.id));
        }

        // clear all webrtc connection is connecting with user is blocked
        webrtc.destroyAllByPeerId(userId);

        return result;
    };
}

export function deleteFriend(userId) {
    return async function (dispatch, getState) {
        const result = await dispatch(
            httpRequest(deleteFriendApi(userId), true),
        );
        if (result?.message === 'success') {
            dispatch(removeFriend(userId));
        }
        return result;
    };
}