import {
    setLoading,
    openPopup,
    changeRoute,
    openModalPage,
} from '../app/actions';
import {
    MODAL_CHOOSE_AGE_FILTER_TOPIC,
    MODAL_CHOOSE_ACTIVE_FILTER_TOPIC,
    MODAL_CHOOSE_GENDER_FILTER_TOPIC,
    MODAL_CHOOSE_ACTIVE_FILTER_TOPIC_AREA,
} from '../app/constants';
import {
    UPDATE_AREA_FILTER_TOPIC,
    UPDATE_ACTIVE_FILTER_TOPIC,
    UPDATE_GENDER_FILTER_TOPIC,
    UPDATE_AGE_FILTER_TOPIC,
} from './actionType';
import {CHECK_AUTH} from '../app/actionTypes';
import {getUserInfoApi, saveFilterApi} from './apis';
import {actives, ages, genders} from './functions';

export function setAreaFilter(area) {
    return {
        type: UPDATE_AREA_FILTER_TOPIC,
        area: area,
    };
}

export function setActiveFilter(active) {
    return {
        type: UPDATE_ACTIVE_FILTER_TOPIC,
        active: active,
    };
}
export function setAgeFilter(age) {
    return {
        type: UPDATE_AGE_FILTER_TOPIC,
        age: age,
    };
}
export function setGenderFilter(gender) {
    return {
        type: UPDATE_GENDER_FILTER_TOPIC,
        gender: gender,
    };
}

export function getGender(currentGender) {
    return function (dispatch, getState) {
        dispatch(
            openModalPage({
                type: MODAL_CHOOSE_GENDER_FILTER_TOPIC,
                genders,
                currentGender: currentGender.id,
            }),
        );
    };
}

export function getAgeSearch(currentAge) {
    return function (dispatch, getState) {
        dispatch(
            openModalPage({
                type: MODAL_CHOOSE_AGE_FILTER_TOPIC,
                ages,
                currentAge: currentAge.id,
            }),
        );
    };
}

export function getActiveSearch(currentActive) {
    return function (dispatch, getState) {
        dispatch(
            openModalPage({
                type: MODAL_CHOOSE_ACTIVE_FILTER_TOPIC,
                actives,
                currentActive: currentActive.id,
            }),
        );
    };
}

export function getActiveArea(areas, currentActive) {
    return function (dispatch, getState) {
        dispatch(
            openModalPage({
                type: MODAL_CHOOSE_ACTIVE_FILTER_TOPIC_AREA,
                areas,
                currentActive: currentActive?.id,
            }),
        );
    };
}

export function getUserInfo() {
    return function (dispatch, getState) {
        let {access_token} = getState()['cocoro/user'].userInfo;
        dispatch(setLoading(true));
        getUserInfoApi(access_token)
            .then((rs) => {
                dispatch(setLoading(false));
                dispatch({type: CHECK_AUTH, status: rs.status});
                try {
                    if (rs.status == 200 || rs.status == 201) {
                        let data = rs.data;
                        if (data.history) {
                            if (data.history.age_id) {
                                let age = ages.find(
                                    (item) => item.id === data.history.age_id,
                                );
                                dispatch(setAgeFilter(age));
                            }
                            if (data.history.gender) {
                                let gender = genders.find(
                                    (item) => item.id === data.history.gender,
                                );
                                dispatch(setGenderFilter(gender));
                            }
                            if (data.history.user_status) {
                                if (data.history.user_status === '1') {
                                    dispatch(setActiveFilter(actives[1]));
                                } else if (data.history.user_status === '2') {
                                    dispatch(setActiveFilter(actives[2]));
                                } else {
                                    dispatch(setActiveFilter(actives[0]));
                                }
                            }
                        }
                    } else {
                        dispatch(openPopup('想定しないエラーが発生しました。'));
                    }
                } catch (e) {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            })
            .catch((err) => {
                dispatch(setLoading(false));
                dispatch(openPopup('想定しないエラーが発生しました。'));
            });
    };
}

export function saveFilter(user_status, gender, age_id, areaId, nextStep) {
    return function (dispatch, getState) {
        let {access_token} = getState()['cocoro/user'].userInfo;
        dispatch(setLoading(true));
        saveFilterApi(user_status, gender, age_id, areaId, access_token)
            .then((rs) => {
                dispatch(setLoading(false));
                if (rs.status == 200 || rs.status == 201) {
                    if (nextStep) {
                        nextStep();
                    }
                }
                if (rs.status == 401) {
                    dispatch(changeRoute('LOGIN'));
                }
            })
            .catch((err) => {
                dispatch(setLoading(false));
            });
    };
}
