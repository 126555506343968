import clock from '../base/clock';
import Storage from '../../util/storage';
import {sortMessages} from './functions';
import {messageStatusTexts} from './constants';

const LIMIT = 20;

const CHAT_PREFIX_ = '_chat';

const addMessage = async (data) => {
    const mkey = `${CHAT_PREFIX_}:${data.room_id}:${data.id}`;
    return Storage.set(mkey, data);
};

const updateMessage = async (id, data) => {
    const keys = Storage.getAllKeys();
    const mkey = keys.find((key) => {
        if (key.indexOf(CHAT_PREFIX_) >= 0 && key.indexOf(id) >= 0) {
            return true;
        } else {
            return false;
        }
    });
    
    const msg = Storage.get(mkey);
    if (msg) {
        if (msg.status === messageStatusTexts.READ && data.status) {
            data.status = messageStatusTexts.READ;
        }
        Storage.set(mkey, {...msg, ...data});
        return true;
    } else {
        console.warn('chatdb -> updateMessage: message not found');
        return false;
    }
};

const updateAllMessageRoomIsRead = async (roomId) => {
    const keys = Storage.getAllKeys();
    const mkeys = keys.filter((key) => {
        if (key.indexOf(CHAT_PREFIX_) >= 0 && key.indexOf(roomId) >= 0) {
            return true;
        } else {
            return false;
        }
    });
    mkeys.forEach((key) => {
        const msg = Storage.get(key);
        if (msg) {
            if (msg.is_read === false) {
                msg.is_read = true;
                Storage.set(key, {...msg});
            }
        }
    });
    return true;
};

const getMessages = async ({room_id, status, offset, limit}) => {
    const keys = Storage.getAllKeys();
    // room_id = '';
    const chatKeys = keys.filter(
        (key) => key.indexOf(`${CHAT_PREFIX_}:${room_id}`) >= 0,
    );
    const values = chatKeys.map((key) => Storage.get(key));
    // sort asc
    sortMessages(values);

    const _limit = limit || LIMIT;
    const _offset = offset || 0;
    const msgs = values.reverse().slice(_offset, _limit);
    return msgs;
};

const getMessages1 = async ({room_id, status, offset, limit, roomInfo, userInfo}) => {
    console.log("0---------------",room_id);
    // if(roomInfo?.participants_array)
    // {
    //     let otherId = roomInfo.participants_array.find(
    //         (item) => item !== userInfo.id,
    //     );
    //     if(roomInfo.anonymous[otherId])
    //     {
    //     }
    //     else{
    //         room_id = '';
    //     }
    // }
    console.log("1---------------",room_id);

    const keys = Storage.getAllKeys();
    // room_id = '';
    const chatKeys = keys.filter(
        (key) => key.indexOf(`${CHAT_PREFIX_}:${room_id}`) >= 0,
    );
    // const valuesBuffer = chatKeys.map((key) => Storage.get(key));
    // // sort asc
    // if(valuesBuffer.length>0)
    // {
    //     if(valuesBuffer[0].topic_id === null)
    //         room_id = '';
    // }
    // else{
    //     room_id = '';
    // }
console.log("2---------------",room_id);
    const keys1 = Storage.getAllKeys();
    const chatKeys1 = keys1.filter(
        (key) => key.indexOf(`${CHAT_PREFIX_}:${room_id}`) >= 0,
    );
    
    const values = chatKeys1.map((key) => Storage.get(key));
    const values1 = Array();
    // sort asc
    for(var i=0; i< values.length; i++)
    {
        if(roomInfo?.participants_array)
        {
            if((values[i].receiver_id === roomInfo.participants_array[0] && values[i].user_id === roomInfo.participants_array[1]) || (values[i].receiver_id === roomInfo.participants_array[1] && values[i].user_id === roomInfo.participants_array[0]))
            {
                values1.push(values[i]);
            }
            // else if((values[i].receiver_id === roomInfo.participants_array[0] && values[i].user_id === userInfo?.id) || (values[i].receiver_id === roomInfo.participants_array[1] && values[i].user_id === userInfo?.id))
            // {
            //     values1.push(values[i]);
            // }
        }
        else
        {
            values1.push(values[i]);
        }
    }
    sortMessages(values1);

    const _limit = limit || LIMIT;
    const _offset = offset || 0;
    const msgs = values1.reverse().slice(_offset, _limit);
    return msgs;
};

// TODO: compatible -> count badge. NEED REFACTOR !!!
const getMyChats = async (myId) => {
    const keys = Storage.getAllKeys();
    const chatKeys = keys.filter((key) => key.indexOf(CHAT_PREFIX_) >= 0);

    const result = {};
    chatKeys.forEach((key) => {
        const msg = Storage.get(key);
        if (msg?.receiver_id === myId || msg?.user_id === myId) {
            const [_, roomId, messageId] = key.split(':');
            result[roomId] = result[roomId] || {
                room_id: roomId,
            };
            result[roomId].messages = result[roomId].messages || {};
            result[roomId].messages[messageId] = Storage.get(key);
        }
    });
    return result;
};

const getMessagesSentFailByRoomId = async (roomId) => {
    return [];
};

const countAllUnreadMessages = async (myId) => {
    return 0;
};

const countUnreadMessageByRoomId = async (roomId) => {
    return 0;
};

const removeAllMessageIsExpired = async () => {
    const keys = Storage.getAllKeys();
    const chatKeys = keys.filter((key) => key.indexOf(CHAT_PREFIX_) >= 0);
    const values = chatKeys.map((key) => {
        return {
            key,
            message: Storage.get(key),
        };
    });
    const expiredValues = values.filter(
        (v) => v.message.expire_at <= clock.now(),
    );
    const expiredKeys = expiredValues.map((v) => v.key);
    return Storage.multiRemove(expiredKeys);
};

const removeRoom = async (roomId) => {
    const keys = Storage.getAllKeys();
    const chatKeys = keys.filter(
        (key) => key.indexOf(`${CHAT_PREFIX_}:${roomId}`) >= 0,
    );
    return Storage.multiRemove(chatKeys);
};

export default {
    addMessage,
    updateMessage,
    updateAllMessageRoomIsRead,
    getMessages,
    getMessages1,
    getMessagesSentFailByRoomId,
    getMyChats,
    countAllUnreadMessages,
    countUnreadMessageByRoomId,
    removeAllMessageIsExpired,
    removeRoom,
};
