import React, {useState, useEffect, useRef} from 'react';
import {getRankUser, getRankCall} from '../function';
import '../createTopicStyle.scss';

export default function SeekBarTopic({
    maxChatPrice,
    maxCallPrice,
    currentChatPrice,
    currentCallPrice,
    setChatPrice,
    setCallPrice,
    isFree,
    setFree,
}) {
    useEffect(() => {
        if (currentChatPrice === '') {
            let halfPrice = maxChatPrice / 2;
            if (halfPrice % 10 !== 0) {
                halfPrice = halfPrice + 5;
            }
            setChatPrice(halfPrice);
        }
        if (currentCallPrice === '') {
            let halfPrice = maxCallPrice / 2;
            if (halfPrice % 10 !== 0) {
                halfPrice = halfPrice + 5;
            }
            setCallPrice(halfPrice);
        }
    });

    let onChatPriceChange = (value) => {
        setChatPrice(value);
    };

    let onCallPriceChange = (value) => {
        setCallPrice(value);
    };

    return (
        <div className="priceSettings">
            <div className="priceContainer">
                <div className="priceValue">
                    <span className="note">
                        チャット課金：
                        {currentChatPrice <= 0
                            ? '無料'
                            : currentChatPrice + 'ポイント'}
                    </span>
                </div>
                <div className="settingPrice">
                    <span className="minPrice">無料</span>
                    <input
                        type="range"
                        min="0"
                        max={maxChatPrice}
                        value={currentChatPrice}
                        step="10"
                        className="priceSlider"
                        onChange={(event) =>
                            onChatPriceChange(event.target.value)
                        }
                    />
                    <span className="maxPrice">
                        {maxChatPrice + 'ポイント'}
                    </span>
                </div>
            </div>
            <div className="priceContainer">
                {currentCallPrice <= 0 ? (
                    <div className="priceValue">
                        <span className="note">通話課金：無料</span>
                    </div>
                ) : (
                    <div className="priceValue">
                        <span className="note">通話課金：1 分</span>
                        <span className="value">
                            {currentCallPrice + 'ポイント'}
                        </span>
                        <img
                            className={
                                isFree
                                    ? 'checkbox-checked'
                                    : 'checkbox-unchecked'
                            }
                            src={
                                isFree
                                    ? '/images/ic_topic_checked.svg'
                                    : '/images/ic_topic_unchecked.svg'
                            }
                            onClick={() => setFree(!isFree)}
                        />
                        <span className="note">最初から課金</span>
                    </div>
                )}
                <div className="settingPrice">
                    <span className="minPrice">無料</span>
                    <input
                        type="range"
                        min="0"
                        max={maxCallPrice}
                        value={currentCallPrice}
                        step="10"
                        className="priceSlider"
                        onChange={(event) =>
                            onCallPriceChange(event.target.value)
                        }
                    />
                    <span className="maxPrice">
                        {maxCallPrice + 'ポイント'}
                    </span>
                </div>
            </div>
        </div>
    );
}
