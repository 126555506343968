import React from 'react';
import {useDispatch} from 'react-redux';
import {confirmRequestPaidCall} from '../actions';

const ConfirmPaidCall = ({data}) => {
    const dispatch = useDispatch();

    const handleConfirm = (status) => {
        dispatch(confirmRequestPaidCall(status));
    };

    return (
        <div className="confirm-paid-call">
            <p>
                課金リクエストが届きました。
                <br />
                表示されているポイントの課金を承認する場合は
                <br />
                「承認」ボタンをタップしてください。
            </p>
            <div className="price">
                <img src="/images/icon-take-charge.svg" />
                <span>
                    1分ごとに　{data.price}
                    ポイント
                </span>
            </div>
            <div className="btnWrap">
                <span
                    className="btn btn-chat"
                    onClick={() => handleConfirm(true)}>
                    承認
                </span>
                <span
                    className="btn btn-call"
                    onClick={() => handleConfirm(false)}>
                    拒否
                </span>
            </div>
        </div>
    );
};

export default ConfirmPaidCall;
