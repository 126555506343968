const dayOfWeeks = ['日', '月', '火', '水', '木', '金', '土'];

export function timeStamp2Date(time) {
    const timestamp = isValidTimestamp(time) ? parseInt(time) : time * 1000;
    if (timestamp)
        if (timestamp) {
            let date = new Date(timestamp);
            let h =
                date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let m =
                date.getMinutes() < 10
                    ? '0' + date.getMinutes()
                    : date.getMinutes();
            return h + ':' + m;
        } else {
            return '';
        }
}

export const getDay = (time) => {
    const timestamp = isValidTimestamp(time) ? parseInt(time) : time * 1000;
    if (timestamp) {
        let date = new Date(timestamp);
        return date.getDate();
    } else {
        return 0;
    }
};

export const getMonthDay = (time) => {
    const timestamp = isValidTimestamp(time) ? parseInt(time) : time * 1000;
    if (timestamp) {
        let date = new Date(timestamp);
        return `${date.getMonth() + 1}/${date.getDate()}(${
            dayOfWeeks[date.getDay()]
        })`;
    } else {
        return 0;
    }
};

export const isValidTimestamp = (timestamp) => {
    if (parseInt(timestamp) < 100000000000) {
        return false;
    }
    const n = new Date(parseInt(timestamp)).getTime();
    return !isNaN(parseFloat(n)) && isFinite(n);
};

export function totalTimetoDate(timestamp) {
    if (timestamp) {
        let s = Math.floor(timestamp % 60);
        let m = Math.floor(timestamp / 60);
        return (m > 0 ? m + '分' : '') + s + '秒';
    } else {
        return '';
    }
}

export function formatPrice(price) {
    if (price) {
        let priceString = String(price);
        return priceString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
        return '0';
    }
}

export const sortMessages = (messages, desc = false) => {
    return messages.sort((a, b) => {
        let createdAtOfA = 0;
        if (a.created_at) {
            createdAtOfA = a.created_at;
        } else if (a.created_at) {
            createdAtOfA = a.created_at;
        }

        let createdAtOfB = 0;
        if (b.created_at) {
            createdAtOfB = b.created_at;
        } else if (b.created_at) {
            createdAtOfB = b.created_at;
        }

        return desc ? createdAtOfB - createdAtOfA : createdAtOfA - createdAtOfB;
    });
};
