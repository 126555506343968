import React from 'react';
import {Link} from 'react-router-dom';
import '../termOfUse.scss';

export default function TermOfUse({}) {
    return (
        <div className="policy">
            <h3>COCORO利用規約</h3>
            <br />
            <article className="article-indent">
                この規約（以下「本規約」といいます。）は、合同会社ＣＯＣＯＲＯ（以下「当社」といいます。）が提供する
                「ＣＯＣＯＲＯ」に関するすべてのサービス（以下「本サービス」といいます。）を利用するにあたっての条件を、本サービスを利用するお客様（以下「お客様」といいます。）と当社との間で約するものです。
            </article>
            <br />
            <h4>1条　用語解説</h4>
            <br />
            <article className="article-indent">
                本規約では、以下の用語を使用します。
            </article>
            <ul className="decimal">
                <li>
                    コンテンツ」とは、文章、音声、音楽、画像、動画、ソフトウェア、プログラム、コードその他の情報のことを示します。
                </li>
                <li>
                    本コンテンツ」とは、本サービスを通じて操作接続することができるコンテンツのことを示します。
                </li>
                <li>
                    アップコンテンツ」とは、お客様が本サービスに投稿、送信、アップロードしたコンテンツのことを示します。
                </li>
                <li>
                    別途利用規約」とは、本サービスに関して、本規約とは別に「規約」、「使用案内」、などの名称で当社が掲示している文書のことを示します。
                </li>
            </ul>
            <br />
            <h4>2条　 規約への同意</h4>
            <br />
            <ul className="decimal">
                <li>
                    本サービスへの利用許諾はお客様が本ソフトウェアを対象端末へインストールした時
                    点で本規約等に同意成立ものとします。尚、同意できない場合は速やかにアンインス
                    トールしなければならないものとします。
                </li>
                <li>
                    お客様は、本規約の定めに従って本サービスを利用しなければなりません。お客様は、
                    本規約に有効な同意がなされなければ本サービスを利用できません。
                </li>
                <li>
                    お客様が18才未満である場合は、親権者など法定代理人の同意を得たうえで本サービ
                    スを利用してください 。
                </li>
                <li>
                    お客様は、本サービスを実際に利用することによって本規約に有効で取消不能な同意
                    をしたものとみなされます。
                </li>
                <li>
                    本サービスにおいて別途利用規約がある場合、お客様は、本規約のほか別途利用規約
                    の定めにも従って本サービスを利用しなければなりません。
                </li>
            </ul>
            <br />
            <h4>3条 規約の変更</h4>
            <br />
            <article className="article-indent">
                当社は、当社が必要と判断する場合、あらかじめお客様に通知することなく、いつでも、
                本規約および別途利用規約を変更できるものとします。変更後の本規約および別途利用規
                約は、当社が運営するウェブサイト内に掲示された時点からその効力を生じるものとし、
                お客様は本規約および別途利用規約の変更後も本サービスを使い続けることにより、変更
                後の本規約および別途利用規約に対する有効な同意をしたものとみなされます。随時変更
                の内容をお客様に個別通知することはいたせません。本サービスをご利用の際には、随時、
                最新の本規約を閲覧ください。
            </article>
            <br />
            <h4>4条　アカウント</h4>
            <br />
            <ul className="decimal">
                <li>
                    お客様は、本サービスの利用に際してお客様ご自身に関する情報を登録する場合、正
                    確で完全な情報を提供しなければならず、常に最新の情報となるよう修正しなければ
                    なりません。
                </li>
                <li>
                    お客様は、本サービスの利用に際して名前（ハンドルネーム）を登録する場合、これ
                    を不正に利用されないようご自身の責任で厳重に管理しなければなりません。当社は、
                    登録されたパスワードを利用して行なわれた一切の行為を、お客様ご自身の行為とみ
                    なすことができます。
                </li>
                <li>
                    本サービスに登録したお客様は、いつでもアカウントを削除して退会することができ
                    ます。
                </li>
                <li>
                    当社は、お客様が本規約に違反しまたは違反するおそれがあると認めた場合、あらか
                    じめお客様に通知することなく、アカウントを停止または削除することができます。
                </li>
                <li>
                    当社は、最終のアクセスから90日以上経過しているアカウントを、あらかじめお客様
                    に通知することなく削除することができます。
                </li>
                <li>
                    お客様の本サービスにおけるすべての利用権は、理由を問わず、アカウントが削除さ
                    れた時点で消滅します。お客様が誤ってアカウントを削除した場合であっても、アカ
                    ウントの復旧はできませんのでご注意ください。
                </li>
                <li>
                    お客様の本サービスにおけるすべての利用権は、第三者に譲渡、貸与または相続させ
                    ることはできません。
                </li>
            </ul>
            <br />
            <h4>5条　プライバシー</h4>
            <br />
            <ul className="decimal">
                <li>
                    お客様のプライバシーを最大限に尊重し、個人情報保護の実現に向けて国が定める指針、
                    関連する法令を遵守します。
                </li>
                <li>
                    個人情報を厳粛に管理しその利用.提供は社内規定を順守し
                    <a
                        target="_blank"
                        href={'https://terms.line.me/line_rules/?lang=ja'}>
                        合同会社ＣＯＣＯＲＯ　プライバシーポリシー
                    </a>
                    に従って適切に取り扱います。
                </li>
                <li>
                    お客様のプライバシーを保護する観点から、情報漏えい、滅失、き損の防止対策を策定
                    し適正に取り扱います。
                </li>
            </ul>
            <br />
            <h4>6条　サービスの提供 </h4>
            <br />
            <ul className="decimal">
                <li>
                    お客様は、本サービスを利用するにあたり、必要なパーソナルコンピュータ、携帯電話
                    機、通信機器、オペレーションシステム、通信手段などを、お客様の費用と責任で用意
                    しなければなりません。
                </li>
                <li>
                    当社は、本サービスの全部または一部を、当社が必要と判断する条件を満たしたお客様
                    に限定して提供することができるものとします。
                </li>
                <li>
                    当社は、当社が必要と判断する場合、あらかじめお客様に通知することなく、いつでも、
                    本サービスの全部または一部の内容を変更し、また、その提供を中止することができる
                    ものとします。
                </li>
            </ul>
            <br />
            <h4>7条　本サービスの停止、廃止</h4>
            <br />
            <ul className="decimal">
                <li>
                    当社はシステムの保守点検、不可抗力、サービスの運営状況、その他の予期できない事情
                    によりお客様に対し事前に何ら通知行うことなく、本サービスの全部または一部の提供を
                    停止又は中止できるものとします。
                    尚、本条に基づき当社が行った措置に基づきお客様に生じた損害について一切の責任を負
                    いません。
                </li>
                <li>
                    当社は本サービスの運営を継続し難いと判断した場合には、本サービスを廃止することが
                    できるものとします。
                    尚、本条に基づき当社が行った措置に基づきお客様に生じた損害について一切の責任を負
                    いません。
                </li>
            </ul>
            <br />
            <h4>8条　 緊急通報機能はありません</h4>
            <br />
            <article className="article-indent">
                本サービスは、警察機　関、海上保安機関、消防機関その他の機関への緊急通報手段を提供する
                ものではありません。
            </article>
            <br />
            <h4>9条　 広告表示</h4>
            <br />
            <article className="article-indent">
                当社は、本サービスに当社または第三者の広告を掲載することができるものとします。
            </article>
            <br />
            <h4>10条　 提携パートナーのサービス</h4>
            <br />
            <article className="article-indent">
                本サービスは、当社と提携する他の事業者が提供するサービスまたはコンテンツを含む場合があり
                ます。かかるサービスまたはコンテンツに対する責任は、これを提供する事業者が負います。また、
                かかるサービスまたはコンテンツには、これを提供する事業者が定める利用規約その他の条件等が
                適用されることがあります。
            </article>
            <br />
            <h4>11条　コンテンツ</h4>
            <br />
            <ul className="decimal">
                <li>
                    当社は、当社が提供する本コンテンツについて、お客様に対し、譲渡および再許諾できず、非独占的な、本サービスの利用を唯一の目的とする利用権を付与します。
                </li>
                <li>
                    お客様は、利用料、利用期間等の利用条件が別途定められた本コンテンツを利用する場合、かかる利用条件に従うものとします。本サービスの画面上で「購入」、「販売」などの表示がされている場合であっても、当社がお客様に対し提供する本コンテンツに関する知的財産権その他の権利はお客様に移転せず、お客様には、上記の利用権のみが付与されます。
                </li>
                <li>
                    お客様は、本コンテンツを、本サービスが予定している利用態様を超えての利用(送信、転載、複製、改変などの行為が含まれます)を禁じます。
                </li>
                <li>
                    アップコンテンツのバックアップは、お客様ご本人で行なっていただくこととなります。当社はアップコンテンツのバックアップを行う義務を負いません。
                </li>
                <li>
                    本サービスは、複数のお客様が修正、投稿、削除等の編集を行える機能を含む場合があります。この場合、お客様はご自身のアップコンテンツに対する他のお客様の編集を許諾するものとします。
                </li>
                <li>
                    お客様は、アップコンテンツに対して有する権利を従前的に保持するものとし、当社が係る権利を取得することはありません。ただし、アップコンテンツのうち、友だち関係にない他のお客様一般にも公開されたものに限り、お客様は、当社に対し、これをプロモーションやサービス等に利用する権利（当社が適正とみなす範囲で省略等の変更を加える権利を含みます。また、係る利用権を当社と提携する第三者に再許諾する権利も含みます。）を、無償で、無期限に、地域の限定なく許諾したこととなるものとします。
                </li>
                <li>
                    当社は、法令または本規約の遵守状況などを確認する必要がある場合、アップコンテンツの内容を確認することができるものとします。但し、当社はそのような確認を行なう義務を負うものではありません。
                </li>
                <li>
                    当社は、お客様がアップコンテンツに関し法令もしくは本規約に違反し、または違反するおそれのあると認められた場合、その他業務上の必要がある場合、あらかじめお客様に通知することなく、アップコンテンツを削除するなどの方法により、本サービスでのアップコンテンツの利用を制限できるものとします。
                </li>
            </ul>
            <br />
            <h4>12条　履歴の消去</h4>
            <br />
            <ul className="decimal">
                <li>
                    本サービス内におけるメッセージ等の通信履歴は最大保存時間に達した時点で自動消去されます。尚、最大時間内において利用者がご都合の保存時間を指定された場合は指定時間に達した時点で自動消去とされます。
                </li>
                <li>
                    お友達リスト（電話帳リスト）におけるユーザーリストの削除は一方のいずれかがリストを削除することで双方のリストが同時自動消去されます。尚、いずれかの一方が削除しない限りリストは常時保存されます。
                </li>
            </ul>
            <br />
            <h4>13条　利用料金</h4>
            <br />
            <ul className="decimal">
                <li>
                    本サービスのご利用は、ポイント購入を除いて無料とします。
                </li>
                <li>
                    ポイントの利用料、購入単位、決済方法、サービスの中におけるポイントの利用方法、サービスの仕組みや算定方法等は、当社が別途定め指定するものとします。
                </li>
                <li>
                    ポイントを必要とするサービスのご利用には、情報料の他にパケット通信料、が必要になります。
                </li>
            </ul>
            <br />
            <h4>14条　ポイントの利用について</h4>
            <br />
            <ul className="decimal">
                <li>
                    ポイントは本サービスでの購入、キャンペーンその他、当社が指定案内する方法で付与されます。
                </li>
                <li>
                    ポイントはこれを取得した利用者のみ当社の定める範囲において利用することができます。
                </li>
                <li>
                    ポイントの利用は、ポイント取得した本人が行うものとし以外の第三者は利用することはできません。
                </li>
            </ul>
            <br />
            <h4>15条　ポイント課金サービス</h4>
            <br />
            <ul className="decimal">
                <li>
                    利用者は、画面上に表示された金額（消費税込み）を選択してポイントを購入することができます。
                </li>
                <li>
                    ポイントの購入等およびポイントによるご利用には、ポイントの他に別途パケット通信料がかかります。
                </li>
                <li>
                    未成年者がポイント購入の場合、保護者又は親権者の同意を得るものとします。
                </li>
                <li>
                    ポイント利用者は、保有するポイントを第三者に移転、譲渡、担保権設定し、又はポイントの共有はできません。
                </li>
                <li>
                    ポイント利用者はいかなる場合も一切ポイントを現金等に交換はできません。
                </li>
                <li>
                    ポイントは原則として払い戻しは行いません。</li>
                <li>
                    本サービス利用者は本サービスに関して以下の行為を理由を問わず一切行ってはならないものとします。
                    <ul className="custom_list">
                        <li>1）本規約等に定める禁止行為</li>
                        <li>
                            2）本サービスに関して当社その他の第三者の権利を害する又はその恐れのある行為
                        </li>
                        <li>3）法令違反、または違反する可能性がある行為</li>
                    </ul>
                </li>
                <li>
                    当社は、7.項1)2)3)に違反した場合その他不正行為があった場合、ポイント利用者に対するポイントの付与もしくはポイント利用者が保有しているポイントの利用停止の行使、又は、ポイント利用者が保有するポイントの一部もしくは全部を取り消すことができることとします。
                </li>
                <li>
                    当社は、ポイント利用者がポイント代金を支払わない場合やその他本規約等に違反した場合、ポイント利用者への本サービスの提供もしくは本アプリの利用を停止し、または本アプリの利用を取り消すことができます。
                </li>
                <li>
                    当社がポイント利用者に対して、本サービスや本アプリ利用を中断、停止もしくは廃止した場合において保有ポイントが残っていてもポイント代金の返金・換金はできません。
                </li>
            </ul>
            <br />
            <h4>16条　禁止事項</h4>
            <br />
            <article className="article-indent">
                お客様は、本サービスの利用に際して、以下の記載条項を行なってはなりません。
            </article>
            <ul className="decimal">
                <li>
                    法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為。
                </li>
                <li>
                    公の秩序または善良の風俗を害するおそれのある行為。
                </li>
                <li>
                    当社または第三者の著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上または契約上の権利を侵害する行為。
                </li>
                <li>
                    過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を、投稿または送信する行為。{' '}
                </li>
                <li>
                    当社または第三者になりすます行為または意図的に虚偽の情報を流布させる行為。
                </li>
                <li>
                    同一または類似のメッセージを不特定多数のお客様に送信する行為（当社の認めたものを除きます。）、他のお客様を無差別に友だちに追加する行為、その他当社がスパムと判断する行為。
                </li>
                <li>
                    本コンテンツの利用権を、現金、財物その他の経済上の利益と交換する行為。
                </li>
                <li>
                    宣伝、広告、勧誘、その他営利を目的とする行為（当社の認めたものを除きます。）、性行為やわいせつな行為を目的とする行為、面識のない異性との出会いや交際を目的とする行為、他のお客様に対する嫌がらせや誹謗中傷を目的とする行為、その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為。
                </li>
                <li>
                    反社会的勢力に対する利益供与その他の協力行為。
                </li>
                <li>
                    宗教活動または宗教団体への勧誘行為。
                </li>
                <li>
                    他人の個人情報、登録情報、利用履歴情報などを、不正に収集、開示または提供する行為。
                </li>
                <li>
                    本サービスのサーバやネットワークシステムに支障を与える行為、その他の技術的手段を利用してサービスを不正に操作する行為、本サービスの不具合を意図的に利用する行為、同様の質問を必要以上に繰り返す等、当社に対し不当な問い合わせまたは要求をする行為、その他当社による本サービスの運営または
                    他のお客様による本サービスの利用を妨害し、これらに支障を与える行為。
                </li>
                <li>
                    上記の全条項いずれかに該当する行為を援助または助長する行為。
                </li>
                <li>
                    その他、当社が不適当と判断した行為。
                </li>
            </ul>
            <br />
            <h4>17条 お客様の責任</h4>
            <br />
            <ul className="decimal">
                <li>
                    お客様は、お客様ご自身の責任において本サービスを利用するものとし、本サービスにおいて行った一切の行為およびその結果について一切の責任を負うものとします。
                </li>
                <li>
                    当社は、お客様が本規約に違反して本サービスを利用していると認めた場合、当社が必要で適切と判断する措置を講じます。ただし、当社は、かかる違反を防止または是正する義務を負いません。
                </li>
                <li>
                    お客様は、本サービスを利用したことに起因して（当社が係る利用を原因とするクレームを第三者から受けた場合を含みます。）、当社が直接的もしくは間接的に何らかの損害（弁護士費用負担を含みます。）を被った場合、当社の請求にしたがって直ちにこれを補償しなければなりません。
                </li>
            </ul>
            <br />
            <h4>18条　当社の免責</h4>
            <br />
            <ul className="decimal">
                <li>
                    当社は、本サービス（本コンテンツを含みます。）に事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。当社は、お客様に対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。
                    本サービスの運営を継続し難いと判断した場合には、本サービスを廃止することができるものとします。
                </li>
                <li>
                    当社は、お客様が本規約に違反して本サービスを利用していると認めた場合、当社が必要で適切と判断する措置を講じます。ただし、当社は、かかる違反を防止または是正する義務を負いません。
                </li>
                <li>
                    当社は、当社の過失による債務不履行または不法行為によりお客様に生じた損害のうち特別な事情から生じた損害（当社またはお客様が損害発生につき予見し、または予見し得た場合を含む）について一切の責任を負いません。
                </li>
            </ul>
            <br />
            <h4>19条　連絡方法</h4>
            <br />
            <ul className="decimal">
                <li>
                    本サービスに関する当社からお客様への連絡は、当社が運営するウェブサイト内の適宜の場所への掲示その他、当社が適当と判断する方法で行ないます。
                </li>
                <li>
                    本サービスに関するお客様から当社への連絡は、当社が運営するウェブサイト内の適宜の場所に設置するお問い合わせフォームの送信または当社が指定する方法により行っていただきます。
                </li>
            </ul>
            <br />
            <h4>20条　準拠法、裁判管轄</h4>
            <br />
            <article className="article-indent">
                本規約の解釈にあたっては、日本法を準拠法とします。
                本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を
                専属的合意管轄とします。
            </article>
            <br />
            <article className="article-indent">以上</article>
            <br />
            <article className="article-indent">
                最終更新日：2022年1月10日
            </article>
        </div>
    );
}
