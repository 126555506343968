import React, {useState, useEffect} from 'react';

export default function InputField({
    title,
    data,
    setData,
    error,
    inputType = 'password',
}) {
    return (
        <div className="inputField">
            <span className="title">{title}</span>
            <input
                className="inputPassword"
                type={inputType}
                autoComplete="off"
                value={data}
                onChange={(event) => setData(event.target.value)}
            />
            {error !== '' && <span className="error">{error}</span>}
        </div>
    );
}
