// import React as * from 'react';
import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import '../styles.scss';
import {logout, login} from '../../user/actions';
import {changeRoute, setNaviIndex} from '../../app/actions';
import {getMyInfo, getMyUsersInfo, getApproval, selfDeleteUser} from '../actions';
import {Link, useHistory} from 'react-router-dom';
import { updateUser } from "../../infoRegister/actions";
import styles from './styles';
import {openUserChanges} from '../actions';

const MyPage = ({userInfo, getMyInfo, getMyUsersInfo, getApproval, setNaviIndex, selfDeleteUser, logout, login, openUserChanges, timeSetting}) => {

    let history  = useHistory();
    let dispatch = useDispatch();
    const  [isFirst, setIsFirst]  = useState(true);
    const  [ showUserChoose, setShowUserChoose ] = useState(false);

    useEffect(()=>{
        
        if(isFirst){
            setIsFirst(false);
            setNaviIndex('profile');
            getMyInfo();
            getMyUsersInfo();
        }
    })

    let openUserChange = () => {
        // timeSetting: state['cocoro/settings'].timeSetting,
        
        openUserChanges(userInfo);
    };

    let storageUserInfo = localStorage.getItem('user_info');
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    let goSettings = () => {
        changeRoute('SETTINGS');
    };

    let selfDelete = () => {
        setShowDeleteModal(true);
    }

    let userBloodSettings;
    try{
        userBloodSettings = JSON.parse(userInfo.userBloodSettings);
        if(userBloodSettings == null){
            userBloodSettings = {
                aggression: '',
                bloodType: "",
                degree: '',
                expression: '',
                fashion: '',
                personality: "",
                sociability: '',
            }
        }
    }catch(e){
        userBloodSettings = {
            aggression: '',
            bloodType: "",
            degree: '',
            expression: '',
            fashion: '',
            personality: "",
            sociability: '',
        }
    }

    let userCharacterSettings;
    try{
        userCharacterSettings = userInfo.userCharacterSettings;
        if(userCharacterSettings == null)
            userCharacterSettings = [];
    }catch(e){
        userCharacterSettings = [];
    }

    let userStorage = localStorage.getItem('user_info');
    try{
        userStorage = JSON.parse(userStorage);
    }catch(e){
        userStorage = {};
    }
    let userCoin = userStorage?.userCoin;
    if(userCoin == null || userCoin == undefined)
        userCoin = 0;

    let avatar;
        try{
            avatar = JSON.parse(userStorage.avatar);
        }catch(e){
            avatar = {}
        }

    const [ userCharacterInputWord, setUserCharacterInputWord ]  = useState(userStorage?.userCharacterInputWord);
    const [ userCharacterInputDetail, setUserCharacterInputDetail ] =  useState(userStorage?.userCharacterInputDetail);
    const [ wordBackColor, setWordBackColor] = useState('#f7f7f7');
    const [ detailBackColor, setDetailBackColor] = useState('#f7f7f7');

    const gotoSelectImage = () => {
        history.push('/changeavatar');
    }
    const gotoSelectUser1 = async () => {
        await logout(true, true);
        setTimeout(async()=>{
            const result = await login('test2@gmail.com2', 'qwer1234');
            if (result?.error && result?.message) {
                setErr(result.message);
            }
            if (result?.access_token) {
                if (result.is_infomation === false) {
                    history.push('/setting-information');
                } else {
                    history.push('/');
                }
            }
        }, 0)
    }
    const gotoSelectUser2 = async () => {
        dispatch(logout(true));
        const result = await dispatch(login('test1@gmail.com1', 'qwer1234'));
        if (result?.error && result?.message) {
            setErr(result.message);
        }
        if (result?.access_token) {
            if (result.is_infomation === false) {
                history.push('/setting-information');
            } else {
                history.push('/');
            }
        }
    }
    const gotoSelectUser3 = async () => {
        logout(true);
        const result = await dispatch(login('test1@gmail.com2', 'qwer1234'));
        if (result?.error && result?.message) {
            setErr(result.message);
        }
        if (result?.access_token) {
            if (result.is_infomation === false) {
                history.push('/setting-information');
            } else {
                history.push('/');
            }
        }
    }

    const [err, setErr ] = useState('');

    const saveUserInputData = (word, detail)=>{
        if(word.length > 100){
            setWordBackColor('#e7adef');
            return false;
        }else{
            setWordBackColor('#fff');
        }
        if(detail.length > 500){
            setWordBackColor('#e7adef');
            return false;
        }else{
            setWordBackColor('#fff');
        }

        userStorage.userCharacterInputWord = userCharacterInputWord;
        userStorage.userCharacterInputDetail = userCharacterInputDetail;

        localStorage.setItem('user_info', JSON.stringify(userStorage));

        try{
            dispatch(
                updateUser(
                    callbackSuccess,
                    userInfo.age_id,
                    1,
                    userInfo.area_id,
                    userInfo.gender_id,
                    avatar.type_image,
                    avatar.imageUri,
                    userInfo.user_name,
                    userStorage.userAvatarRegisterInfo,
                    userStorage.userBloodSettings,
                    userStorage.userCharacterSettings,
                    userStorage.userRegisterInfo,
                    userStorage.userSoundSettings,
                    userCharacterInputWord,
                    userCharacterInputDetail,
                    userStorage.userCoin,
                    setErr,
                )
            );
        }catch(e){

        }

    }

    let callbackSuccess = (data) => {

    };

    return (
        <div id="mypage">
            { showDeleteModal ? 
                <div className={"modal-container"} onClick={()=>{ setShowDeleteModal(false) }}>
                    <div className={"modal"}>
                        <div>
                            本当に削除しますか？
                        </div>
                        <div style={{width: '100%', flexDirection: 'row',  display: 'flex',justifyContent: 'space-between'}}>
                            <button className={"delete"} onClick={()=>{ selfDeleteUser(); }}>
                                消去
                            </button>
                            <button className={"cancel"} onClick={()=>{ setShowDeleteModal(false); }}>
                                キャンセル
                            </button>
                        </div>
                    </div>
                </div>
                : null
            }

            { showUserChoose ? 
                <div className={"modal-container"} onClick={()=>{ setShowDeleteModal(false) }}>
                    <div className={"modal"}>
                        
                    </div>
                </div>
                : null
            }

            <header>
                <div className="back" />
                <h2>マイページ</h2>
            </header>

            { userInfo && (
                <div className="mainContainer">
                    <div className={"row"}>
                        <div className={"level1-row-left"}>
                            <Link to={'settings'}>
                            <img src={require('../../../images/setting.png')}/>
                                {/* <button onClick={goSettings}>
                                    設定<br/>
                                </button> */}
                            </Link>
                        </div>
                        <div className={"level1-row-center"}>
                            <img src={userInfo?.profile_image}/>
                            <button
                                style={{backgroundColor: '#F1AEA6',
                                    padding: 3,
                                    borderRadius: 10,
                                    marginLeft: -20,
                                    marginTop: -10,
                                    borderColor: "#E65B3B",
                                    borderBottomWidth: 3,
                                    cursor: 'pointer',
                                    width: "45%",
                                    height: "30%"
                                }}
                                onClick={gotoSelectImage}
                            >
                                <span style={{ color: '#FFFFFF',
                                
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    marginTop: 3,}}>
                                    変更
                                </span>
                            </button>
                        </div>
                        <div className={"level1-row-right"}>
                                <button onClick={selfDelete}>
                                    この自分を<br/>
                                    削除する
                                </button>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"level2-row-left"} onClick={()=>{ history.push("/manage-point") }}>
                            <img src={require('../../../images/currency_point.png')}/>
                            <div style={{color:"#22B164"}}>{ userCoin }</div>
                        </div>
                        <div className={"level2-row-center"}>
                            <div className={'name'}>{userInfo?.user_name}</div>
                            <div className={'detail'}>{ userInfo?.gender == '1' ? '男性（M）' : userInfo?.gender == '1' ? '女性（F）' : '不特定（X）' } / {userInfo.age.name} / { userInfo?.area?.name }</div>
                        </div>
                        <div className={"level2-row-right"}>
                            <Link to={'agree'}>
                                HELP
                            </Link>
                        </div>
                    </div>
                    <div className={'row-center'}>
                        <div style={{flexDirection: 'row',flexWrap: 'wrap',padding: 1}}>
                            <p1 style={{fontWeight: 'bold'}}> {userBloodSettings?.bloodType}型 </p1>
                            <p1 style={{fontWeight: 'bold'}}> {userBloodSettings?.personality} </p1>
                            <p1 style={{fontWeight: 'bold'}}>社{userBloodSettings?.sociability}/</p1>
                            <p1 style={{fontWeight: 'bold'}}>度{userBloodSettings?.degree}/</p1>
                            <p1 style={{fontWeight: 'bold'}}>感{userBloodSettings?.expression}/</p1>
                            <p1 style={{fontWeight: 'bold'}}>積{userBloodSettings?.aggression}  /</p1>
                            <p1 style={{fontWeight: 'bold'}}>度{userBloodSettings?.fashion} </p1>
                        </div>
                        <div style={{flexDirection: 'row',flexWrap: 'wrap',margin: "3px 26px"}}>
                            <p1 style={{fontWeight: 'bold'}}>{userCharacterSettings} </p1>
                        </div>
                    </div>
                    <div className='row row-center'>
                        <div className={'button-row'}>
                            <button onClick={()=>{ history.push("/changeprofile") }}>プロフィール変更</button>
                            <button  onClick={()=>{history.push('/registration-information/blood-setting-1');}}>興味のある事</button>
                            {/* <button onClick={()=>{ history.push('/registration-information/sound-setting-1') }}>ボイスチェンジ</button> */}
                        </div>
                        <div className={'button-row1'}>
                            <button  onClick={()=>{ openUserChange() }}>3キャラクター</button>
                        </div>
                    </div>
                    <div className={'row'}>
                        <h4 style={{paddingLeft: '20px', paddingTop: '10px', fontWeight: 'bold'}}>相手への禁止事項（１００文字以内）</h4>
                    </div>
                    <div className={'row'}>
                        <h5 style={{paddingLeft: '20px', paddingTop: '10px', color: "#606060"}}>自分のプロフィールを相手が見たときに反映されます。</h5>
                    </div>
                    <div className={'row-center'}>
                        <textarea style={{backgroundColor: wordBackColor}}
                             rows={5}
                             placeholder={'下ネタお断りします。通話中に出たらすぐに切ります。'}
                             value={userCharacterInputWord} onChange={(e)=>{
                                if(e.target.value.length > 100)
                                    setWordBackColor('#f7f7f7');
                                else{
                                    setWordBackColor('#f7f7f7');
                                    setUserCharacterInputWord(e.target.value);
                                }
                        }}/>
                    </div>
                    <div className={'row'}>
                        <h4 style={{paddingLeft: '20px', paddingTop: '10px', fontWeight: 'bold'}}>自分ＰＲ（５００文字以内）</h4>
                    </div>
                    <div className={'row'}>
                        <h5 style={{paddingLeft: '20px', paddingTop: '10px', color: "#606060"}}>自分のプロフィールを相手が見たときに反映されます。</h5>
                    </div>
                    <div className={'row-center'}>
                        <textarea style={{width: '85%', padding: 10, marginHorizontal: '2%', backgroundColor: detailBackColor, resize: 'none'}} rows={5} placeholder={'ジャンル問わず、ちょつとだけ誰かと話したい人待っています。\n出会い目的はお断りします。'} value={userCharacterInputDetail} onChange={(e)=>{
                            if( e.target.value.length > 500)
                                setDetailBackColor('#f7f7f7');
                            else{
                                setUserCharacterInputDetail(e.target.value);
                                setDetailBackColor('#f7f7f7');
                            }
                        }}/>
                    </div>
                    <div className={'button-row'} style={{marginBottom: 20}} onClick={()=>{saveUserInputData(userCharacterInputWord,userCharacterInputDetail)}}>
                            <button style={{width: 200}}>保存 す る</button>
                    </div>
                </div>
            ) }
        </div>
    );
};

function _mapStateToProps(state) {
    return {
        userInfo: state['cocoro/mypage'].userInfo,
        loading: state['cocoro/app'].loading,
        lastRoute: state['cocoro/app'].lastRoute,
    };
}

let _mapDispatchToProps = {
    getMyInfo,
    changeRoute,
    setNaviIndex,
    getApproval,
    selfDeleteUser,
    logout,
    login,
    openUserChanges,
    getMyUsersInfo,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(MyPage);
