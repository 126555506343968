import React, {useState, useRef, useEffect} from 'react';
// import { clipboard } from '@bumble/clipboard'

function Action({setEmoji, send, onChangeFile}) {
    const [msg, setMsg] = useState('');
    const textareaRef = useRef(null);
    useEffect(() => {
        textareaRef.current.style.height = '0px';
        const scrollHeight = textareaRef.current.scrollHeight;
        console.log('scrollHeight', scrollHeight);
        const height = Math.min(70, scrollHeight - 10);
        textareaRef.current.style.height = height + 'px';
    }, [msg]);

    return (
        <div id="footer">
            <div style={{display:'flex', justifyContent:'center'}}>
                <div>
                    <label htmlFor="myFile">
                        <img
                            alt=""
                            src="/images/ic_select_image@4x.png"
                            className="img"
                        />
                    </label>
                    <input
                        onChange={onChangeFile}
                        type="file"
                        id="myFile"
                        accept="image/png,image/jpg,image/jpeg"
                    />
                </div>
                <div>
                    <img
                        alt=""
                        onClick={() => setEmoji(true)}
                        src="/images/ic_select_file@4x.png"
                    />
                </div>
                <div style={{width:'100%'}}>
                    <textarea
                        ref={textareaRef}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
                                send(msg);
                                setMsg('');
                                e.preventDefault();
                            }
                            if (e.keyCode === 13 && e.ctrlKey) {
                                setMsg(msg + '\n');
                            }
                        }}
                        value={msg}
                        type="text"
                        maxLength="255"
                        onChange={(e) => {
                            setMsg(e.target.value);
                        }}
                    />
                </div>
                <div>
                    <img
                        onClick={() => {
                            send(msg);
                            setMsg('');
                        }}
                        alt=""
                        src={
                            msg === ''
                                ? '/images/ic_send_message@4x.png'
                                : '/images/ic_send_message_green.png'
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default Action;
