import React, {useState} from 'react';
import {StyleSheet, Text, View, Button, Image} from 'react-native';
import {useRouteMatch} from 'react-router-dom';
import {updateImageUri} from '../redux/action';
import {connect} from 'react-redux';
import {Constants} from '../../../util/Constants';
import Resizer from 'react-image-file-resizer';
import './styles.scss';

const Avatar = ({history, updateImageUri, imageUri}) => {
    let {path, url} = useRouteMatch();
    let onChangeFile = (e) => {
        let filePer = ['image/png', 'image/jpg', 'image/jpeg'];
        let file = e.target.files[0];
        if (file) {
            if (filePer.findIndex((f) => f === file.type) === -1) {
                // ERROR
            } else {
                Resizer.imageFileResizer(
                    file,
                    Constants.IMG_MAX_WIDTH,
                    Constants.IMG_MAX_HEIGHT,
                    Constants.IMG_JPEG,
                    Constants.IMG_QUANLITY,
                    0,
                    (uri) => {
                        updateImageUri(uri);
                    },
                    Constants.IMG_BASE64,
                );
            }
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.txt}>
                        つぎは あなたのアイコン画像を
                    </Text>
                    <Text style={styles.txt}>設定してみましょう！</Text>
                </View>
            </View>
            <View style={styles.main}>
                {imageUri ? (
                    <img id="img-profile" src={imageUri} />
                ) : (
                    <img id="img-profile" src={Constants.AVATAR_DEFAULT} />
                )}
                <Button
                    title="Take picture"
                    onPress={() => {
                        history.push({pathname: `${url}/take-picture`});
                    }}
                />
                <View style={styles.btn}>
                    <div>
                        <label htmlFor="myFile">
                            <Button
                                title="Choose From Libary"
                                style={styles.btn}
                            />
                        </label>
                        <input
                            onChange={onChangeFile}
                            type="file"
                            id="myFile"
                            accept="image/png,image/jpg,image/jpeg"
                        />
                    </div>
                </View>
                <Button
                    style={styles.btn}
                    title="Choose default avatar"
                    onPress={() => {}}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#A6D837',
    },
    header: {
        alignItems: 'center',
        height: 180,
        paddingBottom: 30,
        flexDirection: 'column-reverse',
    },
    txt: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    main: {
        flex: 1,
        backgroundColor: '#F4F9FA',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    btn: {
        margin: 50,
    },
});

function _mapStateToProps(state) {
    return {
        imageUri: state[Constants.STATE_REGISTRATION_INFOMATION].imageUri,
    };
}

let _mapDispatchToProps = {
    updateImageUri,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(Avatar);
