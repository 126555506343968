import React from 'react';
import {StyleSheet, View, ScrollView} from 'react-native';
import Header from './Header';
import {TermOfUse} from '../../settings';
import phoneBorderFaker from '../../../util/style/phoneBorderFaker';
import {useHistory} from 'react-router-dom';

export default function AgreementView({setIsAgreeReading}) {
    let history = useHistory();

    return (
        <View style={styles.container}>
            <div style={phoneBorderFaker}>
                <Header
                    txt="利用規約"
                    onPressBack={() => {
                        if (setIsAgreeReading) {
                            setIsAgreeReading(false);
                        } else {
                            history.goBack();
                        }
                    }}
                />
                <ScrollView style={mainStyle}>
                    <TermOfUse />
                </ScrollView>
            </div>
        </View>
    );
}

const mainStyle = {
    paddingTop: 16,
    paddingBottom: 16,
    overflowY: 'auto',
    height: 600,
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
});
