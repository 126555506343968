import {createStore} from 'redux';
import thunk from 'redux-thunk';
import StateListenerRegistry from './StateListenerRegistry';
import ReducerRegistry from './ReducerRegistry';
import MiddlewareRegistry from './MiddlewareRegistry';

const _createStore = () => {
    const middleware = MiddlewareRegistry.applyMiddleware(thunk);
    const reducer = ReducerRegistry.combineReducers();

    const store = createStore(reducer, middleware);
    StateListenerRegistry.subscribe(store);

    return store;
};

export const store = _createStore();
