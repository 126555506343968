import React from 'react';
import {timeStamp2Date, totalTimetoDate} from '../functions';
import {
    CHAT_TYPE_TEXT,
    CHAT_TYPE_IMAGE,
    CHAT_TYPE_EMOJI,
    CHAT_TYPE_IMAGE_BASE64,
    CHAT_TYPE_SYSTEM_CALL_CANCEL,
    CHAT_TYPE_SYSTEM_CALL_END,
    messageStatusTexts,
} from '../constants';
import Linkify from 'react-linkify';

const IMAGE_URL = [[
    "/images/trumpet/01.png",
    "/images/trumpet/02.png",
    "/images/trumpet/03.png",
    "/images/trumpet/04.png",
    "/images/trumpet/05.png",
    "/images/trumpet/06.png",
    "/images/trumpet/07.png",
    "/images/trumpet/08.png",
],[
    "/images/trumpet/09.png",
    "/images/trumpet/10.png",
    "/images/trumpet/11.png",
    "/images/trumpet/12.png",
    "/images/trumpet/13.png",
    "/images/trumpet/14.png",
    "/images/trumpet/15.png",
    "/images/trumpet/16.png",
],[
    "/images/trumpet/17.png",
    "/images/trumpet/18.png",
    "/images/trumpet/19.png",
    "/images/trumpet/20.png",
    "/images/trumpet/21.png",
    "/images/trumpet/22.png",
    "/images/trumpet/23.png",
    "/images/trumpet/24.png",
]];

const ChatContentMe = ({message, scroll, setUriImg}) => {
    return (
        <div className="me">
            <div>
                {message.message_type_id == CHAT_TYPE_SYSTEM_CALL_END && (
                    <main className="text">
                        <img src="/images/ic_call_success@4x.png" />
                        <span>
                            通話
                            <br />
                            <i>{totalTimetoDate(message.data)}</i>
                        </span>
                    </main>
                )}
                {message.message_type_id == CHAT_TYPE_SYSTEM_CALL_CANCEL && (
                    <main className="text">
                        <img src="/images/ic_call_missing@4x.png" />
                        <span>キャンセル</span>
                    </main>
                )}
                {message.message_type_id === CHAT_TYPE_EMOJI && (
                    <img
                        alt=""
                        onLoad={scroll}
                        className="emoji-img"
                        src={message.data}
                    />
                )}
                {(message.message_type_id === CHAT_TYPE_IMAGE ||
                    message.message_type_id === CHAT_TYPE_IMAGE_BASE64) && (
                    <img
                        onClick={() => setUriImg(message.data)}
                        alt=""
                        onLoad={scroll}
                        src={message.data}
                    />
                )}
                {message.message_type_id === CHAT_TYPE_TEXT && message.data.search("###sound") === -1 && (
                    <Linkify
                        componentDecorator={(
                            decoratedHref,
                            decoratedText,
                            key,
                        ) => (
                            <a target="_blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}>
                        <span className="text">{message.data}</span>
                    </Linkify>
                )}

                {message.message_type_id === CHAT_TYPE_TEXT && message.data.search("###sound") !== -1 && (
                    <img
                        alt=""
                        onLoad={scroll}
                        style={{width: 100, height: 100}}
                        src={IMAGE_URL[message?.data.split("_")[1]][message?.data.split("_")[2]]}
                    />
                )}

                {(message.message_type_id === CHAT_TYPE_SYSTEM_CALL_END ||
                    message.message_type_id === CHAT_TYPE_SYSTEM_CALL_CANCEL ||
                    message.message_type_id === CHAT_TYPE_TEXT) && (
                    <span className="author">
                        {message.status !== messageStatusTexts.SENT_SUCCESS &&
                            message.status !== messageStatusTexts.RECEIVED &&
                            message.status !== messageStatusTexts.REFUSED &&
                            message.status}
                    </span>
                )}
                {message.message_type_id === CHAT_TYPE_EMOJI && (
                    <span className="author-emoji">
                        {message.status !== messageStatusTexts.SENT_SUCCESS &&
                            message.status !== messageStatusTexts.RECEIVED &&
                            message.status !== messageStatusTexts.REFUSED &&
                            message.status}
                    </span>
                )}
                {(message.message_type_id === CHAT_TYPE_IMAGE ||
                    message.message_type_id === CHAT_TYPE_IMAGE_BASE64) && (
                    <span className="author-img">
                        {message.status !== messageStatusTexts.SENT_SUCCESS &&
                            message.status !== messageStatusTexts.RECEIVED &&
                            message.status !== messageStatusTexts.REFUSED &&
                            message.status}
                    </span>
                )}
                {(message.message_type_id === CHAT_TYPE_SYSTEM_CALL_END ||
                    message.message_type_id === CHAT_TYPE_TEXT ||
                    message.message_type_id ===
                        CHAT_TYPE_SYSTEM_CALL_CANCEL) && (
                    <span className="time">
                        {timeStamp2Date(message.created_at)}
                    </span>
                )}
                {message.message_type_id === CHAT_TYPE_EMOJI && (
                    <span className="time-emoji">
                        {timeStamp2Date(message.created_at)}
                    </span>
                )}
                {(message.message_type_id === CHAT_TYPE_IMAGE ||
                    message.message_type_id === CHAT_TYPE_IMAGE_BASE64) && (
                    <span className="time-img">
                        {timeStamp2Date(message.created_at)}
                    </span>
                )}
            </div>
        </div>
    );
};

export default ChatContentMe;
