import mitt from 'mitt';
import { openPopup } from '../app';
import * as webrtc from '../base/webrtc';
import {genCallId} from './functions';
//-----------------------------------------------

export const State = {
    IsReceiving: 'is_receiving',
    IsWaitingConnect: 'is_waiting_connect',
    IsConnected: 'is_connected',
    IsDisconnected: 'is_disconnected',
    IsUnableConnect: 'is_unable_connect',
    IsRefused: 'is_refused',
    IsAudio: 'is_audio',
    IsBusy: 'is_busy',
    IsEnded: 'is_ended',
};

export const Event = {
    AppStartCall: 'app_start_call',
    AppAnswerCall: 'app_answer_call',
    AppEndCall: 'app_end_call',
    NativeStartCall: 'native_start_call',
    NativeAnswerCall: 'native_answer_call',
    NativeRefuseCall: 'native_refuse_call',
};

export class CallManage {
    constructor() {
        this.peerConn = null;
        this.emitter = mitt();
        this.subs = new Map();

        this.State = State;
        this.Event = Event;

        this.receivingId = null;
        this.isCalling = false;
        this.isAnswering = false;

        this.callerId = null;
        this.callerName = null;
        // subkey forever
        this.subkeyIgnoreDestroy = 'not_destroy';
    }

    replyBusy(peerId, roomId) {
        if (roomId && peerId) {
            webrtc.notifyBusy(roomId, peerId);
        }
    }

    replyRefuse(callerId, roomId) {
        webrtc.notifyRefuse(roomId, callerId);
    }

    async call({calleeId, roomId, callerId, callerName}) {
        if (this.isCalling) {
            console.warn('isCalling...');
            return;
        }
        this.isCalling = true;
        this.callerId = calleeId;
        this.callerName = callerName;

        this.peerConn = webrtc.getConnection(calleeId, roomId);
        if (!this.peerConn) {
            const newCallId = genCallId();
            this.peerConn = webrtc.initConnection({
                localPeerId: callerId,
                remotePeerId: calleeId,
                signalId: roomId,
                callId: newCallId,
                callerId,
            });
        }

        // COMMENT: RNCallKeep destroy audio session -> conflict with InCallManager
        // this.emitter.emit(Event.AppStartCall, {
        //     callId: this.peerConn.callId,
        //     callerId,
        //     callerName,
        // });

        // auto cancel after 60s
        const timeout60s = setTimeout(this.handleCallTimeout.bind(this), 60000);

        // peer connection status listener
        const unsubscribePeerConnStatus = this.peerConn.on(
            'all',
            this.callerListenPeerConnStatus.bind(this),
        );
        this.subs.set('call', () => {
            clearTimeout(timeout60s);
            unsubscribePeerConnStatus();
        });
    }

    handleCallTimeout() {
        if (this.peerConn?.status !== webrtc.Status.Connected) {
            this.isCalling = false;
            this.emitter.emit(State.IsUnableConnect);
            if (this.peerConn) {
                this.emitter.emit(Event.AppEndCall, {
                    callId: this.peerConn.callId,
                    reason: this.EndCallReasons?.MISSED,
                });
                webrtc.notifyEnd(
                    this.peerConn.signalId,
                    this.peerConn.remotePeerId,
                );
            }

            this.destroy('from_call_timeout');
        }
    }

    callerListenPeerConnStatus(status) {
        switch (status) {
            case webrtc.Status.Connected:
                this.emitter.emit(State.IsConnected);
                break;
            case webrtc.Status.Disconnected:
                this.isCalling = false;
                this.emitter.emit(State.IsEnded);
                //
                if (this.peerConn) {
                    this.emitter.emit(Event.AppEndCall, {
                        callId: this.peerConn.callId,
                        reason: this.EndCallReasons?.FAILED,
                    });
                }
                this.destroy('caller_receive_disconnected');
                break;

            case webrtc.Status.Fail:
                this.isCalling = false;
                this.emitter.emit(State.IsUnableConnect);
                //
                if (this.peerConn) {
                    this.emitter.emit(Event.AppEndCall, {
                        callId: this.peerConn.callId,
                        reason: this.EndCallReasons?.FAILED,
                    });
                }
                this.destroy('caller_receive_unable_connect');
                break;

            case webrtc.Status.Refused:
                this.isCalling = false;
                this.emitter.emit(State.IsRefused);
                //
                if (this.peerConn) {
                    this.emitter.emit(Event.AppEndCall, {
                        callId: this.peerConn.callId,
                        reason: this.EndCallReasons?.DECLINED_ELSEWHERE,
                    });
                }
                this.destroy('caller_receive_refused');
                break;
            case webrtc.Status.Audio:                
                this.emitter.emit(State.IsAudio);
                break;
    
            case webrtc.Status.Busy:
                this.isCalling = false;
                this.emitter.emit(State.IsBusy);
                //
                if (this.peerConn) {
                    this.emitter.emit(Event.AppEndCall, {
                        callId: this.peerConn.callId,
                        reason: this.EndCallReasons?.MISSED,
                    });
                }

                this.destroy('caller_receive_busy');
                break;

            case webrtc.Status.EndCall:
                this.isCalling = false;
                this.emitter.emit(State.IsEnded);
                //
                if (this.peerConn) {
                    this.emitter.emit(Event.AppEndCall, {
                        callId: this.peerConn.callId,
                        reason: this.EndCallReasons?.REMOTE_ENDED,
                    });
                }
                this.destroy('caller_receive_endcall');
                break;
            default:
                break;
        }
    }

    async receive({callerId, roomId, calleeId, callerName}) {
        if (this.isAnswering) {
            console.warn('isAnswering...');
            return;
        }
        this.isAnswering = true;

        this.peerConn = webrtc.getConnection(callerId, roomId);
        if (!this.peerConn) {
            const newCallId = genCallId();
            this.peerConn = webrtc.setConnection({
                localPeerId: calleeId,
                remotePeerId: callerId,
                signalId: roomId,
                callId: newCallId,
                callerId,
            });
        }
        if (this.peerConn.status === webrtc.Status.None) {
            this.peerConn.connect();
        }

        // COMMENT: RNCallKeep destroy audio session -> conflict with InCallManager
        // this.emitter.emit(Event.AppAnswerCall, {
        //     callId: this.peerConn.callId,
        //     callerId,
        //     callerName,
        // });

        // peer connection status listener
        const unsubscribePeerConnStatus = this.peerConn.on(
            'all',
            this.calleeListenPeerConnStatus.bind(this),
        );
        this.subs.set('receive', () => unsubscribePeerConnStatus());
    }

    calleeListenPeerConnStatus(status) {
        switch (status) {
            case webrtc.Status.Connected:
                this.emitter.emit(State.IsConnected);
                break;

            case webrtc.Status.Disconnected:
                this.isAnswering = false;
                this.emitter.emit(State.IsDisconnected);
                //
                if (this.peerConn) {
                    this.emitter.emit(Event.AppEndCall, {
                        callId: this.peerConn.callId,
                        reason: this.EndCallReasons?.FAILED,
                    });
                }
                this.destroy('callee_receive_disconnected');
                break;

            case webrtc.Status.Fail:
                this.isAnswering = false;
                this.emitter.emit(State.IsUnableConnect);
                //
                if (this.peerConn) {
                    this.emitter.emit(Event.AppEndCall, {
                        callId: this.peerConn.callId,
                        reason: this.EndCallReasons?.FAILED,
                    });
                }
                this.destroy('callee_receive_unable_connect');
                break;

            case webrtc.Status.EndCall:
                this.isAnswering = false;
                this.emitter.emit(State.IsEnded);
                //
                if (this.peerConn) {
                    this.emitter.emit(Event.AppEndCall, {
                        callId: this.peerConn.callId,
                        reason: this.EndCallReasons?.REMOTE_ENDED,
                    });
                }
                this.destroy('callee_receive_endcall');
                break;
            default:
                break;
        }
    }

    async refuse({callerId, signalId}) {
        this.receivingId = null;
        await webrtc.notifyRefuse(signalId, callerId);
        this.destroy('from_refuse_action');
    }

    async audio({callerId, signalId}) {
        await webrtc.notifyAudio(signalId, callerId);
    }

    async end({peerId, signalId}) {
        this.isCalling = false;
        this.isAnswering = false;
        this.receivingId = null;
        
        if (signalId && peerId) {
            await webrtc.notifyEnd(signalId, peerId);
        } else {
            // fallback if UI missing data
            if (this.peerConn) {
                await webrtc.notifyEnd(
                    this.peerConn.signalId,
                    this.peerConn.remotePeerId,
                );
            }
        }

        if (this.peerConn) {
            this.emitter.emit(Event.AppEndCall, {
                callId: this.peerConn.callId,
                reason: this.EndCallReasons?.REMOTE_ENDED,
            });

            this.peerConn.stopTracks();
            this.peerConn.disconnect();
        }

        this.destroy('from_action_end_action');
    }

    on(key = '_', observer) {
        const self = this;
        Object.keys(observer).forEach((event) => {
            const handler = (data) => {
                console.log(`cm:${key}:receive:${event}`, data || '');
                observer[event](data);
            };
            self.emitter.on(event, handler);
            self.subs.set(`register:${key}:${event}`, () =>
                self.emitter.off(event, handler),
            );
        });
    }

    listenEndEvent({signalId, calleeId, subkey}) {
        const handler = (data) => {

            if (!data?.event) return;
            if (data?.event === webrtc.Status.Audio) {
                return;
            }
            if (data?.event === webrtc.Status.Refused) {
                this.emitter.emit(State.IsRefused);
            } else if (data?.event === webrtc.Status.Busy) {
                this.emitter.emit(State.IsBusy);
            } else if (data?.event === webrtc.Status.EndCall) {
                this.emitter.emit(State.IsEnded);
            }

            if (this.peerConn) {
                this.emitter.emit(Event.AppEndCall, {
                    callId: this.peerConn.callId,
                });
            }
            this.destroy(
                `from_listen_end_event_without_peer_conn:${data.event}`,
            );
        };
        const unsub = webrtc.statusListener(signalId, calleeId, handler);
        this.subs.set(subkey, () => unsub());
    }

    // register from UI
    unsubscribeListenEndEvent(subkey) {
        const webrtcSubKey = `webrtc.statusListener:${subkey}`;
        this.unsubscribeListener(webrtcSubKey);
        this.subs.forEach((unsub, key) => {
            if (key.indexOf(subkey) !== -1) {
                unsub();
                this.subs.delete(key);
                console.log(
                    'cm:unsubscribe_callee_listen_end_event',
                    key,
                    '-> success',
                );
            }
        });
    }

    // unsub a key from list subs
    unsubscribeListener(subkey) {
        if (this.subs.has(subkey)) {
            const unsub = this.subs.get(subkey);
            unsub();
            console.log(`cm:unsub:${subkey} -> success`);
            this.subs.delete(subkey);
        }
    }

    // unsub all listeners, except subkey: 'not_destroy'
    unsubscribeAllListeners() {
        this.subs.forEach((unsub, key) => {
            if (key.indexOf(this.subkeyIgnoreDestroy) === -1) {
                console.log('unsub', key);
                unsub();
            }
        });
        this.subs.clear();
    }

    destroy(source) {
        console.log(`cm:destroy:${source}`);
        this.unsubscribeAllListeners();
        if (this.peerConn) {
            webrtc.destroy(this.peerConn.remotePeerId, this.peerConn.signalId);
        }
        this.peerConn = null;
        this.isCalling = false;
        this.isAnswering = false;
        this.receivingId = null;
    }
}

export let CallManager = null;

export const initCallManager = (storeDispatch) => {
    if (!CallManager) {
        CallManager = new CallManage(storeDispatch);
    }
};
