import ReducerRegistry from '../base/redux/ReducerRegistry';
import {Constants} from '../../util/Constants';
import {
    DEFAULT_IMAGE_REGISTER,
    UPDATE_IMAGE_URI_REGISTER,
    UPDATE_AREA,
    UPDATE_AGE,
    UPDATE_GENDER,
} from './actionType';

let stateDefault = {
    images: [],
    imageUri: '',
    imageType: '',
    isNew: false,
    areaRegister: null,
    ageRegister: null,
    genderRegister: null,
};
ReducerRegistry.register('cocoro/register', (state = stateDefault, action) => {
    switch (action.type) {
        case DEFAULT_IMAGE_REGISTER:
            return {
                ...state,
                images: [...action.images],
            };
        case UPDATE_IMAGE_URI_REGISTER:
            return {
                ...state,
                imageUri: action.imageUri,
                imageType: action.imageType,
                isNew: action.isNew,
            };
        case UPDATE_AREA:
            return {
                ...state,
                areaRegister: action.area,
            };
        case UPDATE_AGE:
            return {
                ...state,
                ageRegister: action.age,
            };
        case UPDATE_GENDER:
            return {
                ...state,
                genderRegister: action.gender,
            };
        default:
            return state;
    }
});
