import React, {useState, useEffect} from 'react';
import {
    StyleSheet,
    span,
    View,
    Image,
    ScrollView,
} from 'react-native';
import {changeRoute, openPopup, setNaviIndex} from '../../app/actions';
import {connect, useDispatch} from 'react-redux';
import {Constants} from '../../../util/Constants';
import {updateMyProfile} from "../../user";
import {updateUser} from "../actions";
import { getMyFilter } from "../../user/actions";

function SettingCharacterResult({navigation, route, openPopup}) {
    route = {
        params : {
            interest: ''
        }
    }
    const dispatch = useDispatch();
    const [err, setErr] = useState('');
    let userInfoStorage;

    let handleClick =  () => {

        let userInfo;
        userInfoStorage = JSON.parse(localStorage.getItem('user_info'));
        try{
            userInfo = userInfoStorage.userRegisterInfo;
        }catch(e){
            userInfo = {};
        }

        let avatar
        try{
            avatar = userInfoStorage.userAvatarRegisterInfo;
        }catch(e){
            avatar = {};
        }

        userInfoStorage.userAvatarRegisterInfo = JSON.stringify(userInfoStorage.userAvatarRegisterInfo);
        userInfoStorage.userBloodSettings = JSON.stringify(userInfoStorage.userBloodSettings);
        userInfoStorage.userCharacterSettings = JSON.stringify(userInfoStorage.userCharacterSettings);
        userInfoStorage.userRegisterInfo = JSON.stringify(userInfoStorage.userRegisterInfo);
        userInfoStorage.userSoundSettings = JSON.stringify(userInfoStorage.userSoundSettings);
        // userInfoStorage.userCoin = "10000";
        userInfoStorage.userCoin = JSON.stringify(userInfoStorage.userCoin);
        userInfoStorage.is_infomation = 1;

        localStorage.setItem("user_info", JSON.stringify(userInfoStorage));
        
        dispatch(
            updateUser(
                callbackSuccess,
                userInfo.age_id,
                1,
                userInfo.area_id,
                userInfo.gender_id,
                avatar.type_image,
                avatar.imageUri,
                userInfo.user_name,
                userInfoStorage.userAvatarRegisterInfo,
                userInfoStorage.userBloodSettings,
                userInfoStorage.userCharacterSettings,
                userInfoStorage.userRegisterInfo,
                userInfoStorage.userSoundSettings,
                '',
                '',
                10000,
                setErr,
            ),
        );
        
    }

    const callbackSuccess = async ()=>{
        // dispatch(updateMyProfile({is_infomation: 1}));
        localStorage.setItem("user_info", JSON.stringify(userInfoStorage));
        window.location.href = '/';
        dispatch(setNaviIndex('home'));
    }

    try{
        userInfoStorage = JSON.parse(localStorage.getItem('user_info'));
    }catch(e){
        userInfoStorage = {};
    }

    let avatarInfo;
    try{
        avatarInfo = userInfoStorage.userAvatarRegisterInfo;
        if(avatarInfo === null)
        {
            avatarInfo = {
                imageUri: Constants.AVATAR_DEFAULT
            };
        }
    }catch(e){
        avatarInfo = {
            imageUri: Constants.AVATAR_DEFAULT
        };
    }
    let userInfo;
    try{
        userInfo = userInfoStorage.userRegisterInfo;
        if(userInfo === null) {
            userInfo = {
                age: "",
                age_id: "",
                area: "",
                area_id: "",
                gender: "",
                gender_id: "",
                user_name: ""
            };
        }
    }catch(e){
        userInfo = {
            age: "",
            age_id: "",
            area: "",
            area_id: "",
            gender: "",
            gender_id: "",
            user_name: ""
        };
    }
    let userBloodSettings;
    try{
        userBloodSettings = userInfoStorage.userBloodSettings;
    }catch(e){
        userBloodSettings = {
            aggression: '',
            bloodType: "",
            degree: '',
            expression: '',
            fashion: '',
            personality: "",
            sociability: '',
        }
    }
    let userCharacterSettings;
    try{
        userCharacterSettings = userInfoStorage.userCharacterSettings.value.split(',');
    }catch(e){
        userCharacterSettings = [];
    }
    return (
        <div style={styles.container} id = { 'character-container' }>
            <View style={styles.header}>
                <span style={styles.txt}>
                    1人目のなりたい自分を
                </span>
                <span style={styles.txt}>
                    決めました。
                </span>
            </View>

            <View style={{paddingHorizontal: 20, marginTop: 10}}>
                <Image
                    style={styles.roundImage}
                    source={{uri: avatarInfo.imageUri ? avatarInfo.imageUri : Constants.AVATAR_DEFAULT}}
                />
                <span style={styles.txt2}>
                    {userInfo.user_name}
                </span>

                <View style={styles.row}>
                    <span style={styles.txt1}>
                        {userInfo.area} / {userInfo.gender} / {userInfo.age}
                    </span>
                </View>
                <span style={styles.txt1}>
                    {userBloodSettings.bloodType}型 / {userBloodSettings.personality}
                </span>
                <span style={styles.txt1}>
                    社{userBloodSettings.sociability} / 度{userBloodSettings.degree} / 感{userBloodSettings.expression} / 積{userBloodSettings.aggression} / 度{userBloodSettings.fashion}
                </span>

                {
                    userCharacterSettings.length > 0 &&
                    <View style={styles.wrap}>
                        {
                            userCharacterSettings.map((value, index) => {
                                if (index === userCharacterSettings.length-1) {
                                    return (
                                        <span style={{...styles.txt1, marginLeft: 10}}>{value}</span>
                                    )
                                } else {
                                    return (
                                        <span style={{...styles.txt1, marginLeft: 10}}>{value} / </span>
                                    )
                                }
                            })
                        }
                    </View>
                }
            </View>

            <View style={styles.warpButton}>
                <button
                    accessibilityLabel={'Tap to Join.'}
                    onClick={handleClick}
                    style={styles.ButtonSubmit}>
                    <span style={styles.spanButton}>つぎへ</span>
                </button>
            </View>
        </div>
    );
}

const styles = {
    wrap: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 10
    },
    container: {
        flex: 1,
        backgroundColor: '#A6D837',
    },
    header: {
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 70
    },
    roundImage: {
        width: 200, height: 200,borderRadius: 200/2, overflow: 'hidden',
        alignSelf: 'center'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    txt: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    txt1: {
        color: 'black',
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    txt2: {
        color: 'black',
        fontSize: 24,
        fontWeight: 'bold',
        alignSelf: 'center',
        marginBottom: 10,
        marginTop: 10
    },
    warpButton: {
        width: '100%',
        marginTop: 20,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 35,
    },
    ButtonSubmit: {
        width: '80%',
        padding: 5,
        fontWeight: 'bold',
        backgroundColor: '#FFE033',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#DBBD16',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 4,
        borderBottomColor: '#DBBD16',
        borderRadius: 50,
        height: 70,
    },
    spanButton: {
        fontSize: 18,
        color: '#36383B',
        fontWeight: 'bold',
    },
};

function _mapStateToProps(state) {
    return {};
}

let _mapDispatchToProps = {
    openPopup,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(SettingCharacterResult);
