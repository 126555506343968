import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import '../stylesArea.scss';
import {getAreaSearch, saveFilter} from '../action';
import RadioItem from './RadioItem.web';

function SearchArea({areas, filterHistory, onSelect}) {
    const dispatch = useDispatch();

    const [selectedArea, setSelectedArea] = useState(
        () => filterHistory.area || {id: '', name: 'エリア'},
    );

    useEffect(() => {
        if (areas === null) {
            dispatch(getAreaSearch());
        }
    }, []);

    const handleSaveSelectedArea = async (data) => {
        onSelect(data);
        if (data.id !== filterHistory.area?.id) {
            dispatch(
                saveFilter({
                    area_id: data.id,
                    area: data,
                }),
            );
        }
    };

    const selectedItemRadio = (data)=>{
        setSelectedArea(data);
        handleSaveSelectedArea(data)
    }

    return (
        <div id="chat-content">
            <div className={"back-no-click"}/>
            <div className="filter-theme-step1-wrap">
                {/* <div className="filter-theme-text">
                    エリアを選択してください
                </div> */}
                <div className="filter-theme-list-option">
                    {Array.isArray(areas) &&
                        areas.map((item, index) => {
                            return (
                                <RadioItem
                                    key={index}
                                    data={item}
                                    isSelected={
                                        selectedArea?.id === item.id
                                            ? true
                                            : false
                                    }
                                    // setSelected={setSelectedArea}
                                    setSelected={selectedItemRadio}
                                />
                            );
                        })}
                    {/* {(Array.isArray(areas) && (selectedArea.id !== '')) ? (
                        <div className="filter-theme-submit-button-wrap">
                            <button
                                className="filter-theme-submit-button"
                                onClick={handleSaveSelectedArea}>
                                OK
                            </button>
                        </div>
                    ) : null} */}
                </div>
            </div>
        </div>
    );
}

const _mapStateToProps = (state) => ({
    areas: state['cocoro/search'].areas,
});

export default connect(_mapStateToProps)(SearchArea);
