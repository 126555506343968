import React from 'react';

function ModalConfirmChat({data, closeModalPage}) {
    return (
        <div className="confirm-chat-price">
            <p>
                この相手方はチャットの途中で課金をお願いする
                ことがあります。ただし、いきなり課金はされること
                はなく、また、その際に断ることもできます。
                この点を理解した上でチャットを開始しますか？
            </p>
            <div className="price">
                <img src="/images/icon-take-charge.svg" />
                <span>
                    1回につき　{data.topic?.chat_price}
                    ポイント
                </span>
            </div>
            <div className="btnWrap">
                <span
                    className="btn btn-chat"
                    onClick={data?.onAgree ? data.onAgree : null}>
                    開始する
                </span>
                <span className="btn btn-call" onClick={closeModalPage}>
                    キャンセル
                </span>
            </div>
        </div>
    );
}

export default ModalConfirmChat;
