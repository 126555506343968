import React from 'react';

export default function ItemInfo({title, data, action}) {
    return (
        <div className="itemContainer">
            <span className="title">{title}</span>
            <div className="infoContainer">
                <span className="data">{data}</span>
                <span className="actionButton" onClick={action}>
                    変更する
                </span>
            </div>
        </div>
    );
}
