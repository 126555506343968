import {commonMessages} from '../../../util/Constants';
import {checkAuth, openPopup, setLoading} from '../../app';
import {networkIsConnected} from './function';

export const httpRequest = (api, loading = false, ignoreError = false) => {
    return async function (dispatch, getState) {
        if (!(await networkIsConnected())) {
            if (ignoreError === false) {
                dispatch(openPopup(commonMessages.LOST_NETWORK));
            }
            return;
        }

        if (loading) dispatch(setLoading(true));
        const res = await api;
        
        if (res?.status) {
            dispatch(checkAuth(res.status));
        }

        if (res?.error && ignoreError === false) {
            dispatch(openPopup(commonMessages.SOMETHING_WRONG));
        }
        if (loading) dispatch(setLoading(false));
        return res?.data;
    };
};
