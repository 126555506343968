export const timeSettings = [
    {name: '5分', value: 5, id: '1'},
    {name: '10分', value: 10, id: '2'},
    {name: '20分', value: 20, id: '3'},
    {name: '30分', value: 30, id: '4'},
    {name: '1時間', value: 60, id: '5'},
    {name: '2時間', value: 120, id: '6'},
    {name: '3時間', value: 180, id: '7'},
    {name: '6時間', value: 360, id: '8'},
    {name: '12時間', value: 720, id: '9'},
    {name: '24時間', value: 1440, id: '10'},
    {name: '48時間', value: 2880, id: '11'},
    {name: '72時間', value: 4320, id: '12'},
];
