import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import fontStyle from '../fontStyle';
import CheckBox from './CheckBox';
import ErrLabel from '../ErrLabel';

export default function Agree({
    checkedBox,
    onPressCheckBox,
    onPressAgreement,
    txt0,
    txt1,
    err,
}) {
    return (
        <View style={styles.container}>
            <View style={styles.agree}>
                <CheckBox
                    checked={checkedBox}
                    onPress={() => {
                        onPressCheckBox();
                    }}
                />
                <View style={styles.txt}>
                    <Text style={styles.txt0} onPress={onPressAgreement}>
                        {txt0}
                    </Text>
                    <Text style={styles.txt1}>{txt1}</Text>
                </View>
            </View>
            <ErrLabel err={err} />
        </View>
    );
}

const fontSize = 16;

const styles = StyleSheet.create({
    container: {
        width: 311,
    },
    agree: {
        flexDirection: 'row',
    },
    txt: {
        marginLeft: 12,
        flexDirection: 'row',
    },
    txt0: {
        ...fontStyle,
        color: '#38BABA',
        fontSize,
        textDecorationLine: 'underline',
    },
    txt1: {
        ...fontStyle,
        color: '#2C3444',
        fontSize,
    },
});
