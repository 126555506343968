import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import '../blockstyles.scss';
import {getBlockList, unBlock} from '../actions';

const BlockList = ({}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const perPage = 30;
    const [blocks, setBlocks] = useState([]);
    const [lastDocId, setLastDocId] = useState(null);
    const [loadedAllData, setLoadedAllData] = useState(false);

    useEffect(() => {
        reloadData();
    }, []);

    const reloadData = async () => {
        const result = await dispatch(
            getBlockList({
                last_doc_id: lastDocId,
                per_page: perPage,
            }),
        );

        if (Array.isArray(result?.data)) {
            setBlocks(blocks.concat(result.data));
            setLastDocId(result.last_doc_id);
        }
    };

    const handleScroll = (event) => {
        const scrollToBottom =
            event.target.scrollHeight - event.target.scrollTop <=
            event.target.clientHeight;
        if (scrollToBottom) {
            handleLoadMore();
        }
    };

    const handleLoadMore = async () => {
        if (loadedAllData || !lastDocId) {
            return;
        }
        const result = await dispatch(
            getBlockList({
                last_doc_id: lastDocId,
                per_page: perPage,
            }),
        );

        if (Array.isArray(result?.data)) {
            if (result.data.length === 0) {
                setLoadedAllData(true);
            }
            setBlocks(blocks.concat(result.data));
            setLastDocId(result.last_doc_id);
        }
    };

    const handleUnblock = async (id) => {
        const result = await dispatch(unBlock(id));
        if (result === true) {
            const _blocks = [...blocks];
            const index = blocks.findIndex((b) => b.user.id === id);
            if (index >= 0) {
                _blocks.splice(index, 1);
                setBlocks(_blocks);
            }
        }
    };

    return (
        <div id="blocklist">
            <header>
                <div className="back">
                    <img
                        alt=""
                        onClick={() => history.goBack()}
                        src="/images/icon-back-grey.png"
                    />
                </div>
                <h3>ブロックリスト</h3>
                <div className="right" />
            </header>
            <div className="mainContainer">
                <div
                    id="block-content"
                    onScroll={(event) => handleScroll(event)}>
                    {blocks.map((item, index) => {
                        return (
                            <div key={`block-item-${index}`} className="item">
                                <img
                                    className="blockAvatar"
                                    src={item.user.profile_image}
                                    alt=""
                                />
                                <span>{item.user.user_name}</span>
                                <img
                                    className="iconBlock"
                                    src="/images/ic_delete_friend.svg"
                                    onClick={() => handleUnblock(item.user.id)}
                                />
                            </div>
                        );
                    })}
                    {/* {isLoadMore && (
                        <div id="block-load-more">
                            <div className="block-load-more" />
                        </div>
                    )} */}
                </div>
            </div>
        </div>
    );
};

export default BlockList;
