import React, {useState, useEffect} from 'react';
import {StyleSheet, SafeAreaView, View} from 'react-native';
import Btn from './Btn';
import Input from './input/Input';
import Agree from './Agree/Agree';
import supportApi from '../../../util/supportApi';
import signUp from '../signUpAction';
import Loader from '../../../util/Loader.web';
import AgreementView from './AgreementView';
import CodeConfirm from './Code/CodeConfirm';
import Header from './Header';
import phoneBorderFaker from '../../../util/style/phoneBorderFaker';
import {useAlert} from 'react-alert';

const agreeErr = '利用規約を必ずクリックして、ご確認ください。';

export default function SignUp({history}) {
    const [isLoading, setIsLoading] = useState(false);

    const [err0, setErr0] = useState('');
    const [err1, setErr1] = useState('');
    const [err2, setErr2] = useState('');
    const [err3, setErr3] = useState('');

    const [mail, setMail] = useState('');
    const [mailConfirm, setMailConfirm] = useState('');
    const [pass, setPass] = useState('');
    const [passConfirm, setPassConfirm] = useState('');

    const [isAgreeRead, setIsAgreeRead] = useState(false);
    const [isAgreeReading, setIsAgreeReading] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);

    const [checkboxClicked, setCheckboxClicked] = useState(false);
    // test
    const [checkedBox, setCheckedBox] = useState(false);

    const alert = useAlert();

    useEffect(() => {
        localStorage.removeItem('userRegisterInfo');
        localStorage.removeItem('userAvatarRegisterInfo');
    }, []);

    function showAlert(txt) {
        alert.show(txt, {
            closeCopy: 'OK',
        });
    }

    const callbackWhenSuccess = () => {
        setIsConfirm(true);
    };

    function callApi() {
        signUp(
            mail,
            mailConfirm,
            pass,
            passConfirm,
            callbackWhenSuccess,
            setIsLoading,
            setErr0,
            setErr1,
            setErr2,
            setErr3,
        );
    }

    return isAgreeReading ? (
        <AgreementView setIsAgreeReading={setIsAgreeReading} />
    ) : isConfirm ? (
        <CodeConfirm email={mail} password={pass} setIsConfirm={setIsConfirm} />
    ) : (
        <View style={styles.container}>
            <div style={phoneBorderFaker}>
                <SafeAreaView>
                    <Header
                        txt="アカウント作成"
                        onPressBack={() => history.push('/login')}
                    />
                    <div style={mainStyle}>
                        <View style={styles.inputs}>
                            <Input
                                txt="メールアドレス"
                                setValue={setMail}
                                err={err0}
                                setErr={setErr0}
                                initVal={mail}
                            />
                            <Input
                                txt="メールアドレス（確認用）"
                                valueConfirm={mail}
                                setValue={setMailConfirm}
                                err={err1}
                                setErr={setErr1}
                                initVal={mailConfirm}
                            />
                            <Input
                                txt="パスワード"
                                secureTextEntry={true}
                                setValue={setPass}
                                err={err2}
                                setErr={setErr2}
                                initVal={pass}
                            />
                            <Input
                                txt="パスワード（確認用）"
                                secureTextEntry={true}
                                valueConfirm={pass}
                                setValue={setPassConfirm}
                                err={err3}
                                setErr={setErr3}
                                initVal={passConfirm}
                            />
                        </View>
                        <View style={styles.agree}>
                            <Agree
                                txt0="利用規約"
                                txt1=" に同意する"
                                onPressAgreement={() => {
                                    setIsAgreeRead(true);
                                    setIsAgreeReading(true);
                                }}
                                onPressCheckBox={() => {
                                    setCheckboxClicked(true);
                                    if (isAgreeRead) {
                                        setCheckedBox(!checkedBox);
                                    }
                                }}
                                err={
                                    !isAgreeRead && checkboxClicked
                                        ? agreeErr
                                        : ''
                                }
                                checkedBox={checkedBox}
                            />
                        </View>
                        <View
                            style={
                                !checkedBox ? styles.btnDisable : styles.btn
                            }>
                            <Btn
                                txt="アカウントを作成"
                                onPress={() => {
                                    supportApi(callApi, showAlert);
                                }}
                                isDisabled={!checkedBox}
                            />
                        </View>
                    </div>
                </SafeAreaView>
                {isLoading && <Loader />}
            </div>
        </View>
    );
}

const width = 375;

const mainStyle = {
    paddingLeft: 27.5,
    paddingRight: 27.5,
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        flexDirection: 'column',
    },
    inputs: {
        marginTop: '1%',
    },
    agree: {
        marginTop: width * 0.1,
    },
    btn: {
        marginTop: width * 0.1,
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer',
    },
    btnDisable: {
        marginTop: width * 0.1,
        alignItems: 'center',
        width: '100%',
    },
});
