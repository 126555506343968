import React, {useState, useEffect} from 'react';
import {getMyProfile, changeName} from '../actions';
import {connect} from 'react-redux';
import {changeRoute, openPopup} from '../../app/actions';
import {validateUsername} from '../functions';
import {useHistory} from 'react-router-dom';
import '../styles.scss';

function ChangeUsername({
    loading,
    profile,
    getMyProfile,
    changeName,
    changeRoute,
    lastRoute,
    openPopup,
}) {
    let history = useHistory();
    let [username, setUsername] = useState(-1);
    let [usernameErr, setUsernameErr] = useState('');

    let onBack = () => {
        history.goBack();
    };

    useEffect(() => {
        getMyProfile();
        if (profile.user_name) {
            setUsername(profile.user_name);
        }
    }, [getMyProfile]);

    let clear = () => {
        setUsername('');
    };

    let changeUsername = () => {
        setUsernameErr('');
        let valUsername = validateUsername(username, setUsernameErr);
        if (valUsername) {
            changeName(username, setUsernameErr, onBack);
        }
    };
    return (
        <div id="changeProfile">
            <header>
                <div className="back">
                    <img
                        alt=""
                        onClick={() => history.goBack()}
                        src="/images/icon-back-grey.png"
                    />
                </div>
                <h3>プロフィール</h3>
                <div className="right"></div>
            </header>
            <div className="mainContainer">
                <div className="avatarContainer">
                    <div className="imageContainer">
                        <img src={profile.profile_image} alt="" />
                    </div>
                    <span className="changeAvatar"></span>
                </div>
                <div className="itemContainer">
                    <span className="title">ユーザーネーム</span>
                    <div className="inputContainer">
                        <input
                            type="text"
                            value={
                                username == -1 ? profile.user_name : username
                            }
                            onChange={(event) =>
                                setUsername(event.target.value)
                            }
                        />
                        <img src="/images/ic_clear.svg" onClick={clear} />
                    </div>
                </div>
                {usernameErr !== '' && (
                    <span className="error">{usernameErr}</span>
                )}
                <div className="buttonContainer">
                    <div className="button" onClick={changeUsername}>
                        <span>OK</span>
                    </div>
                </div>
                <span className="note">
                    ユーザーネームを変更すると、ともだち全員が
                    <br />
                    リセットされて消えてしまいます。 <br />
                    COCOROの世界での人間関係をリセットし <br />
                    たいとき以外の変更にはご注意ください。
                </span>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        profile: state['cocoro/profile'].userProfile,
        lastRoute: state['cocoro/app'].lastRoute,
        loading: state['cocoro/app'].loading,
    };
}

let _mapDispatchToProps = {
    changeRoute,
    getMyProfile,
    changeName,
    openPopup,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ChangeUsername);
