import ReducerRegistry from '../base/redux/ReducerRegistry';
import {
    GET_MY_SETTINGS,
    GET_BLOCK_LIST,
    LOAD_MORE_BLOCK_LIST,
    UPDATE_CALL_RINGTONE_SETTING,
} from './actionTypes';
let stateDefault = {
    timeSetting: {},
    callRingtone: true,
    messageRingtone: true,
    hasNextPage: false,
};

ReducerRegistry.register('cocoro/settings', (state = stateDefault, action) => {
    switch (action.type) {
        case GET_MY_SETTINGS:
            return {
                ...state,
                timeSetting: action.timeSetting,
                callRingtone: action.callRingtone,
                messageRingtone: action.messageRingtone,
            };
        case UPDATE_CALL_RINGTONE_SETTING:
            return {
                ...state,
                callRingtone: action.enable,
            };
        case GET_BLOCK_LIST:
            return {
                ...state,
                blocks: [...action.blocks],
                hasNextPage: action.hasNextPage,
            };
        case LOAD_MORE_BLOCK_LIST:
            return {
                ...state,
                blocks: [...state.blocks, ...action.blocks],
                hasNextPage: action.hasNextPage,
            };
        default:
            return state;
    }
});
