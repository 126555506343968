import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import { getSubCategorySplash, getCategoriesSplash, getAreasSplash } from "../../user/actions";
import '../styles.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Login = ({loggedIn, userInfo,getSubCategorySplash, getCategoriesSplash, getAreasSplash, areas, categories, subcategories}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ isFirst, setIsFirst  ] = useState(true);

    useEffect(() => {
        const getData = async ()=>{
            if (subcategories === null ) {
                await getSubCategorySplash();
            }
            if (areas === null) {
                await getAreasSplash();
            }
            
            if (categories === null) {
                await getCategoriesSplash();
            }
        }
        if(isFirst){
            getData();
            // setIsFirst(false);
        }
        history.push('/login');
    });

    return (
        <div id="splash">
            <header>
                <div>
                    <span>こころの声を届ける 本音のSNS</span>
                </div>
                <img src="/images/img_logo.png"/>
            </header>
            <main>
                <div id="splash-login">
                    <Link to={{ pathname: '/login' }}>
                        <h1 style={{fontSize: 20}}>開始する</h1>
                    </Link>
                </div>
                <div id="splash-tutorial">
                    <Link to={{ pathname: '/tutorial' }}>
                        <h3>チュートリアル</h3>
                    </Link>
                </div>
            </main>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    loggedIn: state['cocoro/user'].loggedIn,
    userInfo: state['cocoro/user'].userInfo,
    areas: state['cocoro/user'].areas,
    subcategories: state['cocoro/user'].subcategories,
    categories: state['cocoro/user'].categories,
});
const _mapDispatchToProps = {
    getSubCategorySplash, 
    getCategoriesSplash, 
    getAreasSplash
}

export default connect(_mapStateToProps, _mapDispatchToProps)(Login);
