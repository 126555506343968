import ReducerRegistry from '../../base/redux/ReducerRegistry';
import {
    GET_HISTORY_SUCCESS,
    GET_HISTORY_FAIL,
    GET_HISTORY,
    CLEAR_DATA_HISTORY,
    GET_REPLY_LIST_SUCCESS,
    CLEAR_REPLY_LIST,
    GET_REPLY_LIST_FAIL,
    LOAD_MORE_REPLY_LIST_SUCCESS,
    GET_TOPIC_SUCCESS,
    UPDATE_TOPIC_IS_CALLABLE_SUCCESS,
    IS_LOADING,
    CONFIRM_ERROR,
    UPDATE_REPLY,
    ADD_REPLY,
    IS_BLOCK_USER,
    SET_HISTORY,
    DELETE_HISTORY_ITEM,
    DELETE_REPLY_ITEM,
} from './actionType';
import {Constants} from '../../../util/Constants';

let stateDefault = {
    histories: [],
    lastDocHistory: null,
    isAbleToLoadmore: true,
    error: null,
    currentFilterType: Constants.HISTORY_FILTER_ALL,
    replyList: [],
    replyLastDocId: '',
    replyFilter: Constants.REPLY_LIST_TYPE_ALL,
    topic: {},
    isLoading: false,
    isBlockUser: false,
    replyTopic: 565656,
};
ReducerRegistry.register(
    Constants.STATE_HISTORY,
    (state = stateDefault, action) => {
        switch (action.type) {
            case 'SET_UNREAD_HISTORY':
                return {
                    ...state,
                    histories: state.histories.map((h) =>
                        h.room_id == action.roomId
                            ? {...h, totalUnRead: action.totalUnRead}
                            : h,
                    ),
                };
            case 'PLUS_UNREAD_HISTORY':
                return {
                    ...state,
                    histories: state.histories.map((h) =>
                        h.room_id == action.roomId
                            ? {...h, totalUnRead: h.totalUnRead + 1}
                            : h,
                    ),
                };
            case SET_HISTORY:
                return {
                    ...state,
                    histories: action.histories,
                    lastDocHistory: action.lastDocHistory
                        ? action.lastDocHistory
                        : state.lastDocHistory,
                };
            case GET_HISTORY:
                return {
                    ...state,
                    lastDocHistory: null,
                };
            case GET_HISTORY_SUCCESS:
                return {
                    ...state,
                    histories: action.histories,
                    lastDocHistory: action.lastDocHistory,
                    error: null,
                    currentFilterType: action.filterType,
                };
            case GET_HISTORY_FAIL:
                return {
                    ...state,
                    error: action.error,
                };
            case CLEAR_DATA_HISTORY:
                return {
                    ...stateDefault,
                };
            case CLEAR_REPLY_LIST:
                return {
                    ...state,
                    replyList: [],
                    replyLastDocId: '',
                    isAbleToLoadmoreReplyList: true,
                    error: null,
                };
            case GET_REPLY_LIST_SUCCESS:
                return {
                    ...state,
                    replyList: action.replyList,
                    replyLastDocId: action.replyLastDocId,
                    replyFilter: action.replyFilter,
                    error: null,
                };
            case GET_REPLY_LIST_FAIL:
                return {
                    ...state,
                    error: action.error,
                };
            case LOAD_MORE_REPLY_LIST_SUCCESS:
                return {
                    ...state,
                    replyList: state.replyList.concat(action.replyList),
                    replyLastDocId: action.replyLastDocId,
                    error: null,
                };
            case GET_TOPIC_SUCCESS:
                return {
                    ...state,
                    topic: action.topic,
                };
            case UPDATE_TOPIC_IS_CALLABLE_SUCCESS:
                return {
                    ...state,
                    topic: {
                        ...state.topic,
                        is_callable: action.is_callable,
                    },
                };
            case UPDATE_REPLY:
                return {
                    ...state,
                    replyList: state.replyList.map((item) =>
                        item.id === action.reply.id ? action.reply : item,
                    ),
                };
            case ADD_REPLY:
                return {
                    ...state,
                    replyList: [action.reply, ...state.replyList],
                };
            case IS_LOADING:
                return {
                    ...state,
                    isLoading: action.isLoading,
                };
            case CONFIRM_ERROR:
                return {
                    ...state,
                    error: null,
                };
            case IS_BLOCK_USER:
                return {
                    ...state,
                    isBlockUser: action.isBlock,
                };
            case DELETE_HISTORY_ITEM:
                return {
                    ...state,
                    histories: state.histories.filter(
                        (h) => h.id !== action.historyId,
                    ),
                };
            case DELETE_REPLY_ITEM:
                return {
                    ...state,
                    replyList: state.replyList.filter(
                        (r) => r.id !== action.replyId,
                    ),
                };
            default:
                return state;
        }
    },
);
