/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useMemo, useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import '../styles.scss';
import {
    clearRoomInfo,
    initChatData,
    resendMessageSentFail,
    sendChat,
    sendPresent,
    subscribeChatConnection,
    syncUnreadMessageNumber,
    uploadFile,
} from '../actions';
import { updateUserCoin } from "../../user/actions";
import {confirmAlert} from 'react-confirm-alert';
import {useHistory, useLocation} from 'react-router-dom';
import DetailUserChat from './DetailUserChat';
import Resizer from 'react-image-file-resizer';
import Price from './Price.web';
import Emoji from './Emoji.web';
import Action from './Action.web';
import Header from './HeaderPresent.web';
import ChatContentMe from './ChatContentMe.web';
import ChatContentNotMe from './ChatContentNotMe.web';
import {Modal, openActionPopup, openPopup, clearUnreadCount} from '../../app';
import {getDay, getMonthDay, sortMessages} from '../functions';
import ImageViewer from './ImageViewer.web';
import {
    CHAT_IMG_MAX_WIDTH,
    CHAT_IMG_MAX_HEIGHT,
    CHAT_IMG_QUANLITY,
    CHAT_TYPE_SYSTEM_CALL_MISS,
    messageTypeIds,
    messageStatusTexts,
} from '../constants';
import $ from 'jquery';
import chatdb from '../chatdb.web';
import { useTopicExpired } from '../../homescreen/hooks';

function Present({roomInfo, userInfo, messages, roomDetail}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {pathname} = useLocation();
    const roomId = pathname.replace('/present/', '');
    const [userid, setUserID] = useState('');
    const [username, setUserName] = useState('');
    const [emoji, setEmoji] = useState(false);
    const [isShowDetail, setShowDetail] = useState(false);
    const [uriImg, setUriImg] = useState('');
    const [isShowRequestMoneyButton, setShowRequestMoneyButton] = useState(
        false,
    );
    const day = useRef('');

    useEffect(() => {
        dispatch(clearUnreadCount(roomInfo.id));
        chatdb.updateAllMessageRoomIsRead(roomInfo.id);
    }, [messages]);

    useEffect(() => {
        let unsub = null;
        const userInfo_id = roomDetail?.incoming_id === undefined ? userInfo?.id : roomDetail?.incoming_id;
        setUserID(userInfo_id);

        let userInfo_name = roomDetail?.incoming_name === undefined ? userInfo?.user_name : roomDetail?.incoming_name;
        setUserName(userInfo_name);

        const initialize = async () => {
            const result = await dispatch(initChatData(roomId, userInfo_id));
            if (result.errors) {
                dispatch(
                    openActionPopup(result.errors, () => {
                        history.goBack();
                    }),
                );
            }
            // result = roomInfo
            unsub = dispatch(
                subscribeChatConnection(
                    result?.id,
                    userInfo.id,
                    result?.userInfo?.id,
                ),
            );
        };

        if (roomId && userInfo.id) {
            initialize();
            return () => (unsub ? unsub() : null);
        }
    }, []);

    useEffect(() => {
        if (roomInfo.deleted === true) {
            dispatch(
                openActionPopup(
                    '該当する声がありません。既に削除されている可能性があります。',
                    () => {
                        history.goBack();
                    },
                ),
            );
        }
    }, [roomInfo]);

    useEffect(() => {
        return () => dispatch(clearRoomInfo());
    }, []);

    const firstRun = useRef(false);
    useEffect(() => {
        if (messages.length > 0 && firstRun.current === false) {
            firstRun.current = true;
            resendMessageFail();
        }
    }, [messages]);

    useEffect(() => {
        if (
            userInfo?.id === roomInfo.topic?.user_id &&
            roomInfo.topic?.chat_price > 0
        ) {
            setShowRequestMoneyButton(true);
        }
    }, [roomInfo, userInfo]);

    const scroll = () => {
        if ($('#chat-content').length != 0) {
            $('#chat-content').animate(
                {scrollTop: $('#chat-content')[0].scrollHeight},
                1,
            );
        }
    };

    const resendMessageFail = () => {
        const msgs = messages.filter((m) => {
            return (
                m.user_id === userInfo.id &&
                m.status === messageStatusTexts.SENT_FAIL
            );
        });
        if (msgs.length > 0) {
            dispatch(resendMessageSentFail(msgs));
        }
    };

    const handleSendPresent = (roomId) => {
        sendPresent( Math.abs(userInfo?.userCoin - showCoin), roomInfo?.userInfo?.id)
            .then((result) => {
                if(result){
                    dispatch(updateUserCoin(showCoin));
                    handleSendEmoji(presentImage);
                    history.push('/chat/'+roomId, { from: 'present' });
                }
                else
                    dispatch(
                        openActionPopup(
                            '想定しないエラーが発生しました。。',
                            () => {
                                history.goBack();
                            },
                        ),
                    );
            })
            .catch((err)=>{
                dispatch(
                    openActionPopup(
                        '想定しないエラーが発生しました。。',
                        () => {
                            history.goBack();
                        },
                    ),
                );
            });
    }

    const handleSendMessage = (msg) => {
        let text = msg.trim();
        if (text === '') {
            return;
        }
        dispatch(sendChat(userid, "0", roomId, messageTypeIds.TEXT, text));
        resendMessageFail();
    };

    const handleSendEmoji = (url) => {
        console.log("==============url===================",url)
        dispatch(sendChat(userid, "0", roomId, messageTypeIds.EMOJI, url));
        setEmoji(false);
    };

    const handleSendFile = (e) => {
        const filePer = ['image/png', 'image/jpg', 'image/jpeg'];
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        if (filePer.findIndex((f) => f === file.type) === -1) {
            confirmAlert({
                customUI: ({onClose}) => {
                    return (
                        <div className="custom-ui custom-ui-chat">
                            <h1>
                                JPG・JPEG・PNGの画像ファイルをアップロードしてください。
                            </h1>
                            <button
                                onClick={() => {
                                    onClose();
                                }}>
                                OK
                            </button>
                        </div>
                    );
                },
            });
        } else {
            if (file) {
                if (navigator.onLine) {
                    if (file.type == '') {
                        sendFile(file, messageTypeIds.IMAGE);
                    } else {
                        Resizer.imageFileResizer(
                            file,
                            CHAT_IMG_MAX_WIDTH,
                            CHAT_IMG_MAX_HEIGHT,
                            'JPEG',
                            CHAT_IMG_QUANLITY,
                            0,
                            (uri) => {
                                sendFile(uri, messageTypeIds.IMAGE_BASE64);
                            },
                            'base64',
                        );
                    }
                } else {
                    dispatch(
                        openPopup(
                            '画像を送信するエラーがありました、再度送信してください。',
                        ),
                    );
                }
            }
        }
    };
    
    const sendFile = async (file, type) => {
        const signedUrl = await dispatch(uploadFile(roomId, type, file));
        let data = file;
        let size = {
            width: 0,
            height: 0,
        };
        if (signedUrl) {
            data = signedUrl.data.url;
            size = signedUrl.data.size;
        }
        dispatch(sendChat(userid, "0", roomId, type, data, size));
    };

    useEffect(() => {
        scroll();
    }, [messages]);

    const messageSortedList = useMemo(() => {
        return sortMessages(messages);
    }, [messages]);

    useTopicExpired(roomInfo.topic, null, history);

    const [ presentImage, setPresentImage ] = useState(false);
    const [ showCoin, setShowCoin ] = useState(userInfo?.userCoin);

    return (
        <div id="present">
            <Header history={history} setPresentImage = { setPresentImage } presentImage = {presentImage} user_name = {username} />
            <div
                id="chat-content">
                <div className={"currency"}>
                    <img src="/images/currency_point.png"/>
                    <p>{ userInfo?.userCoin }P</p>
                </div>
                { presentImage === false ?
                    <div className={"present-container"}>
                        <div className="item" onClick={()=>{
                            if ((userInfo?.userCoin - 100) > 0 ) {
                                setShowCoin(userInfo?.userCoin - 100);
                                setPresentImage("/images/present/25.png");
                            }
                            else{
                                dispatch(
                                    openPopup(
                                        '所有ポイント数が不足しています。',
                                    ),
                                );
                            }
                        }}>
                            <img src={"/images/present/25.png"}/>
                            <p>100P</p>
                        </div>
                        <div className="item" onClick={()=>{
                            if ((userInfo?.userCoin - 300) > 0 ) {
                                setShowCoin(userInfo?.userCoin - 300);
                                setPresentImage("/images/present/26.png");
                            }
                            else{
                                dispatch(
                                    openPopup(
                                        '所有ポイント数が不足しています。',
                                    ),
                                );
                            }
                        }}>
                            <img src={"/images/present/26.png"}/>
                            <p>300P</p>
                        </div>
                        <div className="item" onClick={()=>{
                            if ((userInfo?.userCoin - 500) > 0 ) {
                                setShowCoin(userInfo?.userCoin - 500);
                                setPresentImage("/images/present/27.png");
                            }
                            else{
                                dispatch(
                                    openPopup(
                                        '所有ポイント数が不足しています。',
                                    ),
                                );
                            }
                        }}>
                            <img src={"/images/present/27.png"}/>
                            <p>500P</p>
                        </div>
                        <div className="item" onClick={()=>{
                            if ((userInfo?.userCoin - 1000) > 0 ){
                                setShowCoin(userInfo?.userCoin - 1000);
                                setPresentImage("/images/present/28.png");
                            }
                            else{
                                dispatch(
                                    openPopup(
                                        '所有ポイント数が不足しています。',
                                    ),
                                );
                            }
                        }}>
                            <img src={"/images/present/28.png"}/>
                            <p>1000P</p>
                        </div>
                    </div>
                    : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                        <img src={"/images/bottom_arrow.png"} style={{
                            width: 20, height: 30
                        }}/>
                        <p style={{color: '#00c14d', fontSize: 23}}>{showCoin}P</p>
                        <img src={presentImage} style={{
                            width: 100, height: 100, position: 'absolute', top: 250, left: 130
                        }}/>
                        <p style={{position:'absolute', left: 130, top: 370, fontSize: 21, textAlign: 'center'}}>
                            相手の名前<br/>
                               {roomInfo.userInfo?.user_name}
                        </p>
                        <button className={"award-present"} onClick={()=>{
                            handleSendPresent(roomId)
                            // setPresentImage(false);
                        }} ><span>プレゼントする</span></button>
                    </div>
                 }
            </div>
        </div>
    );
}

const _mapStateToProps = (state) => ({
    messages: state['cocoro/chat'].messages,
    roomInfo: state['cocoro/chat'].roomInfo,
    userInfo: state['cocoro/user'].userInfo,
    roomDetail: state['cocoro/call'].roomDetail,
});

export default connect(_mapStateToProps)(Present);
