import React from 'react';

function Loading({}) {
    return (
        <div id="page-loading">
            <div className="page-loading" />
        </div>
    );
}

export default Loading;
