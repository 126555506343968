/* eslint-disable react/react-in-jsx-scope */
import React, {useState, useEffect, useRef} from 'react';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';

const HistoryListHeader = ({onFilterChange, onFilterChange1}) => {
    const [myTopic, setMyTopic] = useState(false);
    const [otherTopic, setOtherTopic] = useState(false);
    const firstUpdate = useRef(true);


    return (
        <View style={styles.container}>
            <Text style={styles.all}>履歴</Text>
            <TouchableOpacity
                onPress={onFilterChange}
                style={myTopic ? styles.active : styles.unactive}>
                <Text style={styles.text}>自分の投稿</Text>
            </TouchableOpacity>
            <TouchableOpacity
                onPress={onFilterChange1}
                style={otherTopic ? styles.active : styles.unactive}>
                <Text style={styles.text}>全部</Text>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        height: 54,
        backgroundColor: '#ffffff',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    all: {
        width: 100,
        textAlign: 'left',
        fontSize: 17,
        paddingLeft: 10,
    },
    active: {
        width: 110,
        height: 30,
        justifyContent: 'center',
        backgroundColor: '#DFE9EB',
        borderColor: '#ABB3BA',
        borderWidth: 1,
        borderRadius: 10,
        marginHorizontal: 10,
        fontSize: 17,
    },
    unactive: {
        width: 110,
        height: 30,
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        borderColor: '#A6D837',
        borderWidth: 1,
        borderRadius: 10,
        marginHorizontal: 10,
        fontSize: 17,
    },
    text: {
        textAlign: 'center',
        fontSize: 17,
    },
});

export default HistoryListHeader;
