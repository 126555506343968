import React, {useState} from 'react';

export default function SelectionModal({
    title,
    data,
    currentData,
    setData,
    addData,
    onClose,
}) {
    const [selectedData, setSelected] = useState(
        currentData !== undefined && currentData !== null ? currentData : '',
    );
    return (
        <div id="modalBorder">
            <span className="modalTitle">{title}</span>
            <div className="dataContainer" style={
                title === '3キャラクター' ? {minHeight: 250} : {}
            }>
                {data &&
                    data.map((item, index) => {
                        return (
                            <div
                                className="itemContainer"
                                onClick={() => setSelected(item.id)}>
                                <span
                                    className={
                                        item.id === selectedData
                                            ? 'selectedItemName'
                                            : 'unselectedItemName'
                                    }>
                                    {item.name}
                                </span>
                                {item.id === selectedData && (
                                    <img
                                        src="/images/icon-selected.svg"
                                        className="checkedIcon"
                                    />
                                )}
                                <div className="divider" />
                            </div>
                        );
                    })}
                {
                    !data && title === '3キャラクター' ? <h3 style={{marginTop: 100}}>キャラクターがありません。</h3>: null
                }
            </div>
            {
                title === '3キャラクター' ?
                <div className="buttonContainer">
                    <div
                        className="button"
                        style={{width: 100}}
                        onClick={() => {
                            onClose();
                            try{
                                setData(data?.find((item) => item.id === selectedData));
                            }catch(e){
                            }
                        }}>
                        <span>切換</span>
                    </div>
                    {
                    data?.find((item) => item.id === currentData).email_id == data?.find((item) => item.id === currentData).email ?
                    data?.length <3 ?
                        <div
                            className="button"
                            style={{width: 100, marginLeft: 40}}
                            onClick={() => {
                                onClose();
                                try{
                                    addData();
                                }catch(e){
                                }
                            }}>
                            <span>追加</span>
                        </div>
                    :null
                    : null}
                </div>
                : <div className="buttonContainer">
                    <div
                        className="button"
                        onClick={() => {
                            onClose();
                            try{
                                setData(data?.find((item) => item.id === selectedData));
                            }catch(e){
                            }
                        }}>
                        <span>OK</span>
                    </div>
                </div>
            }
        </div>
    );
}
