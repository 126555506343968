import React from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import clock from '../../base/clock';

function TimeTopic({timeExpire}) {
    const [time, setTime] = useState(() => timeExpire - clock.seconds());

    useEffect(() => {
        const itv = setInterval(() => {
            setTime(timeExpire - clock.seconds());
        }, 1000);
        return () => clearInterval(itv);
    }, []);

    if (time > 0) {
        let m = Math.floor(time / 60) % 60;
        let h = Math.floor(time / 3600);
        if (time > 3600) {
            return (
                <div className="time-count-down">
                    <img src="/images/icon-clock-count-down.svg" />
                    <span>{`残り ${h}時間${m}分`}</span>
                </div>
            );
        } else {
            if (time > 60) {
                return (
                    <div className="time-count-down-alarm">
                        <img src="/images/icon-clock-warning.svg" />
                        <span>{`残り ${h}時間${m}分`}</span>
                    </div>
                );
            } else if (time == 60) {
                return (
                    <div className="time-count-down-alarm">
                        <img src="/images/icon-clock-warning.svg" />
                        <span>{`残り 0時間0分`}</span>
                    </div>
                );
            } else {
                let s = Math.floor(time % 3600) % 60;
                return (
                    <div className="time-count-down-alarm">
                        <img src="/images/icon-clock-warning.svg" />
                        <span>{`残り ${s}秒`}</span>
                    </div>
                );
            }
        }
    } else {
        return (
            <div className="time-count-down-alarm">
                <img src="/images/icon-clock-warning.svg" />
                <span>{`残り 0秒`}</span>
            </div>
        );
    }
}

export default TimeTopic;
