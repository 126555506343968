import React from 'react';
import {StyleSheet, Text} from 'react-native';
import fontStyle from '../fontStyle';

export default function MailInput(props) {
    return <Text style={styles.container}>{props.txt}</Text>;
}

const styles = StyleSheet.create({
    container: {
        ...fontStyle,
        fontSize: 14,
        color: '#5F5F5F',
        textAlign: 'left',
        fontWeight: 'bold',
    },
});
