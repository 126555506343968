import React from 'react';
import {emojis} from '../emojis';

function Emoji({sendEmoji, setEmoji, isShowRequestMoneyButton}) {
    return (
        <div id="emoji-wrap" onClick={() => setEmoji(false)}>
            <img
                src="/images/icon-reactanger-chat@4x.png"
                alt=""
                className="arrow"
            />
            <div
                id="emoji"
                style={{bottom: isShowRequestMoneyButton ? '100px' : '60px'}}>
                {emojis &&
                    emojis.map((emoji, index) => {
                        return (
                            <div key={index}>
                                <img
                                    alt=""
                                    onClick={sendEmoji(emoji.url)}
                                    src={emoji.base64}
                                />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

export default Emoji;
