import {v4 as uuidv4} from 'uuid';
import {deviceTypes} from './Constants';

export const isNative = false;

export const getDeviceType = () => {
    return deviceTypes.WEB;
};

export const getUniqueDeviceId = () => {
    const uuid = uuidv4();
    return `${uuid}:browser`;
};

export const genUUIDv4 = () => {
    return uuidv4();
};
