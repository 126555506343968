import ReducerRegistry from '../base/redux/ReducerRegistry';
import {
    UPDATE_USER_NAME,
    UPDATE_TIME_SELECT,
    DISPLAY_LIST_BUBBLE_STYLE,
    UPDATE_CATEGORY_ID_NEW,
    UPDATE_CATEGORY_NEW,
} from './actionType';

const initialState = {
    userNameRegister: {id: '1', name: '匿名さん'},
    timeRegister: {name: '1時間', value: 60, id: '5'},
    isBubbleStyle: true,
    category: { id: '1', name: 'カテゴリー' },
};
ReducerRegistry.register(
    'cocoro/homescreen',
    (state = initialState, action) => {
        switch (action.type) {
            case UPDATE_USER_NAME:
                return {
                    ...state,
                    userNameRegister: action.username,
                };
            case UPDATE_TIME_SELECT:
                return {
                    ...state,
                    timeRegister: action.time,
                };
            case DISPLAY_LIST_BUBBLE_STYLE:
                return {
                    ...state,
                    isBubbleStyle: action.status,
                };
            case UPDATE_CATEGORY_NEW: 
                return {
                    ...state,
                    category: action.category,
                };
            case UPDATE_CATEGORY_ID_NEW:
                return {
                    ...state,
                    categoryId: action.categoryId
                }
            default:
                return state;
        }
    },
);
