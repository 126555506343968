import React from 'react';
import '../stylesArea.scss';
import {timeMiles} from '../function';

export default function RadioItem({
    data,
    isSelected,
    setSelected,
    topicExpireTime,
}) {
    let existTime = '';
    if (topicExpireTime) {
        existTime = timeMiles.find((item) => item.value == topicExpireTime);
        if (!existTime) {
            if (topicExpireTime < 60) {
                existTime = {name: topicExpireTime + '分'};
            }
            if (topicExpireTime >= 60) {
                existTime = {name: (topicExpireTime % 60) + '時間'};
            }
        }
    }
    return (
        <div className="filter-theme-option" onClick={() => setSelected(data)}>
            <label htmlFor={data.id}>{data.name}</label>
        </div>
    );
}
