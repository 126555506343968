import React from 'react';
import '../stylesArea.scss';
import {timeMiles} from '../function';

export default function RadioItem({
    data,
    isSelected,
    setSelected,
    topicExpireTime,
}) {
    let existTime = '';
    if (topicExpireTime) {
        existTime = timeMiles.find((item) => item.value == topicExpireTime);
        if (!existTime) {
            if (topicExpireTime < 60) {
                existTime = {name: topicExpireTime + '分'};
            }
            if (topicExpireTime >= 60) {
                existTime = {name: (topicExpireTime % 60) + '時間'};
            }
        }
    }
    return (
        <div className="filter-theme-option" onClick={() => setSelected(data)}>
            {isSelected ? (
                <input
                    type="radio"
                    id={data.id}
                    name="radio-group"
                    checked="checked"
                />
            ) : (
                <input type="radio" id={data.id} name="radio-group" />
            )}
            <label htmlFor={data.id}>{data.name}</label>
            <div className="timeContainer">
                {topicExpireTime && (
                    <div className="topicTime">
                        <img
                            className="topicClock"
                            src="/images/ic_clock_search.svg"
                            alt=""
                        />
                        <span className="topicExistTime">{existTime.name}</span>
                    </div>
                )}
            </div>
        </div>
    );
}
