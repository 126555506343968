/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useMemo, useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import '../styles.scss';
import {
    clearRoomInfo,
    initChatData,
    resendMessageSentFail,
    sendChat,
    subscribeChatConnection,
    syncUnreadMessageNumber,
    uploadFile,
} from '../actions';
import {confirmAlert} from 'react-confirm-alert';
import {useHistory, useLocation} from 'react-router-dom';
import DetailUserChat from './DetailUserChat';
import Resizer from 'react-image-file-resizer';
import Price from './Price.web';
import Emoji from './Emoji.web';
import Action from './Action.web';
import Header from './Header.web';
import ChatContentMe from './ChatContentMe.web';
import ChatContentNotMe from './ChatContentNotMe.web';
import {Modal, openActionPopup, openPopup, clearUnreadCount} from '../../app';
import {getDay, getMonthDay, sortMessages} from '../functions';
import ImageViewer from './ImageViewer.web';
import {
    CHAT_IMG_MAX_WIDTH,
    CHAT_IMG_MAX_HEIGHT,
    CHAT_IMG_QUANLITY,
    CHAT_TYPE_SYSTEM_CALL_MISS,
    messageTypeIds,
    messageStatusTexts,
} from '../constants';
import $ from 'jquery';
import chatdb from '../chatdb.web';
import { useTopicExpired } from '../../homescreen/hooks';
import { addFriend } from "../../addfriend/actions";
import {HISTORY_PAGE_USER_ID} from '../../app/constants';

function Chat({roomInfo, userInfo, messages, roomDetail, isSmall}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {pathname} = useLocation();
    const roomId = pathname.replace('/chat/', '');
    const [userid, setUserID] = useState('');
    const [username, setUserName] = useState('');
    const [emoji, setEmoji] = useState(false);
    const [isShowDetail, setShowDetail] = useState(false);
    const [uriImg, setUriImg] = useState('');
    const [isShowRequestMoneyButton, setShowRequestMoneyButton] = useState(
        false,
    );
    const day = useRef('');

    useEffect(() => {
        dispatch(clearUnreadCount(roomInfo.id));
        chatdb.updateAllMessageRoomIsRead(roomInfo.id);
    }, [messages]);

    useEffect(() => {
        let unsub = null;

        let userInfo_id = roomDetail?.incoming_id === undefined ? userInfo?.id : roomDetail?.incoming_id;
        if(isSmall === false)
            userInfo_id = userInfo?.id;

        setUserID(userInfo_id);

        let userInfo_name = roomDetail?.incoming_name === undefined ? userInfo?.user_name : roomDetail?.incoming_name;

        if(isSmall === false)
            userInfo_name = userInfo?.user_name;
        setUserName(userInfo_name);

        const initialize = async () => {
            const result = await dispatch(initChatData(roomId, userInfo_id, roomInfo));
            if (result.errors) {
                console.log("error",result);
                userInfo_id = localStorage.getItem(HISTORY_PAGE_USER_ID);
                setUserID(userInfo_id);
                const result1 = await dispatch(initChatData(roomId, userInfo_id, roomInfo));
                if(result1.errors)
                {
                    dispatch(
                        openActionPopup(result1.errors, () => {
                            history.goBack();
                        }),
                    );
                }
                else{
                    unsub = dispatch(
                        subscribeChatConnection(
                            result1?.id,
                            userInfo?.id,
                            result1?.userInfo?.id,
                        ),
                    );
                }
            }
            else{
                unsub = dispatch(
                    subscribeChatConnection(
                        result?.id,
                        userInfo?.id,
                        result?.userInfo?.id,
                    ),
                );
            }
        };

        if (roomId && userInfo?.id) {
            initialize();
            return () => (unsub ? unsub() : null);
        }
    }, []);

    useEffect(() => {
        if (roomInfo.deleted === true) {
            dispatch(
                openActionPopup(
                    '該当する声がありません。既に削除されている可能性があります。',
                    () => {
                        history.goBack();
                    },
                ),
            );
        }
    }, [roomInfo]);

    useEffect(() => {
        return () => dispatch(clearRoomInfo());
    }, []);

    const firstRun = useRef(false);
    useEffect(() => {
        if (messages.length > 0 && firstRun.current === false) {
            firstRun.current = true;
            resendMessageFail();
        }
    }, [messages]);

    useEffect(() => {
        if (
            userInfo?.id === roomInfo.topic?.user_id &&
            roomInfo.topic?.chat_price > 0
        ) {
            setShowRequestMoneyButton(true);
        }
    }, [roomInfo, userInfo]);

    const scroll = () => {
        if ($('#chat-content').length != 0) {
            $('#chat-content').animate(
                {scrollTop: $('#chat-content')[0].scrollHeight},
                1,
            );
        }
    };

    const resendMessageFail = () => {
        const msgs = messages.filter((m) => {
            return (
                m.user_id === userInfo?.id &&
                m.status === messageStatusTexts.SENT_FAIL
            );
        });
        if (msgs.length > 0) {
            dispatch(resendMessageSentFail(msgs));
        }
    };

    const handleSendMessage = (msg) => {
        let text = msg.trim();
        if (text === '') {
            return;
        }
        // dispatch(addFriend('J7PUEIxke6epKr0CCWvA'));
        dispatch(sendChat(userid, username, roomId, messageTypeIds.TEXT, text));
        resendMessageFail();
    };

    const handleSendEmoji = (url) => () => {
        dispatch(sendChat(userid, username, roomId, messageTypeIds.EMOJI, url));
        setEmoji(false);
    };

    const handleSendFile = (e) => {
        const filePer = ['image/png', 'image/jpg', 'image/jpeg'];
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        if (filePer.findIndex((f) => f === file.type) === -1) {
            confirmAlert({
                customUI: ({onClose}) => {
                    return (
                        <div className="custom-ui custom-ui-chat">
                            <h1>
                                JPG・JPEG・PNGの画像ファイルをアップロードしてください。
                            </h1>
                            <button
                                onClick={() => {
                                    onClose();
                                }}>
                                OK
                            </button>
                        </div>
                    );
                },
            });
        } else {
            if (file) {
                if (navigator.onLine) {
                    if (file.type == '') {
                        sendFile(file, messageTypeIds.IMAGE);
                    } else {
                        Resizer.imageFileResizer(
                            file,
                            CHAT_IMG_MAX_WIDTH,
                            CHAT_IMG_MAX_HEIGHT,
                            'JPEG',
                            CHAT_IMG_QUANLITY,
                            0,
                            (uri) => {
                                sendFile(uri, messageTypeIds.IMAGE_BASE64);
                            },
                            'base64',
                        );
                    }
                } else {
                    dispatch(
                        openPopup(
                            '画像を送信するエラーがありました、再度送信してください。',
                        ),
                    );
                }
            }
        }
    };

    const sendFile = async (file, type) => {
        const signedUrl = await dispatch(uploadFile(roomId, type, file));
        let data = file;
        let size = {
            width: 0,
            height: 0,
        };
        if (signedUrl) {
            data = signedUrl.data.url;
            size = signedUrl.data.size;
        }
        dispatch(sendChat(userid, username, roomId, type, data, size));
    };

    useEffect(() => {
        scroll();
    }, [messages]);

    const messageSortedList = useMemo(() => {
        return sortMessages(messages);
    }, [messages]);

    useTopicExpired(roomInfo.topic, null, history);

    return (
        <div id="chat">
            {isShowDetail && (
                <Modal
                    handleClose={() => setShowDetail(false)}
                    Component={DetailUserChat}
                />
            )}
            <Header history={history} user_name={username}/>
            <div
                id="chat-content"
                style={{height: isShowRequestMoneyButton ? '525px' : '565px'}}>
                {messageSortedList.map((message, index) => {
                    
                    const dayOfMonth = getDay(message.created_at);
                    let nextDayOfMonth = null;
                    let checkDifferentDay = false;
                    if (index === 0) {
                        checkDifferentDay = true;
                    } else {
                        if (index > 0) {
                            nextDayOfMonth = getDay(
                                messageSortedList[index - 1].created_at,
                            );
                        }
                        if (dayOfMonth !== nextDayOfMonth) {
                            checkDifferentDay = true;
                        }
                    }
                    return (
                        
                        <div key={message.id}>
                            {checkDifferentDay && (
                                <p className="dayOfChat">
                                    <span>
                                        {getMonthDay(message.created_at)}
                                    </span>
                                </p>
                            )}                            
                            {
                            message.user_id === userid ? (
                                message.message_type_id !=
                                    CHAT_TYPE_SYSTEM_CALL_MISS && (
                                    <ChatContentMe
                                        setUriImg={setUriImg}
                                        message={message}
                                        scroll={scroll}
                                    />
                                )
                            ) : 
                            
                            message.send_user_mail !== userInfo?.email_id ?(
                                <ChatContentNotMe
                                    roomId={roomInfo.id}
                                    setUriImg={setUriImg}
                                    message={message}
                                    scroll={scroll}
                                />
                            ): null}
                        </div>
                    );
                })}
            </div>
            <Action
                setEmoji={setEmoji}
                send={handleSendMessage}
                onChangeFile={handleSendFile}
            />
            {isShowRequestMoneyButton && (
                <Price roomInfo={roomInfo} userInfo={userInfo} />
            )}
            {emoji && (
                <Emoji
                    sendEmoji={handleSendEmoji}
                    setEmoji={setEmoji}
                    isShowRequestMoneyButton={isShowRequestMoneyButton}
                />
            )}
            {uriImg != '' && (
                <ImageViewer
                    img_uri={uriImg}
                    handleClose={() => setUriImg('')}
                />
            )}
        </div>
    );
}

const _mapStateToProps = (state) => ({
    messages: state['cocoro/chat'].messages,
    roomInfo: state['cocoro/chat'].roomInfo,
    userInfo: state['cocoro/user'].userInfo,
    roomDetail: state['cocoro/call'].roomDetail,
    isSmall: state['cocoro/call'].isSmall,
});

export default connect(_mapStateToProps)(Chat);
