import {useState, useEffect} from 'react';
import clock from '../base/clock';
import {db} from '../base/firebase/firebase';
import {debounce} from '../../util/functions';
import {useRef} from 'react';
import {useDispatch, useStore} from 'react-redux';
import {Constants} from '../../util/Constants';
import {closeChatScreen, openActionPopup} from '../app';

export const useWatchExpire = (list) => {
    const [expiredTopicId, setExpiredTopicId] = useState(null);

    useEffect(() => {
        const inv = setInterval(() => {
            list.forEach((item) => {
                const remainSeconds = item.timeExpire - clock.seconds();
                if (remainSeconds <= 0) {
                    setExpiredTopicId(item.id);
                    clearInterval(inv);
                }
            });
        }, 1000);
        return () => clearInterval(inv);
    }, [list]);

    return expiredTopicId;
};

// subscribe topics collection with change type 'add'
// -> notify has new topic -> refetch new topic list...
export const useWatchNewTopic = ({
    area_id,
    category_id,
    sub_category_id,
    thread_id,
}) => {
    const [hasNewTopic, setHasNewTopic] = useState(false);
    const unsubscribe = useRef(null);
    const lastParams = useRef(null);

    const triggerNewTopic = debounce((lastTopicId) => {
        console.log('receive last topic', lastTopicId);
        setHasNewTopic(true);
    }, 1000);

    const resetWatchNewTopic = () => {
        setHasNewTopic(false);
    };

    const subscribeHandler = (params) => {
        let query = db.collection('topics');
        if (params.area_id !== '1' && params.area_id) {
            query = query.where('area_id', '==', params.area_id);
        }
        if (params.category_id) {
            query = query.where('category_id', '==', params.category_id);
        }
        if (params.sub_category_id) {
            query = query.where(
                'sub_category_id',
                '==',
                params.sub_category_id,
            );
        }
        if (params.thread_id) {
            query = query.where('thread_id', '==', params.thread_id);
        }
        query = query.where('deleted_at', '==', null);

        unsubscribe.current = query.onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
                const source = change.doc.metadata.hasPendingWrites
                    ? 'Local'
                    : 'Server';
                if (source === 'Server' && change.type === 'added') {
                    triggerNewTopic(change.doc.id);
                }
            });
        });
    };

    useEffect(() => {
        if (
            lastParams.current === null ||
            area_id !== lastParams.current.area_id ||
            category_id !== lastParams.current.category_id ||
            sub_category_id !== lastParams.current.sub_category_id ||
            thread_id !== lastParams.current.thread_id
        ) {
            lastParams.current = {
                area_id,
                category_id,
                sub_category_id,
                thread_id,
            };
            subscribeHandler(lastParams.current);

            return () => {
                if (unsubscribe.current) {
                    const unsub = unsubscribe.current;
                    unsub();
                }
            };
        }
    }, [area_id, category_id, sub_category_id, thread_id]);

    useEffect(() => {
        return () => {
            if (unsubscribe.current) {
                const unsub = unsubscribe.current;
                unsub();
            }
        };
    }, []);

    return [hasNewTopic, resetWatchNewTopic];
};

export const useTopicExpired = (topic, navigation = null, history = null) => {
    const dispatch = useDispatch();
    const store = useStore();

    useEffect(() => {
        if (!topic) {
            return;
        }
        const inv = setInterval(() => {
            const remainSeconds = topic?.expire_at?._seconds - clock.seconds();
            if (remainSeconds <= 0) {
                dispatch(
                    openActionPopup(Constants.TOPIC_EXPIRED, () => {
                        if (navigation) {
                            // for native
                            navigation.goBack();
                        }
                        if (history) {
                            // for web
                            history.goBack();
                        }
                        dispatch(closeChatScreen());
                    }),
                );
                clearInterval(inv);
            }
        }, 1000);
        return () => clearInterval(inv);
    }, [topic, store.getState()['cocoro/chat'].roomInfo]);
};
