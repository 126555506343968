import React, { useEffect, useState } from 'react';
import '../detailstyles.scss';
import { Constants } from '../../../util/Constants';
import { numberFormat } from '../functions';
import { ChatBtn } from "../../chat";
import { CallBtn } from "../../call";
import { useDispatch } from "react-redux";
import {
    ROOM_TYPE_CALL_FRIEND,
    ROOM_TYPE_CHAT_FRIEND,
    ROOM_TYPE_CHAT_TOPIC,
    ROOM_TYPE_CALL_TOPIC,
} from '../../call/constants';
import { Modal, openModalPage } from '../../app';
import { reportUserApi } from "../../base/api"
import { addFriend } from "../../addfriend/actions";
import { setLoading } from "../../app/actions";
import { useHistory } from "react-router-dom";
import { getFriendInfo, getFriends } from "../actions";
import TimeTopic from '../../chat/components/TimeTopic.web';
import { CATEGORY_LOCAL_STORE, SUBCATEGORY_LOCAL_STORE, AREA_LOCAL_STORE } from "../../app/constants";

export default function DetailFriend({
    friend,
    blockFriend,
    deleteFriend,
    userInfo,
    onClose,
    detail_friend,
}) {
    let userCharacterSettings = '';
    let userBloodSettings = {};
    let dispatch = useDispatch();
    let history = useHistory();

    try {
        userBloodSettings = JSON.parse(friend.userBloodSettings);
    } catch (e) {

    }
    try {
        userCharacterSettings = JSON.parse(friend.userCharacterSettings);
    } catch (e) {

    }

    const [isShowReport, setShowReport] = useState(false);
    const [reportText, setReportText] = useState('');
    const [roomData, setRoomData] = useState({});
    const [isInit, setIsInit] = useState(true);
    const [showCall, setShowCall] = useState(false);

    const handleAddFriend = async (user) => {
        const result = await dispatch(addFriend(user.id));
        if (result?.status === true) {
            setShowCall(true);
            const result = await dispatch(getFriends());
            try {
                await result?.data.map(async (item, index) => {
                    if (item.user_id == friend.id) {
                        await setRoomData(item);
                    }
                })
            } catch (e) {

            }
        }
    };

    useEffect(() => {
        const getRoomData = async () => {
            // await handleAddFriend(friend);
            if (friend?.is_my_friend) {
                const result = await dispatch(getFriends());
                try {
                    await result?.data.map(async (item, index) => {
                        if (item.user_id == friend.id) {
                            await setRoomData(item);
                        }
                    })
                } catch (e) {

                }
            }
            setIsInit(false);
        }
        if (isInit) {
            getRoomData();
            setIsInit(false);
        }
    })

    const sendReport = async () => {
        dispatch(setLoading(true));
        let result;
        result = await reportUserApi(friend.email, reportText);
        if (result?.data?.message === 'success')
            setShowReport(false);
        dispatch(setLoading(false));

    }

    let areas;
    let categories;
    let subcategories;
    try {
        areas = JSON.parse(localStorage.getItem(AREA_LOCAL_STORE));
    } catch (e) {
        areas = [];
    }
    try {
        categories = JSON.parse(localStorage.getItem(CATEGORY_LOCAL_STORE));
    } catch (e) {
        categories = [];
    }
    try {
        subcategories = JSON.parse(localStorage.getItem(SUBCATEGORY_LOCAL_STORE));
    } catch (e) {
        subcategories = [];
    }
    const getSubcategoryName = (history) => {
        for (let i = 0; i < subcategories.length; i++) {
            if (history?.topic?.category_id == subcategories[i]?.category_id && history?.topic?.sub_category_id == subcategories[i]?.id)
                return subcategories[i]?.name;
        }
    }
    const getCategoryName = (history) => {
        for (let i = 0; i < categories.length; i++) {
            if (history?.topic?.category_id == categories[i]?.id)
                return categories[i]?.name;
        }
    }
    const getAreaName = (history) => {
        for (let i = 0; i < areas.length; i++) {
            if (history?.topic?.area_id == areas[i]?.id)
                return areas[i]?.name;
        }
    }

    const setLinkTag = (content) => {
        var temp_urls = content.match(/\bhttps?:\/\/\S+/gi);
        if(temp_urls === null)
        {
            return(
                <div>
                    {content}
                </div>
            )
        }
        else{
            return (
                <div>
                    {
                        temp_urls.map((text, index) => {
                            var result_show = content.split(text)[0];
                            content = content.split(text)[1].replace(text,"");
                            return (
                                
                                <div>
                                    {result_show}
                                    <a href={text} target="_blank" rel="nofollow noopener noreferrer">{text}</a>
                                    <br/>
                                </div>
                                
                            )
                            
                        })
                    }
                    {content}
                </div>
            )
        }
    }

    return (
        <div id="rootContainer">
            {isShowReport && <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 999, textAlign: 'left' }}>
                <div className="dialog">
                    <div style={{ width: 250, height: 400 }}>
                        <div>通報対象: {friend.user_name}</div>
                        <div style={{ marginTop: 10 }}>通報内容</div>
                        <textarea style={{ borderRadius: 10, width: 250, marginTop: 10, marginLeft: -10, padding: 10, resize: 'none' }} rows="15" onChange={(e) => {
                            setReportText(e.target.value)
                        }} value={reportText} />
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 30 }}>
                            <div className="button" onClick={() => { sendReport() }} style={{ width: 100, alignItems: 'center', justifyContent: 'center' }}>
                                <span>通報する</span>
                            </div>
                            <div className="button" onClick={() => setShowReport(false)} style={{ width: 100, alignItems: 'center', justifyContent: 'center', backgroundColor: '#bfa40d' }}>
                                <span>キャンセル</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {friend.id && (
                <div className="detailContainer" style={{ paddingBlock: 10 }}>
                    <div className={'detail-container-row-1'}>
                        <div style={{ width: '30%' }}>
                            {friend.profile_image !== undefined &&
                                friend.profile_image !== null ? (
                                <img
                                    className="avatar"
                                    src={friend.profile_image}
                                    alt=""
                                />
                            ) : (
                                <img
                                    className="avatar"
                                    src={Constants.AVATAR_DEFAULT}
                                    alt=""
                                />
                            )}
                        </div>
                        <div className={'name'}>
                            <div className="username" style={{ fontSize: "30px", fontWeight: 'bold' }}>
                                {friend.isAnonymous ? friend.user_name : friend.user_name}
                            </div>
                            <span className="userInfo" style={{ fontWeight: 'bold' }}>
                                {friend.area?.name + ' | '}
                                {friend.gender === '1' ? ' | 男性（M）' : ''}
                                {friend.gender === '2' ? ' | 女性（F）' : ''}
                                {friend.gender === '3' ? ' | 不特定（X）' : ''}
                                {' | ' + friend.age?.name}
                            </span>
                        </div>
                        {
                            // friend?.id !== userInfo?.id ?
                            //     <div style={{ width: '30%', flexDirection: 'column', textAlign: 'center' }}>
                            //         {
                            //             friend?.is_my_friend === true || showCall === true ?
                            //                 <img
                            //                     src={require('../../../images/friend.png')}
                            //                     style={{ wdith: 33, height: 33 }} />
                            //                 : <img
                            //                     onClick={() => {
                            //                         setIsInit(false);
                            //                         handleAddFriend(friend);
                            //                     }}
                            //                     src={require('../../../images/ic_add_friends.png')}
                            //                     style={{ wdith: 40, height: 40, cursor: 'pointer' }} />
                            //         }
                            //         {friend?.is_my_friend === true ?
                            //             <div style={{ fontSize: 14, marginBottom: 10 }}>
                            //                 私の友人
                            //             </div>
                            //             : <div style={{ fontSize: 14, marginBottom: 10 }}>
                            //                 ともだち申結
                            //             </div>
                            //         }
                            //     </div>
                            //     : 
                                <div style={{ width: '45%', flexDirection: 'column' }} />
                        }
                    </div>
                    <div style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
                        <div style={{ width: '65%', padding: 10 }}>
                            {
                                friend?.topic?.click_number !== undefined && friend?.topic?.heart_number !== undefined ?
                                    <div className={'topic-rating'}>
                                        {friend?.topic?.is_callable === true ? (
                                            <img
                                                className="topic-status-2"
                                                src={require('../../../images/FriendActiveIcon.png')}
                                                onClick={() => {
                                                    // handleExploreClick(topic.thread_id, topic.user_id, index);
                                                }}
                                            />
                                        ) : (
                                            <img
                                                className="topic-status-2"
                                                src={require('../../../images/ic_rating_off.png')}
                                            />
                                        )}
                                        <span style={{ fontSize: 17, color: '#a6d837', marginRight: "30px" }}>{friend?.topic?.click_number === undefined ? 0 : friend?.topic?.click_number}</span>
                                        <img
                                            className="topic-status-2"
                                            src={require('../../../images/UserActiveIcon.png')}
                                            onClick={() => {
                                                // handleHeartClick(topic.thread_id, topic.user_id, index);
                                            }}
                                        />
                                        <span style={{ fontSize: 17, color: '#a6d837' }}>{friend?.topic?.heart_number === undefined ? 0 : friend?.topic?.heart_number}</span>
                                    </div>
                                    : null
                            }
                        </div>
                        <div style={{ width: '80%', marginRight: "10px" }}>
                            {
                                friend?.id !== userInfo?.id ?
                                    <button className="report" onClick={() => { setShowReport(true) }}>この人を通報する</button>
                                    : null
                            }
                        </div>
                    </div>
                    <div className="oneWordComment">
                        <div className={'row-center'}>
                            <div style={{ flexDirection: 'row', flexWrap: 'wrap', padding: 1 }}>
                                <p1 style={{ fontWeight: 'bold' }}> {userBloodSettings?.bloodType}型 </p1>
                                <p1 style={{ fontWeight: 'bold' }}> {userBloodSettings?.personality} </p1>
                                <p1 style={{ fontWeight: 'bold' }}>社{userBloodSettings?.sociability}/</p1>
                                <p1 style={{ fontWeight: 'bold' }}>度{userBloodSettings?.degree}/</p1>
                                <p1 style={{ fontWeight: 'bold' }}>感{userBloodSettings?.expression}/</p1>
                                <p1 style={{ fontWeight: 'bold' }}>積{userBloodSettings?.aggression}  /</p1>
                                <p1 style={{ fontWeight: 'bold' }}>度{userBloodSettings?.fashion} </p1>
                            </div>
                            <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                <p1 style={{ fontWeight: 'bold' }}>{userCharacterSettings?.value} </p1>
                            </div>
                        </div>

                        {/* {friend?.comment !== 'undefined' && friend?.comment !== '' ?
                            <span className="userCommentOne">
                                {friend?.comment}
                            </span>
                            :<span className="userCommentOne">
                                資料がありません。
                            </span>
                        } */}

                        {
                            friend?.topic?.expire_at?._seconds ?
                                <div className={'remain-time-container'}>
                                    <span className={'content'}>
                                        <TimeTopic
                                            timeExpire={
                                                friend?.topic?.expire_at?._seconds
                                            }
                                        />
                                    </span>
                                </div>
                                : null
                        }

                        <div className={"category-green"}>
                            <span>
                                {getCategoryName(friend)}
                            </span>
                            <span style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 2 }}>
                                {
                                    getCategoryName(friend) ?
                                        <img src={'/images/arrow-one.png'} style={{ height: 9 }} />
                                        : null
                                }
                            </span>
                            <span>
                                {getSubcategoryName(friend)}
                            </span>
                            <span style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 2 }}>
                                {
                                    getSubcategoryName(friend) ?
                                        <img src={'/images/arrow-one.png'} style={{ height: 9 }} />
                                        : null
                                }
                            </span>
                            <span>
                                {getAreaName(friend)}
                            </span>


                        </div>
                        {
                            friend?.topic?.title ?
                                <div style={{ fontWeight: 'bold' }}>
                                    {setLinkTag(friend?.topic?.title)}
                                </div>
                                : null
                        }
                        {


                            friend?.topic?.comment !== '' && friend?.topic?.comment !== 'undefined' && friend?.topic?.comment !== undefined ?
                                <div className="title">
                                    <span className="userComment">
                                        {setLinkTag(friend?.topic?.comment)}
                                    </span>
                                </div>
                                : null

                        }
                        {(friend.userCharacterInputWord !== undefined &&
                            friend.userCharacterInputWord !== 'undefined' &&
                            friend.userCharacterInputWord != '') ?
                            <div style={{ width: '100%', marginTop: 10 }}>
                                <div className='category-green'>
                                    <span style={{ paddingTop: '10px', fontSize: 12 }}>禁止事項</span>
                                </div>
                                <div className="">
                                    <span className="userComment">
                                        {setLinkTag(friend?.userCharacterInputWord)}

                                    </span>
                                </div>
                            </div>
                            : <div style={{ width: '100%', marginTop: 10 }}>
                                <div className='category-green'>
                                    <span style={{ paddingTop: '10px', fontSize: 12 }}>禁止事項</span>
                                </div>
                                <div className="">
                                    <span className="userComment">
                                        資料がありません。
                                    </span>
                                </div>
                            </div>
                        }
                        {(friend.userCharacterInputDetail !== undefined &&
                            friend.userCharacterInputDetail !== 'undefined' &&
                            friend.userCharacterInputDetail != '') ?
                            <div style={{ width: '100%', marginTop: 10 }}>
                                <div className='category-green'>
                                    <span style={{ paddingTop: '10px', fontSize: 12 }}>自分ＰＲ</span>
                                </div>
                                <div className="">
                                    <span className="userComment">
                                        {
                                            setLinkTag(friend?.userCharacterInputDetail)
                                        }
                                    </span>
                                </div>
                            </div>
                            : <div style={{ width: '100%', marginTop: 10 }}>
                                <div className='category-green'>
                                    <span style={{ paddingTop: '10px', fontSize: 12 }}>自分ＰＲ</span>
                                </div>
                                <div className="">
                                    <span className="userComment">
                                        資料がありません。
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        friend?.id !== userInfo?.id && friend?.email_id !== userInfo?.email_id ?
                            <div className="functionContainer">
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', width: 100 }}
                                >
                                    {
                                        (friend?.is_my_friend || showCall === true) && friend.friend_flag ?
                                            <ChatBtn
                                                userId={friend.id}
                                                Component={() => {
                                                    return <img src="images/ic_status_message@4x.png" style={{ width: 40, height: 40 }} />
                                                }}
                                                roomTypeId={ROOM_TYPE_CHAT_FRIEND}
                                                topicId={friend?.room?.topic_id}
                                                roomInfo={friend?.room}
                                                isAnonymous={friend?.isAnonymous}
                                            />
                                            : <ChatBtn
                                                userId={friend.id}
                                                Component={() => {
                                                    return <img src="images/ic_status_message@4x.png" style={{ width: 40, height: 40 }} />
                                                }}
                                                roomTypeId={ROOM_TYPE_CHAT_TOPIC}
                                                topicId={friend?.topic?.id}
                                                topicInfo={friend?.topic}
                                                isAnonymous={friend?.isAnonymous}
                                                timeExpire={friend?.topic?.expire_at?._seconds}
                                            />
                                    }
                                    <span style={{
                                        marginLeft: 10,
                                        width: 40, height: 40,
                                        alignItems: 'center', justifyContent: 'center',
                                        borderStyle: 'dotted',
                                        borderWidth: 2,
                                        borderColor: '#d6d605',
                                        borderRadius: 10,
                                        fontSize: 12
                                    }}>チャットする</span>
                                </div>
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', width: 70 }}
                                >
                                    {(friend?.is_my_friend || showCall === true) && friend.friend_flag ?
                                        <CallBtn
                                            userId={friend?.id}
                                            roomId={friend.roomId}
                                            roomTypeId={ROOM_TYPE_CALL_FRIEND}
                                            topicInfo={friend.topic}
                                            disabled={false}
                                            isAnonymous={friend.isAnonymous}
                                            name={friend.user_name}
                                            gender_name = {friend.gender === '1' ? '男性（M）' : friend.gender === '2' ? '女性（F）' : '不特定（X）'}
                                            age_name={friend?.age?.name}
                                            area_name={friend?.area?.name}
                                            profile_image={friend?.profile_image}
                                            soundRecordNo={true}
                                            Component={() => {
                                                return <img src="images/ic_call_active@4x.png" style={{ width: 40, height: 40, cursor: 'pointer' }} />
                                            }}
                                        />
                                        : <CallBtn
                                            userId={friend?.id}
                                            roomId={friend.roomId}
                                            roomTypeId={ROOM_TYPE_CALL_TOPIC}
                                            topicInfo={friend?.topic}
                                            timeExpire={friend?.topic?.expire_at?._seconds}
                                            disabled={friend?.topic?.is_callable ? false : true}
                                            name={friend.user_name}
                                            gender_name = {friend.gender === '1' ? '男性（M）' : friend.gender === '2' ? '女性（F）' : '不特定（X）'}
                                            age_name={friend.age?.name}
                                            area_name={friend.area?.name}
                                            profile_image={friend?.profile_image}
                                            Component={() => {
                                                if (friend?.topic?.is_callable)
                                                    return <img src="images/ic_call_active@4x.png" style={{ width: 40, height: 40, cursor: 'pointer' }} />
                                                else
                                                    return <img src="images/ic_call_missing@4x.png" style={{ width: 40, height: 40, cursor: 'pointer' }} />
                                            }}
                                        />

                                    }
                                    <span style={{
                                        marginLeft: 10, fontSize: 12,
                                        width: 40, height: 40,
                                        alignItems: 'center', justifyContent: 'center',
                                        borderStyle: 'dotted',
                                        borderWidth: 2,
                                        borderColor: '#d6d605',
                                        minWidth: 40,
                                        borderRadius: 10,
                                        marginLeft: 25,
                                    }}>電話<br />する</span>
                                </div>
                            </div>
                            : null
                    }
                </div>
            )}
        </div>
    );
}
