import {GET_MY_PROFILE} from './actionTypes';
import {setLoading, openPopup, openModalPage} from '../app/actions';
import {updateImageUri} from '../inforeg';
import {CHECK_AUTH} from '../app/actionTypes';
import {
    getMyProfile as profileApi,
    changeUsername,
    getAge as ageApi,
    getAreas as areaApi,
    changeProfile as updateApi,
} from './apis';
import {Constants} from '../../util/Constants';
import {
    MODAL_CHOOSE_GENDER,
    MODAL_CHOOSE_AGE,
    MODAL_CHOOSE_AREA,
} from '../app/constants';
import chatdb from '../chat/chatdb';

export function setProfile(profile) {
    return {
        type: GET_MY_PROFILE,
        profile,
    };
}

export function getMyProfile() {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        profileApi(access_token).then((resolve) => {
            dispatch(setLoading(false));
            try {
                dispatch({type: CHECK_AUTH, status: resolve.status});
                if (resolve.status === 200 || resolve.status === 201) {
                    dispatch(setProfile(resolve.data));
                    dispatch(
                        updateImageUri(
                            resolve.data.profile_image,
                            Constants.IMAGE_TYPE_LINK,
                            false,
                        ),
                    );
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                    return;
                }
            } catch (error) {
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}

export function getGender(currentGender) {
    return function (dispatch) {
        let gender = [
            {id: '2', name: '女性（F）'},
            {id: '1', name: '男性（M）'},
            {id: '3', name: '不特定（X）'},
        ];
        dispatch(
            openModalPage({type: MODAL_CHOOSE_GENDER, gender, currentGender}),
        );
    };
}

export function changeGender(gender) {
    return function (dispatch, getState) {
        let {age_id, area_id, user_name, profile_image} = getState()[
            'cocoro/profile'
        ].userProfile;
        dispatch(
            changeProfile(
                age_id,
                area_id,
                gender.id,
                Constants.IMAGE_TYPE_LINK,
                profile_image,
                user_name,
            ),
        );
    };
}

export function changeAge(age) {
    return function (dispatch, getState) {
        let {gender, area_id, user_name, profile_image} = getState()[
            'cocoro/profile'
        ].userProfile;
        dispatch(
            changeProfile(
                age.id,
                area_id,
                gender,
                Constants.IMAGE_TYPE_LINK,
                profile_image,
                user_name,
            ),
        );
    };
}

export function changeArea(area) {
    return function (dispatch, getState) {
        let {age_id, gender, user_name, profile_image} = getState()[
            'cocoro/profile'
        ].userProfile;
        dispatch(
            changeProfile(
                age_id,
                area.id,
                gender,
                Constants.IMAGE_TYPE_LINK,
                profile_image,
                user_name,
            ),
        );
    };
}

export function changeProfile(
    age,
    area,
    gender,
    type,
    avatar,
    user_name,
    onBack,
) {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        updateApi(
            access_token,
            user_name,
            age,
            area,
            gender,
            type,
            avatar,
        ).then((resolve) => {
            dispatch(setLoading(false));
            try {
                dispatch({type: CHECK_AUTH, status: resolve.status});
                if (resolve.status === 200 || resolve.status === 201) {
                    dispatch(setProfile(resolve.data));
                    if (onBack) {
                        onBack();
                    }
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            } catch (error) {
                console.error(error);
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}

export function changeName(userName, callback, onBack) {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        changeUsername(access_token, userName).then((resolve) => {
            dispatch(setLoading(false));
            try {
                dispatch({type: CHECK_AUTH, status: resolve.status});
                if (resolve.status === 200 || resolve.status === 201) {
                    resolve.data.roomIds.map((roomId) => {
                        chatdb.removeRoom(roomId);
                    });
                    onBack();
                } else if (resolve.status === 403) {
                    callback(resolve.data.errors.user_name);
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            } catch (error) {
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}

export function getArea(currentArea) {
    return function (dispatch) {
        dispatch(setLoading(true));
        areaApi().then((resolve) => {
            dispatch(setLoading(false));
            try {
                if (resolve.status === 200 || resolve.status === 201) {
                    dispatch(
                        openModalPage({
                            type: MODAL_CHOOSE_AREA,
                            data: resolve.data.filter((a) => a.name != '四国'),
                            currentArea,
                        }),
                    );
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            } catch (error) {
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}

export function getAge(currentAge) {
    return function (dispatch) {
        dispatch(setLoading(true));
        ageApi().then((resolve) => {
            dispatch(setLoading(false));
            try {
                if (resolve.status === 200 || resolve.status === 201) {
                    dispatch(
                        openModalPage({
                            type: MODAL_CHOOSE_AGE,
                            data: resolve.data,
                            currentAge,
                        }),
                    );
                } else {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            } catch (error) {
                dispatch(openPopup('想定しないエラーが発生しました。'));
            }
        });
    };
}
