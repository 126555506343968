/* eslint-disable no-sparse-arrays */
import {Dimensions, Platform, StatusBar} from 'react-native';

export const TIME_MILES_SMALLEST = {name: '5分', value: 5, id: '1'};

export const timeMiles = [
    {name: '5分', value: 5, id: '1'},
    {name: '10分', value: 10, id: '2'},
    {name: '20分', value: 20, id: '3'},
    {name: '30分', value: 30, id: '4'},
    {name: '1時間', value: 60, id: '5'},
    {name: '2時間', value: 120, id: '6'},
    {name: '3時間', value: 180, id: '7'},
    {name: '6時間', value: 360, id: '8'},
    {name: '12時間', value: 720, id: '9'},
    {name: '24時間', value: 1440, id: '10'},
    {name: '48時間', value: 2880, id: '11'},
    {name: '72時間', value: 4320, id: '12'},
];

export function timeStamp2Date(time) {
    if (time) {
        let date = new Date(time);
        let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        let m =
            date.getMinutes() < 10
                ? '0' + date.getMinutes()
                : date.getMinutes();
        return h + ':' + m;
    } else {
        return '';
    }
}

export function getScrollStep(contentHeight, visibleHeight, ratio) {
    const distance = visibleHeight / ratio;
    console.log('distance', distance);
    if (Platform.OS === 'android') {
        return (distance / 200) * ratio;
    }
    return (distance / 100) * ratio * ratio * ratio;
}

export function getMarginBottom() {
    if (Platform.OS === 'android') {
        return 0;
    }
    let isPhoneX = false;
    const dimen = Dimensions.get('window');
    if (
        Platform.OS === 'ios' &&
        !Platform.isPad &&
        !Platform.isTVOS &&
        (dimen.height === 812 ||
            dimen.width === 812 ||
            dimen.height === 896 ||
            dimen.width === 896)
    ) {
        isPhoneX = true;
    }
    if (isPhoneX) {
        return 0;
    } else {
        return 0;
    }
}

export function isIPhoneX() {
    const dimen = Dimensions.get('window');
    if (
        Platform.OS === 'ios' &&
        !Platform.isPad &&
        !Platform.isTVOS &&
        (dimen.height === 812 ||
            dimen.width === 812 ||
            dimen.height === 896 ||
            dimen.width === 896)
    ) {
        return true;
    }
    return false;
}

export function getRankUser(userInfo) {
    if (userInfo.rank != undefined) {
        return userInfo.rank.max_point_for_chatting;
    } else {
        return 0;
    }
}
export function getRankCall(userInfo) {
    if (userInfo.rank != undefined) {
        return userInfo.rank.max_point_for_a_call;
    } else {
        return 0;
    }
}
export function checkRankUser(userInfo) {
    if (userInfo.rank) {
        if (
            userInfo.rank.max_point_for_a_call +
                userInfo.rank.max_point_for_chatting !==
            0
        ) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function checkThreadId(history) {
    if (history) {
        if (history.latest_joined_thread !== '0') {
            return history.latest_joined_thread;
        }
    } else {
        return null;
    }
}

export function checkData(styleList, userInfoData) {
    if (styleList === 1) {
        const datalist = [
            {title: '5分', value: '5', id: '1'},
            {title: '10分', value: '10', id: '2'},
            {title: '20分', value: '20', id: '3'},
            {title: '30分', value: '30', id: '4'},
            {title: '1時間', value: '60', id: '5'},
            {title: '2時間', value: '120', id: '6'},
            {title: '3時間', value: '180', id: '7'},
            {title: '6時間', value: '360', id: '8'},
            {title: '12時間', value: '720', id: '9'},
            {title: '24時間', value: '1440', id: '10'},
            {title: '48時間', value: '2880', id: '11'},
            {title: '72時間', value: '4320', id: '12'},
        ];
        return datalist;
    }
    if (styleList === 2) {
        const dataUser = [
            {title: userInfoData.user_name, value: true},
            {title: userInfoData.user_name, value: false},
        ];
        return dataUser;
    } else {
        return null;
    }
}
