import React from 'react';
import '../styles.scss'
export default function Header({onPressBack, txt}) {
    return (
        <div id='header'>
            <div id='header-button' onClick={() => onPressBack()}>
                <img src={"/images/icon-back-grey.png"} />
            </div>
            <div style={{fontWeight: 'bold'}}>写真</div>
            <div id='header-button'></div>
        </div>
    );
}

const styles = {
    nav: {
        height: 53,
        width: '100%',
        borderBottom: '1px solid #d8d8d8',
    },
    title: {
        fontFamily: 'Hiragino Kaku Gothic ProN',
        fontSize: 18,
        textAlign: 'center',

        width: '50%',
        float: 'left',
        paddingTop: 15,
    },
    backBtn: {
        width: '24.9%',
        float: 'left',
        height: 45,
        paddingTop: 8,
    }
};
