import React, {useState, useEffect} from 'react';
import {
    StyleSheet,
    span,
    View,
    button,
    ScrollView,
} from 'react-native';
import {openPopup} from '../../app/actions';
import {connect} from 'react-redux';

import '../character.scss';


let tmp = [];

function SettingCharacter({navigation, route, openPopup}) {
    const [selected, setSelected] = useState('');

    let handleClick = () => {

        let userInfoStorage = localStorage.getItem('user_info');
        try{
            userInfoStorage = JSON.parse(userInfoStorage);
        }catch(e){
            userInfoStorage = {}
        }

        userInfoStorage.userCharacterSettings = {
            value: tmp.toString(),
        }

        localStorage.setItem('user_info', JSON.stringify(userInfoStorage))

        window.location.href = '/registration-information/result-setting';
    }

    let arr = [
        '筋トレ・ウォーキング', 'グルメ',
        '旅行', '温泉巡り', '登山', 'スポーツ', 'ゴルフ', '映画鑑賞', 'ショッピング', 'ドライブ', 'パソコン',
        'カメラ', 'カラオケ', '車・バイク', '自転車', '楽器', '犬・猫・ペット', '資格取得', '家庭菜園', '英会話', 'ヨガ', 'エステ',
        '着物', '手芸', 'お花', '料理' , '書道', 'カフェ巡り', 'アロマ'
    ];

    let handleSelected = (val) => {
        if (tmp.length === 0 ) {
            tmp.push(val);
        } else {
            let result;
            tmp.some(value => {
                if (val === value) {
                    result = true;
                    return true;
                } else {
                    result = false;
                }
            })
            if (result) {
                let index = tmp.indexOf(val);
                tmp.splice(index, 1);
            } else {
                tmp.push(val);
            }
        }
    }

    return (
        <div style={styles.container} id = { 'character-container' }>
            <View style={styles.header}>
                <span style={styles.txt}>
                    キャラの興味を
                </span>
                <span style={styles.txt}>
                    何個でも選んでください
                </span>
                <span style={styles.txt}>
                    (後から設定できます)
                </span>
            </View>

            <View style={styles.wrap}>
                {
                    arr.map(val => {
                        return (
                            <button
                                style={{
                                    ...styles.wrapPadding,
                                    backgroundColor: tmp.indexOf(val) > -1 ? 'red' : 'white'
                                }}
                                onClick={() => {
                                    if (selected === val) {
                                        setSelected('');
                                    } else {
                                        setSelected(val);
                                    }
                                    handleSelected(val);
                                }}
                            >
                                <span>{val}</span>
                            </button>
                        )
                    })
                }
            </View>

            <View style={styles.warpButton}>
                <button
                    onClick={handleClick}
                    style={styles.ButtonSubmit}>
                    <span style={styles.spanButton}>つぎへ</span>
                </button>
            </View>
        </div>
    );
}

const styles = {
    wrap: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 10
    },
    wrapPadding: {
        paddingHorizontal: 15,
        paddingVertical: 10,
        backgroundColor: '#66da2c',
        borderRadius: 10,
        marginTop: 10,
        marginLeft: 10,
        cursor: 'pointer',
    },
    container: {
        flex: 1,
        backgroundColor: '#d6f27e',
    },
    header: {
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 50
    },
    txt: {
        color: '#2b2828',
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'center',
        marginTop: 5
    },
    warpButton: {
        width: '100%',
        marginTop: 20,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 35,
    },
    ButtonSubmit: {
        width: '50%',
        padding: 5,
        fontWeight: 'bold',
        backgroundColor: '#FFE033',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#DBBD16',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 4,
        borderBottomColor: '#DBBD16',
        borderRadius: 10,
        height: 50,
        cursor: 'pointer'
    },
    spanButton: {
        fontSize: 18,
        color: '#36383B',
        fontWeight: 'bold',
    },
};

function _mapStateToProps(state) {
    return {};
}

let _mapDispatchToProps = {
    openPopup,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(SettingCharacter);
