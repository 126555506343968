import React, { useState } from 'react';
import Header from '../../inforeg/web/custom/Header';
import '../styles.scss';
import Webcam from 'react-webcam';
import {updateImageUri} from '../actions';
import {connect} from 'react-redux';
import {Constants} from '../../../util/Constants';

const TakeAvatar = ({history, onTakePicture, imageUri, updateImageUri}) => {
    const webcamRef = React.useRef(null);
    const [webcamError, setWebcamError] = useState(null);
    const onPressBack = () => {
        history.goBack();
    };

    const capture = () => {
        if(webcamError === null || webcamError === ''){
            const imageSrc = webcamRef.current.getScreenshot();
            updateImageUri(imageSrc);
            let userInfoStorage;
            try{
                userInfoStorage = JSON.parse(localStorage.getItem('user_info'));
            }catch(e){
                userInfoStorage = {};
            }
            userInfoStorage.userAvatarRegisterInfo = {imageUri: imageSrc, type_image: Constants.IMAGE_TYPE_BASE64};
            localStorage.setItem('user_info', JSON.stringify(userInfoStorage));
            history.goBack();
        }
    };



    return (
        <div className="container">
            <Header onPressBack={onPressBack} />
            <div class="main">
                <div id="wrap-cam">
                    <Webcam
                        id="web-cam"
                        ref={webcamRef}
                        audio={false}
                        screenshotFormat="image/jpeg"
                        onUserMediaError={(error) => {
                            if(error === null || error === undefined){
                                setWebcamError('');
                            } else {
                                setWebcamError(error.message);
                            }
                        }}
                    />
                    <img id="bg-wrap-camera" src="/images/bg_camera.svg" />
                    {
                        webcamError && (
                            <span className="webcamError">{webcamError}</span>
                        )
                    }
                </div>
                <div id="capture">
                    <img
                        id="img-capture"
                        src="/images/ic_capture.svg"
                        onClick={capture}
                    />
                </div>
            </div>
        </div>
    );
};

function _mapStateToProps(state) {
    return {
        imageUri: state['cocoro/register'].imageUri,
    };
}

let _mapDispatchToProps = {
    updateImageUri,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(TakeAvatar);
