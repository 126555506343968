import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './features/app';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {loggedIn, updateDeviceId, updateMyInfo} from './features/user';
import {auth} from './features/base/firebase/firebase';
import {transitions, Provider as AlertProvider} from 'react-alert';
import AlertTemplate from 'react-alert-template-mui';
import {store} from './features/base/redux';
import {initCallManager} from './features/call/callmanager.web';
import {setAccessToken} from './features/base/api';
import clock from './features/base/clock';
import {persistKeys} from './util/Constants';
import Storage from './util/storage';

clock.run();

const cacheData = Storage.multiGet([
    persistKeys.USER_INFO,
    persistKeys.DEVICE_ID,
]);
const userInfo = cacheData?.[persistKeys.USER_INFO];
const deviceId = cacheData?.[persistKeys.DEVICE_ID];

if (userInfo) {
    if (userInfo.access_token) {
        setAccessToken(userInfo.access_token);
        //
        try {
            auth.signInWithCustomToken(userInfo.token_firebase);
        } catch (e) {
            console.warn(e);
        }

        store.dispatch(loggedIn(true));
        store.dispatch(updateMyInfo(userInfo));
        store.dispatch(updateDeviceId(deviceId));
    }
}

initCallManager();

const alertOptions = {
    transition: transitions.SCALE,
};

ReactDOM.render(
    <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
            <App />
        </AlertProvider>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
