import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {changeRoute, openPopup} from '../../app/actions';
import {changePassword} from '../actions';
import InputField from './InputField.web';
import '../styles.scss';

function ChangePassword({
    loading,
    changeRoute,
    lastRoute,
    openPopup,
    changePassword,
}) {
    let [oldPassword, setOldPassword] = useState('');
    let [newPassword, setNewPassword] = useState('');
    let [confirmPassword, setConfirmPassword] = useState('');
    let [oldPasswordError, setOldPasswordError] = useState('');
    let [newPasswordError, setNewPasswordError] = useState('');
    let [confirmPasswordError, setConfirmPasswordError] = useState('');

    let history = useHistory();

    let onBack = () => {
        history.goBack();
    };

    const callback = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
    };

    let changePasswordFunc = () => {
        setOldPasswordError('');
        setNewPasswordError('');
        setConfirmPasswordError('');
        changePassword(
            oldPassword,
            newPassword,
            confirmPassword,
            setOldPasswordError,
            setNewPasswordError,
            setConfirmPasswordError,
            callback,
        );
    };

    return (
        <div id="changePassword">
            <header>
                <div className="back">
                    <img
                        alt=""
                        onClick={onBack}
                        src="/images/icon-back-grey.png"
                    />
                </div>
                <h3>パスワード変更</h3>
                <div className="none" />
            </header>
            <div className="mainContainer">
                <InputField
                    title={'旧パスワード'}
                    data={oldPassword}
                    setData={setOldPassword}
                    error={oldPasswordError}
                />
                <InputField
                    title={'新パスワード'}
                    data={newPassword}
                    setData={setNewPassword}
                    error={newPasswordError}
                />
                <InputField
                    title={'新パスワード（確認用）'}
                    data={confirmPassword}
                    setData={setConfirmPassword}
                    error={confirmPasswordError}
                />

                <div className="buttonContainer">
                    <div className="button" onClick={changePasswordFunc}>
                        <span>パスワード変更</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        lastRoute: state['cocoro/app'].lastRoute,
        loading: state['cocoro/app'].loading,
    };
}

let _mapDispatchToProps = {
    changeRoute,
    openPopup,
    changePassword,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ChangePassword);
