export function validateUsername(value) {
    if (value === '' || value.trim().length === 0) {
        return 'メールアドレスを入力してください。';
    }
    if (value.length > 100) {
        return 'メールアドレスは100文字以内で入力してください。';
    }
    if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            value,
        )
    ) {
        return '正しいメールアドレスを入力してください。';
    }
    return null;
}

export function validatePassword(value) {
    if (value === '' || value.trim().length === 0) {
        return 'パスワードを入力してください。';
    }
    if (value.length < 8) {
        return 'パスワードは8文字以上で入力してください。';
    }
    if (value.length > 20) {
        return 'パスワードは20文字以内で入力してください。';
    }
    return null;
}
