import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {refuseCall, answerCall, audioCall} from '../actions';
import {
    closeActionPopup,
    closeModalPage,
    closeModalPageSpecial,
    closePopup,
    closeTopicModal,
    Modal,
} from '../../app';
import {Constants} from '../../../util/Constants';
import CalleeFriend from "../../friendlist/components/Callee.web"
import { blockFriend, deleteFriend } from '../../friendlist/actions';

const Callee = ({roomDetail, userInfo, blockFriend, deleteFriend, onClose}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(closePopup());
        dispatch(closeActionPopup());
        dispatch(closeModalPage());
        dispatch(closeModalPageSpecial());
        dispatch(closeTopicModal());
    }, []);

    const handleRefuseCall = () => {
        dispatch(refuseCall(userInfo.user_id, roomDetail.room_id));
    };

    const handleAudioCall = () => {
        dispatch(audioCall(userInfo.user_id, roomDetail.room_id));
    };

    const handleAnswerCall = () => {
        dispatch(answerCall(userInfo.user_id, roomDetail.room_id));
    };

    let myInfo = {};
    try{
        myInfo = JSON.parse(localStorage.getItem("user_info"));
    }catch(e){

    }

    const component = () => {
        return (
            <div id="calleeWrap">
                <CalleeFriend
                    friend = {userInfo}
                    blockFriend = {blockFriend}
                    deleteFriend = {deleteFriend}
                    onClose = {onClose}
                    myInfo = {myInfo}
                    handleRefuseCall = {handleRefuseCall}
                    handleAudioCall = {handleAudioCall}
                    handleAnswerCall = {handleAnswerCall}
                    roomDetail = {roomDetail}
                />
            </div>
        );
    };

    return <Modal Component={component} />;
};

const _mapStateToProps = (state) => ({
    userInfo: state['cocoro/call'].userInfo,
    roomDetail: state['cocoro/call'].roomDetail,
});

export default connect(_mapStateToProps)(Callee);
