import axios from 'axios';
import {URL_API} from '../../../env.json';  

axios.defaults.baseURL = `${URL_API}/api/v1`;
axios.defaults.timeout = 10 * 1000; // 10 seconds;
axios.defaults.validateStatus = (status) => status < 500;

const apiAuth = axios.create();
const api = axios.create();

export const setAccessToken = (token) => {
    apiAuth.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const getTimeServerApi = async () => {
    try {
        const res = await api.post('/users/timeserver');
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const verifyCodeApi = async ({email, verify_code}) => {
    try {
        const res = await api.post('/users/verify', {
            email,
            verify_code,
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const loginApi = async ({
    email,
    password,
    device_type,
    device_id,
    fcm_token,
    voip_token,
}) => {
    try {
        const res = await api.post('/users/login', {
            email,
            password,
            device_type,
            device_id,
            fcm_token,
            voip_token,
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const logoutApi = async () => {
    try {
        const res = await apiAuth.post('/logout');
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getUserProfileApi = async () => {
    try {
        const res = await apiAuth.get('/users/profile');
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getUserDeviceApi = async () => {
    try {
        const res = await apiAuth.get('/users/device');
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const updateUserDeviceApi = async ({fcm_token, voip_token}) => {
    try {
        const res = await apiAuth.put('/users/device', {fcm_token, voip_token});
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getUserSettingApi = async () => {
    try {
        const res = await apiAuth.get('/users/setting');
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getFilterHistoryApi = async () => {
    try {
        const res = await apiAuth.get('/users/filter');
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const addUserApi = async () => {
    try {
        const res = await apiAuth.post('/users/add_myusers');
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const updateFilterApi = async (data) => {
    try {
        const res = await apiAuth.post('/users/filter', data);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const updateFirstSearchIsFiredApi = async () => {
    try {
        const res = await apiAuth.put('/users/first_search');
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const searchUserApi = async ({keyword, last_doc_id}) => {
    try {
        const res = await apiAuth.post('/users/search', {
            keyword,
            last_doc_id,
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getFriendsApi = async ({last_doc_id, per_page}) => {
    try {
        const res = await apiAuth.get('/users/friends', {
            params: {
                last_doc_id,
                per_page,
            },
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getFriendApi = async (id) => {
    try {
        const res = await apiAuth.get(`/users/friends/${id}`);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const addFriendApi = async ({user_id, room_id, topic_id}) => {
    try {
        const res = await apiAuth.post('/users/friends', {
            user_id,
            room_id,
            topic_id,
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const deleteFriendApi = async (id) => {
    try {
        // issue: axios.delete not working body data
        const res = await apiAuth({
            method: 'delete',
            url: '/users/friends',
            data: {
                id,
            },
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getUserApi = async (id) => {
    try {
        const res = await apiAuth.get(`/users/${id}`);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

// block
export const getBlockApi = async ({last_doc_id, per_page}) => {
    try {
        const res = await apiAuth.get('/users/blocks', {
            params: {
                last_doc_id,
                per_page,
            },
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const blockApi = async (id) => {
    try {
        const res = await apiAuth.post('/users/block', {id});
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const unblockApi = async (id) => {
    try {
        const res = await apiAuth.post('/users/unblock', {id});
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

// room call
export const createRoomApi = async (
    topicId,
    userId,
    room_id,
    roomTypeId,
    isAnonymous,
) => {
    try {
        const result = await apiAuth.post('/rooms', {
            topic_id: topicId,
            user_id: userId,
            room_type_id: roomTypeId,
            is_anonymous: isAnonymous,
            room_id,
        });
        return result;
    } catch (e) {
        return {error: e.message};
    }
};

// save present audio data
export const saveAudioRecord = async (
    userId,
    audioRecord,
) => {
    let temp = audioRecord;
    let data = [];
    try{
        let per = Math.floor(temp.length/7)
        for(var i = 0; i < 6; i++){
            data[i] = temp.substr(per*i, per);
        }
        data[6] = temp.substr(per*6);
    }catch(e){

    }
    try {

        for(var i = 0; i < 7; i++) {
            let send_data = {};
            send_data['user_id'] = userId;
            send_data['audioRecord' + (i+1)] = data[i];
            await apiAuth.put('/users/audio_record_' + (i+1), send_data);
        }
        
        return true;
    } catch (e) {
        return {error: e.message};
    }
};

export const getRoomDetailApi = async (roomId, myId) => {
    try {
        const opts = roomId
            ? {
                  params: {
                      user_id: myId || '',
                  },
              }
            : null;
        const res = await apiAuth.get(`/rooms/${roomId}`, opts);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getRoomCallingDataApi = async (roomId, otherUserId) => {
    try {
        const res = await apiAuth.get(
            `/rooms/${roomId}/calling?user_id=${otherUserId}`,
        );
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const pingOnPaidCallApi = async (roomId) => {
    try {
        const res = await apiAuth.post(`/rooms/${roomId}/pingOnPaidCall`);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const replyActionCallApi = async (roomId, action) => {
    try {
        const res = await apiAuth.post(`/rooms/${roomId}/callanswer`, {action});
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const pingConnectRoomChatApi = async ({room_id, signal_id, user_id, id}) => {
    try {
        const res = await apiAuth.post(
            `/rooms/${room_id}/pingConnectRoomChat`,
            {
                signal_id,
                user_id,
                id,
            },
        );
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const sendMessageApi = async (data, user_id) => {
    try {
        // const res = await apiAuth.post('/messages', {data,user_id});
        const res = await apiAuth.post('/messages', data);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const sendPresentApi = async (coin, receiveUserId) => {
    try {
        const data = { coin: coin, receiveUserId: receiveUserId };
        const res = await apiAuth.put('/users/send_coin', data);
        return res;
    } catch (e) {
        return {error: e.message};
    }
}

export const rateRoomApi = async ({room_id, star, type}) => {
    try {
        const res = await apiAuth.put(`/rooms/${room_id}/rate`, {
            type,
            star,
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const reportRoomApi = async ({room_id, content, type}) => {
    try {
        const res = await apiAuth.post(`/rooms/${room_id}/report`, {
            type,
            content,
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getSignedFileUrl = async ({message_type_id, id, data}) => {
    try {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('message_type_id', message_type_id);
        formData.append('data', data);
        const res = await apiAuth.post('/messages/signedFileUrl', formData, {
            'Content-Type': 'multipart/form-data',
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getMessageRoomApi = async (roomId) => {
    try {
        const res = await apiAuth.get(`rooms/${roomId}/messages`);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getMessageToMeApi = async (roomId) => {
    try {
        const res = await apiAuth.get(`/messages/toMe`);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getMessageRoomToMeApi = async (roomId, myId) => {
    try {
        const opts = roomId
            ? {
                  params: {
                      user_id: myId || '',
                  },
              }
            : null;
        const res = await apiAuth.get(`/messages/toMe/${roomId}`, opts);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

// topic && thread
export const getTopicApi = async (topicId, roomId) => {
    try {
        const opts = roomId
            ? {
                  params: {
                      room_id: roomId || '',
                  },
              }
            : null;
        const res = await apiAuth.get(`/topics/${topicId}`, opts);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getAreaApi = async () => {
    try {
        const res = await apiAuth.get('/areas');
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getCategoryApi = async () => {
    try {
        const res = await apiAuth.get('/categories');
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getSubCategoryApi = async (categoryId) => {
    try {
        const res = await apiAuth.get(
            `/subCategories?category_id=${categoryId}`,
        );
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getSubCategoryAllApi = async () => {
    try {
        const res = await apiAuth.post(
            `/subCategories`,
        );
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const createThreadApi = async (data) => {
    try {
        const res = await apiAuth.post(`/threads`, data);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getThreadApi = async ({
    area_id,
    category_id,
    sub_category_id,
    last_doc_id,
    per_page,
}) => {
    try {
        if (category_id && !sub_category_id) {
            throw 'missing params';
        }
        const res = await apiAuth.get('/threads', {
            params: {
                area_id,
                category_id,
                sub_category_id,
                last_doc_id,
                per_page,
            },
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getThreadByIdApi = async (id) => {
    try {
        const res = await apiAuth.get(`/threads/${id}`);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getTopicsApi = async ({
    area_id,
    category_id,
    sub_category_id,
    thread_id,
    gender,
    age_id,
    user_status,
    last_doc_id,
    per_page,
}) => {
    try {
        const res = await apiAuth.get('/topics', {
            params: {
                area_id,
                category_id,
                sub_category_id,
                thread_id,
                gender,
                age_id,
                user_status,
                last_doc_id,
                per_page,
            },
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getHistoryApi = async (type, last_doc_id) => {
    try {
        const res = await apiAuth.get('/history', {
            params: {type, last_doc_id},
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const getReplyListApi = async (topicId, type, last_doc_id) => {
    try {
        const url = `/topics/${topicId}/reply-list`;

        const res = await apiAuth.get(url, {
            params: {type, last_doc_id},
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

// chat
export const createRoomChatApi = async ({
    room_type_id,
    topic_id,
    user_id,
    is_anonymous,
}) => {
    try {
        const res = await apiAuth.post('/rooms', {
            room_type_id,
            topic_id,
            user_id,
            is_anonymous,
        });
        return res;
    } catch (e) {
        return {error: e.meesage};
    }
};

// chat1
export const createRoomChatApi1 = async ({
    id,
    room_type_id,
    topic_id,
    user_id,
    is_anonymous,
}) => {
    try {
        const res = await apiAuth.post('/rooms/3charactor', {
            id,
            room_type_id,
            topic_id,
            user_id,
            is_anonymous,
        });
        return res;
    } catch (e) {
        return {error: e.meesage};
    }
};

export const requestBonusChatApi = async (roomId) => {
    try {
        const res = await apiAuth.post(`/rooms/${roomId}/bonuschat`);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const confirmBonusChatApi = async (roomId, status) => {
    try {
        const res = await apiAuth.post(`/rooms/${roomId}/confirmbonuschat`, {
            status,
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const deleteMessagesApi = async (msgIds) => {
    try {
        const res = await apiAuth.post('/messages/delete', {
            ids: msgIds,
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const deleteMessageApi = async (messageId) => {
    try {
        const res = await apiAuth.delete(`/messages/${messageId}`);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const updateUnreadMessageNumberApi = async (unread) => {
    try {
        const res = await apiAuth.put('/users/unread', {unread});
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const createHistoryApi = async (room_id, latest_message) => {
    try {
        const res = await apiAuth.post('/history', {
            room_id,
            latest_message,
        });
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const updateRoomReplied = async (room_id) => {
    try {
        const res = await apiAuth.put(`/rooms/${room_id}/replied`);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const updateShowBonusStatus = async (room_id) => {
    try {
        const res = await apiAuth.put(`/rooms/${room_id}/showBonusStatus`);
        return res;
    } catch (e) {
        return {error: e.message};
    }
};

export const reportUserApi = async (email, text) => {
    try {
        const res = await apiAuth.put('/users/user_report', {
            email: email,
            report: text
        });
        return res;
    }catch(e){
        return { err: e.message };
    }
}