import {get, put} from '../base/portal/apis';

export function getMyProfile(token) {
    let url = '/api/v1/users/profile';
    return get(url, token);
}

export function getAge() {
    let url = '/api/v1/age';
    return get(url, '');
}

export function getAreas() {
    let url = '/api/v1/areas';
    return get(url, '');
}

export function changeUsername(token, username) {
    let url = '/api/v1/users/change_user_name';
    let data = {user_name: username};
    return put(url, token, data);
}

export function changeProfile(
    token,
    user_name,
    age,
    area,
    gender,
    type,
    profile_image_data,
) {
    let url = '/api/v1/users';
    let data = {user_name, age, area, gender, type, profile_image_data};
    return put(url, token, data);
}
