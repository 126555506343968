import {get, post, deleteApi, put} from '../base/portal/apis';

export function getFriendList(token, lastId) {
    let url = '/api/v1/users/friends';
    if (lastId !== null) {
        url = url + '?last_doc_id=' + lastId;
    }
    return get(url, token);
}

export function getFriendInfo(token, id) {
    let url = '/api/v1/users/' + id;
    return get(url, token);
}

export function getTopicInfo(token, id) {
    let url = '/api/v1/topics/' + id;
    return get(url, token);
}

export function getRoomInfo(token, id) {
    let url = '/api/v1/rooms/' + id;
    return get(url, token);
}

export function blockFriend(token, id) {
    let url = '/api/v1/users/block';
    let data = {id};
    return post(url, token, data);
}

export function deleteFriend(token, userID) {
    let url = '/api/v1/users/friends';
    let data = {id: userID};
    return deleteApi(url, token, data);
}