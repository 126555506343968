import firebase from 'firebase';
import {auth, db, database} from '../base/firebase/firebase';
import {changeRoute, openPopup, setNaviIndex} from '../app';
import {getDeviceType, getUniqueDeviceId} from '../../util/device';
import Notification from '../base/notification';
import {isNative} from '../../util/device';
import {persistKeys} from '../../util/Constants';
import Storage from '../../util/storage';
import {
    getFilterHistoryApi,
    setAccessToken,
    getUserProfileApi,
    loginApi,
    addUserApi,
    getUserDeviceApi,
    logoutApi,
    getAreaApi,
    getCategoryApi,
    getSubCategoryAllApi,
} from '../base/api';
import {httpRequest} from '../base/api/actions';
import {userStatus} from './contants';
import {
    LOGGED_IN,
    UPDATE_FILTER_HISTORY,
    UPDATE_MY_INFO,
    CLEAR_MY_DATA,
    CLEAR_MY_DATA_TEMP,
    UPDATE_DEVICE_ID,
    SPLASH_GET_AREAS,
    SPLAH_GET_CATEGORIES,
    SPLASH_GET_SUBCATEGORIES,
} from './actionTypes';
import {closeChatScreen, updateLatestRooms} from '../app/actions';
import * as webrtc from '../base/webrtc';
import { AREA_LOCAL_STORE, CATEGORY_LOCAL_STORE, SUBCATEGORY_LOCAL_STORE } from "../app/constants";

export function loggedIn(status) {
    return {
        type: LOGGED_IN,
        status,
    };
}

export function updateMyInfo(data) {
    return {
        type: UPDATE_MY_INFO,
        data,
    };
}

export function updateDeviceId(deviceId) {
    return {
        type: UPDATE_DEVICE_ID,
        deviceId,
    };
}

export function updateFilterHistory(data) {
    return {
        type: UPDATE_FILTER_HISTORY,
        data,
    };
}

export function clearMyData(flag=false) {
    if(flag)
        return {
            type: CLEAR_MY_DATA_TEMP,
        };
    return {
        type: CLEAR_MY_DATA,
    };
}

export function login(email, password) {
    return async function (dispatch, getState) {
        const deviceType = getDeviceType();
        const deviceId = getUniqueDeviceId();
        let fcmToken = Notification.fcmToken;
        // sometime fcm token return late, retry to get...
        if (!fcmToken && Notification.getFcmToken) {
            fcmToken = await Notification.getFcmToken();
        }
        const result = await dispatch(
            httpRequest(
                loginApi({
                    email,
                    password,
                    fcm_token: fcmToken,
                    voip_token: Notification.voipToken,
                    device_type: deviceType,
                    device_id: deviceId,
                }),
                true,
            ),
        );
        
        if (result?.token_firebase) {
            await auth.signInWithCustomToken(result.token_firebase);
        }
        if (result?.access_token) {
            setAccessToken(result.access_token);
            const userInfo = {...result, device_id: deviceId};
            dispatch(updateDeviceId(deviceId));
            dispatch(updateMyInfo(userInfo));
            dispatch(loggedIn(true));

            await Storage.multiSet({
                [persistKeys.USER_INFO]: userInfo,
                [persistKeys.DEVICE_ID]: deviceId,
            });
        }
        return result;
    };
}

export function logout(callLogoutApi = true, goToflag = false) {
    
    return async function (dispatch, getState) {
        webrtc.destroyAll();
        if (callLogoutApi) {
            if (unsubscribeListenUserLoggedInOtherDevice) {
                unsubscribeListenUserLoggedInOtherDevice();
            }
            await dispatch(httpRequest(logoutApi(), true));
        }

        await Storage.multiRemove([
            persistKeys.USER_INFO,
            persistKeys.DEVICE_ID,
            // TODO: remove old log chats
            persistKeys.CHATS,
        ]);
        dispatch(clearMyData(goToflag));
        if (isNative) {
            dispatch(changeRoute('LOGIN'));
        }
        dispatch(setNaviIndex('home'));

        clearSubscribeListenUserLogin();
        if (!callLogoutApi) {
            dispatch(closeChatScreen());
            dispatch(
                openPopup(
                    'ユーザー情報を取得できませんでした。\n再度ログインしてください。',
                ),
            );
        }
    };
}
export function addUser() {
    return async function (dispatch, getState) {
        const {userInfo} = getState()['cocoro/user'];
        const result = await dispatch(httpRequest(addUserApi()));
        if (result) {
            dispatch(
                openPopup(
                    `キャラクター追加されました。
                    再度ログインしなければ、します。`,
                ),
            );
        }
        return result;
    };
}

export let unsubscribeListenUserLoggedInOtherDevice = null;

export function clearSubscribeListenUserLogin() {
    unsubscribeListenUserLoggedInOtherDevice = null;
}

export function checkLoggedInOtherDevice() {
    return async function (dispatch, getState) {
        const {deviceId} = getState()['cocoro/user'];
        const result = await dispatch(httpRequest(getUserDeviceApi()));
        if (deviceId && result?.device_id && result.device_id !== deviceId) {
            dispatch(logout(false));
        }
    };
}

export function listenLoggedInOtherDevice() {
    return function (dispatch, getState) {
        const {userInfo, deviceId} = getState()['cocoro/user'];
        let ignoreFirstQuery = false;
        const handler = (doc) => {
            if (ignoreFirstQuery) {
                const data = doc.data();
                if (
                    deviceId &&
                    data?.device_id &&
                    deviceId !== data.device_id
                ) {
                    dispatch(logout(false));
                }
            } else {
                ignoreFirstQuery = true;
            }
        };
        unsubscribeListenUserLoggedInOtherDevice = db
            .collection('devices')
            .doc(userInfo.id)
            .onSnapshot(handler);
        return unsubscribeListenUserLoggedInOtherDevice;
    };
}

export function getMyFilter() {
    return async function (dispatch, getState) {
        const result = await dispatch(httpRequest(getFilterHistoryApi()));
        if (result) {
            dispatch(updateFilterHistory(result));
        }
        return result;
    };
}

export function getMyProfile() {
    return async function (dispatch, getState) {
        const {userInfo} = getState()['cocoro/user'];
        const result = await dispatch(httpRequest(getUserProfileApi()));
        if (result) {
            Storage.set(persistKeys.USER_INFO, {...userInfo, ...result});
            dispatch(updateMyInfo(result));
        }
        return result;
    };
}

export function updateMyProfile(data) {
    return function (dispatch, getState) {
        const {userInfo} = getState()['cocoro/user'];
        dispatch(updateMyInfo({...userInfo, ...data}));
        Storage.set(persistKeys.USER_INFO, {...userInfo, ...data});
    };
}

export function subcribeUserStatus() {
    return function (dispatch, getState) {
        const {userInfo} = getState()['cocoro/user'];
        if (userInfo) {
            const uid = userInfo.id;
            // Create a reference to this user's specific status node.
            // This is where we will store data about being online/offline.
            const userStatusDatabaseRef = database.ref('/status/' + uid);

            const userStatusFirestoreRef = db.doc('/user_status/' + uid);

            // Firestore uses a different server timestamp value, so we'll
            // create two more constants for Firestore state.
            const offlineForFirestore = {
                state: userStatus.OFFLINE,
                last_changed: firebase.firestore.FieldValue.serverTimestamp(),
            };

            const onlineForFirestore = {
                state: userStatus.ONLINE,
                last_changed: firebase.firestore.FieldValue.serverTimestamp(),
            };

            const offlineForDatabase = {
                state: 'offline',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };

            const onlineForDatabase = {
                state: 'online',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };

            const handler = (snapshot) => {
                if (snapshot.val() == false) {
                    // Instead of simply returning, we'll also set Firestore's state
                    // to 'offline'. This ensures that our Firestore cache is aware
                    // of the switch to 'offline.'
                    userStatusFirestoreRef.set(offlineForFirestore);
                    return;
                }

                userStatusDatabaseRef
                    .onDisconnect()
                    .set(offlineForDatabase)
                    .then(function () {
                        userStatusDatabaseRef.set(onlineForDatabase);

                        // We'll also add Firestore set here for when we come online.
                        userStatusFirestoreRef.set(onlineForFirestore);
                    });
            };

            database.ref('.info/connected').on('value', handler);

            return () => database.ref('.info/connected').off('value', handler);
        } else {
            return () => null;
        }
    };
}

export function updateUserStatus(online = true) {
    return function (dispatch, getState) {
        const {userInfo} = getState()['cocoro/user'];
        if (userInfo?.id) {
            const uid = userInfo.id;

            const offlineForFirestore = {
                state: userStatus.OFFLINE,
                last_changed: firebase.firestore.FieldValue.serverTimestamp(),
            };

            const onlineForFirestore = {
                state: userStatus.ONLINE,
                last_changed: firebase.firestore.FieldValue.serverTimestamp(),
            };

            const userStatusFirestoreRef = db.doc('/user_status/' + uid);
            if (online) {
                userStatusFirestoreRef.set(onlineForFirestore);
            } else {
                userStatusFirestoreRef.set(offlineForFirestore);
            }
        }
    };
}

export function updateUserCoin(coin) {
    let a = localStorage.getItem('user_info');
    try {
        a = JSON.parse(a);
    }catch(e){

    }
    a.userCoin = coin;
    localStorage.setItem( 'user_info' ,JSON.stringify(a));

    return {
        type : "UPDATE_USER_COIN",
        data: coin,
    }
}

export function getAreas(areas) {
    return {
        type: SPLASH_GET_AREAS,
        data: areas,
    };
}

export function getAreasSplash(){
    return async function (dispatch, getState) {
        let data = await dispatch(httpRequest(getAreaApi(), false));
        if (data) {
            console.log("getAreasSplash");
            localStorage.setItem(AREA_LOCAL_STORE, JSON.stringify(data))
            dispatch(getAreas(data));
        }
    };
}

export function getCategories(categories) {
    return {
        type: SPLAH_GET_CATEGORIES,
        data: categories,
    };
}

export function getCategoriesSplash(){
    return async function (dispatch, getState) {
        const data = await dispatch(httpRequest(getCategoryApi(), false));
        if (data) {
            localStorage.setItem(CATEGORY_LOCAL_STORE, JSON.stringify(data))
            dispatch(getCategories(data));
        }
    };
}

export function getSubCategories(subcategories) {
    return {
        type: SPLASH_GET_SUBCATEGORIES,
        data: subcategories,
    };
}

export function getSubCategorySplash() {
    return async function (dispatch, getState) {
        const data = await dispatch(
            httpRequest(getSubCategoryAllApi(), false),
        );
        if (data) {
            localStorage.setItem(SUBCATEGORY_LOCAL_STORE, JSON.stringify(data))
            dispatch(getSubCategories(data));
        }
    };
}