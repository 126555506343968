import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {updateSmallStatus, endCall} from '../actions';
import {formatPrice} from '../../chat/functions';
import {openPopup} from '../../app/actions';
import {callStates} from '../constants';
import {Constants} from '../../../util/Constants';
import { ChatBtn, clearOldMsg, createRoomChat  } from "../../chat";
import {
    ROOM_TYPE_CHAT_FRIEND,
    ROOM_TYPE_CHAT_TOPIC,
} from '../../call/constants';
import {CREATE_NEW_ROOM_ID} from '../../app/constants';
import {updateRoomInfo} from '../../chat';
import * as webrtc from '../../base/webrtc';
import { useHistory } from "react-router-dom";
import { CALL_ID_TEMP } from "../../app/constants";

const Caller = ({roomDetail, userInfo, callState, roomChatId, isSmall}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [mic, setMic] = useState(true);

    const [isFirst, setIsFirst] = useState(true);

    useEffect(()=>{
        // if(isFirst){
            // setIsFirst(false);
            localStorage.setItem(CALL_ID_TEMP, roomChatId);
        // }
    })

    const handleToggleMic = () => {
        
        if (roomDetail?.room_id && userInfo?.user_id) {
            const conn = webrtc.getConnection(
                userInfo?.user_id,
                roomDetail?.room_id,
            );
            if (conn) {
                const status = conn.toggleAudioTrack();
                setMic(status);
            }
        }
    };

    const handleEndCall = () => {
        if (userInfo?.user_id && roomDetail?.room_id) {
            dispatch(endCall(userInfo?.user_id, roomDetail?.room_id, true));
        }
        dispatch(endCall(userInfo?.user_id1, roomDetail?.room_id, true));
        dispatch(endCall(userInfo?.user_id2, roomDetail?.room_id, true));
        dispatch(endCall(userInfo?.user_id3, roomDetail?.room_id, true));
        dispatch(endCall(userInfo?.user_id4, roomDetail?.room_id, true));
        dispatch(endCall(userInfo?.user_id5, roomDetail?.room_id, true));
        dispatch(endCall(userInfo?.user_id6, roomDetail?.room_id, true));
        dispatch(endCall(userInfo?.user_id7, roomDetail?.room_id, true));
        dispatch(endCall(userInfo?.user_id8, roomDetail?.room_id, true));
        dispatch(endCall(userInfo?.user_id9, roomDetail?.room_id, true));
        dispatch(endCall(userInfo?.user_id10, roomDetail?.room_id, true));

        // for(var i=1;i<=10;i++)
        // {
        //     dispatch(endCall(userInfo?.user_id1, roomDetail?.room_id, true));
        // }
    };

    const handleUpdateSmallStatus = () => {
        dispatch(updateSmallStatus(true));
    };

    const handleToggleSpeak = () => {
        dispatch(openPopup('この機能は、ウェブ版には対応しません。'));
    };


    const handleAgreeChat =  async () => {
        const path = history.location.pathname;
        if(path.search('/chat/') != -1){
            setTimeout((path)=>{
                history.push(path, { from: 'whitescreen' });
                console.log('time out');
            }, 500, path);
            history.push('/whitescreen');
        }else{
            const result = await dispatch(
                createRoomChat({
                    id: '0',
                    user_id: userInfo?.user_id,
                    room_type_id: ROOM_TYPE_CHAT_TOPIC,
                    topic_id: roomDetail?.topicInfo?.id,
                    is_anonymous: roomDetail?.topicInfo?.is_anonymous,
                }),
            );
            if (result?.id) {
                history.push('/chat/' + result.id);
            }
        }
    };

    const handleAgreeChatFriend =  async () => {
        const path = history.location.pathname;
        if(path.search('/chat/') != -1){
            setTimeout((path)=>{
                history.push(path, { from: 'whitescreen' });
                console.log('time out');
            }, 500, path);
            history.push('/whitescreen');
        }else{
            await history.push('/chat/' + roomChatId);
        }
    };

    const handleAgreePrssent = async () => {
        const result = await dispatch(
            createRoomChat({
                id: '0',
                user_id: userInfo?.user_id,
                room_type_id: ROOM_TYPE_CHAT_TOPIC,
                topic_id: roomDetail?.topicInfo?.id,
                is_anonymous: roomDetail?.topicInfo?.is_anonymous,
            }),
        );
        if (result?.id) {
            // dispatch(updateRoomInfo(result));
            // dispatch(clearOldMsg());
            console.log("present================================",result.id);
            history.push('/present/' + result.id);
        }
    };

    const handleAgreeTrumpet = async () => {
        const result = await dispatch(
            createRoomChat({
                id: '0',
                user_id: userInfo?.user_id,
                room_type_id: ROOM_TYPE_CHAT_TOPIC,
                topic_id: roomDetail?.topicInfo?.id,
                is_anonymous: roomDetail?.topicInfo?.is_anonymous,
            }),
        );
        if (result?.id) {
            // dispatch(updateRoomInfo(result));
            // dispatch(clearOldMsg());
            console.log("trumpet================================",result.id);
            history.push('/trumpet/' + result.id);
        }
    };

    const handleCallMessage = async () => {
        await dispatch(updateSmallStatus(true));
        await dispatch(clearOldMsg());
        if(!userInfo?.is_anonymous)
            handleAgreeChatFriend();
        else{
            handleAgreeChat();
        }
    }

    const handlePresent = () => {
        dispatch(updateSmallStatus(true));
        dispatch(clearOldMsg());
        if(!userInfo?.is_anonymous){
            history.push('/present/' + roomChatId, { url: history.location });
        }else{
            handleAgreePrssent();
        }
    }

    const handleTrumpet = () => {
        dispatch(updateSmallStatus(true));
        if(!userInfo?.is_anonymous){
            history.push('/trumpet/' + roomChatId);
        }else{
            handleAgreeTrumpet();
        }
    }

    var audio = new Audio();

    useEffect(()=>{
            // audio = new Audio('/audio/incallmanager_ringback.mp3');
            // audio.loop = true;
            // audio.play();
            // dispatch(openPopup('着信を待っています。着信してメッセージを再生しています。'));
    }, []);

    useEffect(() => {
        return () => {
            audio.pause();
            audio = new Audio();
        }
    }, [])

    return (
        <div id="callerWrap">
            {/* <img
                onClick={handleUpdateSmallStatus}
                className="zoom"
                src={ require("../../../images/back.png") }
            /> */}
            
            <img
                onClick={handleCallMessage}
                className="call-message"
                src={ require("../../../images/call_message.png") }
            />

            <img
                onClick={handlePresent}
                className="present"
                src={ "/images/present.png" }
            />
            <img
                className="present-decorate"
                src={ "/images/decorate_right.png" }
            />
            <img
                onClick={handleTrumpet}
                className="trumpet"
                src={ "/images/trumpet.png" }
            />
            <img
                className="trumpet-decorate"
                src={ "/images/decorate_left.png" }
            />
            <header>
                <p>トーク中</p>
            </header>
            {/* <audio loop autoPlay src={'/audio/incallmanager_ringback.mp3'} /> */}
            <main>
                <div className="price">
                    {userInfo &&
                        roomDetail?.topicInfo &&
                        userInfo?.price !== 0 &&
                        roomDetail?.user_id !== roomDetail?.topicInfo?.user_id &&
                        roomDetail?.topicInfo?.purchase_on_start && (
                            <span>
                                課金開始: {userInfo?.time}分
                                {formatPrice(userInfo?.price)}ポイント
                            </span>
                        )}
                </div>
                <div className="image">
                    <img
                        src={
                            userInfo?.profile_image
                                ? userInfo?.profile_image
                                : Constants.AVATAR_DEFAULT
                        }
                        alt=""
                    />
                </div>
                <div className="info" style={{color: 'black'}}>
                    <p className="name">
                        {userInfo?.is_anonymous
                            ? userInfo?.user_name
                            : userInfo?.user_name}
                        {callState === callStates.BUSY &&
                            userInfo?.is_anonymous &&
                            'が話し中です。'}
                        {callState === callStates.BUSY &&
                            Boolean(userInfo?.is_anonymous) === false &&
                            'さんが話し中です。'}
                        {callState === callStates.REFUSE &&
                            userInfo?.is_anonymous &&
                            'は電話に出ることができません。'}
                        {callState === callStates.REFUSE &&
                            Boolean(userInfo?.is_anonymous) === false &&
                            'さんは電話に出ることができません。'}
                    </p>
                    <p className="infoDetail">
                        {userInfo && (userInfo?.area)}
                        {userInfo && parseInt(userInfo.gender) == 1 ? ' | 男性（M）' : ''}
                        {userInfo && parseInt(userInfo.gender) == 2 ? ' | 女性（F）' : ''}
                        {userInfo && parseInt(userInfo.gender) == 3 ? ' | 不特定（X）' : ''}
                        {userInfo && (' | ' + userInfo?.age)}
                    </p>
                    <p className="detail">{userInfo?.comment}</p>
                </div>
                <div className="btnSpecial">
                    {mic ? (
                        <div>
                            <img
                                onClick={handleToggleMic}
                                src="/images/icon-micro-off.png"
                            />
                            <br />
                            <span>ミュート</span>
                        </div>
                    ) : (
                        <div>
                            <img
                                onClick={handleToggleMic}
                                src="/images/icon-micro-on.png"
                            />
                            <br />
                            <span></span>
                        </div>
                    )}

                    <div>
                        <img
                            onClick={handleToggleSpeak}
                            src="/images/icon-speaker-on.png"
                        />
                        <br />
                        <span></span>
                    </div>
                </div>
                <div className="cancelBtn">
                    <span onClick={handleEndCall}>トーク終了</span>
                </div>
            </main>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    userInfo: state['cocoro/call'].userInfo,
    roomDetail: state['cocoro/call'].roomDetail,
    callState: state['cocoro/call'].callState,
    roomChatId: state['cocoro/call'].roomChatId,
    isSmall: state['cocoro/call'].isSmall,
});

export default connect(_mapStateToProps)(Caller);
