const {getTimeServerApi} = require('../api');

class Clock {
    constructor() {
        this.time = new Date().getTime();
        this.diff = 0;
        this.intervalSecondsSyncServer = 30;
        this.intervalSyncServer = null;
    }

    run() {
        this.syncWithServer();
    }

    syncWithServer() {
        const sync = async () => {
            const res = await getTimeServerApi();
            if (res?.data) {
                this.diff = res.data - new Date().getTime();
            }
        };

        this.intervalSyncServer = setInterval(
            sync,
            this.intervalSecondsSyncServer * 1000,
        );

        sync();
    }

    // timestamp
    now() {
        return new Date().getTime() + this.diff;
    }

    date() {
        return new Date(this.now());
    }

    seconds() {
        return (new Date().getTime() + this.diff) / 1000;
    }
}

const clock = new Clock();
export default clock;
