import React, {useState, useEffect, Button, Link} from 'react';
import {Text, View,} from 'react-native';
import {useRouteMatch} from 'react-router-dom';
import {updateImageUri} from '../actions';
import {connect, useDispatch} from 'react-redux';
import '../avatar.scss';
import {Slider} from "@material-ui/core";
import {Constants} from "../../../util/Constants";
import { updateUser } from "../actions";

const SettingSoundChange = ({history}) => {
    const {url} = useRouteMatch();

    const [slide1, setSlide1] = useState(30);
    const [slide2, setSlide2] = useState(50);
    const [slide3, setSlide3] = useState(40);

    const goToSettingBlood = () =>{
        let userInfoStorage = localStorage.getItem('user_info');
        try{
            userInfoStorage = JSON.parse(userInfoStorage);
        }catch(e){
            userInfoStorage = {}
        }

        userInfoStorage.userSoundSettings = {
            'setting1': slide1,
            'setting2': slide2,
            'setting3': slide3
        }

        localStorage.setItem('user_info', JSON.stringify(userInfoStorage))
        
        history.push({
            pathname: '/registration-information/blood-setting',
        });
    }

   

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.txt}>
                        ボ イ ス チ ェ ン ジ
                    </Text>
                    <Text style={styles.txt}/>
                </View>
            </View>

            <View style={styles.slideView}>
                <Slider value={slide1} aria-label="Default" valueLabelDisplay="auto" color={"secondary"}
                        onChange={(e, val) => {
                            setSlide1(val)
                        }}/>
            </View>
            <View style={styles.slideView}>
                <Slider value={slide2} aria-label="Default" valueLabelDisplay="auto" color={"primary"}
                    onChange={(e, val) => {
                        setSlide2(val)
                    }}/>
            </View>
            <View style={styles.slideView}>
                <Slider value={slide3} aria-label="Default" valueLabelDisplay="auto" color={"secondary"}
                    onChange={(e, val) => {
                        setSlide3(val)
                    }}/>
            </View>

            <View style={styles.warpButton}>
                <button
                    style={styles.ButtonSubmit} onClick={goToSettingBlood}>
                    つぎへ
                </button>
            </View>
        </View>
    );
};
const styles = {
    container: {
        flex: 1,
        backgroundColor: '#d6f27e',
        height: '667px',
        width: '375px',
        padding: '30px',
    },
    header: {
        alignItems: 'center',
        height: 180,
        paddingBottom: 30,
        flexDirection: 'column-reverse',
    },
    txt: {
        color: '#211e1e',
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    warpButton: {
        width: '100%',
        marginTop: 70,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 35,
    },
    slideView: {
        marginVertical: 10
    },
    ButtonSubmit: {
        width: '50%',
        padding: 5,
        fontWeight: 'bold',
        backgroundColor: '#FFE033',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#DBBD16',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 4,
        borderBottomColor: '#DBBD16',
        borderRadius: 10,
        height: 50,
        cursor: 'pointer'
    },
    textButton: {
        fontSize: 18,
        color: '#36383B',
        fontWeight: 'bold',
    },
};

function _mapStateToProps(state) {
    return {
        imageUri: state['cocoro/register'].imageUri,
    };
}

let _mapDispatchToProps = {
    updateImageUri,
};
export default connect(_mapStateToProps, _mapDispatchToProps)(SettingSoundChange);

