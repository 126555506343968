import {URL_API} from '../../env.json';
import {get, put} from '../base/portal/apis';
const FormData = require('form-data');
const axios = require('axios');

export function heartClickApi(thread_id, author_id, token){
    return new Promise(function (resolve, reject) {
        const apiURL = URL_API + '/api/v1/topics/updateHeart';
        const header = {Authorization: 'Bearer ' + token};
        const data = {
            threadId: thread_id,
            heartUserId: author_id
        };
        if (thread_id) {
            axios({
                method: 'PATCH',
                url: apiURL,
                headers: header,
                data: data,
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    if (error.response && error.response.status) {
                        resolve({
                            status: error.response.status,
                            message: error.response
                                ? error.response.data.message
                                : '',
                            errors: error.response.data,
                        });
                    } else {
                        resolve({
                            status: 404,
                            message: error.response
                                ? error.response.data.message
                                : '',
                        });
                    }
                });
        }
    });
}

export function exploreClickApi(thread_id, author_id, token){
    return new Promise(function (resolve, reject) {
        const apiURL = URL_API + '/api/v1/topics/updateClick';
        const header = {Authorization: 'Bearer ' + token};
        const data = {
            threadId: thread_id,
            clickUserId: author_id
        };
        if (thread_id) {
            axios({
                method: 'PATCH',
                url: apiURL,
                headers: header,
                data: data,
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    if (error.response && error.response.status) {
                        resolve({
                            status: error.response.status,
                            message: error.response
                                ? error.response.data.message
                                : '',
                            errors: error.response.data,
                        });
                    } else {
                        resolve({
                            status: 404,
                            message: error.response
                                ? error.response.data.message
                                : '',
                        });
                    }
                });
        }
    });
}


export function getSubCategory(token, id) {
    let url = '/api/v1/subCategories/' + id;
    return get(url, token);
}

export function checkIsFriend(userId, token) {
    let url = '/api/v1/users/isfriend/' + userId;
    return get(url, token);
}

export function updateFistLogin(token) {
    return new Promise(function (resolve, reject) {
        const apiURL = URL_API + '/api/v1/users';
        const header = {Authorization: 'Bearer ' + token};
        const form = new FormData();
        form.append('is_first_login', false);
        axios({
            method: 'PUT',
            url: apiURL,
            headers: header,
            data: form,
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export function createTopic(
    thread_id,
    title,
    detail,
    sub_category_id,
    is_callable,
    is_anonymous,
    chat_price,
    call_price,
    purchase_type,
    comment,
    expire_after_minute,
    is_not_free,
    token,
) {
    return new Promise(function (resolve, reject) {
        const apiURL = URL_API + '/api/v1/topics/';
        const header = {Authorization: 'Bearer ' + token};
        const data = {
            title,
            detail,
            sub_category_id,
            is_callable,
            is_anonymous,
            chat_price,
            call_price,
            purchase_type,
            comment,
            expire_after_minute,
            is_not_free,
        };
        if (thread_id) {
            axios({
                method: 'POST',
                url: apiURL + thread_id,
                headers: header,
                data: data,
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    if (error.response && error.response.status) {
                        resolve({
                            status: error.response.status,
                            message: error.response
                                ? error.response.data.message
                                : '',
                            errors: error.response.data,
                        });
                    } else {
                        resolve({
                            status: 404,
                            message: error.response
                                ? error.response.data.message
                                : '',
                        });
                    }
                });
        }
    });
}

export function getUserInfo(token) {
    return new Promise(function (resolve, reject) {
        const apiURL = URL_API + '/api/v1/users/profile';
        const header = {Authorization: 'Bearer ' + token};
        const form = new FormData();

        axios({
            method: 'GET',
            url: apiURL,
            headers: header,
            data: form,
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error.response && error.response.status) {
                    resolve({
                        status: error.response.status,
                        message: error.response
                            ? error.response.data.message
                            : '',
                    });
                } else {
                    resolve({
                        status: 404,
                        message: error.response
                            ? error.response.data.message
                            : '',
                    });
                }
            });
    });
}

export function saveFilterApi(
    area_id,
    category_id,
    sub_category_id,
    latest_joined_thread,
    user_status,
    gender,
    age_id,
    token,
) {
    return new Promise(function (resolve, reject) {
        const apiURL = URL_API + '/api/v1/users/filter';
        const header = {Authorization: 'Bearer ' + token};
        axios({
            method: 'POST',
            url: apiURL,
            headers: header,
            data: {
                latest_joined_thread,
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export function getThreadByIdApi(token, id) {
    return new Promise(function (resolve, reject) {
        const apiURL = URL_API + '/api/v1/threads/';
        const header = {Authorization: 'Bearer ' + token};
        const form = new FormData();

        axios({
            method: 'GET',
            url: apiURL + id,
            headers: header,
            data: form,
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error.response && error.response.status) {
                    resolve({
                        status: error.response.status,
                        message: error.response
                            ? error.response.data.message
                            : '',
                    });
                } else {
                    resolve({
                        status: 404,
                        message: error.response
                            ? error.response.data.message
                            : '',
                    });
                }
            });
    });
}

export function getMyTopicApi(thread_id, token) {
    return new Promise(function (resolve, reject) {
        const apiURL = URL_API + '/api/v1/users/topics';
        const header = {Authorization: 'Bearer ' + token};
        axios({
            method: 'GET',
            url: apiURL,
            headers: header,
            params: {
                thread_id: thread_id,
            },
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject({error: error.message});
            });
    });
}
export function getAreas() {
    let url = '/api/v1/areas';
    return get(url, '');
}