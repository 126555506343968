/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useMemo, useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import '../styles.scss';
import {
    clearRoomInfo,
    initChatData,
    resendMessageSentFail,
    subscribeChatConnection,
    sendChat,
} from '../actions';
import {useHistory, useLocation} from 'react-router-dom';
import Header from './HeaderTrumpet.web';
import {Modal, openActionPopup, clearUnreadCount} from '../../app';
import {
    messageStatusTexts,
    messageTypeIds,
} from '../constants';
import $ from 'jquery';
import chatdb from '../chatdb.web';
import { useTopicExpired } from '../../homescreen/hooks';

function Trumpet({roomInfo, userInfo, messages, roomDetail}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {pathname} = useLocation();
    const roomId = pathname.replace('/trumpet/', '');
    const [userid, setUserID] = useState('');
    const [selectButtonIndex, setSelectButtonIndex] = useState(0);

    useEffect(() => {
        dispatch(clearUnreadCount(roomInfo.id));
        chatdb.updateAllMessageRoomIsRead(roomInfo.id);
    }, [messages]);

    useEffect(() => {
        let unsub = null;
        const userInfo_id = roomDetail?.incoming_id === undefined ? userInfo?.id : roomDetail?.incoming_id;
        setUserID(userInfo_id);

        const initialize = async () => {
            const result = dispatch(initChatData(roomId, userInfo_id));
            if (result.errors) {
                dispatch(
                    openActionPopup(result.errors, () => {
                        history.goBack();
                    }),
                );
            }
            // result = roomInfo
            unsub = dispatch(
                subscribeChatConnection(
                    result?.id,
                    userInfo.id,
                    result?.userInfo?.id,
                ),
            );
        };

        if (roomId && userInfo.id) {
            initialize();
            return () => (unsub ? unsub() : null);
        }
    }, []);

    useEffect(() => {
        if (roomInfo.deleted === true) {
            dispatch(
                openActionPopup(
                    '該当する声がありません。既に削除されている可能性があります。',
                    () => {
                        history.goBack();
                    },
                ),
            );
        }
    }, [roomInfo]);

    useEffect(() => {
        return () => dispatch(clearRoomInfo());
    }, []);

    const firstRun = useRef(false);
    useEffect(() => {
        if (messages.length > 0 && firstRun.current === false) {
            firstRun.current = true;
            resendMessageFail();
        }
    }, [messages]);

    const scroll = () => {
        if ($('#chat-content').length != 0) {
            $('#chat-content').animate(
                {scrollTop: $('#chat-content')[0].scrollHeight},
                1,
            );
        }
    };

    const resendMessageFail = () => {
        const msgs = messages.filter((m) => {
            return (
                m.user_id === userInfo.id &&
                m.status === messageStatusTexts.SENT_FAIL
            );
        });
        if (msgs.length > 0) {
            dispatch(resendMessageSentFail(msgs));
        }
    };

    useEffect(() => {
        scroll();
    }, [messages]);


    useTopicExpired(roomInfo.topic, null, history);

    const [ trumpetImage, setTrumpetImage ] = useState(false);
    const [ showCoin, setShowCoin ] = useState(userInfo?.userCoin);

    const IMAGE_URL = [[
        "/images/trumpet/01.png",
        "/images/trumpet/02.png",
        "/images/trumpet/03.png",
        "/images/trumpet/04.png",
        "/images/trumpet/05.png",
        "/images/trumpet/06.png",
        "/images/trumpet/07.png",
        "/images/trumpet/08.png",
    ],[
        "/images/trumpet/09.png",
        "/images/trumpet/10.png",
        "/images/trumpet/11.png",
        "/images/trumpet/12.png",
        "/images/trumpet/13.png",
        "/images/trumpet/14.png",
        "/images/trumpet/15.png",
        "/images/trumpet/16.png",
    ],[
        "/images/trumpet/17.png",
        "/images/trumpet/18.png",
        "/images/trumpet/19.png",
        "/images/trumpet/20.png",
        "/images/trumpet/21.png",
        "/images/trumpet/22.png",
        "/images/trumpet/23.png",
        "/images/trumpet/24.png",
    ]];

    let MUSIC_URL = [[
        "/images/trumpet/mp3/1/1.mp3",
        "/images/trumpet/mp3/1/2.mp3",
        "/images/trumpet/mp3/1/3.mp3",
        "/images/trumpet/mp3/1/4.mp3",
        "/images/trumpet/mp3/1/5.mp3",
        "/images/trumpet/mp3/1/6.mp3",
        "/images/trumpet/mp3/1/7.mp3",
        "/images/trumpet/mp3/1/8.mp3",
    ],[
        "/images/trumpet/mp3/2/1.mp3",
        "/images/trumpet/mp3/2/2.mp3",
        "/images/trumpet/mp3/2/3.mp3",
        "/images/trumpet/mp3/2/4.mp3",
        "/images/trumpet/mp3/2/5.mp3",
        "/images/trumpet/mp3/2/6.mp3",
        "/images/trumpet/mp3/2/7.mp3",
        "/images/trumpet/mp3/2/8.mp3",
    ],[
        "/images/trumpet/mp3/3/1.mp3",
        "/images/trumpet/mp3/3/2.mp3",
        "/images/trumpet/mp3/3/3.mp3",
        "/images/trumpet/mp3/3/4.mp3",
        "/images/trumpet/mp3/3/5.mp3",
        "/images/trumpet/mp3/3/6.mp3",
        "/images/trumpet/mp3/3/7.mp3",
        "/images/trumpet/mp3/3/8.mp3",
    ]];


    return (
        <div id="trumpet">
            <Header history={history} setTrumpetImage = { setTrumpetImage } trumpetImage = {trumpetImage} />
            <div
                id="chat-content">
                <div className={"currency"}>
                    <div className={selectButtonIndex === 0 ? "button-green" : "button"} style={{position: 'absolute', left: 30, top:100}} onClick={
                        () => {
                            setSelectButtonIndex(0)
                        }
                    }>
                        効果
                    </div>
                    <div className={selectButtonIndex === 1 ? "button-green" : "button"} style={{ position: 'absolute', left: 150, top:100 }} onClick = {()=>{
                        setSelectButtonIndex(1)
                    }}>
                        演出
                    </div>
                    <div className={selectButtonIndex === 2 ? "button-green" : "button"} style={{ position: 'absolute', left: 270, top:100 }} onClick={()=>{
                        setSelectButtonIndex(2)
                    }}>
                        他
                    </div>
                </div>
                <div className={"present-container"}>
                    {
                        IMAGE_URL[selectButtonIndex].map((item,index)=>{
                          return(
                            <div className="item" key= {index} onClick={()=>{
                                new Audio(MUSIC_URL[selectButtonIndex][index]).play();
                                dispatch(sendChat(userid, "0", roomInfo.id, messageTypeIds.TEXT, "###sound_" + selectButtonIndex + "_" + index ));
                            }} key={selectButtonIndex*10 + index}>
                                <img src={item}/>
                            </div>
                          )  
                        })
                    }
                </div>
            </div>
        </div>
    );
}

const _mapStateToProps = (state) => ({
    messages: state['cocoro/chat'].messages,
    roomInfo: state['cocoro/chat'].roomInfo,
    userInfo: state['cocoro/user'].userInfo,
    roomDetail: state['cocoro/call'].roomDetail,
});

export default connect(_mapStateToProps)(Trumpet);
