import {URL_API} from '../../../env.json';
const axios = require('axios');

export function getHistories(token, filterType) {
    return new Promise(function (resolve, reject) {
        let bearerToken = 'Bearer ' + token;
        let url = URL_API + '/api/v1/history?type=' + filterType;
        axios
            .get(url, {
                headers: {
                    authorization: bearerToken,
                },
            })
            .then(function (response) {
                const currentDate = new Date().getTime();
                const data = response.data;
                const result = [];
                data.forEach((element) => {
                    if (element.expire_at._seconds * 1000 >= currentDate) {
                        result.push(element);
                    }
                });
                resolve(result);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export function getReplyList(token, topicId, filterType, lastDocId) {
    return new Promise(function (resolve, reject) {
        let bearerToken = 'Bearer ' + token;
        let url =
            URL_API +
            '/api/v1/topics/' +
            topicId +
            '/reply-list?type=' +
            filterType +
            '&last_doc_id=' +
            lastDocId;
        axios
            .get(url, {
                headers: {
                    authorization: bearerToken,
                },
            })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export function updateTopicCallable(token, topicId, isCallable) {
    return new Promise(function (resolve, reject) {
        let bearerToken = 'Bearer ' + token;
        let url = URL_API + '/api/v1/topics/' + topicId;
        axios({
            method: 'put',
            url: url,
            headers: {
                authorization: bearerToken,
            },
            data: {
                is_callable: isCallable,
            },
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
}
