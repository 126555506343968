import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {closeModalPage, openPopup, addFriendTopic} from '../../app/actions';
import {DetailUserChat, BonusChat} from '../../chat';
import {DetailFriend, blockFriend, deleteFriend} from '../../friendlist';
import {logout, login, addUser} from '../../user/actions';
import {Link, useHistory} from 'react-router-dom';
import {
    DetailHistoryUser,
    DetailReplyUser,
} from '../../history/components/custom';
import {
    SelectionModal,
    changeAge,
    changeArea,
    changeGender,
} from '../../changeprofile';
import {setArea, setAge, setGender} from '../../infoRegister';
import {setUserCreateTopic, setTimeCreateTopic} from '../../homescreen';
import {
    setGenderFilter,
    setAgeFilter,
    setActiveFilter,
    setAreaFilter,
} from '../../filtertopic';
import {updateTimeSettings} from '../../settings';
import ModalConfirmCall from './ModalConfirmCall';
import DetailUserHome from './DetailUserHome';
import ModalConfirmChat from './ModalConfirmChat';
import ModalInputPW from './ModalInputPW';
import RateChat from './RateChat';
import ReportChat from './ReportChat';
import { updateFilterHistory } from "../../user";
import {
    MODAL_CONFIRM_CHAT,
    MODAL_DETAIL_USER_HOME,
    MODAL_DETAIL_USER_CHAT,
    MODAL_BONUS_CHAT,
    MODAL_CONFIRM_CALL,
    MODAL_CHOOSE_GENDER,
    MODAL_CHOOSE_AGE,
    MODAL_CHOOSE_AREA,
    MODAL_INPUT_PW,
    MODAL_CHOOSE_TIME,
    MODAL_CHOOSE_USER,
    MODAL_DETAIL_FRIEND,
    MODAL_PAID_CALL,
    MODAL_CHOOSE_GENDER_REGISTER,
    MODAL_CHOOSE_AGE_REGISTER,
    MODAL_CHOOSE_AREA_REGISTER,
    MODAL_CHOOSE_GENDER_FILTER_TOPIC,
    MODAL_CHOOSE_AGE_FILTER_TOPIC,
    MODAL_CHOOSE_ACTIVE_FILTER_TOPIC,
    MODAL_CHOOSE_ACTIVE_FILTER_AREA,
    MODAL_CHOOSE_EXPIRE_TIME,
    MODAL_CHOOSE_NAME,
    MODAL_HISTORY_USER,
    MODAL_REPLY_USER,
    MODAL_RATE,
    MODAL_REPORT,
    MODAL_CHOOSE_ACTIVE_FILTER_TOPIC_AREA
} from '../constants';
import {ConfirmPaidCall} from '../../call';

const ModalPage = ({
    modal,
    closeModalPage,
    addFriendTopic,
    blockFriend,
    deleteFriend,
    updateTimeSettings,
    changeAge,
    changeArea,
    changeGender,
    setArea,
    setAge,
    setGenderFilter,
    setAgeFilter,
    setActiveFilter,
    setAreaFilter,
    setGender,
    setTimeCreateTopic,
    setUserCreateTopic,
    getAreaSearch,
    filterHistory,
}) => {
    const dispatch = useDispatch();
    let history  = useHistory();
    const setActiveFilterArea = async (data)=>{
        await dispatch(setAreaFilter(data));
    }
    const switchUser = async (data) => {
        await dispatch(logout(true,false));
        setTimeout(async()=>{
            const result = await dispatch(login(data.value, data.nick));
            // if (result?.error && result?.message) {
            //     setErr(result.message);
            // }
            if (result?.access_token) {
                if (result.is_infomation === false) {
                    history.push('/setting-information');
                } else {
                    history.push('/');
                }
            }
        }, 0)
    }
    const addUsers = async () => {
        await dispatch(logout(true,false));
        await dispatch(addUser());
        // setTimeout(async()=>{
        //     const result = await dispatch(login(data.value, data.nick));
        //     // if (result?.error && result?.message) {
        //     //     setErr(result.message);
        //     // }
        //     if (result?.access_token) {
        //         if (result.is_infomation === false) {
        //             history.push('/setting-information');
        //         } else {
        //             history.push('/');
        //         }
        //     }
        // }, 0)
    }
    return (
        <div id="page-modalpage">
            <div className="page-modalpage-content">
                {modal.data.type == MODAL_RATE && (
                    <RateChat data={modal.data} />
                )}
                {modal.data.type == MODAL_REPORT && (
                    <ReportChat data={modal.data} />
                )}
                {modal.data.type == MODAL_CHOOSE_EXPIRE_TIME && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'声が消えるまでの時間設定'}
                            data={modal.data.data}
                            currentData={modal.data.currentTime}
                            setData={setTimeCreateTopic}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_CHOOSE_NAME && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'投稿する名前'}
                            data={modal.data.data}
                            currentData={modal.data.currentName}
                            setData={setUserCreateTopic}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_CHOOSE_TIME && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'チャット最大保存時間'}
                            data={modal.data.timeSettings}
                            currentData={modal.data.id}
                            setData={updateTimeSettings}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_CHOOSE_USER && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'3キャラクター'}
                            data={modal.data.myUsers}
                            currentData={modal.data.id}
                            setData={switchUser}
                            addData={addUsers}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_CHOOSE_AREA && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'エリア'}
                            data={modal.data.data}
                            currentData={modal.data.currentArea}
                            setData={changeArea}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_INPUT_PW && (
                    <div id="detail-friend">
                        <ModalInputPW
                        data={modal.data}
                        // handleExcute={handleExcute}
                        closeModalPage={closeModalPage}
                    />
                    </div>
                )}
                {modal.data.type == MODAL_CHOOSE_AGE && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'年代'}
                            data={modal.data.data}
                            currentData={modal.data.currentAge}
                            setData={changeAge}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_CHOOSE_GENDER && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'性別'}
                            data={modal.data.gender}
                            currentData={modal.data.currentGender}
                            setData={changeGender}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {/* Phi */}
                {modal.data.type == MODAL_CHOOSE_AREA_REGISTER && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'エリア'}
                            data={modal.data.area}
                            currentData={modal.data.currentArea}
                            setData={setArea}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_CHOOSE_AGE_REGISTER && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'年代'}
                            data={modal.data.age}
                            currentData={modal.data.currentAge}
                            setData={setAge}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_CHOOSE_GENDER_REGISTER && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'性別'}
                            data={modal.data.gender}
                            currentData={modal.data.currentGender}
                            setData={setGender}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {/* Phi filter topic */}
                {modal.data.type == MODAL_CHOOSE_GENDER_FILTER_TOPIC && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'性別'}
                            data={modal.data.genders}
                            currentData={modal.data.currentGender}
                            setData={setGenderFilter}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_CHOOSE_AGE_FILTER_TOPIC && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'年代'}
                            data={modal.data.ages}
                            currentData={modal.data.currentAge}
                            setData={setAgeFilter}
                            onClose={closeModalPage}
                        />
                    </div>
                )}

                {modal.data.type == MODAL_CHOOSE_ACTIVE_FILTER_TOPIC && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'在席'}
                            data={modal.data.actives}
                            currentData={modal.data.currentActive}
                            setData={setActiveFilter}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_CHOOSE_ACTIVE_FILTER_TOPIC_AREA && (
                    <div id="detail-friend">
                        <SelectionModal
                            title={'地域'}
                            data={modal.data.areas}
                            currentData={modal.data.currentActive}
                            setData={setActiveFilterArea}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_PAID_CALL && (
                    <ConfirmPaidCall data={modal.data} />
                )}
                {modal.data.type == MODAL_CONFIRM_CALL && (
                    <ModalConfirmCall
                        data={modal.data}
                        closeModalPage={closeModalPage}
                    />
                )}
                {modal.data.type == MODAL_CONFIRM_CHAT && (
                    <ModalConfirmChat
                        data={modal.data}
                        closeModalPage={closeModalPage}
                    />
                )}
                {modal.data.type == MODAL_BONUS_CHAT && (
                    <BonusChat data={modal.data} />
                )}

                {modal.data.type == MODAL_DETAIL_USER_CHAT && (
                    <DetailUserChat
                        data={modal.data}
                        handleClose={closeModalPage}
                    />
                )}
                {modal.data.type == MODAL_DETAIL_FRIEND && (
                    <div id="detail-friend">
                        <DetailFriend
                            friend={modal.data}
                            userInfo={modal.data.userInfo}
                            blockFriend={blockFriend}
                            deleteFriend={deleteFriend}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_HISTORY_USER && (
                    <div id="detail-friend">
                        <DetailHistoryUser
                            history={modal.data.history}
                            userInfo={modal.data.userInfo}
                            addFriend={addFriendTopic}
                            onClose={closeModalPage}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_REPLY_USER && (
                    <div id="detail-friend">
                        <DetailReplyUser
                            user={modal.data}
                            addFriend={addFriendTopic}
                            blockFriend={blockFriend}
                            deleteFriend={deleteFriend}
                            onClose={closeModalPage}
                            handleError={openPopup}
                        />
                    </div>
                )}
                {modal.data.type == MODAL_DETAIL_USER_HOME && (
                    <DetailUserHome data={modal.data} />
                )}
                {modal.data.type != MODAL_BONUS_CHAT &&
                    modal.data.type != MODAL_RATE &&
                    modal.data.type != MODAL_REPORT &&
                    modal.data.type != MODAL_PAID_CALL && (
                        <img
                            className="page-modal-content-close"
                            src="/images/ic_close.svg"
                            onClick={closeModalPage}
                        />
                    )}
            </div>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    modal: state['cocoro/app'].modal,
    filterHistory: state['cocoro/user'].filterHistory,
});

const _mapDispatchToProps = {
    closeModalPage,
    addFriendTopic,
    blockFriend,
    deleteFriend,
    changeAge,
    changeArea,
    changeGender,
    setArea,
    setAge,
    setGender,
    setGenderFilter,
    setAgeFilter,
    setActiveFilter,
    setAreaFilter,
    updateTimeSettings,
    setTimeCreateTopic,
    setUserCreateTopic,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ModalPage);
