import React from 'react';

function ImageViewer({img_uri, handleClose}) {
    return (
        <div id="image-viewer">
            <div class="backend" onClick={handleClose}></div>
            <div className="frontend">
                <img class="img" src={img_uri} />
                <img
                    className="close"
                    src="/images/ic_close.svg"
                    onClick={handleClose}
                />
            </div>
        </div>
    );
}

export default ImageViewer;
