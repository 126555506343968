import {URL_API} from '../../../env.json';
const axios = require('axios');

export function getImages(age) {
    return new Promise(function (resolve, reject) {
        let url = URL_API + '/api/v1/age/' + age + '/images';
        axios
            .get(url)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error.response && error.response.status) {
                    resolve({
                        status: error.response.status,
                        message: error.response
                            ? error.response.data.message
                            : '想定しないエラーが発生しました。',
                    });
                } else {
                    resolve({
                        status: 404,
                        message: error.response
                            ? error.response.data.message
                            : '想定しないエラーが発生しました。',
                    });
                }
            });
    });
}
