import React, {useState, useEffect} from 'react';
import {searchUsers, addFriend} from '../actions';
import {useDispatch} from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useHistory} from 'react-router-dom';
import {Modal} from '../../app';
import {setNaviIndex, openPopup} from '../../app/actions';
import '../styles.scss';

const AddFriend = ({}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const perPage = 30;
    const [isSearched, setSearched] = useState(false);
    const [users, setUsers] = useState([]);
    const [addedFriend, setAddedFriend] = useState(null);
    const [keyword, setKeyword] = useState('');
    const [lastDocId, setLastDocId] = useState(null);
    const [loadedAllData, setLoadedAllData] = useState(false);
    const [isLoadMore, setLoadMore] = useState(false);
    const [isShowDialog, setShowDialog] = useState(false);

    useEffect(() => {
        dispatch(setNaviIndex('friends'));
    }, []);

    const handleSearch = async () => {
        setSearched(true);

        const result = await dispatch(
            searchUsers({
                keyword,
                per_page: perPage,
            }),
        );
        if (Array.isArray(result?.data)) {
            if (result.data.length === 0) {
                setLoadedAllData(true);
            }
            setUsers(result.data);
            setLastDocId(result.last_doc_id);
        }
    };

    const handleKeydown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleScroll = (event) => {
        const scrollToBottom =
            event.target.scrollHeight - event.target.scrollTop <=
            event.target.clientHeight;
        if (scrollToBottom) {
            handleLoadMore();
        }
    };

    const handleLoadMore = async () => {
        if (loadedAllData || !lastDocId) {
            return;
        }
        setLoadMore(true);
        const result = await dispatch(
            searchUsers(
                {
                    keyword,
                    last_doc_id: lastDocId,
                    per_page: perPage,
                },
                true,
            ),
        );
        setLoadMore(false);

        if (Array.isArray(result?.data)) {
            if (result.data.length === 0) {
                setLoadedAllData(true);
            }
            setUsers(users.concat(result.data));
            setLastDocId(result.last_doc_id);
        }
    };

    const showFriendPopup = (user) => {
        if (user.is_friend) {
            dispatch(openPopup('すでにともだちになっています'));
        }
    };

    const handleAddFriend = async (user) => {
        const result = await dispatch(addFriend(user.id));
        if (result?.status === true) {
            setAddedFriend(user);
            setShowDialog(true);
            let newUsers = users.map((item) => {
                if (item.id === user.id) {
                    return {
                        ...item,
                        is_friend: true,
                    };
                } else {
                    return item;
                }
            });
            setUsers(newUsers);
        }
    };

    const addFriendDialog = () => {
        return (
            <div className="dialog">
                <img src={addedFriend.profile_image} alt="" />
                <span>{addedFriend.user_name + ' さんを'}</span>
                <span>ともだちに追加しました！</span>
                <div className="button" onClick={() => setShowDialog(false)}>
                    <span>OK</span>
                </div>
            </div>
        );
    };

    return (
        <div id="addFriend">
            <header>
                <div className="back">
                    <img
                        alt=""
                        onClick={() => history.goBack()}
                        src="/images/icon-back-grey.png"
                    />
                </div>
                <h3>ともだち追加</h3>
                <div className="none" />
            </header>
            <div className="mainContainer">
                <div className="inputContainer">
                    <input
                        type="text"
                        placeholder="フレンドの名前を入力してください"
                        onChange={(event) => setKeyword(event.target.value)}
                        onKeyDown={handleKeydown}
                    />
                    {keyword !== '' ? (
                        <img
                            className="searchEnable"
                            alt=""
                            onClick={handleSearch}
                            src="/images/ic_search_friend.svg"
                        />
                    ) : (
                        <img
                            className="searchDisable"
                            alt=""
                            src="/images/ic_search_friend_disable.svg"
                        />
                    )}
                </div>
                <div
                    id="resultsContainer"
                    onScroll={(event) => handleScroll(event)}>
                    {isSearched === false ? (
                        <div className="exception"></div>
                    ) : (
                        Array.isArray(users) &&
                        (users.length === 0 ? (
                            <div className="exception">
                                <span>
                                    該当する検索結果が見つかりませんでした
                                </span>
                            </div>
                        ) : (
                            users.map((user, index) => {
                                return (
                                    <div key={index} className="item">
                                        <img src={user.profile_image} alt="" />
                                        <h3>{user.user_name}</h3>
                                        {user.is_friend ? (
                                            <div
                                                className="friendItem"
                                                onClick={() =>
                                                    showFriendPopup(user)
                                                }
                                            />
                                        ) : (
                                            <div
                                                className="buttonContainer"
                                                onClick={() =>
                                                    handleAddFriend(user)
                                                }>
                                                <div className="button">
                                                    <span>追加する</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })
                        ))
                    )}
                    {isLoadMore && (
                        <div id="result-load-more">
                            <div className="result-load-more" />
                        </div>
                    )}
                </div>
            </div>
            {isShowDialog && <Modal Component={addFriendDialog} />}
        </div>
    );
};

export default AddFriend;
