import React from 'react';
import {Link} from 'react-router-dom';
import '../styles.scss';
import {filterTextDefault, Views} from '../../search/constants';
import {useHistory} from 'react-router-dom';

function FilterTopic({filterHistory, searchCategory, searchSubcategory, searchArea, category, subcategory}) {
    const history = useHistory();
    const filterArea = filterHistory?.area?.name || filterTextDefault.Area;
    const filterCategory =
        category || filterTextDefault.Category;
    const filterSubCategory =
        subcategory || filterTextDefault.SubCategory;

    const gotoSearchSubcategory = () => {
        if (filterHistory?.category?.name) {
            history.push({
                pathname: '/search_list',
                state: {view: Views.SelectSubCategory},
            });
        }
    };

    return (
        <div id="selection-option">
            <header>

                <Link
                    className="href">
                    <img
                        className="imgCategory"
                        src="images/smile-icon-solid.svg"
                    />
                    <h3 onClick={ ()=>{ searchCategory(); } }>{filterCategory}</h3>
                </Link>

                <div
                    className={
                        filterHistory?.category?.name ? 'href' : 'href-unactive'
                    }
                    onClick={searchSubcategory}>
                    <img
                        className="imgSubCategory"
                        src={
                            filterHistory?.category?.name
                                ? 'images/square-icon-solid.svg'
                                : 'images/square-icon-solid-unactive.svg'
                        }
                    />
                    <h3 className="h3-unactive-subcategory">
                        {filterSubCategory}
                    </h3>
                </div>


                <div
                    className={
                        filterHistory?.category?.name ? 'href' : 'href-unactive'
                    }
                    onClick={searchArea}>
                    <img
                       className="imgArea"
                       src="images/location-icon-solid.svg"  
                    />
                    <h3>
                        {filterArea}
                    </h3>
                </div>
            </header>
        </div>
    );
}

export default FilterTopic;
