const phoneBorderFaker = {
    backgroundColor: '#fff',
    width: 375,
    position: 'relative',
    height: 667,
    border: 0.5,
    borderStyle: 'solid',
    borderColor: '#f0f0f0',
    // paddingLeft: 27.5,
    // paddingRight: 27.5,
    // paddingTop: 18,
    boxSizing: 'border-box',
    background: '#fff',
};

export default phoneBorderFaker;
