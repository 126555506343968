import {URL_API} from '../../../env.json';
const axios = require('axios');

export function post(url, token, data) {
    return new Promise(function (resolve, reject) {
        axios
            .post(URL_API + url, data, {
                headers: {Authorization: 'Bearer ' + token},
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error.response && error.response.status) {
                    resolve({
                        status: error?.response?.status,
                        message: error?.response
                            ? error?.response?.data?.message
                            : '',
                        errors: error?.response?.data,
                    });
                } else {
                    resolve({
                        status: 404,
                        message: error?.response
                            ? error?.response?.data?.message
                            : '',
                        errors: error?.response?.data,
                    });
                }
            });
    });
}

export function get(url, token) {
    return new Promise(function (resolve, reject) {
        axios
            .get(URL_API + url, {
                headers: {Authorization: 'Bearer ' + token},
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error?.response && error?.response?.status) {
                    resolve({
                        status: error?.response?.status,
                        message: error?.response?.data?.message,
                        errors: error?.response?.data,
                    });
                } else {
                    resolve({
                        status: 404,
                        message: error?.response?.data?.message,
                        errors: error?.response?.data,
                    });
                }
            });
    });
}

export function put(url, token, data) {
    return new Promise(function (resolve, reject) {
        axios
            .put(URL_API + url, data, {
                headers: {Authorization: 'Bearer ' + token},
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error.response && error.response.status) {
                    resolve({
                        status: error?.response?.status,
                        message: error?.response?.data?.message,
                        errors: error?.response?.data,
                    });
                } else {
                    resolve({
                        status: 404,
                        message: error?.response?.data?.message,
                        errors: error?.response?.data,
                    });
                }
            });
    });
}

export function deleteApi(url, token, data) {
    return new Promise(function (resolve, reject) {
        axios({
            method: 'delete',
            url: URL_API + url,
            headers: {Authorization: 'Bearer ' + token},
            data,
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error.response && error.response.status) {
                    resolve({
                        status: error.response.status,
                        message: error.response
                            ? error.response.data.message
                            : '',
                        errors: error.response.data,
                    });
                } else {
                    resolve({
                        status: 404,
                        message: error.response
                            ? error.response.data.message
                            : '',
                        errors: error.response.data,
                    });
                }
            });
    });
}
