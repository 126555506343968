import React from 'react';
import fontStyle from '../fontStyle';
import BackBtn from './BackBtn';

export default function Header({onPressBack, txt}) {
    return (
        <div style={styles.nav}>
            <div style={styles.backBtn}>
                <BackBtn onPress={onPressBack} />
            </div>
            <div style={styles.title}>{txt}</div>
            <div style={styles.forwardBtn}></div>
        </div>
    );
}

const width = 36;

const styles = {
    nav: {
        height: 53,
        width: '100%',
        borderBottom: '1px solid #d8d8d8',
    },
    title: {
        ...fontStyle,
        fontSize: 18,
        textAlign: 'center',

        width: '50%',
        float: 'left',
        paddingTop: 15,
    },
    backBtn: {
        width: '24.9%',
        float: 'left',
        height: 45,
        paddingTop: 8,
    },
    forwardBtn: {
        width: '24.9%',
        height: 36,
        float: 'left',
    },
};
