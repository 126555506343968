export const createRTCPeerConnection = (config) => {
    return new RTCPeerConnection(config);
};

export const createRTCIceCandidate = (data) => {
    return new RTCIceCandidate(data);
};

export const createRTCSessionDescription = (data) => {
    return new RTCSessionDescription(data);
};

export const getUserMedia = async (config) => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia(config);
        return stream;
    } catch (e) {
        console.warn('getUserMedia', e.message);
        return null;
    }
};
