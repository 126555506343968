import {CHAT_TYPE_TEXT} from '../chat/constants';

export const DEFAULT_LATEST_ROOM_DATA = {
    unreadCount: 0,
    latestMessage: {
        data: '',
        message_type_id: CHAT_TYPE_TEXT,
        user_id: '',
        room_id: '',
        is_read: false,
    },
};
