/* eslint-disable react/react-in-jsx-scope */
import React, {useState, useEffect, useRef} from 'react';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import {Constants} from '../../../../util/Constants';
import {Image} from 'react-native';
import { HISTORY_PAGE_URL } from "../../../app/constants";
import {setNaviIndex} from '../../../app/actions';

const ReplyListNavigationBar = ({history, changeFilter, topic, filterType}) => {
    return (
        <div id="reply-navigation-bar">
            <View style={styles.container}>
                <TouchableOpacity
                    style={styles.leftBtn}
                    onPress={() => {
                        localStorage.setItem(HISTORY_PAGE_URL, '');
                        history.push('/history');
                    }}>
                    <img
                        id="img-back"
                        alt="back"
                        src={'/images/icon_back.svg'}
                    />
                    <Text
                        numberOfLines={1}
                        style={styles.topicName}
                        ellipsizeMode="tail">
                        {topic ? topic.title : ''}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => changeFilter()}>
                    <Text
                        style={
                            filterType === Constants.REPLY_LIST_TYPE_REPLIED
                                ? styles.filterAll
                                : styles.filterReply
                        }>
                        〇返事を返した人だけ
                    </Text>
                </TouchableOpacity>
            </View>
        </div>
    );
};

const styles = StyleSheet.create({
    container: {
        height: 54,
        backgroundColor: '#ffffff',
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
        justifyContent: 'space-between',
    },
    leftBtn: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    topicName: {
        fontSize: 17,
        paddingHorizontal: 5,
        width: 200,
    },
    filter: {
        fontSize: 17,
        paddingHorizontal: 5,
        color: '#FF9D00',
    },
    filterAll: {
        fontSize: 17,
        paddingHorizontal: 5,
        color: '#FF9D00',
    },
    filterReply: {
        fontSize: 17,
        paddingHorizontal: 5,
        color: '#ABB3BA',
    },
    all: {
        width: 100,
        textAlign: 'right',
        fontSize: 17,
        paddingRight: 10,
    },
    active: {
        width: 110,
        height: 30,
        justifyContent: 'center',
        backgroundColor: '#DFE9EB',
        borderColor: '#ABB3BA',
        borderWidth: 1,
        borderRadius: 10,
        marginHorizontal: 10,
        fontSize: 17,
    },
    unactive: {
        width: 110,
        height: 30,
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        borderColor: '#A6D837',
        borderWidth: 1,
        borderRadius: 10,
        marginHorizontal: 10,
        fontSize: 17,
    },
    text: {
        textAlign: 'center',
        fontSize: 17,
    },
});

export default ReplyListNavigationBar;
