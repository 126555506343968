import React, {useState, useEffect} from 'react';
import './style/DetailReplyStyle.scss';
import {SocialButton} from '../../../friendlist';
import {numberFormat} from '../../../friendlist/functions';
import {useDispatch} from 'react-redux';
import {addFriendTopic} from '../../../app/actions';
import {setIsBlockUser} from '../../redux/action';
import {Constants} from '../../../../util/Constants';

export const FACEBOOK = 1;
export const TWITTER = 2;
export const INSTAGRAM = 3;

export default function DetailReplyUser({
    user,
    blockFriend,
    deleteFriend,
    onClose,
}) {
    const dispatch = useDispatch();
    const [isFriend, setFriend] = useState(user.is_my_friend);
    const [canAddFriend, setCanAddFriend] = useState(false);

    useEffect(() => {
        if (user.anonymous) {
            var anonymous = Object.values(user.anonymous);
            setCanAddFriend(!anonymous[0] && !anonymous[1]);
        }
    }, []);

    const handleAddFriend = async () => {
        const result = await dispatch(
            addFriendTopic(user.id, user.topic_id, user.room_id),
        );
        if (result.status) {
            setFriend(true);
        }
    };

    return (
        <div id="rootContainer">
            {user.id && (
                <div className="detailContainer">
                    {user.profile_image !== undefined &&
                    user.profile_image !== null ? (
                        <img
                            className="avatar"
                            src={user.profile_image}
                            alt=""
                        />
                    ) : (
                        <img
                            className="avatar"
                            src={Constants.AVATAR_DEFAULT}
                            alt=""
                        />
                    )}
                    <span className="username">
                        {user.is_anonymous ? '匿名さん' : user.user_name}
                    </span>
                    {canAddFriend && !user.is_my_friend && !isFriend && (
                        <div
                            className="addFriendContainer"
                            onClick={() => handleAddFriend()}>
                            <img src="/images/ic_add_friend@2x.png" alt="" />
                            <span className="addFriendText">ともだち追加</span>
                        </div>
                    )}
                    <span className="userInfo">
                        {user.area.name + ' | '}
                        {user.gender === '1'
                            ? '男性（M）'
                            : user.gender === '2'
                            ? '女性（F）'
                            : '不特定（X）'}
                        {' | ' + user.age.name}
                    </span>
                    {((Boolean(user.topic?.chat_price) &&
                        Boolean(user.topic.chat_price !== 0)) ||
                        (Boolean(user.topic?.call_price) &&
                            Boolean(user.topic.call_price !== 0))) && (
                        <div className="feeContainer">
                            <img src="images/icon-take-charge.svg" alt="" />
                            <div className="feeTitleContainer">
                                <span>
                                    {Boolean(user.topic?.chat_price) &&
                                        Boolean(user.topic.chat_price !== 0) &&
                                        'チャット課金（初回のみ)'}
                                </span>
                                <span>
                                    {Boolean(user.topic?.call_price) &&
                                        Boolean(user.topic.call_price !== 0) &&
                                        '通話課金 (1分ごとに)'}
                                </span>
                            </div>
                            <div className="feeValueContainer">
                                <span>
                                    {Boolean(user.topic?.chat_price) &&
                                        Boolean(user.topic.chat_price !== 0) &&
                                        numberFormat(user.topic.chat_price) +
                                            'ポイント'}
                                </span>
                                <span>
                                    {Boolean(user.topic?.call_price) &&
                                        Boolean(user.topic.call_price !== 0) &&
                                        numberFormat(user.topic.call_price) +
                                            'ポイント'}
                                </span>
                            </div>
                        </div>
                    )}
                    <div className="socialCommentContainer">
                        {((user.comment !== undefined &&
                            user.comment !== null) ||
                            (user.facebook !== undefined &&
                                user.facebook !== null) ||
                            (user.twitter !== undefined &&
                                user.twitter !== null) ||
                            (user.instagram !== undefined &&
                                user.instagram !== null)) && (
                            <div className="horizontalContainer">
                                {user.comment !== undefined &&
                                    user.comment !== null && (
                                        <span className="commentLabel">
                                            一言コメント
                                        </span>
                                    )}
                                {!user.is_anonymous && (
                                    <div className="socialContainer">
                                        <SocialButton
                                            link={user.facebook}
                                            type={FACEBOOK}
                                        />
                                        <SocialButton
                                            link={user.twitter}
                                            type={TWITTER}
                                        />
                                        <SocialButton
                                            link={user.instagram}
                                            type={INSTAGRAM}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {user.comment !== undefined && user.comment !== null && (
                        <span className="userComment">{user.comment}</span>
                    )}
                    {canAddFriend && isFriend ? (
                        <div className="functionContainer">
                            <div
                                className="functionItem"
                                onClick={() => {
                                    blockFriend(user.id);
                                    dispatch(setIsBlockUser(true));
                                    onClose();
                                }}>
                                <img src="/images/ic_block_friend.png" alt="" />
                                <span>ブロックする</span>
                            </div>
                            <div
                                className="functionItem"
                                onClick={() => {
                                    deleteFriend(user.id);
                                    setFriend(false);
                                }}>
                                <img
                                    src="/images/ic_delete_friend.png"
                                    alt=""
                                />
                                <span>削除する</span>
                            </div>
                        </div>
                    ) : (
                        <div className="padding" />
                    )}
                </div>
            )}
        </div>
    );
}
