import React from 'react';
import '../styles.scss';
function ModalInputPW({data, closeModalPage, inputType = 'password',}) {
    let password = "";
    const checkPW = async () => {
        if(password === data.currentOrder.pw)
        {
            data.handleSaveSelectedSubCategoryPW(data.currentOrder);
            closeModalPage();
        }
    }
    const closePW = async () => {
        data.handleCancelSelectedSubCategoryPW();
        closeModalPage();
    }
    
    return (
        <div className="confirm-chat-price">
            <div className="price">
                <div>
                <span>
                    {data.currentOrder.name}
                </span>
                </div>
                <div>
                <span>
                    --------------------------
                </span>
                </div>
                <div>
                <span>
                    {data.currentOrder?.comment}
                </span>
                </div>
                <img src="/images/icon-take-charge.svg" />
                <b1>
                入場パスワードを入力します。
                </b1>
            </div>
            <input
                className="inputPassword"
                type={inputType}
                autoComplete="off"
                // value={data}
                onChange={(event) => password = event.target.value}
            />
            <div className="btnWrap">
                <span
                    className="btn btn-chat"
                    onClick={()=>
                        checkPW()
                    }>
                    入場
                </span>
                <span className="btn btn-call" 
                    onClick={()=>
                        closePW()
                    }>
                    キャンセル
                </span>
            </div>
        </div>
    );
}

export default ModalInputPW;
