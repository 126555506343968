import React, {useState} from 'react';
import {getApprovalApi} from '../apis';
import {connect} from 'react-redux';
import '../styles.scss';
import {useDispatch} from 'react-redux';
import {setLoading, openPopup} from '../../app';
import {CHECK_AUTH} from '../../app/actionTypes';

function UnrankContainer({isRegisterExpert, userInfo}) {
    const dispatch = useDispatch();
    const [isRequest, setIsRequest] = useState(false);

    let handleRequest = () => {
        getApprovalApi(userInfo.access_token, '', '', '').then((resolve) => {
            dispatch(setLoading(false));
            try {
                dispatch({type: CHECK_AUTH, status: resolve.status});
                if (resolve.status === 200 || resolve.status === 201) {
                    setIsRequest(true);
                } else {
                    openPopup('想定しないエラーが発生しました。');
                }
            } catch (error) {
                openPopup('想定しないエラーが発生しました。');
            }
        });
    };
    return (
        <div id="unrankContainer">
            {!isRegisterExpert || isRequest ? (
                <div className="buttonDisable">
                    <span>リクエスト承認待ち</span>
                </div>
            ) : (
                <div className="buttonContainer" onClick={handleRequest}>
                    <span>課金リクエスト</span>
                </div>
            )}
            <div className="instructions">
                <span className="instructions">
                    有料チャットや有料トークといった課金サービスを
                    <br />
                    提供したい場合は、上のボタンをタップして課金を
                    <br />
                    可能にするためのリクエストをしてください。
                    <br />
                    課金リクエストの結果、課金可能になった場合は
                    <br />
                    ここにあなたの課金ランクが表示されます。
                </span>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        userInfo: state['cocoro/user'].userInfo,
    };
}

let _mapDispatchToProps = {};

export default connect(_mapStateToProps, _mapDispatchToProps)(UnrankContainer);
