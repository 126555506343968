import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {ReactComponent as Logo} from '../../../../images/check.svg';

export default function Checkbox({onPress, checked}) {
    return (
        <TouchableOpacity
            onPress={onPress}
            style={checked ? styles.checked : styles.unCheck}>
            <Logo color="#fff" />
        </TouchableOpacity>
    );
}

const boxStyle = {
    borderRadius: 4,
    width: 24,
    height: 24,
};

const styles = StyleSheet.create({
    checked: {
        ...boxStyle,
        backgroundColor: '#a4dc34',
    },
    unCheck: {
        ...boxStyle,
        borderColor: '#a4dc34',
        borderWidth: 2.4,
        alignItems: 'center',
    },
    checkedIcon: {
        color: '#fff',
    },
});
