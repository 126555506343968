import React from 'react';

function Modal({Component, handleClose=()=>{}}) {
    return (
        <div id="page-modal">
            <div className="page-modal-content">
                <Component handleClose={handleClose} />
            </div>
        </div>
    );
}

export default Modal;
