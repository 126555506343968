import React, {useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Constants} from '../../../util/Constants';
import {CANCEL_REPORT} from '../../call/constants';
import {closeModalPage, reportRoom} from '../actions';
import {TYPE_RATE_CALL, TYPE_RATE_CHAT} from '../constants';

const ReportChat = ({data, roomInfo, userInfo}) => {
    const [contentReport, setContentReport] = useState('');
    const [err, setErr] = useState('');
    const [sending, setSending] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClose = async () => {
        dispatch(closeModalPage());
        if (parseInt(data.typeRate) === TYPE_RATE_CHAT) {
            report(null, CANCEL_REPORT);
        }
        if (data.onDone) {
            data.onDone();
        }
    };

    const handleReport = async () => {
        if (contentReport.trim().length == 0 || contentReport.length == 0) {
            setErr('通報内容を入力してください。');
        } else {
            report(contentReport, data.typeRate);
        }

        if (data.onDone) {
            data.onDone();
        }
    };

    const report = async (contentReport, type) => {
        if (!sending) {
            setSending(true);
            // support data from call report
            const roomId = data.roomId || roomInfo.id;

            await dispatch(reportRoom(roomId, contentReport, type));
            setSending(false);

            dispatch(closeModalPage());
            if (parseInt(data.typeRate) === TYPE_RATE_CHAT) {
                history.goBack();
            }
        }
    };

    return (
        <div id="rating-chat" className="confirm-chat-price">
            <p className="bold">
                通報対象: {Boolean(data.username) ? data.username : '匿名さん'}
            </p>
            <p className="bold">通報内容</p>
            <textarea
                onChange={(e) => setContentReport(e.target.value)}
                className="report">
                {contentReport}
            </textarea>
            {err != '' && <span className="err">{err}</span>}
            <div className="btnWrap">
                <span className="btn btn-chat" onClick={handleReport}>
                    通報する
                </span>
                <span className="btn btn-call" onClick={handleClose}>
                    キャンセル
                </span>
            </div>
        </div>
    );
};
function _mapStateToProps(state) {
    return {
        roomInfo: state['cocoro/chat'].roomInfo,
        userInfo: state['cocoro/user'].userInfo,
    };
}

let _mapDispatchToProps = {};

export default connect(_mapStateToProps, _mapDispatchToProps)(ReportChat);
