import React, {useState} from 'react';
import {StyleSheet, TouchableWithoutFeedback, View, Text} from 'react-native';
import fontStyle from './fontStyle';

export default function Btn({isDisabled, onPress, txt}) {
    const [isPressIn, setIsPressIn] = useState(false);
    return (
        <TouchableWithoutFeedback
            disabled={isDisabled}
            onPress={onPress}
            onPressIn={() => {
                setIsPressIn(true);
            }}
            onPressOut={() => {
                setIsPressIn(false);
            }}
            style={styles.container}>
            <View
                style={
                    isDisabled
                        ? styles.disabledBtn
                        : isPressIn
                        ? styles.pressIn
                        : styles.pressOut
                }>
                <Text style={styles.txt}>{txt}</Text>
            </View>
        </TouchableWithoutFeedback>
    );
}

const paddingTopBottom = 18;

const pressOut = {
    backgroundColor: '#FFE034',
    borderRadius: paddingTopBottom * 2,
    paddingTop: paddingTopBottom,
    paddingBottom: paddingTopBottom,
    shadowColor: '#DBBD16',
    shadowOffset: {
        width: 0,
        height: 3,
    },
    shadowOpacity: 1,
    shadowRadius: 0,
    elevation: 3,
    width: 256,
    cursor: 'pointer',
};

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        width: 256,
        cursor: 'pointer',
    },
    pressIn: {
        ...pressOut,
        shadowOffset: {
            width: 0,
            height: 0,
        },
        marginTop: 3,
        elevation: 0,
    },
    pressOut,
    disabledBtn: {
        ...pressOut,
        opacity: 0.5,
    },
    txt: {
        ...fontStyle,
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 3,
        textAlign: 'center',
        userSelect: 'none',
    },
});
