import ReducerRegistry from '../base/redux/ReducerRegistry';
import {
    ADD_FRIEND,
    DETAIL_FRIEND,
    UPDATE_FRIEND,
    REMOVE_FRIEND,
    LOAD_MORE_FRIENDS,
    CLEAR_FRIENDS,
    NEW_FRIEND,
} from './actionTypes';

let stateDefault = {
    friends: [],
    selectedFriend: {},
    lastDocId: null,
};

ReducerRegistry.register('cocoro/friends', (state = stateDefault, action) => {
    switch (action.type) {
        case ADD_FRIEND:
            return {
                ...state,
                friends: [...action.friends],
                hasNextPage: action.hasNextPage,
            };

        case LOAD_MORE_FRIENDS:
            return {
                ...state,
                friends: [...state.friends, ...action.friends],
                hasNextPage: action.hasNextPage,
            };

        case NEW_FRIEND:
            return {
                ...state,
                friends: [action.friend, ...state.friends],
            };

        case UPDATE_FRIEND:
            const newFriendList = state.friends.map((friend) => {
                if (friend.user.id === action.id) {
                    return {
                        ...friend,
                        message: action.message,
                        message_created_at: action.created_at,
                        is_read: action.is_read,
                        unreadCount: action.unreadCount,
                    };
                } else {
                    return friend;
                }
            });

            return {
                ...state,
                friends: newFriendList,
            };

        case REMOVE_FRIEND:
            return {
                ...state,
                friends: state.friends.filter(
                    (friend) => friend.user.id !== action.id,
                ),
            };

        case DETAIL_FRIEND:
            const listUpdated = state.friends.map((friend) => {
                if (friend?.user?.id === action.id) {
                    return {
                        ...friend,
                        user_name: action.friend.user_name,
                        profile_image: action.friend.profile_image,
                        area: action.friend.area,
                        age: action.friend.age,
                        gender: action.friend.gender,
                        comment: action.friend.comment,
                    };
                } else {
                    return friend;
                }
            });

            return {
                ...state,
                friends: listUpdated,
            };

        case CLEAR_FRIENDS:
            return {
                ...state,
                friends: [],
            };

        default:
            return state;
    }
});
