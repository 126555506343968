import React, {useState, useEffect, Button, Link} from 'react';
import {Text, View,} from 'react-native';
import {useRouteMatch} from 'react-router-dom';
import {updateImageUri} from '../actions';
import {connect, useDispatch} from 'react-redux';
import '../avatar.scss';
import {Slider} from "@material-ui/core";
import {Constants} from "../../../util/Constants";
import { updateUser } from "../actions";

const SettingSoundChange = ({history}) => {
    const {url} = useRouteMatch();

    const dispatch = useDispatch();
    const [err, setErr] = useState('');
    let userInfoStorage = localStorage.getItem('user_info');
    try{
        userInfoStorage = JSON.parse(userInfoStorage);
        userInfoStorage.userSoundSettings = JSON.parse(userInfoStorage.userSoundSettings);
    }catch(e){
        // userInfoStorage = {userSoundSettings: {}}
    }

    const [slide1, setSlide1] = useState(userInfoStorage.userSoundSettings.setting1);
    const [slide2, setSlide2] = useState(userInfoStorage.userSoundSettings.setting2);
    const [slide3, setSlide3] = useState(userInfoStorage.userSoundSettings.setting3);

    const goToSettingBlood = () =>{
        userInfoStorage.userSoundSettings = JSON.stringify({
            'setting1': slide1,
            'setting2': slide2,
            'setting3': slide3
        });

        localStorage.setItem('user_info', JSON.stringify(userInfoStorage));

        let userInfo;
        try{
            userInfo = JSON.parse(userInfoStorage.userRegisterInfo);
        }catch(e){
            userInfo = {};
        }

        let avatar;
        try{
            avatar = JSON.parse(userInfoStorage.avatar);
        }catch(e){
            avatar = {}
        }

        try{
            dispatch(
                updateUser(
                    callbackSuccess,
                    userInfo.age_id,
                    1,
                    userInfo.area_id,
                    userInfo.gender_id,
                    avatar.type_image,
                    avatar.imageUri,
                    userInfo.user_name,
                    userInfoStorage.userAvatarRegisterInfo,
                    userInfoStorage.userBloodSettings,
                    userInfoStorage.userCharacterSettings,
                    userInfoStorage.userRegisterInfo,
                    userInfoStorage.userSoundSettings,
                    userInfoStorage.userCharacterInputWord,
                    userInfoStorage.userCharacterInputDetail,
                    userInfoStorage.userCoin,
                    setErr,
                )
            );
        }catch(e){

        }

    }

     let callbackSuccess = (data) => {
        history.goBack();
    };

    return (
        <View style={styles.container}>
            {/* <div className={"back-container"}> */}
                <img src={"/images/icon-back-grey.png"} className={'my-back'} alt = {"back"} onClick={()=> history.goBack()}/>
            {/* </div> */}
            <View style={styles.header}>
                <View>
                    <Text style={styles.txt}>
                        ボ イ ス チ ェ ン ジ
                    </Text>
                    <Text style={styles.txt}/>
                </View>
            </View>

            <View style={styles.slideView}>
                <Slider defaultValue={slide1} aria-label="Default" valueLabelDisplay="auto" color={"secondary"}
                        onChange={(e, val) => {
                            setSlide1(val)
                        }}/>
            </View>
            <View style={styles.slideView}>
                <Slider defaultValue={slide2} aria-label="Default" valueLabelDisplay="auto" color={"primary"}
                        onChange={(e, val) => {
                            setSlide2(val)
                        }}/>
            </View>
            <View style={styles.slideView}>
                <Slider defaultValue={slide3} aria-label="Default" valueLabelDisplay="auto" color={"secondary"}
                        onChange={(e, val) => {
                            setSlide3(val)
                        }}/>
            </View>

            <View style={styles.warpButton}>
                <button
                    style={styles.ButtonSubmit} onClick={goToSettingBlood}>
                    つぎへ
                </button>
            </View>
        </View>
    );
};
const styles = {
    container: {
        flex: 1,
        backgroundColor: '#d6f27e',
        height: '667px',
        width: '375px',
    },
    header: {
        alignItems: 'center',
        height: 180,
        paddingBottom: 30,
        flexDirection: 'column-reverse',
    },
    txt: {
        color: '#211e1e',
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    warpButton: {
        width: '100%',
        marginTop: 70,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 35,
    },
    slideView: {
        marginVertical: 10,
        marginHorizontal: 30,
    },
    ButtonSubmit: {
        width: '50%',
        padding: 5,
        fontWeight: 'bold',
        backgroundColor: '#FFE033',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#DBBD16',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 4,
        borderBottomColor: '#DBBD16',
        borderRadius: 10,
        height: 50,
        cursor: 'pointer'
    },
    textButton: {
        fontSize: 18,
        color: '#36383B',
        fontWeight: 'bold',
    },
};

function _mapStateToProps(state) {
    return {
        imageUri: state['cocoro/register'].imageUri,
    };
}

let _mapDispatchToProps = {
    updateImageUri,
};
export default connect(_mapStateToProps, _mapDispatchToProps)(SettingSoundChange);

