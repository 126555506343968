/* eslint-disable react/react-in-jsx-scope */
import React, {useState, useEffect, useRef} from 'react';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import {Constants} from '../../../../util/Constants';

const HistoryListHeader = ({onFilterChange}) => {
    const [myTopic, setMyTopic] = useState(false);
    const [otherTopic, setOtherTopic] = useState(true);
    const firstUpdate = useRef(true);

    useEffect(() => {
        if (!firstUpdate.current) {
            if ((myTopic && otherTopic) || (!myTopic && !otherTopic)) {
                onFilterChange(Constants.HISTORY_FILTER_ALL);
            } else if (myTopic && !otherTopic) {
                onFilterChange(Constants.HISTORY_FILTER_MY_TOPIC);
            } else if (!myTopic && otherTopic) {
                onFilterChange(Constants.HISTORY_FILTER_OTHERS_TOPIC);
            }
        }
    }, [myTopic, otherTopic, onFilterChange]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
    }, []);

    const onFilterMyTopic = () => {
        setMyTopic(true);
        setOtherTopic(false);
        // if (!myTopic) {
        //     setOtherTopic(false);
        // }
    };

    const onFilterOtherTopic = () => {
        setMyTopic(false);
        setOtherTopic(true);
        // if (!otherTopic) {
        //     setMyTopic(false);
        // }
    };

    return (
        <View style={styles.container}>
            <Text style={styles.all}>履歴</Text>
            <TouchableOpacity
                onPress={onFilterMyTopic}
                style={myTopic ? styles.active : styles.unactive}>
                <Text style={styles.text}>自分の投稿</Text>
            </TouchableOpacity>
            <TouchableOpacity
                onPress={onFilterOtherTopic}
                style={otherTopic ? styles.active : styles.unactive}>
                <Text style={styles.text}>全部</Text>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        height: 54,
        backgroundColor: '#ffffff',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    all: {
        width: 100,
        textAlign: 'right',
        fontSize: 17,
        paddingRight: 10,
    },
    active: {
        width: 110,
        height: 30,
        justifyContent: 'center',
        backgroundColor: '#DFE9EB',
        borderColor: '#ABB3BA',
        borderWidth: 1,
        borderRadius: 10,
        marginHorizontal: 10,
        fontSize: 17,
    },
    unactive: {
        width: 110,
        height: 30,
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        borderColor: '#A6D837',
        borderWidth: 1,
        borderRadius: 10,
        marginHorizontal: 10,
        fontSize: 17,
    },
    text: {
        textAlign: 'center',
        fontSize: 17,
    },
});

export default HistoryListHeader;
