import { data } from 'jquery';
import {get, post, put, deleteApi} from '../base/portal/apis';

export function getMyProfile(token) {
    let url = '/api/v1/users/profile';
    return get(url, token);
}

export function getMyUsers(token, email_id) {
    let url = '/api/v1/users/myusers';
    let data = {email_id: email_id};
    return post(url, token, data);
}

export function updateSNSApi(token, facebook, instagram, twitter) {
    let url = '/api/v1/users/update_sns';
    let data = {facebook, instagram, twitter};
    return put(url, token, data);
}

export function getApprovalApi(token) {
    let url = '/api/v1/users/get_approval';
    let data = {};
    return post(url, token, data);
}

export function selfDeleteUserApi(token) {
    let url = '/api/v1/users/deleteUser';
    let data = {};
    return deleteApi(url, token, data);
}
