import ReducerRegistry from '../base/redux/ReducerRegistry';
import {
    GET_AREAS,
    GET_CATEGORIES,
    GET_SUBCATEGORIES,
    CLEAR_SUBCATEGORIES,
} from './actionTypes';

const initialState = {
    areas: null,
    categories: null,
    subcategories: null,
};

ReducerRegistry.register('cocoro/search', (state = initialState, action) => {
    switch (action.type) {
        case GET_AREAS:
            return {
                ...state,
                areas: action.areas,
            };
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.categories,
            };
        case GET_SUBCATEGORIES:
            return {
                ...state,
                subcategories: action.subcategories,
            };
        case CLEAR_SUBCATEGORIES:
            return {
                ...state,
                subcategories: null,
            };
        default:
            return state;
    }
});
