import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import '../styleCreateThread.scss';
import {saveFilter} from '../action';
import {setNaviIndex} from '../../app/actions';

const CreateThreadSuccess = ({idThreadCreate, handlerSearchResult}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handlerCreateThread = async () => {
        const result = await dispatch(
            saveFilter({latest_joined_thread: idThreadCreate}),
        );
        if (result) {
            history.push('/');
            dispatch(setNaviIndex('home'));
        }
    };

    return (
        <div class="wrapSearchThread">
            <div className="search-success-title">
                <img src="images/icon-create-thread.svg" />
                <div class="theme-text">テーマを作成しましょう！</div>
            </div>
            <div class="submit-button-wrap">
                <button class="button-link-home" onClick={handlerCreateThread}>
                    みんなの声へ
                </button>
            </div>

            <div class="submit-button-wrap2">
                <button class="button-back-list" onClick={handlerSearchResult}>
                    テーマ一覧に戻る
                </button>
            </div>
        </div>
    );
};

export default CreateThreadSuccess;
