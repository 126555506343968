import React from 'react';
import {Link} from 'react-router-dom';
import '../styles.scss';
import {numberFormat} from '../functions';

export default function BudgetContainer({budget}) {
    let pointValue = numberFormat(Math.round(budget));
    let textSize = 35;
    let topMargin = 10;
    let length = pointValue.length;
    if (length < 11) {
        textSize = 40;
    } else if (length === 11) {
        topMargin = 20;
        textSize = 38;
    } else if (length === 12) {
        topMargin = 18;
        textSize = 30;
    } else if (length === 13) {
        topMargin = 15;
        textSize = 32;
    } else if (length === 14) {
        topMargin = 20;
        textSize = 30;
    } else if (length === 15) {
        topMargin = 20;
        textSize = 28;
    } else {
        topMargin = 22;
        textSize = 22;
    }

    return (
        <div id="budgetContainer">
            <span className="budgetTitle">所有ポイント数</span>
            <a
                className="addPoint"
                target="_blank"
                rel="noopener noreferrer"
                href={'https://www.google.com'}>
                <img src="/images/ic_add_point.svg" />
            </a>
            <div className="pointContainer">
                <img
                    style={{
                        height: 50,
                        width: 50,
                    }}
                    src={require('../../../images/currency_point.png')}/>
                <span
                    style={{
                        // marginTop: topMargin,
                        maxWidth: '80%',
                        fontSize: textSize,
                        height: 80,
                        color: '#36383B',
                        marginLeft: 10,
                    }}>
                    {pointValue}
                </span>
                <span className="pointName">ポイント</span>
            </div>
        </div>
    );
}
