import {isWidthUp} from '@material-ui/core';
import {signUp as signUpApi} from './apis';

export default function signUp(
    email,
    confirm_email,
    password,
    confirm_password,
    callbackWhenSuccess,
    setIsLoading,
    setErr0,
    setErr1,
    setErr2,
    setErr3,
) {
    setErr0('');
    setErr1('');
    setErr2('');
    setErr3('');
    setIsLoading(true);
    signUpApi(email, confirm_email, password, confirm_password)
        .then((rs) => {
            setIsLoading(false);
            if (rs.status === 200 || rs.status === 201) {
                callbackWhenSuccess();
                return;
            }
            if (rs.status === 403) {
                const errors = rs.data.errors;

                if (errors) {
                    if (errors.email) {
                        setErr0(errors.email);
                    }
                    if (errors.confirm_email) {
                        setErr1(errors.confirm_email);
                    }
                    if (errors.password) {
                        setErr2(errors.password);
                    }
                    if (errors.confirm_password) {
                        setErr3(errors.confirm_password);
                    }
                }
            }
        })
        .catch((err) => {
            alert(err);
            console.log('signUpApi', err);
            setIsLoading(false);
        });
}
