export function validateUsername(username, setErr) {
    if (username === '' || username.trim().length == 0) {
        setErr('ユーザーネームを入力してください。');
        return false;
    }
    if (username.length > 10) {
        setErr('ユーザーネームは10文字以内で入力してください。');
        return false;
    }
    setErr('');
    return true;
}
