import React from 'react';
import {Link} from 'react-router-dom';
import '../styles.scss';
import {filterTextDefault, Views} from '../../search/constants';
import {useHistory} from 'react-router-dom';

function FilterTopic({searchCategory, category, categoryId}) {
    const history = useHistory();
    const filterCategory =
        category?.name || filterTextDefault.Category;

    return (
        <div id="selection-option">
            <header>

                <Link
                    className="href"
                    style={{border:'none'}}
                    >
                    <img
                        className="imgCategory"
                        src="images/smile-icon-solid.svg"
                    />
                    <h3 onClick={ ()=>{ searchCategory(); } }>{filterCategory}</h3>
                </Link>

                <div style={{width: '66%'}}/>
            </header>
        </div>
    );
}

export default FilterTopic;
