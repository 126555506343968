import React, {useEffect, useState} from 'react';
import '../createTopicStyle.scss';
import $ from 'jquery';
import {
    createTopicModal,
    openDialogChooseName,
    openDialogChooseTime,
    getSubCategoryById,
} from '../actions';
import {connect} from 'react-redux';
import {
    checkRankUser,
    checkThreadId,
    getRankCall,
    getRankUser,
    timeMiles,
} from '../function';
import {
    changeRoute,
    openActionPopup,
    openPopup,
    setNaviIndex,
} from '../../app/actions';
import {useHistory} from 'react-router-dom';
import SeekBar from './SeekBarTopic.web';

import { CATEGORY_LOCAL_STORE, SUBCATEGORY_LOCAL_STORE, AREA_LOCAL_STORE } from "../../app/constants";

function ModalCreateTopic({
    onClose,
    modalTitle,
    userInfo,
    userNameRegister,
    timeRegister,
    createTopicModal,
    openDialogChooseName,
    openDialogChooseTime,
    getSubCategoryById,
    openActionPopup,
    onCreateTopicSuccess,
    filterHistory,
}) {
    let history = useHistory();
    const [err, setErr] = useState('');
    const [isHaveRank, setRank] = useState();
    const [title, setTitle] = useState('');
    const [detail, setDetail] = useState(userInfo?.userCharacterInputDetail || '');
    const [sub_category_id, setSubCategoryId] = useState('1');
    const [thread_id, setThreadId] = useState('');
    const [times, setTimes] = useState(timeMiles);
    const [is_callable, setCallLabel] = useState(true);
    const [isFree, setFree] = useState(true);

    const [chat_price, setChatPrice] = useState('');
    const [call_price, setCallPrice] = useState('');

    const [purchase_type, setPurchaseType] = useState(1);
    const [comment, setComment] = useState('');

    useEffect(() => {
        if (userInfo !== null) {
            setRank(checkRankUser(userInfo));
            setSubCategoryId(userInfo.history?.sub_category?.id);
            setThreadId(checkThreadId(userInfo.history));
            getSubCategoryById(userInfo.history?.sub_category?.id, setTimes);
        }
    }, []);

    let handLerCreateTopic = () => {
        setErr('');
        // call API
        createTopicModal(
            thread_id,
            // DEFAULT_THEMA_ID,
            title,
            detail,
            sub_category_id,
            is_callable,
            userNameRegister.id === '1' ? true : true,
            chat_price,
            call_price,
            purchase_type,
            comment,
            timeRegister.value,
            isFree,
            setErr,
            callback,
        );
    };

    let callback = (data) => {
        if (data.status == 200 || data.status == 201) {
            openActionPopup(' 声を投稿しました。', handlerBackHome);
        }
    };

    let handlerBackHome = () => {
        setNaviIndex('search');
        history.push({
            pathname: '/',
        });
        onCreateTopicSuccess();
        onClose();
    };
    let onChangeTitle = (value) => {
        let newTitle = value.trim();
        setTitle(newTitle);
    };

    let onChangeDetail = (value) => {
        let newDetail = value.trim();
        setDetail(newDetail);
    };

    let onChangeComment = (value) => {
        let newComment = value.trim();
        setComment(newComment);
    };

    let onChangeChatPrice = (value) => {
        setChatPrice(value);
    };

    let onChangeCallPrice = (value) => {
        setCallPrice(value);
    };

    let chooseName = () => {
        let user = [
            {id: '1', name: userInfo.user_name},
            {id: '2', name: userInfo.user_name},
        ];
        openDialogChooseName(user, userNameRegister);
    };

    let chooseExpireTime = () => {
        openDialogChooseTime(times, timeRegister);
    };

    useEffect(() => {
        $('.detailContainer').css(
            'margin-top',
            (667 - parseInt($('.detailContainer').css('height'))) / 2 + 'px',
        );
    });

    const [ showError1, setShowError1 ] = useState(false);
    const [ showError2, setShowError2 ] = useState(false);
    const [ showError3, setShowError3 ] = useState(false);

    let areas;
    let categories;
    let subcategories;
    try{
        areas = JSON.parse(localStorage.getItem(AREA_LOCAL_STORE));
    }catch(e){
        areas = [];
    }
    try{
        categories = JSON.parse(localStorage.getItem(CATEGORY_LOCAL_STORE));
    }catch(e){
        categories = [];
    }
    try{
        subcategories = JSON.parse(localStorage.getItem(SUBCATEGORY_LOCAL_STORE));
    }catch(e){
        subcategories = [];
    }
    const getSubcategoryName = (history) => {
        for(let i = 0; i < subcategories.length; i++) {
            if(history?.history?.category_id == subcategories[i]?.category_id && history?.history?.sub_category_id == subcategories[i]?.id )
                return subcategories[i]?.name;
        }
    }
    const getCategoryName = (history)=> {
        for(let i = 0; i < categories.length; i++) {
            if(history?.history?.category_id == categories[i]?.id )
                return categories[i]?.name;
        }
    }
    const getAreaName = (history)=> {
        for(let i = 0; i < areas.length; i++) {
            if(history?.history?.area_id == areas[i]?.id )
                return areas[i]?.name;
        }
    }

    return (
        <div id="rootContainerCreate">
            <div className="detailContainerCreate">
                <img
                    className="close"
                    onClick={() => onClose()}
                    src="/images/ic_close_modal.svg"
                    alt=""
                />
                <h3>{modalTitle}</h3>
                <div className="topic-title">
                    <div className={'detail-container-row-1'}>
                        <div className={'name'}>
                            <div className="username" style={{fontSize: "27px", fontWeight: 'bold'}}>
                                {userInfo.user_name}
                            </div>
                            <span className="userInfo" style={{fontWeight: 'bold'}}>
                                {userInfo.area?.name + ' | '}
                                {userInfo.gender === '1'
                                    ? '男性（M）'
                                    : 
                                userInfo.gender === '2'
                                    ? '女性（F）'
                                    : '不特定（X）'
                                }
                                {' | ' + userInfo.age?.name}
                            </span>
                        </div>
                    </div>
                    <div className={"category-red"}>
                        <span>
                            { getCategoryName(userInfo) }
                        </span>
                        <span style={{paddingLeft: 10, paddingRight: 10, paddingTop: 2}}>
                            {">"}
                        </span>
                        <span>
                            { getSubcategoryName(userInfo) }
                        </span>
                        <span style={{paddingLeft: 10, paddingRight: 10, paddingTop: 2}}>
                            {">"}
                        </span>
                        <span>
                            { getAreaName(userInfo) }
                        </span>

                        
                    </div>
                    <h4>投稿のタイトル （１００文字以内）</h4>
                    { showError1 ?
                        <p style={{color: 'red', fontWeight:'normal'}}>文字列の最大長さ100</p>
                        : null
                    }
                    <div className="textarea">
                        <textarea
                            className="title"
                            id="title"
                            type="text"
                            onChange={(e) => {
                                if(e.target.value.length < 100){
                                    onChangeTitle(e.target.value);
                                    setShowError1(false);
                                }else{
                                    setShowError1(true);
                                }
                            }} value={title}/>
                    </div>
                    {err && err.title ? (
                        <span className="msg">{err.title}</span>
                    ) : null}
                    <h4 style={{marginTop: "10px"}}>投稿の内容（２００文字以内）</h4>
                    { showError2 ?
                        <p style={{color: 'red', fontWeight:'normal'}}>文字列の最大長さ200</p>
                        : null
                    }
                    <div className="textarea">
                        <textarea
                            className="comment"
                            onChange={(e) => {
                                if(e.target.value.length < 200){
                                    onChangeComment(e.target.value);
                                    setShowError2(false);
                                }else{
                                    setShowError2(true);
                                }

                            }} value={comment} />
                    </div>
                    {err && err.comment ? (
                        <span className="msg">{err.comment}</span>
                    ) : null}
                    {err && err.detail ? (
                        <span className="msg">{err.detail}</span>
                    ) : null}
                    <div className="wrap-time">
                        <div className="horizontalTime">
                            <span className="timeLabel">
                                声が消えるまでの時間設定
                            </span>

                            <div
                                className="topicExpireTime"
                                onClick={chooseExpireTime}>
                                <span>{timeRegister.name}</span>
                                <img src="/images/ic_dropdown.svg" />
                            </div>
                        </div>
                    </div>

                    <div className="wrap-call-label">
                        <div className={'area'}>
                            { filterHistory.area?.name }
                        </div>
                        <div>
                            <span className="label-switch-time">
                                トークの着信を許可する{' '}
                            </span>

                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={is_callable}
                                    onChange={(e) => setCallLabel(e.target.checked)}
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                    </div>

                    {/* test rank  - can support */}

                    {isHaveRank == true && (
                        <SeekBar
                            maxCallPrice={getRankCall(userInfo)}
                            maxChatPrice={getRankUser(userInfo)}
                            currentCallPrice={call_price}
                            currentChatPrice={chat_price}
                            setCallPrice={onChangeCallPrice}
                            setChatPrice={onChangeChatPrice}
                            isFree={isFree}
                            setFree={setFree}
                        />
                    )}

                    <div className="submit">
                        <span
                            onClick={handLerCreateTopic}
                            className="button-submit">
                            {' '}
                            投稿する{' '}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        userNameRegister: state['cocoro/homescreen'].userNameRegister,
        timeRegister: state['cocoro/homescreen'].timeRegister,
        filterHistory: state['cocoro/user'].filterHistory,
    };
}

let _mapDispatchToProps = {
    createTopicModal,
    openDialogChooseName,
    openDialogChooseTime,
    changeRoute,
    openActionPopup,
    openPopup,
    getSubCategoryById,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ModalCreateTopic);

