import React from 'react';
import {StyleSheet, View} from 'react-native';
import BaseInput from './BaseInput';
import InputTitle from './InputTitle';

import ErrLabel from '../ErrLabel';

export default function Input({err, txt, setValue, secureTextEntry, initVal}) {
    const onChangeText = (_txt) => {
        setValue(_txt);
    };

    return (
        <View style={styles.container}>
            <InputTitle txt={txt} />
            <View style={styles.input}>
                <BaseInput
                    onChangeText={onChangeText}
                    secureTextEntry={secureTextEntry}
                    initVal={initVal}
                />
            </View>
            <ErrLabel err={err} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        marginTop: 12,
    },
    input: {
        marginTop: 6,
    },
});
