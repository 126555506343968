/* eslint-disable handle-callback-err */
import {
    UPDATE_IMAGE_URI_REGISTER,
    DEFAULT_IMAGE_REGISTER,
    UPDATE_AREA,
    UPDATE_GENDER,
    UPDATE_AGE,
} from '../infoRegister/actionType';
import {
    setLoading,
    openPopup,
    changeRoute,
    openModalPage,
} from '../app/actions';
import {
    MODAL_CHOOSE_AGE_REGISTER,
    MODAL_CHOOSE_AREA_REGISTER,
    MODAL_CHOOSE_GENDER_REGISTER,
} from '../app/constants';
import {getAllArea, getAllAgeApi, updateUserApi, getImagesApi} from './apis';
import {CHECK_AUTH} from '../app/actionTypes';

export function updateImageUri(imageUri) {
    return function (dispatch, getState) {
        dispatch({type: UPDATE_IMAGE_URI_REGISTER, imageUri});
    };
}

export function setImages(images) {
    return {
        type: DEFAULT_IMAGE_REGISTER,
        images,
    };
}

export function setArea(area) {
    return {
        type: UPDATE_AREA,
        area: area.id,
    };
}
export function setAge(age) {
    return {
        type: UPDATE_AGE,
        age: age.id,
    };
}
export function setGender(gender) {
    return {
        type: UPDATE_GENDER,
        gender: gender.id,
    };
}

export function getAreaSearch(callbackData, currentArea) {
    return function (dispatch, getState) {
        let {userInfo} = getState()['cocoro/user'];
        dispatch(setLoading(true));
        getAllArea(userInfo.access_token)
            .then((rs) => {
                dispatch(setLoading(false));
                dispatch({type: CHECK_AUTH, status: rs.status});
                if (rs.status === 200 || rs.status === 201) {
                    callbackData(rs.data);
                    dispatch(
                        openModalPage({
                            type: MODAL_CHOOSE_AREA_REGISTER,
                            area: rs.data.filter((a) => a.name != '四国'),
                            currentArea,
                        }),
                    );
                    return;
                }
                if (rs.status === 404) {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            })
            .catch((err) => {
                dispatch(setLoading(false));
                dispatch(openPopup('想定しないエラーが発生しました。'));
            });
    };
}
export function getAgeSearch(callbackAge, currentAge) {
    return function (dispatch, getState) {
        let {userInfo} = getState()['cocoro/user'];
        dispatch(setLoading(true));
        getAllAgeApi(userInfo.access_token)
            .then((rs) => {
                dispatch(setLoading(false));
                dispatch({type: CHECK_AUTH, status: rs.status});
                if (rs.status === 200 || rs.status === 201) {
                    callbackAge(rs.data);
                    dispatch(
                        openModalPage({
                            type: MODAL_CHOOSE_AGE_REGISTER,
                            age: rs.data,
                            currentAge,
                        }),
                    );
                    return;
                }
                if (rs.status === 404) {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            })
            .catch((err) => {
                dispatch(setLoading(false));
                dispatch(openPopup('想定しないエラーが発生しました。'));
            });
    };
}
export function getGender(callBackGender, currentGender) {
    return function (dispatch, getState) {
        let gender = [
            {id: '2', name: '女性（F）'},
            {id: '1', name: '男性（M）'},
            {id: '3', name: '不特定（X）'},
        ];
        callBackGender(gender);
        dispatch(
            openModalPage({
                type: MODAL_CHOOSE_GENDER_REGISTER,
                gender,
                currentGender,
            }),
        );
    };
}

export function updateUser(
    callbackSuccess,
    age,
    is_finish,
    area,
    gender,
    type,
    profile_image_data,
    user_name,
    userAvatarRegisterInfo,
    userBloodSettings,
    userCharacterSettings,
    userRegisterInfo,
    userSoundSettings,
    userCharacterInputWord,
    userCharacterInputDetail,
    userCoin,
    setErr,
) {
    return function (dispatch, getState) {
        let {userInfo} = getState()['cocoro/user'];
        dispatch(setLoading(true));
        updateUserApi(
            age,
            is_finish,
            area,
            gender,
            type,
            profile_image_data,
            user_name,
            userAvatarRegisterInfo,
            userBloodSettings,
            userCharacterSettings,
            userRegisterInfo,
            userSoundSettings,
            userCharacterInputWord,
            userCharacterInputDetail,
            userCoin,
            setErr,
            userInfo.access_token,
        )
            .then((rs) => {
                dispatch(setLoading(false));
                dispatch({type: CHECK_AUTH, status: rs.status});
                if (rs.status === 200 || rs.status === 201) {
                    callbackSuccess(rs.data);
                    return;
                }
                if (rs.status === 422 || rs.status === 403) {
                    setErr(rs.data.errors);
                }
                if (rs.status === 404) {
                    dispatch(openPopup('想定しないエラーが発生しました。'));
                }
            })
            .catch((err) => {
                dispatch(setLoading(false));
                dispatch(openPopup('想定しないエラーが発生しました。'));
            });
    };
}
