import React from 'react';
import {useDispatch} from 'react-redux';
import {closeModalPage} from '../../app';
import {confirmBonusChat} from '../actions';
import {formatPrice} from '../functions';

const BonusChat = ({data}) => {
    const dispatch = useDispatch();

    const handleConfirmBonusChat = (status) => {
        const roomId = data.id;
        dispatch(closeModalPage());
        dispatch(confirmBonusChat(roomId, status));
    };

    return (
        <div className="confirm-paid-call">
            <p>
                課金リクエストが届きました。
                <br />
                表示されているポイントの課金を承認する場合は
                <br />
                「承認」ボタンをタップしてください。
            </p>
            <div className="price">
                <img src="/images/icon-take-charge.svg" />
                <span>
                    1回につき{' '}
                    <x className="price-chat-bonus">
                        {formatPrice(data.topic?.chat_price)}
                    </x>
                    ポイント
                </span>
            </div>
            <div className="btnWrap">
                <span
                    className="btn btn-chat"
                    onClick={() => handleConfirmBonusChat(true)}>
                    承認
                </span>
                <span
                    className="btn btn-call"
                    onClick={() => handleConfirmBonusChat(false)}>
                    拒否
                </span>
            </div>
            <img
                className="page-modal-content-close"
                src="/images/ic_close.svg"
                onClick={() => handleConfirmBonusChat(false)}
            />
        </div>
    );
};

export default BonusChat;
