import ReducerRegistry from '../base/redux/ReducerRegistry';
import {GET_MY_PROFILE} from './actionTypes';
let stateDefault = {
    userProfile: {},
};

ReducerRegistry.register('cocoro/profile', (state = stateDefault, action) => {
    switch (action.type) {
        case GET_MY_PROFILE:
            return {
                ...state,
                userProfile: action.profile,
            };

        default:
            return state;
    }
});
