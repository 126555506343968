import React, {useEffect, useState} from 'react';
import '../createTopicStyle.scss';
import $ from 'jquery';
import {confirmAlert} from 'react-confirm-alert';
import {connect} from 'react-redux';
import {changeRoute} from '../../app';

function ModalHaveTopic({onClose, onCreateOke}) {
    return (
        <div id="rootContainerCreate">
            <div className="detailContainerHave">
                <img
                    className="close"
                    onClick={() => onClose()}
                    src="/images/ic_close_modal.svg"
                    alt=""
                />

                <h3>
                    このテーマにはすでにメッセージを投稿済みです。
                    新たにメッセージを投稿すると以前のメッセージはすべて削除されます。よろしいですか。{' '}
                </h3>
                <div className="topic-title">
                    <div className="submit">
                        <span onClick={onCreateOke} className="button-submit">
                            {' '}
                            OK{' '}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {};
}

let _mapDispatchToProps = {
    changeRoute,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ModalHaveTopic);
