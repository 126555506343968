import React, {useEffect, useState} from 'react';
import '../styles.scss';
import {connect} from 'react-redux';
import {getAreaSearch, getAgeSearch, updateUser, getGender} from '../actions';
import SpinnerSetting from './SpinnerSetting.web';
function SettingInformation({
    history,
    currentArea,
    currentAge,
    currentGender,
    route,
    areaRegister,
    ageRegister,
    genderRegister,
    getAreaSearch,
    getGender,
    getAgeSearch,
    updateUser,
}) {
    const [err, setErr] = useState('');
    const [user_name, setUserName] = useState('');
    const [dataArea, setDataArea] = useState('');
    const [dataAge, setDataAge] = useState('');
    const [dataGender, setDataGender] = useState();

    const [age, setAgeSelected] = useState('');
    const [age_id, setAgeSelectedId] = useState('');

    const [area, setAreaSelected] = useState('');
    const [area_id, setAreaSelectedId] = useState('');

    const [gender, setGender] = useState('');
    const [gender_id, setGenderId] = useState('');

    const [profile_image_data, setProfileImage] = useState('');
    const [type, setType] = useState('');
    const [isChangeGender, setChangeGender] = useState(false);
    const [isChangeAge, setChangeAge] = useState(false);
    let userInfoStorage = localStorage.getItem('user_info');
    try{
        userInfoStorage = JSON.parse(userInfoStorage);
    }catch(e){
        userInfoStorage = {}
    }

    let aValue = JSON.stringify(userInfoStorage.userRegisterInfo);

    useEffect(() => {
        if (aValue) {
            const info = JSON.parse(aValue);
            setAreaSelected(info.area);
            setAreaSelectedId(info.area_id);
            setAgeSelected(info.age);
            setAgeSelectedId(info.age_id);
            setGender(info.gender);
            setGenderId(info.gender_id);
            setUserName(info.user_name);
        }
    }, [aValue]);

    let callbackArea = (data) => {
        if (data) {
            setDataArea(data);
        }
    };
    let onShowModalArea = () => {
        if (area_id) {
            getAreaSearch(callbackArea, area_id);
        } else {
            getAreaSearch(callbackArea, currentArea);
        }
    };

    useEffect(() => {
        if (dataArea) {
            dataArea.forEach((element) => {
                if (element.id === areaRegister) {
                    setAreaSelected(element.name);
                    setAreaSelectedId(areaRegister);
                }
            });
        } else {
        }
        if (dataAge) {
            dataAge.forEach((element) => {
                if (element.id === ageRegister) {
                    setAgeSelected(element.name);
                    setAgeSelectedId(ageRegister);
                }
            });
        }
        if (dataGender) {
            dataGender.forEach((element) => {
                if (element.id === genderRegister) {
                    setGender(element.name);
                    setGenderId(genderRegister);
                }
            });
        }
    }, [
        ageRegister,
        areaRegister,
        genderRegister,
        dataAge,
        dataArea,
        dataGender,
    ]);

    let callbackData = (data) => {
        if (data) {
            setDataArea(data);
        }
    };

    let onShowModalAge = () => {
        if (age_id) {
            getAgeSearch(callbackAge, age_id);
        } else {
            getAgeSearch(callbackAge, currentAge);
        }
    };
    let callbackAge = (data) => {
        if (data) {
            setDataAge(data);
        }
    };
    let onShowModalSex = () => {
        if (gender_id) {
            getGender(callBackGender, gender_id);
        } else {
            getGender(callBackGender, currentGender);
        }
    };
    let callBackGender = (data) => {
        if (data) {
            setDataGender(data);
        }
    };
    let onChangeUserName = (value) => {
        setUserName(value);
    };

    let handlerValidateUser = () => {
        setErr('');
        updateUser(
            callbackSuccess,
            age_id,
            0,
            area_id,
            gender_id,
            type,
            profile_image_data,
            user_name,
            setErr,
        );
    };

    let callbackSuccess = (data) => {
        let userInfoStorage = localStorage.getItem('user_info');
        try{
            userInfoStorage = JSON.parse(userInfoStorage);
        }catch(e){
            userInfoStorage = {}
        }

        userInfoStorage.userRegisterInfo = {
            age_id: age_id,
            age: age,
            area_id: area_id,
            area: area,
            gender_id: gender_id,
            gender: gender,
            user_name: user_name,
        }

        localStorage.setItem('user_info', JSON.stringify(userInfoStorage));

        if (isChangeAge || isChangeGender) {
            history.push({
                pathname: '/registration-information/avatar',
                state: {
                    age_id: age_id,
                    age: age,
                    area_id: area_id,
                    area: area,
                    gender_id: gender_id,
                    gender: gender,
                    user_name: user_name,
                },
            });
        } else {
            history.push({
                pathname: '/registration-information/confirm',
            });
        }
    };

    return (
        <div className="information-container">
            <div className="modalPage"></div>
            <div className="main-title">
                <div className="wrap-main-title">
                    <h3>
                        はじめに あなたの
                        <br />
                        名前、性別、年代、エリア
                        <br />
                        を決めましょう！
                    </h3>
                </div>

                <div className="wrap-title-fill">
                    <img src="/images/img-title-info.svg" />
                </div>

                <div className="wrap-fill-data">
                    <div className="fill-title">
                        <p>ユーザーネーム</p>
                        <input
                            id="title"
                            type="text"
                            onChange={(e) => {
                                if(e.target.value.length <= 10)
                                    onChangeUserName(e.target.value);
                            }}
                            value={user_name}
                        />
                        {err && err.user_name ? (
                            <span className="msg">{err.user_name}</span>
                        ) : null}

                        <div className="settingContainer">
                            <SpinnerSetting
                                title={'性別'}
                                currentValue={gender}
                                action={() => {
                                    onShowModalSex();
                                    setChangeGender(true);
                                }}
                            />
                            {err && err.gender ? (
                                <span className="msg">{err.gender}</span>
                            ) : null}
                        </div>

                        <div className="settingContainer">
                            <SpinnerSetting
                                title={'年代'}
                                currentValue={age}
                                action={() => {
                                    onShowModalAge();
                                    setChangeAge(true);
                                }}
                            />
                            {err && err.age ? (
                                <span className="msg">{err.age}</span>
                            ) : null}
                        </div>

                        <div className="settingContainer">
                            <SpinnerSetting
                                title={'エリア'}
                                currentValue={area}
                                action={onShowModalArea}
                            />
                            {err && err.area ? (
                                <span className="msg">{err.area}</span>
                            ) : null}
                        </div>

                        <div className="info-submit">
                            <span
                                onClick={handlerValidateUser}
                                className="button-info-submit">
                                決定
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        areaRegister: state['cocoro/register'].areaRegister,
        ageRegister: state['cocoro/register'].ageRegister,
        genderRegister: state['cocoro/register'].genderRegister,
    };
}

let _mapDispatchToProps = {
    getAreaSearch,
    getAgeSearch,
    updateUser,
    getGender,
};

export default connect(
    _mapStateToProps,
    _mapDispatchToProps,
)(SettingInformation);
