export const SET_LOADING = Symbol('SET_LOADING');
export const SET_POPUP = Symbol('SET_POPUP');
export const SET_ACTION_POPUP = Symbol('SET_ACTION_POPUP');
export const SET_MODAL = Symbol('SET_MODAL');
export const SET_INPUT_PW_MODAL = Symbol('SET_INPUT_PW_MODAL');
export const SET_MODAL_SPECIAL = Symbol('SET_MODAL_SPECIAL');
export const SET_CONNECT = Symbol('SET_CONNECT');
export const CHECK_AUTH = Symbol('CHECK_AUTH');
export const SET_FCM_TOKEN = Symbol('SET_FCM_TOKEN');
export const SET_VOIP_TOKEN = Symbol('SET_VOIP_TOKEN');
export const SET_TOPIC_MODAL = Symbol('SET_TOPIC_MODAL');
export const SET_CREATE_TOPIC_MODAL = Symbol('SET_CREATE_TOPIC_MODAL');
export const SET_FRIEND_IDS = Symbol('SET_FRIEND_IDS');
export const ADD_FRIEND_ID = Symbol('ADD_FRIEND_ID');
export const CURRENT_APP_STATE = Symbol('CURRENT_APP_STATE');
export const UPDATE_ROOM_DATA = Symbol('UPDATE_ROOM_DATA');
export const UPDATE_LATEST_ROOMS = Symbol('UPDATE_LATEST_ROOMS');
export const CLEAR_UNREAD_COUNT = Symbol('CLEAR_UNREAD_COUNT');

export const SET_NAVI_INDEX = Symbol('SET_NAVI_INDEX');
export const CHANGE_ROUTE = Symbol('CHANGE_ROUTE');
export const OPEN_CHAT_SCREEN = Symbol('OPEN_CHAT_SCREEN');
export const CLOSE_CHAT_SCREEN = Symbol('CLOSE_CHAT_SCREEN');
export const OPEN_PRESENT_SCREEN = Symbol('OPEN_PRESENT_SCREEN');
export const CLOSE_PRESENT_SCREEN = Symbol('CLOSE_PRESENT_SCREEN');
export const CHANGE_HISTORY_TAB = Symbol('CHANGE_HISTORY_TAB');
export const SET_IS_FOCUS_HOME_FALSE = Symbol('SET_IS_FOCUS_HOME_FALSE');
export const SET_IS_FOCUS_SEARCH_FALSE = Symbol('SET_IS_FOCUS_SEARCH_FALSE');
export const TOGGLE_STYLE_LIST = Symbol('TOGGLE_STYLE_LIST');
export const TOGGLE_SEARCH_SCREEN = Symbol('TOGGLE_SEARCH_SCREEN');
export const SET_IMG_URI = Symbol('SET_IMG_URI');
export const SET_FROM_HOME = Symbol('SET_FROM_HOME');
export const SET_AUDIO_PRESENT = Symbol('SET_AUDIO_PRESENT');