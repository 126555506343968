import React, {useDebugValue, useEffect, useState} from 'react';
import HeaderTopic from './HeaderTopic';
import '../styles.scss';
import {
    getGender,
    getAgeSearch,
    getActiveSearch,
    getActiveArea,
    getUserInfo,
    saveFilter,
    setActiveFilter,
    setAreaFilter,
    setAgeFilter,
    setGenderFilter,
} from '../actions';
import {ages, actives, genders} from '../functions';
import {useHistory} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import SpinnerTopic from './SpinnerTopic.web';
import { getAreaSearch } from "../../search/action";

const FilterTopic = ({
    getUserInfo,
    ageFilter,
    activeFilter,
    genderFilter,
    areas,
    getGender,
    getAgeSearch,
    getActiveSearch,
    getActiveArea,
    setActiveFilter,
    setAreaFilter,
    setAgeFilter,
    setGenderFilter,
    saveFilter,
    filterHistory,
}) => {
    let history = useHistory();
    let dispatch = useDispatch();

    // useEffect(() => {
    //     getUserInfo();
    // }, getUserInfo);

    useEffect(() => {
        if (areas === null) {
            dispatch(getAreaSearch());
        }
    }, []);

    const onBack = () => {
        // history.goBack();
        history.push('/');
    };

    let onPressClear = () => {
        setActiveFilter(actives[0]);
        setAgeFilter(ages[0]);
        setAreaFilter({id: '0', name: '四国'});
        setGenderFilter(genders[0]);
        onFilterTopic(actives[0].id, genders[0].id, ages[0].id, '0');
    };

    let onFilterTopic = (activeId, genderId, ageId, areaId) => {
        if (activeId && genderId && ageId && areaId) {
            saveFilter(activeId, genderId, ageId, areaId, onBack);
        } else {
            saveFilter(
                activeFilter.id.toString(),
                genderFilter.id,
                ageFilter.id,
                '0',
                onBack,
            );
        }
    };

    let onShowModalGender = () => {
        getGender(genderFilter);
    };

    let onShowModalAge = () => {
        getAgeSearch(ageFilter);
    };

    let onShowModalActive = () => {
        getActiveSearch(activeFilter);
    };

    let onShowModalArea = async () => {
        await getActiveArea( areas, filterHistory);
    };

    return (
        <div class="container-filter-topic">
            <HeaderTopic onPressBack={onBack} onPressClear={onPressClear} />
            <div className="main-filter-topic">
                <div className="settingContainer-filter-topic">
                    <SpinnerTopic
                        title={'性別'}
                        currentValue={genderFilter}
                        action={onShowModalGender}
                    />
                </div>
                <div className="settingContainer-filter-topic">
                    <SpinnerTopic
                        title={'年代'}
                        currentValue={ageFilter}
                        action={onShowModalAge}
                    />
                </div>
                <div className="settingContainer-filter-topic">
                    <SpinnerTopic
                        title={'在席'}
                        currentValue={activeFilter}
                        action={onShowModalActive}
                    />
                </div>

                {/* <div className="settingContainer-filter-topic">
                    <SpinnerTopic
                        title={'地域'}
                        currentValue={filterHistory ? filterHistory : {name: '四国'}}
                        action={onShowModalArea}
                    />
                </div> */}

                <div className="button-filter-topic">
                    <div className="button-submit-icon" onClick={onFilterTopic}>
                        <img
                            className="imgFilter"
                            src="images/icon-filter.svg"
                        />
                        <span className="button-submit-topic">絞り込む</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

function _mapStateToProps(state) {
    return {
        activeFilter: state['cocoro/filtertopic'].activeFilter,
        ageFilter: state['cocoro/filtertopic'].ageFilter,
        genderFilter: state['cocoro/filtertopic'].genderFilter,
        filterHistory: state['cocoro/filtertopic'].filterHistory,
        areas: state['cocoro/search'].areas,
    };
}

let _mapDispatchToProps = {
    getGender,
    getAgeSearch,
    getActiveSearch,
    getActiveArea,
    getUserInfo,
    saveFilter,
    setActiveFilter,
    setAreaFilter,
    setAgeFilter,
    setGenderFilter,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(FilterTopic);
