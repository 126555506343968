/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import React, {useState, useEffect, useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {setNaviIndex, openModalPage, openPopup} from '../../app';
import '../styles.scss';
import {Views} from '../../search/constants';
import FilterTopic from './FilterTopic';
import ModalErrorCreateTopic from './ModalErrorCreateTopic';
import ModalCreateTopic from './ModalCreateTopic';
import ModalHaveTopic from './ModalHaveTopic';
import Toolbar from './Toolbar';
import {Constants, persistKeys} from '../../../util/Constants';
import {
    updateFistLogin,
    getTopicList,
    getMyTopic,
    exploreClick,
} from '../actions';
import {getMyFilter, getMyProfile} from '../../user';
import {CATEGORY_LOCAL_STORE, MODAL_DETAIL_FRIEND} from '../../app/constants';
import TimeTopic from '../../chat/components/TimeTopic.web';
import {useWatchExpire, useWatchNewTopic} from '../hooks';
import $ from 'jquery';
import { getFriendInfo } from "../../friendlist/actions";
import { getAreaSearch, getCategorySearch, getSubCategorySearch, clearSubCategories, saveFilter, getThreadList, getAreas } from "../../search/action";
import RadioItem from '../../search/components/RadioItem1.web';
import {createThread} from '../../search/action';
import { DEFAULT_TEMA_TITLE, SUBCATEGORY_LOCAL_STORE, CATEGORY_LOCAL_STOR, AREA_LOCAL_STORE } from "../../app/constants";
import { Category } from '@material-ui/icons';

import {getInputPW} from '../actions';
let view_sel_category = '1';
let aa = 1;
let tempCategory = '1';

const HomeScreenMode = ({userInfo, filterHistory}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isBubbleStyle = false;

    const perPage = 30;
    const [isInitialized, setInitialized] = useState(false);
    const [topics, setTopics] = useState([]);
    const [lastDocId, setLastDocId] = useState([]);
    const [ sel_category, setSelCategory ] = useState(filterHistory?.category);
    const [ sel_subcategory, setSelSubcategory] = useState(filterHistory?.sub_category);
    const [ sel_subcategories, setSelSubcategoires ] = useState([]);
    const [isInit, setIsInit] = useState(true);
    const [tempHistory, setTempHistory] = useState({});

    // let view_sel_category = '';

    let area, category, subcategory;

    try{
        area = JSON.parse(localStorage.getItem(AREA_LOCAL_STORE));
        if(Array.isArray(area))
        {
            area.reverse();
            area=area.concat({name:'全地域',id:'1'});
            area.reverse();
        }
    }catch(e){
        area = {};
    }

    try{
        category = JSON.parse(localStorage.getItem(CATEGORY_LOCAL_STORE));
    }catch(e){
        category = {};
    }

    try{
        subcategory = JSON.parse(localStorage.getItem(SUBCATEGORY_LOCAL_STORE));
    }catch(e){
        subcategory = {};
    }

    useEffect(() => {
        async function getInitData() {
            await Promise.all([
                dispatch(getMyFilter()),
            ]);
            setInitialized(true);
        }
        getInitData();
        dispatch(setNaviIndex('search'));
    }, []);

    const saveDeafultData = async ()=>{
        await dispatch(
            saveFilter({
                category: {name: "みんなの広場", id: "1"},
                category_id: "1"
            })
        )
    }

    useEffect(() => {
        if (isInitialized) {
            if(isInit){
                if(!filterHistory?.category?.id){
                    saveDeafultData();
                }
                tempCategory = filterHistory?.category;
                setSelSubcategory(filterHistory?.sub_category);
                setSelCategory(filterHistory?.category);
                setSelSubcategoires(subcategory.filter(item => item.category_id === filterHistory?.category?.id ));
                if(filterHistory?.category?.id < 1){
                    aa = {name: "カテゴリー", id: "0"};
                }else{
                    aa = filterHistory?.category;
                }
                setIsInit(false);
            }
            reloadTopicData(true, true);
        }
    }, [isInitialized]);

    const bubbleListJquerySelector = useRef(null);
    const currentViewOffset = useRef(0);
    const scrollInterval = useRef(null);
    const waitingInterval = useRef(null);
    const [isAutoScrollActive, setAutoScroll] = useState(false);

    useEffect(() => {
        dispatch(setNaviIndex('search'));
    }, []);

    useEffect(() => {
        if (isBubbleStyle) {
            bubbleListJquerySelector.current = $('.bubble-list').first();
            currentViewOffset.current = 0;
        }
    }, [isBubbleStyle]);

    useEffect(() => {
        if (isBubbleStyle) {
            clearInterval(scrollInterval.current);
            clearInterval(waitingInterval.current);
            waitingInterval.current = setInterval(() => {
                clearInterval(waitingInterval.current);
                setAutoScroll(true);
            }, 3000);
        }
    }, [topics, isBubbleStyle]);

    useEffect(() => {
        if (isBubbleStyle && isAutoScrollActive) {
            clearInterval(scrollInterval.current);
            scrollInterval.current = setInterval(() => {
                currentViewOffset.current += 2;
                if (bubbleListJquerySelector.current) {
                    bubbleListJquerySelector.current.animate(
                        {scrollTop: currentViewOffset.current},
                        50,
                        'swing',
                    );
                }
            }, 50);
        }
    }, [isAutoScrollActive, isBubbleStyle]);

    const [modalVisible, setModalVisible] = useState(userInfo.is_first_login);
    const [isShowCreatTopic, setShowModalCreatTopic] = useState(false);
    const [isShowModalHaveTopic, setShowModalHaveTopic] = useState(false);
    const [isShowModalError, setShowModalError] = useState(false);

    const [] = useWatchNewTopic({
        area_id: filterHistory.area?.id || '',
        category_id: filterHistory.category?.id || '',
        sub_category_id: filterHistory.sub_category?.id || '',
        thread_id: filterHistory.latest_joined_thread || '',
    });

    const expiredTopicId = useWatchExpire(
        topics
            ? topics.map((topic) => {
                  return {
                      id: topic.id,
                      timeExpire: topic.expire_at._seconds,
                  };
              })
            : [],
    );

    useEffect(() => {
        if (expiredTopicId) {
            setTopics(topics.filter((topic) => topic.id !== expiredTopicId));
        }
    }, [expiredTopicId]);


    const reloadTopicData = async (loading = true, isFocus = false) => {
        let result;
        if(filterHistory.category?.id === ''){
            result = await dispatch(
                getTopicList(
                    {
                        thread_id: null,
                        area_id: filterHistory.area?.id,
                        category_id: filterHistory.category?.id,
                        sub_category_id: filterHistory.sub_category?.id,
                        gender: filterHistory.gender || '0',
                        age_id: filterHistory.age_id || '0',
                        user_status: filterHistory.user_status || '0',
                        last_doc_id: null,
                        per_page: perPage,
                    },
                    loading,
                ),
            );
        }else{
            result = await dispatch(
                getTopicList(
                    {
                        thread_id: filterHistory.latest_joined_thread,
                        area_id: filterHistory.area?.id,
                        category_id: filterHistory.category?.id,
                        sub_category_id: filterHistory.sub_category?.id,
                        gender: filterHistory.gender || '0',
                        age_id: filterHistory.age_id || '0',
                        user_status: filterHistory.user_status || '0',
                        last_doc_id: null,
                        per_page: perPage,
                    },
                    loading,
                ),
            );
        }
        if (Array.isArray(result?.data)) {
            setTopics(result.data);
            setLastDocId(result.last_doc_id);

            if (isBubbleStyle && isFocus) {
                clearInterval(scrollInterval.current);
                clearInterval(waitingInterval.current);
                $('.bubble-list').animate({scrollTop: 0}, 500);
                waitingInterval.current = setInterval(() => {
                    setAutoScroll(true);
                    clearInterval(waitingInterval.current);
                }, 3000);
            }
        }
    };

    const handleCreateTopic = async () => {
        
        if(filterHistory.sub_category.category_id>5)
        {
            if(filterHistory.owner_setting?.topic_max_num <= topics.length)
            {
                dispatch(openPopup('ユーザーの投稿数が制限されています。'));
                return ;
            }
        }
        
        if(filterHistory.sub_category?.id === ''){
            dispatch(openPopup(' サブカテゴリーを選択してください。'));
            return ;
        }
        if(filterHistory.area?.id == '1'){
            dispatch(openPopup(' 地域を選択してください。'));
            return ;
        }
        const result = await dispatch(
            getThreadList({
                area_id: filterHistory.area?.id,
                category_id: filterHistory.category?.id,
                sub_category_id: filterHistory.sub_category?.id,
                last_doc_id: null,
                per_page: perPage,
            }),
        );
        if (result?.last_doc_id) {
            await dispatch(
                saveFilter({
                    latest_joined_thread: result.last_doc_id,
                })
            );
            const result1 = await dispatch(
                getMyTopic(result.last_doc_id),
            );
            if (result1.length === 0) {
                setShowModalError(false);
                setShowModalCreatTopic(true);
                setShowModalHaveTopic(false);
            } else {
                setShowModalError(false);
                setShowModalCreatTopic(false);
                setShowModalHaveTopic(true);
            }
        } else {

            if (filterHistory.category?.id && filterHistory.sub_category?.id) {
                const result1 = await dispatch(
                    createThread({
                        name: DEFAULT_TEMA_TITLE,
                        area_id: filterHistory.area?.id,
                        category_id: filterHistory.category?.id,
                        sub_category_id: filterHistory.sub_category?.id,
                    }),
                );
                if (result1?.errors) {
                    dispatch(openPopup(' カテゴリを選択してください。'));
                    setShowModalError(false);
                    setShowModalCreatTopic(false);
                    setShowModalHaveTopic(false);
                } else if (result1?.id) {
                    await dispatch(
                        saveFilter({
                            latest_joined_thread: result1?.id,
                        }),
                    );

                    const result2 = await dispatch(
                        getMyTopic(result1?.id),
                    );
                    if (result2.length === 0) {
                        setShowModalError(false);
                        setShowModalCreatTopic(true);
                        setShowModalHaveTopic(false);
                    } else {
                        setShowModalError(false);
                        setShowModalCreatTopic(false);
                        setShowModalHaveTopic(true);
                    }

                } else {
                    dispatch(openPopup(' カテゴリを選択してください。'));
                    setShowModalError(false);
                    setShowModalCreatTopic(false);
                    setShowModalHaveTopic(false);
                }
            } else {    
                dispatch(openPopup(' カテゴリを選択してください。'));
                setShowModalError(false);
                setShowModalCreatTopic(false);
                setShowModalHaveTopic(false);
            }
        }
    };

    const showUserDetailModal = async (friend, filterHistory) => {
        const result = await dispatch(getFriendInfo(friend.user.id));
        if (result) {
            dispatch(
                openModalPage({
                    type: MODAL_DETAIL_FRIEND,
                    ...result,
                    topic: friend,
                    isAnonymous: friend.isAnonymous,
                    roomId: friend.id, //thread_id, //.room.topic_id,
                    room: friend.room,
                    userInfo: userInfo,
                    filterHistory: filterHistory,
                }),
            );
        }
    };

    const handleExploreClick = async (thread_id, author_id, index) =>{
        let a = topics;
        a[index].click_number = await a[index].click_number + 1;
        await setTopics(a);
        const result = await dispatch(exploreClick(thread_id, author_id));
        if(result?.message === "success"){
            
        }
    }

    const onSearchThread = () => {
        setShowModalError(false);
        if (!filterHistory.area) {
            history.push({
                pathname: '/search',
                state: {view: Views.SelectArea},
            });
            return;
        }
        if (!filterHistory.category) {
            history.push({
                pathname: '/search',
                state: {view: Views.SelectCategory},
            });
            return;
        }
        if (!filterHistory.sub_category) {
            history.push({
                pathname: '/search',
                state: {view: Views.SelectSubCategory},
            });
            return;
        }
        history.push({
            pathname: '/search',
            state: {view: Views.ShowThread},
        });
    };

    const handleScroll = (event) => {
        if (isBubbleStyle) {
            const scrollToBottom =
                event.target.scrollHeight - event.target.scrollTop <=
                event.target.clientHeight;
            if (scrollToBottom) {
                handleLoadMore();
            }
        } else {
            if (event.target.scrollTop === 0) {
                handleLoadMore();
            }
        }
    };

    const [hasAllTopics, setHasAllTopics] = useState(false);

    const handleLoadMore = async () => {
        if (hasAllTopics || !lastDocId) {
            return;
        }
        const result = await dispatch(
            getTopicList({
                thread_id: filterHistory.latest_joined_thread,
                area_id: filterHistory.area?.id,
                category_id: filterHistory.category?.id,
                sub_category_id: filterHistory.sub_category?.id,
                gender: filterHistory.gender || '0',
                age_id: filterHistory.age_id || '0',
                user_status: filterHistory.user_status || '0',
                per_page: perPage,
                last_doc_id: lastDocId,
            }),
        );
        if (Array.isArray(result.data)) {
            if (result.data.length === 0) {
                setHasAllTopics(true);
            }
            setTopics(topics.concat(result.data));
            setLastDocId(result.last_doc_id);
        }
    };


    const [viewSearchCategory, setViewSearchCategory] = useState(false);
    const [viewSearchSubcategory, setViewSearchSubcategory] = useState(false);
    const [viewSearchArea, setViewSearchArea] = useState(false);

    const searchCategory = ()=>{
        setViewSearchCategory(true);
        setViewSearchSubcategory(false);
        setViewSearchArea(false);
    }

    const searchSubcategory = ()=>{
        setViewSearchCategory(false);
        setViewSearchSubcategory(true);
        setViewSearchArea(false);
    }

    const searchArea = ()=>{
        setViewSearchCategory(false);
        setViewSearchSubcategory(false);
        setViewSearchArea(true);
    }

    const handleSaveSelectedCategory = (data) => {
        view_sel_category = data;
        tempCategory = data;
        // aa = data;
        setViewSearchCategory(false);
        setViewSearchSubcategory(true);
        setViewSearchArea(false);
        setSelCategory(data);
        setSelSubcategoires(subcategory.filter(item => item.category_id === data.id ));
    };

    const handleSaveSelectedSubCategoryPW = async (data) => {
        aa = tempCategory;
        setSelSubcategory(data);
        await dispatch(
            saveFilter({
                sub_category_id: data.id,
                sub_category: data,
                category_id: aa?.id,
                category: aa,
                latest_joined_thread: lastDocId,
            })
        );
        await reloadThreadData({sub_category_id: data.id, category_id: aa?.id});
        setViewSearchCategory(false);
        setViewSearchSubcategory(false);
    }
    const handleCancelSelectedSubCategoryPW = async () => {
        setSelCategory(aa);
    }
    const handleSaveSelectedSubCategory = async (data) => {
        if(tempCategory?.id>5)
        {
            await dispatch(getInputPW(data,handleSaveSelectedSubCategoryPW,handleCancelSelectedSubCategoryPW));
            setViewSearchCategory(false);
            setViewSearchSubcategory(false);
        }
        else
        {
            aa = tempCategory;
            setSelSubcategory(data);
            await dispatch(
                saveFilter({
                    sub_category_id: data.id,
                    sub_category: data,
                    category_id: aa?.id,
                    category: aa,
                    latest_joined_thread: lastDocId,
                })
            );
            await reloadThreadData({sub_category_id: data.id, category_id: aa?.id});
            setViewSearchCategory(false);
            setViewSearchSubcategory(false);
        }
    };

    const handleSaveSelectedArea = async (data) => {
        if (data.id !== filterHistory.area?.id) {
            dispatch(
                saveFilter({
                    area_id: data.id,
                    area: data,
                    latest_joined_thread: lastDocId,
                })
            );
            setViewSearchCategory(false);
            setViewSearchSubcategory(false);
            setViewSearchArea(false);
            const result1 = await dispatch(
                getTopicList(
                    {
                        thread_id: lastDocId,
                        area_id: data.id,
                        category_id: filterHistory.category?.id,
                        sub_category_id: filterHistory.sub_category?.id,
                        gender: filterHistory.gender || '0',
                        age_id: filterHistory.age_id || '0',
                        user_status: filterHistory.user_status || '0',
                        last_doc_id: null,
                        per_page: perPage,
                    },
                    true,
                ),
            );
            if (Array.isArray(result1?.data)) {
                setTopics(result1.data);
                setLastDocId(result1.last_doc_id);
            }
        }else {
            setViewSearchCategory(false);
            setViewSearchSubcategory(false);
            setViewSearchArea(false);
        }
    };

    const reloadThreadData = async (params = {}) => {
        const result = await dispatch(
            getTopicList(
                {
                    thread_id: params.thread_id,
                    area_id: filterHistory.area?.id,
                    category_id: params?.category_id,
                    sub_category_id: params?.sub_category_id,
                    gender: filterHistory.gender || '0',
                    age_id: filterHistory.age_id || '0',
                    user_status: filterHistory.user_status || '0',
                    last_doc_id: null,
                    per_page: perPage,
                },
                true,
            ),
        );
        if (Array.isArray(result?.data)) {
            setTopics(result.data);
            setLastDocId(result.last_doc_id);
        }
    };

    return (
        <div id="title">
            {isShowModalError && (
                <ModalErrorCreateTopic
                    onSearch={() => {
                        onSearchThread();
                    }}
                    onClose={() => setShowModalError(false)}
                />
            )}
            {isShowCreatTopic && (
                <ModalCreateTopic
                    modalTitle={''}
                    onClose={() => setShowModalCreatTopic(false)}
                    userInfo={{...userInfo, history: filterHistory}}
                    onCreateTopicSuccess={reloadTopicData}
                />
            )}

            {isShowModalHaveTopic && (
                <ModalHaveTopic
                    onCreateOke={() => {
                        setShowModalHaveTopic(false);
                        setShowModalCreatTopic(true);
                    }}
                    onClose={() => setShowModalHaveTopic(false)}
                />
            )}

            <Toolbar title={"投稿リスト"}  />

            <FilterTopic filterHistory={filterHistory} searchCategory={searchCategory} searchSubcategory = { searchSubcategory } searchArea = { searchArea } category={sel_category?.name} subcategory={sel_subcategory?.name} />

            {
                viewSearchCategory ? 
                    <div>
                        <div className={'modal'} onClick={ async ()=>{
                            setViewSearchCategory(false);
                            setViewSearchSubcategory(false);
                            setSelCategory(aa);
                            tempCategory = aa;
                        }}/>
                        <div className={"modal-category"}>
                        {
                            Array.isArray(category) &&
                            category.map((item, index) => {
                                return (
                                    <RadioItem
                                        key={index}
                                        data={item}
                                        isSelected={
                                            filterHistory.category?.id === item.id
                                                ? true
                                                : false
                                        }
                                        setSelected={handleSaveSelectedCategory}
                                    />
                                );
                            })
                        }
                    </div>  
                    </div>
                    : null
            }

            {
                viewSearchSubcategory ? 
                    <div>
                        <div className={'modal'} onClick={ async ()=>{
                            setViewSearchCategory(false);
                            setViewSearchSubcategory(false);    
                            // if(tempCategory != '' && tempCategory?.name != undefined && aa != tempCategory){
                                setSelCategory(aa);
                                tempCategory = aa;
                                setSelSubcategoires(subcategory.filter(item => item.category_id === aa?.id ));
                            // }
                        }}/>
                        <div className={"modal-sub-category"}>
                            {/* {
                                Array.isArray(sel_subcategories) &&
                                    <RadioItem
                                        key={'all'}
                                        data={{name: 'すべて', id: ''}}
                                        isSelected={
                                            filterHistory.sub_category?.id === ''
                                                ? true
                                                : false
                                        }
                                        setSelected={handleSaveSelectedSubCategory}
                                    />
                            } */}
                            {
                                Array.isArray(sel_subcategories) &&
                                sel_subcategories.map((item, index) => {
                                    return (
                                        <RadioItem
                                            key={index}
                                            data={item}
                                            isSelected={
                                                filterHistory.sub_category?.id === item.id
                                                    ? true
                                                    : false
                                            }
                                            setSelected={handleSaveSelectedSubCategory}
                                        />
                                    );
                                })
                            }
                        </div>  
                    </div>
                    : null
            }
            
            {
                viewSearchArea ? 
                    <div>
                        <div className={'modal'} onClick={ async ()=>{
                            setViewSearchCategory(false);
                            setViewSearchSubcategory(false);
                            setViewSearchArea(false);
                            setSelCategory(aa);
                            tempCategory = aa;
                        }}/>
                      <div className={"modal-area"}>
                        {
                            Array.isArray(area) &&
                            area.map((item, index) => {
                                return (
                                    <RadioItem
                                        key={index}
                                        data={item}
                                        isSelected={
                                            filterHistory.area?.id === item.id
                                                ? true
                                                : false
                                        }
                                        // setSelected={setSelectedArea}
                                        setSelected={handleSaveSelectedArea}
                                    />
                                );
                            })
                        }
                    </div>  
                    </div>
                    : null
            }


            <div id="chat-content" onScroll={(e) => handleScroll(e)}>
                {modalVisible && (
                    <div
                        id="Welcome"
                        onClick={() => {
                            dispatch(updateFistLogin());
                            userInfo.is_first_login = false;
                            localStorage.setItem(
                                persistKeys.USER_INFO,
                                JSON.stringify(userInfo),
                            );
                            setModalVisible(false);
                        }}>
                        <div className="welcome-icon">
                            <img
                                className="welcome-img"
                                src="/images/icon-message-welcome.svg"
                            />
                        </div>
                        <div className="welcome-title">
                            <span className="span-title">
                                COCOROへようこそ！
                            </span>
                        </div>
                        <div className="welcome-button">
                            <img
                                src="images/button-hide-welcome.svg"
                                onClick={() => {
                                    dispatch(updateFistLogin());
                                    userInfo.is_first_login = false;
                                    localStorage.setItem(
                                        persistKeys.USER_INFO,
                                        JSON.stringify(userInfo),
                                    );
                                    setModalVisible(false);
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="square-list">
                        {Array.isArray(topics) &&
                            topics.map((topic, index) => {
                                return (
                                    
                                    <div key={topic.id} className={"topic-container"} style={filterHistory.sub_category?.user_id === (topic?.user_id) ? { background:'#88adff'} :{ background:'write'}}>
                                        <div className="topic-content">
                                            <div className="topic-avatar">
                                                <img
                                                    onClick={() =>{
                                                            handleExploreClick(topic.thread_id, topic.user_id, index);
                                                            showUserDetailModal(topic, filterHistory);
                                                        }
                                                    }
                                                    src={
                                                        topic?.user
                                                            ?.profile_image
                                                            ? topic.user
                                                                  .profile_image
                                                            : Constants.AVATAR_DEFAULT
                                                    }
                                                />
                                            </div>
                                            {/* <div > */}
                                                {topic.is_callable === true ? (
                                                    <img
                                                        src={require('../../../images/ic_call_active@4x.png')} width="15px;" height="15px;"
                                                    />
                                                ) : (
                                                    <img
                                                        src={ require('../../../images/ic_call_missing@4x.png') } width="15px;" height="15px;"
                                                    />
                                                )}
                                            {/* </div> */}
                                            <div
                                                className="topic-message"
                                                onClick={() =>{
                                                    handleExploreClick(topic.thread_id, topic.user_id, index);
                                                    showUserDetailModal(topic, filterHistory);
                                                }   
                                                }>
                                                <span className="text-line-1">
                                                    {topic.title}{' '}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="topic-end">
                                            <div className={'topic-detail'}>
                                                {topic?.user?.user_name}
                                            </div>
                                            <div className={'topic-rating'}>
                                                {topic.is_callable === true ? (
                                                    <img
                                                        className="topic-status-2"
                                                        src={require('../../../images/FriendActiveIcon.png')}
                                                        onClick={()=>{
                                                            handleExploreClick(topic.thread_id, topic.user_id, index);
                                                        }}
                                                    />
                                                ) : (
                                                    <img
                                                        className="topic-status-2"
                                                        src={ require('../../../images/FriendActiveIcon.png') }
                                                    />
                                                )}
                                                
                                                <span className={'topic-rating'} style={{color: '#d03c3c'}}>{topic.click_number}</span>

                                                <img
                                                    className="topic-status-2"
                                                    src={ require('../../../images/UserActiveIcon.png') }
                                                    onClick={()=>{
                                                        // handleHeartClick(topic.thread_id, topic.user_id, index);
                                                    }}
                                                    style={{cursor: 'none'}}
                                                />
                                                <span className={'topic-rating'}>{topic?.heart_number}</span>
                                                
                                            </div>
                                            <div className={'topic-call'}>
                                                {(topic.chat_price > 0.0 ||
                                                    topic.call_price > 0.0) ==
                                                true ? (
                                                    <img
                                                        className="topic-status"
                                                        src="images/icon-take-charge.svg"
                                                    />
                                                ) : (
                                                    <img
                                                        className="topic-status"
                                                        src="images/icon-transparent.svg"
                                                    />
                                                )}
                                                <TimeTopic
                                                    timeExpire={
                                                        topic.expire_at._seconds
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                
            </div>
            {
                (filterHistory?.area?.id == '1' || filterHistory?.area?.id == undefined) ?
                null
                : <div id="footer" onClick={handleCreateTopic}>
                    <h5>+ 自分の声を投稿する</h5>
                </div>
            }
        </div>
    );
};

const _mapStateToProps = (state) => ({
    userInfo: state['cocoro/user'].userInfo,
    filterHistory: state['cocoro/user'].filterHistory,
    isBubbleStyle: state['cocoro/homescreen'].isBubbleStyle,
    areas: state['cocoro/search'].areas,
    categories: state['cocoro/search'].categories,
    subcategories: state['cocoro/search'].subcategories,
});

export default connect(_mapStateToProps)(HomeScreenMode);
