import React from 'react';
import {StyleSheet, Text} from 'react-native';

export default function ErrLabel({err}) {
    return <Text style={styles.labelErr}>{err}</Text>;
}

const styles = StyleSheet.create({
    labelErr: {
        color: 'red',
        fontSize: 12,
        marginTop: '1%',
    },
});
