import React,{useState} from 'react';
import Header from './custom/Header';
import './styles.scss';
import Webcam from 'react-webcam';
import {updateImageUri} from '../redux/action';
import {connect} from 'react-redux';
import {Constants} from '../../../util/Constants';
import {useHistory} from 'react-router-dom';

const TakePicture = ({updateImageUri}) => {
    const webcamRef = React.useRef(null);
    const [webcamError, setWebcamError] = useState(null);
    let history = useHistory();
    const onPressBack = () => {
        history.goBack();
    };

    const capture = () => {
        if(webcamError === null || webcamError === ''){
            const imageSrc = webcamRef.current.getScreenshot();
            updateImageUri(imageSrc, Constants.IMAGE_TYPE_BASE64, true);
            localStorage.setItem('profile_image', imageSrc);
            history.goBack();
        }
    }

    return (
        <div className="container">
            <Header onPressBack={onPressBack} />
            <div className="main">
                <div id="wrap-cam">
                    <Webcam
                        id="web-cam"
                        ref={webcamRef}
                        audio={false}
                        screenshotFormat="image/jpeg"
                        onUserMediaError={(error) => {
                            if(error === null || error === undefined){
                                setWebcamError('');
                            } else {
                                setWebcamError(error.message);
                            }
                        }}
                    />
                    <img id="bg-wrap-camera" src="/images/bg_camera.svg" />
                    {
                        webcamError && (
                            <span className="webcamError">{webcamError}</span>
                        )
                    }
                </div>
                <div id="capture">
                    <img
                        id="img-capture"
                        src="/images/ic_capture.svg"
                        onClick={capture}
                    />
                </div>
            </div>
        </div>
    );
};

function _mapStateToProps(state) {
    return {
        imageUri: state[Constants.STATE_REGISTRATION_INFORMATION].imageUri,
    };
}

let _mapDispatchToProps = {
    updateImageUri,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(TakePicture);
