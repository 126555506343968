import React from 'react';
import '../styles.scss';
import {Link} from 'react-router-dom';

export default function SwitchSetting({title, checked, callBack}) {
    return (
        <div id="arrowSetting">
            <span>{title}</span>
            {
                checked === true ? <img src= {require('../../../images/checked.png')} style={{width: 50, cursor: 'pointer'}} onClick={callBack}/>
                        : <img src={ require('../../../images/unchecked.png') } style={{width: 50, cursor: 'pointer'}} onClick={callBack}/>
            }
        </div>
    );
}
