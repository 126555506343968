import {setLoading, openPopup} from '../../app/actions';
import {getImages} from './api';
import {DEFAULT_IMAGE, UPDATE_IMAGE_URI, IMAGE_CHANGE} from './actionType';
import {Constants} from '../../../util/Constants';
import {changeProfile} from '../../changeprofile';

export function setImages(images) {
    return {
        type: DEFAULT_IMAGE,
        images,
    };
}

export function setImageChange(isChange) {
    return {
        type: IMAGE_CHANGE,
        isChange,
    };
}

export function updateAppImage(imageData, type, onBack) {
    return function (dispatch, getState) {
        let {age_id, gender, user_name, area_id} = getState()[
            'cocoro/profile'
        ].userProfile;
        dispatch(
            changeProfile(
                age_id,
                area_id,
                gender,
                type,
                imageData,
                user_name,
                onBack,
            ),
        );
    };
}

export function updateImages(
    age_id,
    area_id,
    gender,
    imageType,
    imageUri,
    user_name,
    onBack
) {
    return function (dispatch, getState) {
        dispatch(
            changeProfile(
                age_id,
                area_id,
                gender,
                imageType,
                imageUri,
                user_name,
                onBack,
            ),
        );
    };
}

export function updateImageUri(imageUri, imageType, isNew) {
    return function (dispatch, getState) {
        dispatch({type: UPDATE_IMAGE_URI, imageUri, imageType, isNew});
    };
}
