import React from 'react';
import {Views} from '../constants';
import '../styles.scss';

const ToolBar = ({currentView, showBackButton, onClickBackButton}) => {
    return (
        <header>
            {showBackButton ? (
                <div className="address" onClick={onClickBackButton}>
                    <img
                        className="imgAddress"
                        src="images/icon-back-grey.png"
                    />
                </div>
            ) : (
                <div className="address">
                    <img className="imgAddress" src="" />
                </div>
            )}

            <div className="title-name">
                {currentView === Views.CreateThread ||
                currentView === Views.CreateThreadSuccess ? (
                    <h4>新しくテーマをつくる</h4>
                ) : currentView === Views.ShowThread ? (
                    <h4>投稿一覧</h4>
                ) :
                (currentView === Views.SelectArea ?
                    (<h4>絞り込み一覧</h4>)
                    : (currentView === Views.SelectCategory
                         ? <h4>カテゴリ一覧</h4>
                        : (currentView === Views.SelectSubCategory) 
                            ? <h4>サブカテゴリ一覧</h4>
                            : <h4>絞り込み一覧</h4>
                        )
                )}
            </div>
        </header>
    );
};

export default ToolBar;
