import React from 'react';
import '../styles.scss';

export default function SpinnerSetting({title, currentValue, action}) {
    return (
        <div id="spinnerSetting">
            <span>{title}</span>
            <div className="spinnerContainer" onClick={action}>
                <span>{currentValue.name}</span>
                <img src="/images/ic_dropdown.svg" />
            </div>
        </div>
    );
}
