import React from 'react';
import {Link} from 'react-router-dom';
import '../socialstyles.scss';

export const FACEBOOK = 1;
export const TWITTER = 2;
export const INSTAGRAM = 3;

export default function SocialButton({link, type}) {
    let image = '';

    switch (type) {
        case FACEBOOK:
            image = '/images/ic_facebook.svg';
            break;
        case TWITTER:
            image = '/images/ic_twitter.svg';
            break;
        case INSTAGRAM:
            image = '/images/ic_instagram.svg';
            break;
        default:
            break;
    }
    let url = link;

    if (link === undefined || link === null) {
        url = '';
    } else if (link.includes('https://') || link.includes('http://')) {
        url = link;
    } else {
        url = 'https://' + link;
    }

    return (
        link !== undefined &&
        link !== '' && (
            <a target="_blank" href={url}>
                <img id="socialImg" src={image} alt="" />
            </a>
        )
    );
}
