import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {openPopup} from '..';
import {requestCall} from '../../call';

const ModalConfirmCall = ({data, myInfo, closeModalPage}) => {
    const dispatch = useDispatch();

    const handleCall = () => {
        closeModalPage();

        const myPoint = myInfo?.point || 0;
        const callPrice = data.topicInfo?.call_price || 0;
        if (parseInt(myPoint) < parseInt(callPrice)) {
            dispatch(openPopup('ポイントが不足です。'));
            return;
        }

        dispatch(
            requestCall(
                data.userId,
                data.roomId,
                data.roomTypeId,
                data.topicInfo.id,
                data.isAnonymous,
            ),
        );
    };

    return (
        <div className="confirm-chat-price">
            <p>
                {data.topicInfo.purchase_on_start ? (
                    <>
                        この相手方との通話は課金されます。
                        <br />
                        ここで通話を開始すると、その時点からすぐに課金
                        が開始されます。
                        <br />
                        通話を開始しますか？
                    </>
                ) : (
                    <>
                        この相手方は、通話の途中で課金開始のリクエストをすることがあります。課金リクエストが届いた際には、課金を承諾することもできますし、また、断ることもできます。
                        <br />
                        通話を開始しますか？
                    </>
                )}
            </p>
            <div className="price">
                <img src="/images/icon-take-charge.svg" />
                <span>
                    1分ごとに　{data.topicInfo.call_price}
                    ポイント
                </span>
            </div>
            <div className="btnWrap">
                <span className="btn btn-chat" onClick={handleCall}>
                    開始する
                </span>
                <span className="btn btn-call" onClick={closeModalPage}>
                    キャンセル
                </span>
            </div>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    myInfo: state['cocoro/user'].userInfo,
});

export default connect(_mapStateToProps)(ModalConfirmCall);
