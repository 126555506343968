import {
    CLEAR_SUBCATEGORIES,
    GET_AREAS,
    GET_CATEGORIES,
    GET_SUBCATEGORIES,
} from './actionTypes';
import {openPopup, setLoading} from '../app/actions';
import {CHECK_AUTH} from '../app/actionTypes';
import {
    createThreadApi,
    getAreaApi,
    getCategoryApi,
    getSubCategoryApi,
    getThreadApi,
    getThreadByIdApi,
    updateFilterApi,
    updateFirstSearchIsFiredApi,
} from '../base/api';
import {updateFilterHistory} from '../user';
import {httpRequest} from '../base/api/actions';

export function getAreas(areas) {
    return {
        type: GET_AREAS,
        areas,
    };
}

export function getCategories(categories) {
    return {
        type: GET_CATEGORIES,
        categories,
    };
}

export function getSubCategories(subcategories) {
    return {
        type: GET_SUBCATEGORIES,
        subcategories,
    };
}

export function clearSubCategories() {
    return {
        type: CLEAR_SUBCATEGORIES,
    };
}

export function getAreaSearch() {
    return async function (dispatch, getState) {
        const data = await dispatch(httpRequest(getAreaApi(), true));
        if (data) {
            dispatch(getAreas(data));
        }
    };
}

export function getCategorySearch() {
    return async function (dispatch, getState) {
        const data = await dispatch(httpRequest(getCategoryApi(), true));
        if (data) {
            dispatch(getCategories(data));
        }
    };
}

export function getSubCategorySearch(categoryId) {
    return async function (dispatch, getState) {
        const data = await dispatch(
            httpRequest(getSubCategoryApi(categoryId), false),
        );
        if (data) {
            dispatch(getSubCategories(data));
        }
    };
}

export function createThread({name, area_id, category_id, sub_category_id}) {
    return async function (dispatch, getState) {
        const res = await dispatch(
            httpRequest(
                createThreadApi({
                    name,
                    area_id,
                    category_id,
                    sub_category_id,
                }),
                true,
            ),
        );
        return res;
    };
}

export function getThreadList(
    {area_id, category_id, sub_category_id, last_doc_id, per_page},
    showLoading = false,
) {
    return async function (dispatch, getState) {
        const res = await dispatch(
            httpRequest(
                getThreadApi({
                    area_id,
                    category_id,
                    sub_category_id,
                    last_doc_id,
                    per_page,
                }),
                showLoading,
            ),
        );

        return res;
    };
}

export function updateFirstSearch() {
    return async function (dispatch, getState) {
        await dispatch(httpRequest(updateFirstSearchIsFiredApi()));
    };
}

export function saveFilter({
    area_id,
    area,
    category_id,
    category,
    sub_category_id,
    sub_category,
    latest_joined_thread,
    user_status,
    gender,
    age_id,
}) {
    return async function (dispatch) {
        const result = await dispatch(
            httpRequest(
                updateFilterApi({
                    area_id,
                    area,
                    category_id,
                    category,
                    sub_category_id,
                    sub_category,
                    latest_joined_thread,
                    user_status,
                    gender,
                    age_id,
                }),
            ),
        );
        if (result?.data) {
            dispatch(updateFilterHistory(result.data));
        }
        return result?.data;
    };
}
