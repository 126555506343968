export const CHAT_TYPE_TEXT = '1';
export const CHAT_TYPE_IMAGE = '2';
export const CHAT_TYPE_EMOJI = '3';
export const CHAT_TYPE_IMAGE_BASE64 = '4';
export const CHAT_TYPE_SYSTEM_CALL_END = '6';
export const CHAT_TYPE_CALL_SUCCESS = '6';
export const CHAT_TYPE_SYSTEM_CALL_CANCEL = '7';
export const CHAT_TYPE_SYSTEM_CALL_MISS = '8';
export const CHAT_IMG_MAX_WIDTH = 1080;
export const CHAT_IMG_MAX_HEIGHT = 1080;
export const CHAT_IMG_QUANLITY = 80;

export const messageTypeIds = {
    TEXT: '1',
    IMAGE: '2',
    EMOJI: '3',
    IMAGE_BASE64: '4',
};

export const messageStatus = {
    READ: 'read',
    SENDING: 'sending',
    SENT_FAIL: 'sent_fail',
};

export const messageStatusTexts = {
    READ: '既読',
    SENDING: '送信中',
    SENT_FAIL: '送信失敗',
    RECEIVED: 'received',
    SENT_SUCCESS: 'sent_success',
    REFUSED: 'refused',
};

export const replyMessageTypes = {
    RECEIVED: 'received',
    READ: 'read',
};
