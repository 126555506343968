import React from 'react';
import {StyleSheet, View} from 'react-native';
import BaseCodeInput from './BaseCodeInput';
import InputTitle from './InputTitle';
import ErrLabel from '../ErrLabel';

export default function CodeInput({setValue, txt, err}) {
    const onChangeText = (_txt) => {
        setValue(_txt);
    };

    return (
        <View style={styles.container}>
            <InputTitle txt={txt} />
            <View style={styles.input}>
                <BaseCodeInput onChangeText={onChangeText} />
            </View>
            <ErrLabel err={err} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        marginTop: 12,
    },
    input: {
        marginTop: 6,
    },
});
