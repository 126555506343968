import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {formatPrice} from '../../chat/functions';
import { ChatBtn, clearOldMsg, createRoomChat, updateRoomInfo  } from "../../chat";
import {
    ROOM_TYPE_CHAT_TOPIC,
} from '../../call/constants';
import {callStates} from '../constants';
import {updateSmallStatus, endCall, requestPaidCall} from '../actions';
import {openPopup} from '../../app/actions';
import * as webrtc from '../../base/webrtc';
import {Constants} from '../../../util/Constants';
import { useHistory } from "react-router-dom";
import { CALL_ID_TEMP } from "../../app/constants";
import { addFriend } from "../../addfriend/actions";

const Calling = ({
    callState,
    roomDetail,
    userInfo,
    myInfo,
    balance,
    paidCall,
    audioPresent,
}) => {
    const dispatch = useDispatch();

    const [mic, setMic] = useState(true);
    const [flagEnd, setFlagEnd] = useState(true);

    const history = useHistory();

    let roomChatId = localStorage.getItem(CALL_ID_TEMP);

    const handleToggleMic = () => {
        if (roomDetail?.room_id && userInfo?.user_id) {
            const conn = webrtc.getConnection(
                userInfo?.user_id,
                roomDetail?.room_id,
            );
            if (conn) {
                const status = conn.toggleAudioTrack();
                setMic(status);
            }
        }
    };

    const handleRequestPailCall = () => {
        dispatch(requestPaidCall());
    };

    const handleEndCall = () => {
        if(!flagEnd)
            return ;
        dispatch(endCall(userInfo?.user_id, roomDetail?.room_id));
    };

    const handleUpdateSmallStatus = () => {
        dispatch(updateSmallStatus(true));
    };

    const handleToggleSpeak = () => {
        dispatch(openPopup('この機能は、ウェブ版には対応しません。'));
    };

    
    const handleAgreeChat =  async () => {
        const path = history.location.pathname;
        if(path.search('/chat/') != -1){
            setTimeout((path)=>{
                history.push(path, { from: 'whitescreen' });
                console.log('time out');
            }, 500, path);
            history.push('/whitescreen');
        }else{
            const result = await dispatch(
                createRoomChat({
                    id: roomDetail?.incoming_id === undefined ? '0' : roomDetail?.incoming_id,
                    user_id: userInfo?.user_id,
                    room_type_id: ROOM_TYPE_CHAT_TOPIC,
                    topic_id: roomDetail?.topicInfo?.id,
                    is_anonymous: roomDetail?.topicInfo?.is_anonymous,
                }),
            );
            if (result?.id) {
                history.push('/chat/' + result.id);
            }
        }
    };

    const handleAgreeChatFriend =  async () => {
        if(roomDetail.roomChatId !== undefined)
            roomChatId = roomDetail.roomChatId;
        const path = history.location.pathname;
        if(path.search('/chat/') != -1){
            setTimeout((path)=>{
                history.push(path, { from: 'whitescreen' });
                console.log('time out');
            }, 500, path);
            history.push('/whitescreen');
        }else{
            await history.push('/chat/' + roomChatId);
        }
    };

    const handleAgreePrssent = async () => {
        const result = await dispatch(
            createRoomChat({
                id: roomDetail?.incoming_id === undefined ? '0' : roomDetail?.incoming_id,
                user_id: userInfo?.user_id,
                room_type_id: ROOM_TYPE_CHAT_TOPIC,
                topic_id: roomDetail?.topicInfo?.id,
                is_anonymous: roomDetail?.topicInfo?.is_anonymous,
            }),
        );
        if (result?.id) {
            dispatch(updateRoomInfo(result));
            dispatch(clearOldMsg());
            console.log("present================================",result.id);
            history.push('/present/' + result.id);
        }
    };

    const handleAgreeTrumpet = async () => {
        if(roomDetail.roomChatId !== undefined)
            roomChatId = roomDetail.roomChatId;
        const result = await dispatch(
            createRoomChat({
                id: roomDetail?.incoming_id === undefined ? '0' : roomDetail?.incoming_id,
                user_id: userInfo?.user_id,
                room_type_id: ROOM_TYPE_CHAT_TOPIC,
                topic_id: roomDetail?.topicInfo?.id,
                is_anonymous: roomDetail?.topicInfo?.is_anonymous,
            }),
        );
        if (result?.id) {
            dispatch(updateRoomInfo(result));
            dispatch(clearOldMsg());
            console.log("trumpet================================",result.id);
            history.push('/trumpet/' + result.id);
        }
    };

    const handleCallMessage = async () => {
        await dispatch(updateSmallStatus(true));
        await dispatch(clearOldMsg());
        if(!userInfo?.is_anonymous)
            handleAgreeChatFriend();
        else{
            handleAgreeChat();
        }
    }

    const handlePresent = () => {
        if(roomDetail.roomChatId !== undefined)
            roomChatId = roomDetail.roomChatId;
        dispatch(updateSmallStatus(true));
        dispatch(clearOldMsg());
        if(!userInfo?.is_anonymous){
            history.push('/present/' + roomChatId, { url: history.location });
        }else{
            handleAgreePrssent();
        }
    }

    const handleTrumpet = () => {
        if(roomDetail.roomChatId !== undefined)
            roomChatId = roomDetail.roomChatId;
        dispatch(updateSmallStatus(true));
        if(!userInfo?.is_anonymous){
            history.push('/trumpet/' + roomChatId);
        }else{
            handleAgreeTrumpet();
        }
    }

    const [isFirst, setIsFirst] = useState(true);
    let countTime = 0;
    let timerHandle = null;

    useEffect(()=>{
        if(isFirst){
            setIsFirst(false);
            timerHandle = setInterval(()=>{
                countTime++;
                if(countTime == 30){
                    dispatch(addFriend(userInfo?.id));
                }
            }, 1000, { countTime });
        }
    })

    useEffect(()=>{
        return () => {
            clearInterval(timerHandle);
        }
    }, [])

    return (
        <div id="callerWrap">
            {/* <img
                onClick={handleUpdateSmallStatus}
                className="zoom"
                src={ require("../../../images/back.png") }
            /> */}
            
            <img
                onClick={handleCallMessage}
                className="call-message"
                src={ require("../../../images/call_message.png") }
            />

            <img
                onClick={handlePresent}
                className="present"
                src={ "/images/present.png" }
            />
            <img
                className="present-decorate"
                src={ "/images/decorate_right.png" }
            />
            <img
                onClick={handleTrumpet}
                className="trumpet"
                src={ "/images/trumpet.png" }
            />
            <img
                className="trumpet-decorate"
                src={ "/images/decorate_left.png" }
            />
            <header>
                <p>トーク中</p>
            </header>
            {/* <audio loop autoPlay src={'/audio/incallmanager_ringback.mp3'} /> */}
            <main>
                {roomDetail?.topicInfo &&
                    roomDetail?.user_id !== roomDetail?.topicInfo?.user_id && (
                        <div className="priced">
                            {parseInt(balance) !== 0 && (
                                <span>
                                    課金中:{' '}
                                    {formatPrice(Number(balance).toFixed(0))}
                                    ポイント
                                </span>
                            )}
                            {parseInt(balance) === 0 &&
                                roomDetail?.topicInfo?.user_id === myInfo?.id &&
                                Boolean(roomDetail?.topicInfo?.call_price) && (
                                    <>
                                        {paidCall ? (
                                            <span className="disable">
                                                課金開始: 1分
                                                {formatPrice(
                                                    Number(
                                                        roomDetail?.userInfo
                                                            ?.price,
                                                    ).toFixed(0),
                                                )}
                                                ポイント
                                            </span>
                                        ) : (
                                            <span
                                                className="request-btn"
                                                onClick={handleRequestPailCall}>
                                                課金開始: 1分
                                                {formatPrice(
                                                    Number(
                                                        roomDetail?.userInfo
                                                            ?.price,
                                                    ).toFixed(0),
                                                )}
                                                ポイント
                                            </span>
                                        )}
                                    </>
                                )}
                        </div>
                    )}

                <div className="image">
                    <img
                        src={
                            userInfo?.profile_image
                                ? userInfo?.profile_image
                                : Constants.AVATAR_DEFAULT
                        }
                        alt=""
                    />
                </div>

                <div className="info" style={{color: 'black'}}>
                    <p className="name">
                        {userInfo?.is_anonymous
                            ? userInfo?.user_name
                            : userInfo?.user_name}
                        {callState === callStates.UNABLE_CONNECT &&
                            userInfo?.is_anonymous &&
                            'と接続できませんでした。'}
                        {callState === callStates.UNABLE_CONNECT &&
                            Boolean(userInfo?.is_anonymous) === false &&
                            'さんと接続できませんでした。'}
                    </p>
                    <p className="infoDetail">
                        {userInfo && userInfo?.area} |{' '}
                        {userInfo && parseInt(userInfo.gender) == 1 ? ' | 男性（M）' : ''}
                        {userInfo && parseInt(userInfo.gender) == 2 ? ' | 女性（F）' : ''}
                        {userInfo && parseInt(userInfo.gender) == 3 ? ' | 不特定（X）' : ''}
                        | {userInfo && userInfo?.age}
                    </p>
                    <p className="detail">{userInfo?.comment}</p>
                </div>
                <div className="btnSpecial">
                    {mic ? (
                        <div>
                            <img
                                onClick={handleToggleMic}
                                src="/images/icon-micro-off.png"
                            />
                            <br />
                            <span>ミュート</span>
                        </div>
                    ) : (
                        <div>
                            <img
                                onClick={handleToggleMic}
                                src="/images/icon-micro-on.png"
                            />
                            <br />
                            <span></span>
                        </div>
                    )}

                    <div>
                        <img
                            onClick={handleToggleSpeak}
                            src="/images/icon-speaker-on.png"
                        />
                        <br />
                        <span></span>
                    </div>
                </div>
                <div className="cancelBtn">
                    <span onClick={handleEndCall}>トーク終了</span>
                </div>
            </main>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    myInfo: state['cocoro/user'].userInfo,
    userInfo: state['cocoro/call'].userInfo,
    roomDetail: state['cocoro/call'].roomDetail,
    balance: state['cocoro/call'].balance,
    paidCall: state['cocoro/call'].paidCall,
    callState: state['cocoro/call'].callState,
    audioPresent: state['cocoro/app'].audioPresent,
});

export default connect(_mapStateToProps)(Calling);
