import {Constants} from '../../util/Constants';

export function numberFormat(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatMessage(
    message,
    myId,
    myName,
    friendName,
    isAnonymous = false,
) {
    let displayMessage = '';
    // let friendUserName = isAnonymous ? '匿名' : friendName;
    let friendUserName = '';
    switch (message.message_type_id) {
        case Constants.MESSAGE_TYPE_ID_TEXT:
            if (message.user_id === myId) {
                displayMessage = 'あなた: ';
            } else {
                displayMessage = '';
            }
            displayMessage =
                displayMessage + removeBreakLine(message?.data || '');
            break;
        case Constants.MESSAGE_TYPE_ID_IMAGE:
        case Constants.MESSAGE_TYPE_ID_BASE64:
            if (message.user_id === myId) {
                displayMessage = 'あなたが画像を送信しました。';
            } else {
                displayMessage = friendUserName + 'さんが画像を送信しました。';
            }
            break;

        case Constants.MESSAGE_TYPE_ID_EMOJI:
            if (message.user_id === myId) {
                displayMessage = 'あなたがスタンプを送信しました。';
            } else {
                displayMessage =
                    friendUserName + 'さんがスタンプを送信しました。';
            }
            break;

        case Constants.MESSAGE_TYPE_ID_MISSED_CALL:
        case Constants.MESSAGE_TYPE_ID_SYSTEM_CALL_CANCEL:
        case Constants.MESSAGE_TYPE_ID_SYSTEM_CALL_MISS:
            if (message.user_id === myId) {
                displayMessage =
                    friendUserName + 'さんが通話に応答しませんでした。';
            } else {
                displayMessage =
                    friendUserName + 'さんからの不在着信がありました。';
            }
            break;

        case Constants.MESSAGE_TYPE_ID_CALL_SUCCESS:
            displayMessage = friendUserName + 'さんと通話がありました。';
            break;
    }
    if(displayMessage === '')
        displayMessage = 'メッセージありません。';
    return displayMessage;
}

const removeBreakLine = (message) => {
    return message.replace(/(\r\n|\n|\r)/gm, ' ');
};
