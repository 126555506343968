import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {changeRoute, openPopup} from '../../app/actions';
import {useHistory} from 'react-router-dom';
import {resetVerifyCode} from '../actions';
import '../verifyStyles.scss';

function InputCode({
    loading,
    changeRoute,
    lastRoute,
    openPopup,
    resetVerifyCode,
}) {
    let history = useHistory();

    let onBack = () => {
        history.goBack();
    };

    let [code, setCode] = useState('');
    let [codeError, setCodeError] = useState('');

    let toResetPassword = () => {
        history.replace('/reset_password');
    };

    let confirmCode = () => {
        resetVerifyCode(code, setCodeError, toResetPassword);
    };
    return (
        <div id="verify">
            <header>
                <div className="back">
                    <img
                        alt=""
                        onClick={onBack}
                        src="/images/icon-back-grey.png"
                    />
                </div>
                <h3></h3>
                <div className="none" />
            </header>
            <div className="mainContainer">
                <span className="note">
                    届いたメールに記載されている認証コード
                    <br />
                    （6桁の数字）を以下に入力して
                    <br />
                    認証ボタンをタップしてください。
                </span>
                <div className="inputContainer">
                    <span className="title">認証コード</span>
                    <input
                        type="text"
                        autoFocus={true}
                        onChange={(event) => setCode(event.target.value)}
                        value={code}
                    />
                    {codeError !== '' && (
                        <span className="error">{codeError}</span>
                    )}
                </div>
                <div className="buttonContainer">
                    <div className="button" onClick={confirmCode}>
                        <span>認証</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        lastRoute: state['cocoro/app'].lastRoute,
        loading: state['cocoro/app'].loading,
    };
}

let _mapDispatchToProps = {
    changeRoute,
    openPopup,
    resetVerifyCode,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(InputCode);
