const getAllKeys = () => {
    const numberOfKeys = localStorage.length;
    const keys = [];
    for (let i = 0; i < numberOfKeys; i += 1) {
        const key = localStorage.key(i);
        keys.push(key);
    }
    return keys;
};

const set = (key, val) => {
    try {
        localStorage.setItem(key, JSON.stringify(val));
        return true;
    } catch (e) {
        console.log(`Storage:set -> key:${key} error`, e.message);
        return false;
    }
};

const get = (key) => {
    try {
        const val = localStorage.getItem(key);
        return JSON.parse(val);
    } catch (e) {
        console.log(`Storage:get -> key:${key} error`, e.message);
        return null;
    }
};

const multiSet = (obj) => {
    let keys = [];
    try {
        Object.keys(obj).forEach((key) => {
            localStorage.setItem(key, JSON.stringify(obj[key]));
        });
        return true;
    } catch (e) {
        console.log(
            `Storage:multiSet -> keys:[${keys.join(',')}] error`,
            e.message,
        );
        return false;
    }
};

const multiGet = (keys) => {
    try {
        const result = {};
        keys.forEach((key) => {
            const val = localStorage.getItem(key);
            result[key] = JSON.parse(val);
        });
        return result;
    } catch (e) {
        console.log(
            `Storage:multiGet -> keys:[${keys.join(',')}] error`,
            e.message,
        );
        return false;
    }
};

const remove = (key) => {
    try {
        localStorage.removeItem(key);
        return true;
    } catch (e) {
        console.log(`Storage:remove -> key:${key} error`, e.message);
        return false;
    }
};

const multiRemove = (keys) => {
    try {
        keys.forEach((key) => localStorage.removeItem(key));
        return true;
    } catch (e) {
        console.log(
            `Storage:multiRemove -> keys:[${keys.join(',')}] error`,
            e.message,
        );
        return false;
    }
};

export default {
    getAllKeys,
    set,
    get,
    remove,
    multiGet,
    multiSet,
    multiRemove,
};
