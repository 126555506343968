import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {changeRoute, openPopup} from '../../app/actions';
import {useHistory} from 'react-router-dom';
import {requestChangePassword} from '../actions';
import '../verifyStyles.scss';

function InputEmail({
    changeRoute,
    lastRoute,
    openPopup,
    requestChangePassword,
}) {
    let [email, setEmail] = useState('');
    let [emailError, setEmailError] = useState('');
    let history = useHistory();

    let toInputCode = () => {
        history.push('/verify_code');
    };

    let setResetEmail = () => {
        requestChangePassword(email, setEmailError, toInputCode);
    };

    return (
        <div id="verify">
            <header>
                <div className="back">
                    <img
                        alt=""
                        onClick={() => history.goBack()}
                        src="/images/icon-back-grey.png"
                    />
                </div>
                <h3></h3>
                <div className="none" />
            </header>
            <div className="mainContainer">
                <span className="note">
                    パスワードを忘れた方は、
                    <br />
                    アカウントのログイン時に使用する
                    <br />
                    メールアドレスを入力してくだい。
                </span>
                <div className="inputContainer">
                    <span className="title">メールアドレス</span>
                    <input
                        type="text"
                        autoFocus={true}
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}
                    />
                    {emailError !== '' && (
                        <span className="error">{emailError}</span>
                    )}
                </div>
                <div className="buttonContainer">
                    <div className="button" onClick={setResetEmail}>
                        <span>送信</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        lastRoute: state['cocoro/app'].lastRoute,
        loading: state['cocoro/app'].loading,
    };
}

let _mapDispatchToProps = {
    changeRoute,
    openPopup,
    requestChangePassword,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(InputEmail);
