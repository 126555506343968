import {post} from '../base/portal/apis';

export function changePasswordApi(
    token,
    oldPassword,
    newPassword,
    confirmPassword,
) {
    let url = '/api/v1/users/change_password';
    let data = {
        password: oldPassword,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
    };
    return post(url, token, data);
}
