import MiddlewareRegistry from '../base/redux/MiddlewareRegistry';
import {CHECK_AUTH} from './actionTypes';
import {isNative} from '../call/media';
import {loggedIn} from '../user';
import {changeRoute, setNaviIndex, closeModalPage, closePopup} from './actions';
import {Constants, persistKeys} from '../../util/Constants';
import {AsyncStoragex} from '../call/functions';

MiddlewareRegistry.register((store) => (next) => (action) => {
    const {dispatch, getState} = store;
    switch (action.type) {
        case CHECK_AUTH:
            if (action.status == 401) {
                dispatch(closePopup());
                dispatch(closeModalPage());
                dispatch(loggedIn(false));
                if (isNative) {
                    AsyncStoragex.removeItem(persistKeys.USER_INFO);
                    dispatch(setNaviIndex('home'));
                    dispatch(changeRoute('LOGIN'));
                } else {
                    localStorage.removeItem(persistKeys.USER_INFO);
                }
                return;
            }
    }

    return next(action);
});
