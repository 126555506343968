import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import '../stylesArea.scss';
import {clearSubCategories, getCategorySearch, saveFilter} from '../action';
import RadioItem from './RadioItem.web';

const SearchCategory = ({categories, filterHistory, onSelect}) => {
    const dispatch = useDispatch();

    const [selectedCategory, setSelectedCategory] = useState(
        () =>
            filterHistory.category || {
                id: '',
                name: 'カテゴリー',
            },
    );

    useEffect(() => {
        if (categories === null) {
            dispatch(getCategorySearch());
        }
    }, [categories]);

    const handleSaveSelectedCategory = (data) => {
        onSelect(data);
        if (data.id !== filterHistory.category?.id) {
            dispatch(
                saveFilter({
                    category_id: data.id,
                    category: data,
                    sub_category_id: '',
                    sub_category: null,
                    latest_joined_thread: '',
                }),
            );
            dispatch(clearSubCategories());
        }
    };

    const selectedItemRadio = (data)=>{
        setSelectedCategory(data);
        handleSaveSelectedCategory(data);
    }

    return (
        <div id="chat-content">
            <div className={"back-no-click"}/>
            <div className="filter-theme-step1-wrap">
                {/* <div className="filter-theme-text">
                    カテゴリを選択してください
                </div> */}
                <div className="filter-theme-list-option">
                    {Array.isArray(categories) &&
                        categories.map((item, index) => (
                            <RadioItem
                                key={index}
                                data={item}
                                isSelected={
                                    selectedCategory?.id === item.id
                                        ? true
                                        : false
                                }
                                setSelected={selectedItemRadio}
                            />
                        ))}
                    {/* {Array.isArray(categories) && (selectedCategory.id !== '') ? (
                        <div className="filter-theme-submit-button-wrap">
                            <button
                                className="filter-theme-submit-button"
                                onClick={handleSaveSelectedCategory}>
                                OK
                            </button>
                        </div>
                    ) : null} */}
                </div>
            </div>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    categories: state['cocoro/search'].categories,
});

export default connect(_mapStateToProps)(SearchCategory);
