import React, {useState, useEffect} from 'react';
import {getMyProfile, getGender, getAge, getArea} from '../actions';
import {connect} from 'react-redux';
import {changeRoute, openPopup} from '../../app/actions';
import {useHistory} from 'react-router-dom';
import {updateImageUri} from '../../inforeg/redux/action';
import '../styles.scss';
import ItemInfo from './ItemInfo.web';
import {Constants} from '../../../util/Constants';

function ChangeProfile({
    loading,
    profile,
    getMyProfile,
    getGender,
    getAge,
    getArea,
    updateImageUri,
    changeRoute,
    lastRoute,
    openPopup,
}) {
    let history = useHistory();

    useEffect(() => {
        getMyProfile();
    }, [getMyProfile]);

    let changeAvatar = () => {
        updateImageUri(profile.profile_image, Constants.IMAGE_TYPE_LINK, false);
        history.push('/changeavatar');
    };

    let changeUsername = () => {
        history.push('changeusername');
    };

    let changeGender = () => {
        getGender(profile.gender);
    };

    let changeAge = () => {
        getAge(profile.age_id);
    };

    let changeArea = () => {
        getArea(profile.area_id);
    };

    return (
        <div id="changeProfile">
            <header>
                <div className="back">
                    <img
                        alt=""
                        onClick={() => history.goBack()}
                        src="/images/icon-back-grey.png"
                    />
                </div>
                <h3>プロフィール</h3>
                <div className="right"></div>
            </header>
            <div className="mainContainer">
                <div className="avatarContainer">
                    <div className="imageContainer">
                        {profile.profile_image !== undefined &&
                        profile.profile_image !== null &&
                        profile.profile_image !== '' ? (
                            <img src={profile.profile_image} alt="" />
                        ) : (
                            <img src={Constants.AVATAR_DEFAULT} alt="" />
                        )}
                    </div>
                    <span className="changeAvatar" onClick={changeAvatar}>
                        変更する
                    </span>
                </div>
                <ItemInfo
                    title="ユーザーネーム"
                    action={changeUsername}
                    data={profile.user_name}
                />
                <ItemInfo
                    title="性別"
                    action={changeGender}
                    data={
                        profile.gender === '1'
                            ? '男性（M）'
                            : profile.gender === '2'
                            ? '女性（F）'
                            : '不特定（X）'
                    }
                />
                <ItemInfo
                    title="年代"
                    action={changeAge}
                    data={profile.age?.name}
                />
                <ItemInfo
                    title="エリア"
                    action={changeArea}
                    data={profile.area?.name}
                />
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        profile: state['cocoro/profile'].userProfile,
        lastRoute: state['cocoro/app'].lastRoute,
        loading: state['cocoro/app'].loading,
    };
}

let _mapDispatchToProps = {
    changeRoute,
    getMyProfile,
    getGender,
    getAge,
    getArea,
    openPopup,
    updateImageUri,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ChangeProfile);
