import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import '../stylesArea.scss';
import {getSubCategorySearch, saveFilter} from '../action';
import RadioItem from './RadioItem.web';

const SearchSubCategory = ({subcategories, filterHistory, onSelect}) => {
    const dispatch = useDispatch();

    const [selectedSubCategory, setSelectedSubCategory] = useState(
        () =>
            filterHistory.sub_category || {
                id: '',
                name: 'サブカテゴリー',
            },
    );

    useEffect(() => {
        if (subcategories === null && filterHistory.category?.id) {
            dispatch(getSubCategorySearch(filterHistory.category.id));
        }
    }, [filterHistory.category?.id, subcategories]);

    useEffect(() => {
        setSelectedSubCategory(
            filterHistory.sub_category || {
                id: '',
                name: 'サブカテゴリー',
            },
        );
    }, [filterHistory.sub_category]);

    const handleSaveSelectedSubCategory = (data) => {
        onSelect(data);
        if (data.id !== filterHistory.sub_category?.id) {
            dispatch(
                saveFilter({
                    sub_category_id: data.id,
                    sub_category: data,
                    latest_joined_thread: '',
                }),
            );
        }
    };

    const selectedItemRadio = (data)=>{
        setSelectedSubCategory(data);
        handleSaveSelectedSubCategory(data)
    }

    return (
        <div id="chat-content">
            <div className={"back-no-click"}/>
            <div className="filter-theme-step1-wrap">
                <div className="filter-theme-list-option">
                    {Array.isArray(subcategories) &&
                        subcategories.map((item, index) => {
                            return (
                                <RadioItem
                                    key={index}
                                    data={item}
                                    isSelected={
                                        selectedSubCategory?.id === item.id
                                            ? true
                                            : false
                                    }
                                    setSelected={selectedItemRadio}
                                    topicExpireTime={
                                        item?.topic_expire_after_max_minutes
                                    }
                                />
                            );
                        })}
                    {/* {(Array.isArray(subcategories) && selectedSubCategory?.id !== '') ? (
                        <div className="filter-theme-submit-button-wrap">
                            <button
                                className="filter-theme-submit-button"
                                onClick={handleSaveSelectedSubCategory}>
                                テーマをみる
                            </button>
                        </div>
                    ) : null} */}
                </div>
            </div>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    subcategories: state['cocoro/search'].subcategories,
});

export default connect(_mapStateToProps)(SearchSubCategory);
