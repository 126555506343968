import React from 'react';
import {StyleSheet, Text, View, Button} from 'react-native';
import { useRouteMatch } from "react-router-dom";
export default function InfoReg({history}) {
    let { path, url } = useRouteMatch();
    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.txt}>はじめに あなたの</Text>
                    <Text style={styles.txt}>名前、性別、年代、エリア</Text>
                    <Text style={styles.txt}>を決めましょう！</Text>
                </View>
            </View>
            <View style={styles.main}>
                <Button
                    title="Submit Info"
                    onPress={() => {
                        history.push(`${url}/avata`)
                    }}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#A6D837',
    },
    header: {
        alignItems: 'center',
        height: 180,
        paddingBottom: 30,
        flexDirection: 'column-reverse',
    },
    txt: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    main: {
        flex: 1,
        backgroundColor: '#F4F9FA',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
});
