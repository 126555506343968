import React from 'react';
import {useDispatch} from 'react-redux';
import {requestBonusChat} from '../actions';
import {formatPrice} from '../functions';

function Price({roomInfo, userInfo}) {
    const dispatch = useDispatch();

    const handleRequestBonusChat = () => {
        dispatch(requestBonusChat(roomInfo.id));
    };

    return (
        <div id="footer_price">
            {Boolean(roomInfo.topic?.user_id == userInfo.id) &&
                Boolean(roomInfo.topic?.chat_price) &&
                Boolean(roomInfo.topic.chat_price != 0) &&
                (roomInfo.bonus === false ? (
                    <span className="waiting">
                        課金開始: {formatPrice(roomInfo.topic.chat_price)}
                        ポイント
                    </span>
                ) : (
                    <span onClick={handleRequestBonusChat}>
                        課金開始: {formatPrice(roomInfo.topic.chat_price)}
                        ポイント
                    </span>
                ))}
        </div>
    );
}

export default Price;
