import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {ReactComponent as BackIcon} from '../../../../images/chevron-back-outline.svg';

export default function BackBtn({onPress}) {
    return (
        <TouchableOpacity onPress={onPress} style={styles.container}>
            <BackIcon style={styles.backIcon} />
        </TouchableOpacity>
    );
}

const wh = 36;

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        width: wh,
        height: wh,
    },
});

styles.backIcon = {height: wh, width: wh};
