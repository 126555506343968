import {Dimensions, Platform} from 'react-native';
import {URL_WEB as uri} from '../../env.json';

const maleAvatar = [
    {url: uri + '/images/male/avtar_m_1.png',},
    {url: uri + '/images/male/avtar_m_10.png',},
    {url: uri + '/images/male/avtar_m_11.png',},
    {url: uri + '/images/male/avtar_m_12.png',},
    {url: uri + '/images/male/avtar_m_13.png',},
    {url: uri + '/images/male/avtar_m_14.png',},
    {url: uri + '/images/male/avtar_m_15.png',},
    {url: uri + '/images/male/avtar_m_16.png',},
    {url: uri + '/images/male/avtar_m_17.png',},
    {url: uri + '/images/male/avtar_m_18.png',},
    {url: uri + '/images/male/avtar_m_19.png',},
    {url: uri + '/images/male/avtar_m_2.png',},
    {url: uri + '/images/male/avtar_m_20.png',},
    {url: uri + '/images/male/avtar_m_21.png',},
    {url: uri + '/images/male/avtar_m_22.png',},
    {url: uri + '/images/male/avtar_m_23.png',},
    {url: uri + '/images/male/avtar_m_24.png',},
    {url: uri + '/images/male/avtar_m_25.png',},
    {url: uri + '/images/male/avtar_m_26.png',},
    {url: uri + '/images/male/avtar_m_27.png',},
    {url: uri + '/images/male/avtar_m_28.png',},
    {url: uri + '/images/male/avtar_m_29.png',},
    {url: uri + '/images/male/avtar_m_3.png',},
    {url: uri + '/images/male/avtar_m_30.png',},
    {url: uri + '/images/male/avtar_m_31.png',},
    {url: uri + '/images/male/avtar_m_32.png',},
    {url: uri + '/images/male/avtar_m_33.png',},
    {url: uri + '/images/male/avtar_m_34.png',},
    {url: uri + '/images/male/avtar_m_35.png',},
    {url: uri + '/images/male/avtar_m_36.png',},
    {url: uri + '/images/male/avtar_m_37.png',},
    {url: uri + '/images/male/avtar_m_38.png',},
    {url: uri + '/images/male/avtar_m_39.png',},
    {url: uri + '/images/male/avtar_m_4.png',},
    {url: uri + '/images/male/avtar_m_40.png',},
    {url: uri + '/images/male/avtar_m_41.png',},
    {url: uri + '/images/male/avtar_m_42.png',},
    {url: uri + '/images/male/avtar_m_43.png',},
    {url: uri + '/images/male/avtar_m_44.png',},
    {url: uri + '/images/male/avtar_m_45.png',},
    {url: uri + '/images/male/avtar_m_46.png',},
    {url: uri + '/images/male/avtar_m_47.png',},
    {url: uri + '/images/male/avtar_m_48.png',},
    {url: uri + '/images/male/avtar_m_49.png',},
    {url: uri + '/images/male/avtar_m_5.png',},
    {url: uri + '/images/male/avtar_m_50.png',},
    {url: uri + '/images/male/avtar_m_51.png',},
    {url: uri + '/images/male/avtar_m_52.png',},
    {url: uri + '/images/male/avtar_m_53.png',},
    {url: uri + '/images/male/avtar_m_54.png',},
    {url: uri + '/images/male/avtar_m_55.png',},
    {url: uri + '/images/male/avtar_m_56.png',},
    {url: uri + '/images/male/avtar_m_57.png',},
    {url: uri + '/images/male/avtar_m_58.png',},
    {url: uri + '/images/male/avtar_m_59.png',},
    {url: uri + '/images/male/avtar_m_6.png',},
    {url: uri + '/images/male/avtar_m_60.png',},
    {url: uri + '/images/male/avtar_m_61.png',},
    {url: uri + '/images/male/avtar_m_62.png',},
    {url: uri + '/images/male/avtar_m_63.png',},
    {url: uri + '/images/male/avtar_m_64.png',},
    {url: uri + '/images/male/avtar_m_65.png',},
    {url: uri + '/images/male/avtar_m_66.png',},
    {url: uri + '/images/male/avtar_m_67.png',},
    {url: uri + '/images/male/avtar_m_68.png',},
    {url: uri + '/images/male/avtar_m_69.png',},
    {url: uri + '/images/male/avtar_m_7.png',},
    {url: uri + '/images/male/avtar_m_70.png',},
    {url: uri + '/images/male/avtar_m_71.png',},
    {url: uri + '/images/male/avtar_m_72.png',},
    {url: uri + '/images/male/avtar_m_73.png',},
    {url: uri + '/images/male/avtar_m_74.png',},
    {url: uri + '/images/male/avtar_m_75.png',},
    {url: uri + '/images/male/avtar_m_8.png',},
    {url: uri + '/images/male/avtar_m_9.png',},
];

const female = [
    {url: uri + '/images/female/avatar_f_1.png',},
	{url: uri + '/images/female/avatar_f_10.png',},
	{url: uri + '/images/female/avatar_f_11.png',},
	{url: uri + '/images/female/avatar_f_12.png',},
	{url: uri + '/images/female/avatar_f_13.png',},
	{url: uri + '/images/female/avatar_f_14.png',},
	{url: uri + '/images/female/avatar_f_15.png',},
	{url: uri + '/images/female/avatar_f_16.png',},
	{url: uri + '/images/female/avatar_f_17.png',},
	{url: uri + '/images/female/avatar_f_18.png',},
	{url: uri + '/images/female/avatar_f_19.png',},
	{url: uri + '/images/female/avatar_f_2.png',},
	{url: uri + '/images/female/avatar_f_20.png',},
	{url: uri + '/images/female/avatar_f_21.png',},
	{url: uri + '/images/female/avatar_f_22.png',},
	{url: uri + '/images/female/avatar_f_23.png',},
	{url: uri + '/images/female/avatar_f_24.png',},
	{url: uri + '/images/female/avatar_f_25.png',},
	{url: uri + '/images/female/avatar_f_26.png',},
	{url: uri + '/images/female/avatar_f_27.png',},
	{url: uri + '/images/female/avatar_f_28.png',},
	{url: uri + '/images/female/avatar_f_29.png',},
	{url: uri + '/images/female/avatar_f_3.png',},
	{url: uri + '/images/female/avatar_f_30.png',},
	{url: uri + '/images/female/avatar_f_31.png',},
	{url: uri + '/images/female/avatar_f_32.png',},
	{url: uri + '/images/female/avatar_f_33.png',},
	{url: uri + '/images/female/avatar_f_34.png',},
	{url: uri + '/images/female/avatar_f_35.png',},
	{url: uri + '/images/female/avatar_f_36.png',},
	{url: uri + '/images/female/avatar_f_37.png',},
	{url: uri + '/images/female/avatar_f_38.png',},
	{url: uri + '/images/female/avatar_f_39.png',},
	{url: uri + '/images/female/avatar_f_4.png',},
	{url: uri + '/images/female/avatar_f_40.png',},
	{url: uri + '/images/female/avatar_f_41.png',},
	{url: uri + '/images/female/avatar_f_42.png',},
	{url: uri + '/images/female/avatar_f_43.png',},
	{url: uri + '/images/female/avatar_f_44.png',},
	{url: uri + '/images/female/avatar_f_45.png',},
	{url: uri + '/images/female/avatar_f_46.png',},
	{url: uri + '/images/female/avatar_f_47.png',},
	{url: uri + '/images/female/avatar_f_48.png',},
	{url: uri + '/images/female/avatar_f_49.png',},
	{url: uri + '/images/female/avatar_f_5.png',},
	{url: uri + '/images/female/avatar_f_50.png',},
	{url: uri + '/images/female/avatar_f_51.png',},
	{url: uri + '/images/female/avatar_f_52.png',},
	{url: uri + '/images/female/avatar_f_53.png',},
	{url: uri + '/images/female/avatar_f_54.png',},
	{url: uri + '/images/female/avatar_f_55.png',},
	{url: uri + '/images/female/avatar_f_56.png',},
	{url: uri + '/images/female/avatar_f_57.png',},
	{url: uri + '/images/female/avatar_f_58.png',},
	{url: uri + '/images/female/avatar_f_59.png',},
	{url: uri + '/images/female/avatar_f_6.png',},
	{url: uri + '/images/female/avatar_f_60.png',},
	{url: uri + '/images/female/avatar_f_61.png',},
	{url: uri + '/images/female/avatar_f_62.png',},
	{url: uri + '/images/female/avatar_f_63.png',},
	{url: uri + '/images/female/avatar_f_64.png',},
	{url: uri + '/images/female/avatar_f_65.png',},
	{url: uri + '/images/female/avatar_f_66.png',},
	{url: uri + '/images/female/avatar_f_67.png',},
	{url: uri + '/images/female/avatar_f_68.png',},
	{url: uri + '/images/female/avatar_f_69.png',},
	{url: uri + '/images/female/avatar_f_7.png',},
	{url: uri + '/images/female/avatar_f_70.png',},
	{url: uri + '/images/female/avatar_f_71.png',},
	{url: uri + '/images/female/avatar_f_72.png',},
	{url: uri + '/images/female/avatar_f_73.png',},
	{url: uri + '/images/female/avatar_f_74.png',},
	{url: uri + '/images/female/avatar_f_75.png',},
	{url: uri + '/images/female/avatar_f_76.png',},
	{url: uri + '/images/female/avatar_f_8.png',},
	{url: uri + '/images/female/avatar_f_9.png',},
];

const maleAvatar_female = [
    {url: uri + '/images/female/avatar_f_1.png',},
	{url: uri + '/images/female/avatar_f_10.png',},
	{url: uri + '/images/female/avatar_f_11.png',},
	{url: uri + '/images/female/avatar_f_12.png',},
	{url: uri + '/images/female/avatar_f_13.png',},
	{url: uri + '/images/female/avatar_f_14.png',},
	{url: uri + '/images/female/avatar_f_15.png',},
	{url: uri + '/images/female/avatar_f_16.png',},
	{url: uri + '/images/female/avatar_f_17.png',},
	{url: uri + '/images/female/avatar_f_18.png',},
	{url: uri + '/images/female/avatar_f_19.png',},
	{url: uri + '/images/female/avatar_f_2.png',},
	{url: uri + '/images/female/avatar_f_20.png',},
	{url: uri + '/images/female/avatar_f_21.png',},
	{url: uri + '/images/female/avatar_f_22.png',},
	{url: uri + '/images/female/avatar_f_23.png',},
	{url: uri + '/images/female/avatar_f_24.png',},
	{url: uri + '/images/female/avatar_f_25.png',},
	{url: uri + '/images/female/avatar_f_26.png',},
	{url: uri + '/images/female/avatar_f_27.png',},
	{url: uri + '/images/female/avatar_f_28.png',},
	{url: uri + '/images/female/avatar_f_29.png',},
	{url: uri + '/images/female/avatar_f_3.png',},
	{url: uri + '/images/female/avatar_f_30.png',},
	{url: uri + '/images/female/avatar_f_31.png',},
	{url: uri + '/images/female/avatar_f_32.png',},
	{url: uri + '/images/female/avatar_f_33.png',},
	{url: uri + '/images/female/avatar_f_34.png',},
	{url: uri + '/images/female/avatar_f_35.png',},
	{url: uri + '/images/female/avatar_f_36.png',},
	{url: uri + '/images/female/avatar_f_37.png',},
	{url: uri + '/images/female/avatar_f_38.png',},
	{url: uri + '/images/female/avatar_f_39.png',},
	{url: uri + '/images/female/avatar_f_4.png',},
	{url: uri + '/images/female/avatar_f_40.png',},
	{url: uri + '/images/female/avatar_f_41.png',},
	{url: uri + '/images/female/avatar_f_42.png',},
	{url: uri + '/images/female/avatar_f_43.png',},
	{url: uri + '/images/female/avatar_f_44.png',},
	{url: uri + '/images/female/avatar_f_45.png',},
	{url: uri + '/images/female/avatar_f_46.png',},
	{url: uri + '/images/female/avatar_f_47.png',},
	{url: uri + '/images/female/avatar_f_48.png',},
	{url: uri + '/images/female/avatar_f_49.png',},
	{url: uri + '/images/female/avatar_f_5.png',},
	{url: uri + '/images/female/avatar_f_50.png',},
    {url: uri + '/images/female/avatar_f_51.png',},
	{url: uri + '/images/female/avatar_f_52.png',},
    {url: uri + '/images/male/avtar_m_1.png',},
    {url: uri + '/images/male/avtar_m_10.png',},
    {url: uri + '/images/male/avtar_m_11.png',},
    {url: uri + '/images/male/avtar_m_12.png',},
    {url: uri + '/images/male/avtar_m_13.png',},
    {url: uri + '/images/male/avtar_m_14.png',},
    {url: uri + '/images/male/avtar_m_15.png',},
    {url: uri + '/images/male/avtar_m_16.png',},
    {url: uri + '/images/male/avtar_m_17.png',},
    {url: uri + '/images/male/avtar_m_18.png',},
    {url: uri + '/images/male/avtar_m_19.png',},
    {url: uri + '/images/male/avtar_m_2.png',},
    {url: uri + '/images/male/avtar_m_20.png',},
    {url: uri + '/images/male/avtar_m_21.png',},
    {url: uri + '/images/male/avtar_m_22.png',},
    {url: uri + '/images/male/avtar_m_23.png',},
    {url: uri + '/images/male/avtar_m_24.png',},
    {url: uri + '/images/male/avtar_m_25.png',},
    {url: uri + '/images/male/avtar_m_26.png',},
    {url: uri + '/images/male/avtar_m_27.png',},
    {url: uri + '/images/male/avtar_m_28.png',},
    {url: uri + '/images/male/avtar_m_29.png',},
    {url: uri + '/images/male/avtar_m_3.png',},
    {url: uri + '/images/male/avtar_m_30.png',},
    {url: uri + '/images/male/avtar_m_31.png',},
    {url: uri + '/images/male/avtar_m_32.png',},
    {url: uri + '/images/male/avtar_m_33.png',},
    {url: uri + '/images/male/avtar_m_34.png',},
    {url: uri + '/images/male/avtar_m_35.png',},
    {url: uri + '/images/male/avtar_m_36.png',},
    {url: uri + '/images/male/avtar_m_37.png',},
    {url: uri + '/images/male/avtar_m_38.png',},
    {url: uri + '/images/male/avtar_m_39.png',},
    {url: uri + '/images/male/avtar_m_4.png',},
    {url: uri + '/images/male/avtar_m_40.png',},
    {url: uri + '/images/male/avtar_m_41.png',},
    {url: uri + '/images/male/avtar_m_42.png',},
    {url: uri + '/images/male/avtar_m_43.png',},
    {url: uri + '/images/male/avtar_m_44.png',},
    {url: uri + '/images/male/avtar_m_45.png',},
    {url: uri + '/images/male/avtar_m_46.png',},
    {url: uri + '/images/male/avtar_m_47.png',},
    {url: uri + '/images/male/avtar_m_48.png',},
    {url: uri + '/images/male/avtar_m_49.png',},
    {url: uri + '/images/male/avtar_m_5.png',},
    {url: uri + '/images/male/avtar_m_50.png',},
    {url: uri + '/images/male/avtar_m_51.png',},
    {url: uri + '/images/male/avtar_m_52.png',},
    {url: uri + '/images/male/avtar_m_53.png',},
    {url: uri + '/images/male/avtar_m_54.png',},
    {url: uri + '/images/male/avtar_m_55.png',},
    {url: uri + '/images/male/avtar_m_56.png',},
    {url: uri + '/images/male/avtar_m_57.png',},
    {url: uri + '/images/male/avtar_m_58.png',},
    {url: uri + '/images/male/avtar_m_59.png',},
    {url: uri + '/images/male/avtar_m_6.png',},
    {url: uri + '/images/male/avtar_m_60.png',},
    {url: uri + '/images/male/avtar_m_61.png',},
    {url: uri + '/images/male/avtar_m_62.png',},
    {url: uri + '/images/male/avtar_m_63.png',},
    {url: uri + '/images/male/avtar_m_64.png',},
    {url: uri + '/images/male/avtar_m_65.png',},
    {url: uri + '/images/male/avtar_m_66.png',},
    {url: uri + '/images/male/avtar_m_67.png',},
    {url: uri + '/images/male/avtar_m_68.png',},
    {url: uri + '/images/male/avtar_m_69.png',},
    {url: uri + '/images/male/avtar_m_7.png',},
    {url: uri + '/images/male/avtar_m_70.png',},
    {url: uri + '/images/male/avtar_m_71.png',},
    {url: uri + '/images/male/avtar_m_72.png',},
    {url: uri + '/images/male/avtar_m_73.png',},
    {url: uri + '/images/male/avtar_m_74.png',},
    {url: uri + '/images/male/avtar_m_75.png',},
    {url: uri + '/images/male/avtar_m_8.png',},
    {url: uri + '/images/male/avtar_m_9.png',},
];

export const getDefaultImages = (age_id, gender) => {
    if(gender === '1')
        return maleAvatar;
    if(gender === '2')
        return female;
    if(gender === '3')
        return maleAvatar_female;
    return [];
};
