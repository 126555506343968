import React from 'react';
import '../styles.scss';
export default function HeaderTopic({onPressBack, onPressClear}) {
    return (
        <div id="header">
            {/* <span id="back-button" onClick={() => onPressBack()}>
                キャンセル
            </span> */}
                <img
                    id={"back-button"}
                    alt=""
                    onClick={onPressBack}
                    src="/images/icon-back-grey.png"
                />
            <h3>絞り込み</h3>
            <span id="reset-button" onClick={() => onPressClear()}>
                すべてクリア
            </span>
        </div>
    );
}
