import React from 'react';
import {connect} from 'react-redux';
import {Login} from '../../user';
import { Splash } from "../../splash";
import {SignUp, CodeConfirm, AgreementView} from '../../signup';
import {
    InfoReg,
    Avatar,
    TakePicture,
    ChangeAvatar,
    DefaultImages,
} from '../../inforeg';
import {
    ProfileConfirm,
    SettingAvatar,
    SettingInformation,
    SettingBlood,
    SettingBlood1,
    TakeAvatar,
    ListPicture,
    SettingSoundChange,
    SettingSoundChange1,
    SettingCharacter,
    SettingCharacter1,
    SettingCharacterResult,
} from '../../infoRegister';
import {Chat, Present, Trumpet} from '../../chat';
import {HomeScreenMode, HomeScreenModeNew} from '../../homescreen';
import {FilterTopic} from '../../filtertopic';
import {FriendList, FriendCallInfo} from '../../friendlist';
import {SearchScreen} from '../../search';
import {AddFriend} from '../../addfriend';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {browserHistory} from 'react-dom';
import PrivateComponent from './PrivateComponent';
import BottomTabsLayout from './BottomTabsLayout.js';
import Loading from './Loading';
import Popup from './Popup';
import ActionPopup from './ActionPopup';
import ModalPage from './ModalPage';
import {CallWrap} from '../../call';
import {closePopup, closeActionPopup} from '../actions';
import '../styles.scss';
import {MyPage, SNSInfo, ManagePoint} from '../../mypage';
import {Settings, BlockList} from '../../settings';
import {ChangeProfile, ChangeUsername} from '../../changeprofile';
import {HistoryList, ReplyList} from '../../history';
import {ChangePassword} from '../../changepassword';
import {
    InputCode,
    InputEmail,
    ResetPassword,
} from '../../resetpassword/components';
import { Tutorial } from "../../tutorial";
import { OTP, OTPConfirm, OTPResult } from "../../OTP";
import WhiteScreen from "./WhiteScreen.web";



let ForgetPass = () => {
    return <p>ForgetPass</p>;
};

function Main({
    loading,
    popup,
    closePopup,
    modal,
    actionPopup,
    closeActionPopup,
}) {
    return (
        <main id="page-wrap">
            <Router history={browserHistory}>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/whitescreen" component={WhiteScreen} />
                    <Route path="/splash" component={Splash} />
                    <Route path="/tutorial" component={Tutorial} />
                    <Route path="/verify_email" component={InputEmail} />
                    <Route path="/verify_code" component={InputCode} />
                    <Route path="/reset_password" component={ResetPassword} />
                    <Route path="/signup" component={SignUp} />
                    <Route path="/agree" component={AgreementView} />
                    <Route path="/code" component={CodeConfirm} />
                    <Route path="/term_of_use" component={AgreementView} />
                    <Route path="/forgetpass" component={ForgetPass} />
                    <Route path="/OTP" component={OTP}/>
                    <Route path="/OTPConfirm" component={OTPConfirm}/>
                    <Route path="/OTPResult" component={OTPResult} />

                    <Route path="/">
                        <PrivateComponent>
                            <CallWrap />
                            <Route exact path="/">
                                <BottomTabsLayout>
                                    <Route
                                        exact
                                        path="/"
                                        // component={SearchScreen}
                                        component={HomeScreenModeNew}
                                    />
                                </BottomTabsLayout>
                            </Route>
                            <Route exact path="/friendlist">
                                <BottomTabsLayout>
                                    <Route
                                        exact
                                        path="/friendlist"
                                        component={FriendList}
                                    />
                                </BottomTabsLayout>
                            </Route>
                            <Route exact path="/friendlistcall">
                                <BottomTabsLayout>
                                    <Route
                                        exact
                                        path="/friendlistcall"
                                        component={FriendCallInfo}
                                    />
                                </BottomTabsLayout>
                            </Route>
                            <Route exact path="/addfriend">
                                <BottomTabsLayout>
                                    <Route
                                        exact
                                        path="/addfriend"
                                        component={AddFriend}
                                    />
                                </BottomTabsLayout>
                            </Route>
                            <Route exact path="/search">
                                <BottomTabsLayout>
                                    <Route
                                        exact
                                        path="/search"
                                        component={HomeScreenMode}
                                    />
                                </BottomTabsLayout>
                            </Route>
                            <Route exact path="/search_list">
                                <BottomTabsLayout>
                                    <Route
                                        exact
                                        path="/search_list"
                                        component={SearchScreen}
                                    />
                                </BottomTabsLayout>
                            </Route>
                            <Route exact path="/mypage">
                                <BottomTabsLayout>
                                    <Route
                                        exact
                                        path="/mypage"
                                        component={MyPage}
                                    />
                                </BottomTabsLayout>
                            </Route>
                            <Route exact path="/manage-point">
                                <BottomTabsLayout>
                                    <Route
                                        exact
                                        path="/manage-point"
                                        component={ManagePoint}
                                    />
                                </BottomTabsLayout>
                            </Route>
                            <Route
                                exact
                                path="/settings"
                                component={Settings}
                            />
                            <Route
                                exact
                                path="/filtertopic"
                                component={FilterTopic}
                            />
                            <Route exact path="/history/:topicId/replies">
                                <BottomTabsLayout>
                                    <Route
                                        exact
                                        path="/history/:topicId/replies"
                                        component={ReplyList}
                                    />
                                </BottomTabsLayout>
                            </Route>
                            <Route exact path="/history">
                                <BottomTabsLayout>
                                    <Route
                                        exact
                                        path="/history"
                                        component={HistoryList}
                                    />
                                </BottomTabsLayout>
                            </Route>
                            <Route path="/chat/:roomId" component={Chat} />
                            <Route path="/present/:roomId" component={Present} />
                            <Route path="/trumpet/:roomId" component={Trumpet} />
                            <Route path="/blocklist" component={BlockList} />
                            <Route
                                path="/changeprofile"
                                component={ChangeProfile}
                            />
                            <Route
                                path="/changeusername"
                                component={ChangeUsername}
                            />
                            <Route
                                path="/changeavatar"
                                component={ChangeAvatar}
                            />
                            <Route
                                path="/takepicture"
                                component={TakePicture}
                            />
                            <Route
                                path="/defaultimages"
                                component={DefaultImages}
                            />
                            <Route
                                path="/changepassword"
                                component={ChangePassword}
                            />
                            <Route path="/sns_info" component={SNSInfo} />
                            <Route
                                exact
                                path="/registration-information/avatar/list"
                                component={ListPicture}
                            />
                            <Route
                                exact
                                path="/registration-information/avatar/take-picture"
                                component={TakeAvatar}
                            />
                            <Route
                                exact
                                path="/registration-information/confirm"
                                component={ProfileConfirm}
                            />
                            <Route
                                exact
                                path="/registration-information/avatar"
                                component={SettingAvatar}
                            />
                            <Route
                                exact
                                path="/registration-information"
                                component={SettingInformation}
                            />
                            <Route
                                exact
                                path="/registration-information/sound-setting"
                                component={SettingSoundChange}
                            />
                            <Route
                                exact
                                path="/registration-information/sound-setting-1"
                                component={SettingSoundChange1}
                            />
                            <Route
                                exact
                                path="/registration-information/blood-setting-1"
                                component={SettingBlood1}
                            />
                            <Route
                                exact
                                path="/registration-information/blood-setting"
                                component={SettingBlood}
                            />
                            <Route
                                exact
                                path="/registration-information/character-setting"
                                component={SettingCharacter}
                            />
                            <Route
                                exact
                                path="/registration-information/character-setting-1"
                                component={SettingCharacter1}
                            />
                            <Route
                                exact
                                path="/registration-information/result-setting"
                                component={SettingCharacterResult}
                            />
                        </PrivateComponent>
                    </Route>
                </Switch>

                {loading && <Loading />}
                {popup.status && (
                    <Popup closePopup={closePopup} data={popup.data} />
                )}
                {actionPopup.status && (
                    <ActionPopup
                        closePopup={closeActionPopup}
                        data={actionPopup.data}
                        action={actionPopup.action}
                    />
                )}
                {modal.status && <ModalPage />}
            </Router>
        </main>
    );
}

function _mapStateToProps(state) {
    return {
        loading: state['cocoro/app'].loading,
        popup: state['cocoro/app'].popup,
        actionPopup: state['cocoro/app'].actionPopup,
        modal: state['cocoro/app'].modal,
    };
}

let _mapDispatchToProps = {
    closePopup,
    closeActionPopup,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(Main);
