import {URL_API} from '../../env.json';
import {data} from 'jquery';

const FormData = require('form-data');
const axios = require('axios');

export function getAllArea(token) {
    return new Promise(function (resolve, reject) {
        const apiURL = URL_API + '/api/v1/areas';
        const header = {Authorization: 'Bearer ' + token};
        const form = new FormData();

        axios({
            method: 'GET',
            url: apiURL,
            headers: header,
            data: form,
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error.response && error.response.status) {
                    resolve({
                        status: error.response.status,
                        message: error.response
                            ? error.response.data.message
                            : '',
                    });
                } else {
                    resolve({
                        status: 404,
                        message: error.response
                            ? error.response.data.message
                            : '',
                    });
                }
            });
    });
}

export function getAllAgeApi(token) {
    return new Promise(function (resolve, reject) {
        const apiURL = URL_API + '/api/v1/age';
        const header = {Authorization: 'Bearer ' + token};
        const form = new FormData();

        axios({
            method: 'GET',
            url: apiURL,
            headers: header,
            data: form,
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error.response && error.response.status) {
                    resolve({
                        status: error.response.status,
                        message: error.response
                            ? error.response.data.message
                            : '',
                    });
                } else {
                    resolve({
                        status: 404,
                        message: error.response
                            ? error.response.data.message
                            : '',
                    });
                }
            });
    });
}

export function updateUserApi(
    age,
    is_finish,
    area,
    gender,
    type,
    profile_image_data,
    user_name,
    userAvatarRegisterInfo,
    userBloodSettings,
    userCharacterSettings,
    userRegisterInfo,
    userSoundSettings,
    userCharacterInputWord,
    userCharacterInputDetail,
    userCoin,
    setErr,
    token,
) {
    return new Promise(function (resolve, reject) {
        const apiURL = URL_API + '/api/v1/users';
        const header = {Authorization: 'Bearer ' + token};
        // TOKEN TEST
        const form = new FormData();
        form.append('age', age);
        form.append('area', area);
        form.append('is_infomation', is_finish);
        form.append('gender', gender);
        form.append('type', type);
        form.append('profile_image_data', profile_image_data);
        form.append('user_name', user_name);
        form.append('userAvatarRegisterInfo', userAvatarRegisterInfo);
        form.append('userBloodSettings', userBloodSettings);
        form.append('userCharacterSettings', userCharacterSettings);
        form.append('userRegisterInfo', userRegisterInfo);
        form.append('userSoundSettings', userSoundSettings);
        form.append('userCharacterInputWord', userCharacterInputWord);
        form.append('userCharacterInputDetail', userCharacterInputDetail);
        form.append('userCoin', userCoin);
        axios({
            method: 'PUT',
            url: apiURL,
            headers: header,
            data: form,
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error.response && error.response.status) {
                    setErr(error.response.data.errors);
                    resolve({
                        status: error.response.status,
                        message: error.response
                            ? error.response.data.message
                            : '',
                    });
                } else {
                    resolve({
                        status: 404,
                        message: error.response
                            ? error.response.data.message
                            : '',
                    });
                }
            });
    });
}

export function getImagesApi(age) {
    return new Promise(function (resolve, reject) {
        let url = URL_API + '/api/v1/age/' + age + '/images';
        axios
            .get(url)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error.response && error.response.status) {
                    resolve({
                        status: error.response.status,
                        message: error.response
                            ? error.response.data.message
                            : '想定しないエラーが発生しました。',
                    });
                } else {
                    resolve({
                        status: 404,
                        message: error.response
                            ? error.response.data.message
                            : '想定しないエラーが発生しました。',
                    });
                }
            });
    });
}
