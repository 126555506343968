/* eslint-disable no-shadow */
import React, {useMemo} from 'react';
import '../styles.scss';
import {useHistory} from 'react-router-dom';

const Toolbar = ({title, flag}) => {
    const history = useHistory();
    const handleClickFilterTopic = () => {
        history.push({
            pathname: '/filtertopic',
        });
    };

    return (
        <header>
            <div className={"title"}>
                <h2>{title}</h2>
                { !flag ?
                    <div className={'filter'} onClick={()=>{handleClickFilterTopic()}}>
                        <img
                            className="imgFilter"
                            src="/images/icon-un-filter.svg"
                        />
                        <span type="button">絞り込み</span>
                    </div>
                    : null
                 }
            </div>
        </header>
    );
};

export default Toolbar;
