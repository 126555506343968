import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import '../styles.scss';
import {login} from '../actions';
import {validateUsername, validatePassword} from '../functions';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Login = ({loggedIn, userInfo}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (loggedIn && userInfo) {
            if (userInfo.is_infomation === false) {
                history.push('/setting-information');
            } else {
                history.push('/');
            }
        }
    }, [loggedIn, userInfo]);

    const [username, setUsername] = useState('');
    const [usernameErr, setUsernameErr] = useState('');
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [err, setErr] = useState('');

    const handleLogin = async () => {
        setErr('');
        const valUser = validateUsername(username);
        setUsernameErr(valUser);
        const valPass = validatePassword(password);
        setPasswordErr(valPass);

        if (valUser === null && valPass === null) {
            const result = await dispatch(login(username, password));
            if (result?.error && result?.message) {
                setErr(result.message);
            }
            if (result?.access_token) {
                if (result.is_infomation === false) {
                    history.push('/setting-information');
                } else {
                    history.push('/');
                }
            }
        }
    };

    return (
        <div id="login">
            <header>
                <span>こころの声を届ける 本音のSNS</span>
                <br />
                <img src="/images/img_logo.png" />
            </header>
            <main>
                <label htmlFor="username">メールアドレス</label>
                <br />
                <input
                    id="username"
                    value={username}
                    type="text"
                    onChange={(e) => {
                        setUsername(e.target.value);
                    }}
                />
                <br />
                <span className="msg">{usernameErr}</span>
                <label htmlFor="password">パスワード</label>
                <br />
                <input
                    id="password"
                    value={password}
                    type="password"
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                />
                <br />
                <span className="msg">{passwordErr}</span>
                <span className="msg">{err}</span>
                <div>
                    <Link className="regiter" to="/verify_email">
                        パスワードを忘れてしまった場合
                    </Link>
                </div>
                <div>
                    <button onClick={handleLogin} type="button">
                        ログイン
                    </button>
                </div>
                <div>
                    <Link className="forgetpass" to="/tutorial">
                        新しくアカウントを作成する
                    </Link>
                </div>
            </main>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    loggedIn: state['cocoro/user'].loggedIn,
    userInfo: state['cocoro/user'].userInfo,
});

export default connect(_mapStateToProps)(Login);
