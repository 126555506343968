import React, {Component, } from 'react';

export default class Dot extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <button
                style={this.props.pressStatus ? styles.selectedDot : styles.dot}
                onClick={this.props.setPageIndex}
            />
        );
    }
}



const dotSize = 7;
const selectedDotSize = 2 + dotSize;

const styles = {
    dot: {
        backgroundColor: '#84BF00',
        borderRadius: dotSize / 2,
        height: dotSize,
        width: dotSize,
        margin: 5,
        borderWidth:0,
    },
    selectedDot: {
        backgroundColor: '#FFFFFF',
        borderRadius: selectedDotSize / 2,
        height: selectedDotSize,
        width: selectedDotSize,
        margin: 5,
        borderWidth: 0,
    },
};
