import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom';
import '../styles.scss';
import SearchArea from './SearchArea.web';
import SearchCategory from './SearchCategory.web';
import SearchSubCategory from './SearchSubCategory.web';
import ThreadOtherCreate from './ThreadOtherCreate.web';
import ThreadMyCreate from './ThreadMyCreate.web';
import CreateThreadScreen from './CreateThreadScreen.web';
import CreateThreadSuccess from './CreateThreadSuccess.web';
import FilterTopic from './FilterTopic.web';
import ToolBar from './ToolBar.web';
import {getThreadList, updateFirstSearch} from '../action';
import {getMyFilter, updateFilterHistory} from '../../user/actions';
import {setNaviIndex, openPopup} from '../../app/actions';
import {Views} from '../constants';
import {isFirstSearch} from '../function';
import { DEFAULT_TEMA_TITLE } from "../../app/constants";
import {createThread} from '../action';
import {saveFilter} from '../action';
import {commonMessages} from '../../../util/Constants';

const SearchScreen = ({userInfo, filterHistory}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const perPage = 30;
    const [isInitialized, setInitialized] = useState(false);
    const [threads, setThreads] = useState(null);
    const [currentView, setCurrentView] = useState(null);
    const [idThreadCreate, setIdThreadCreate] = useState(null);
    const [, setErr] = useState(null);

    useEffect(() => {
        async function getInitData() {
            const result = await dispatch(getMyFilter());
            if (result) {
                dispatch(updateFilterHistory(result));
            }
            setViewDefault(result);
            setInitialized(true);
        }
        getInitData();
    }, []);

    const setViewDefault = (history) => {
        if (isFirstSearch(history)) {
            // setCurrentView(Views.WelcomeSearch);
            setCurrentView(Views.SelectCategory);
        } else if (history.area?.id && history.sub_category?.id) {
            setCurrentView(Views.ShowThread);
            // reloadThreadData();
        } else if (history.area?.id && history.category?.id) {
            setCurrentView(Views.SelectCategory);
        } else if (history.area?.id) {
            setCurrentView(Views.SelectCategory);
        } else {
            setCurrentView(Views.SelectCategory);
        }
    };

    useEffect(() => {
        dispatch(setNaviIndex('search'));
    }, []);

    useEffect(()=>{
        
    })

    useEffect(() => {
        if (
            isInitialized &&
            // filterHistory.is_first_search === false &&
            location.state?.view
        ) {
            switch (location.state.view) {
                case Views.SelectArea:
                    setCurrentView(Views.SelectArea);
                    break;

                case Views.SelectCategory:
                    setCurrentView(Views.SelectCategory);
                    break;

                case Views.SelectSubCategory:
                    if(filterHistory?.category?.name && filterHistory?.category?.name != '')
                        setCurrentView(Views.SelectSubCategory);
                    else
                        setCurrentView(Views.SelectCategory);
                    break;
            }
        }
    }, [isInitialized, location]);


    const handleClickAreaTab = () => {
        history.push('/filtertopic')
    };

    const handleClickCategoryTab = () => {
        setCurrentView(Views.SelectCategory);
    };

    const handleClickSubCategoryTab = () => {
        setCurrentView(Views.SelectSubCategory);
    };

    const handleSelectedArea = (value) => {
        dispatch(updateFilterHistory({...filterHistory, area: value}));
        setCurrentView(Views.ShowThread);
    };

    const handleSelectedCategory = (value) => {
        dispatch(updateFilterHistory({...filterHistory, category: value}));
        setCurrentView(Views.SelectSubCategory);
    };

    const handleSelectedSubCategory = (value) => {
        dispatch(setNaviIndex('search'));
        dispatch(updateFilterHistory({...filterHistory, sub_category: value}));
        reloadThreadData({sub_category_id: value.id});
    };

    const handleShowCreateThreadView = () => {
        if (!filterHistory.area?.id) {
            dispatch(openPopup('エリアを選択してください。'));
        } else {
            setCurrentView(Views.CreateThread);
        }
    };


    const handleChooseThread = async (id) => {
        const result = await dispatch(
            saveFilter({
                latest_joined_thread: id,
            }),
        );
        if (result) {
            dispatch(setNaviIndex('search'));
            history.push('/');
        }
    };

    const handleCreateThreadSuccess = (id) => {
        setIdThreadCreate(id);
        
         handleChooseThread(id);

    };

    const [lastDocId, setLastDocId] = useState(null);
    const [loadedAllThreads, setLoadedAllThreads] = useState(false);

    const reloadThreadData = async (params = {}) => {
        setThreads(null);
        setLoadedAllThreads(false);

        const result = await dispatch(
            getThreadList({
                area_id: filterHistory.area?.id,
                category_id: filterHistory.category?.id,
                sub_category_id: filterHistory.sub_category?.id,
                last_doc_id: null,
                per_page: perPage,
                ...params,
            }),
        );
        if (Array.isArray(result?.data) && result?.data.length !== 0) {
            setThreads(result.data);
            setLastDocId(result.last_doc_id);

            await dispatch(
                saveFilter({
                    latest_joined_thread: result.last_doc_id,
                })
            );

            dispatch(setNaviIndex('search'));
            history.push('/');
        }
        else{
            await handleCreateThread();

        }

    };

    const handleCreateThread = async () => {
        if (filterHistory.category?.id && filterHistory.sub_category?.id) {
            const result = await dispatch(
                createThread({
                    name: DEFAULT_TEMA_TITLE,
                    area_id: filterHistory.area?.id,
                    category_id: filterHistory.category?.id,
                    sub_category_id: filterHistory.sub_category?.id,
                }),
            );
            if (result?.errors) {
                setErr(result.errors);
            } else if (result?.id) {
                handleCreateThreadSuccess(result.id);
            } else {
                dispatch(openPopup(commonMessages.SOMETHING_WRONG));
            }
        } else {
            dispatch(openPopup(commonMessages.SOMETHING_WRONG));
        }
    };

    const handleScroll = (event) => {
        const scrollToBottom =
            event.target.scrollHeight - event.target.scrollTop <=
            event.target.clientHeight;
        if (scrollToBottom) {
            handleLoadMore();
        }
    };

    const handleLoadMore = async () => {
        if (loadedAllThreads || !lastDocId) {
            return;
        }
        const result = await dispatch(
            getThreadList({
                area_id: filterHistory.area?.id,
                category_id: filterHistory.category?.id,
                sub_category_id: filterHistory.sub_category?.id,
                last_doc_id: lastDocId,
                per_page: perPage,
            }),
        );

        if (Array.isArray(result?.data)) {
            if (result.data.length === 0) {
                setLoadedAllThreads(true);
            }
            setThreads(threads.concat(result.data));
            setLastDocId(result.last_doc_id);
        }
    };

    const handleClickBackButton = () => {
        history.goBack();
    };

    return (
        <div id="body">
            <ToolBar
                currentView={currentView}
                showBackButton={
                    currentView !== Views.WelcomeSearch &&
                    currentView !== Views.SelectArea
                }
                onClickBackButton={handleClickBackButton}
            />

            <FilterTopic
                currentView={currentView}
                filterHistory={filterHistory}
                onClickArea={handleClickAreaTab}
                onClickCategory={handleClickCategoryTab}
                onClickSubCategory={handleClickSubCategoryTab}
            />

            <div id="search-content">
                {isInitialized && (
                    <>
                        {currentView === Views.WelcomeSearch && (
                            <SearchSubCategory
                                filterHistory={filterHistory}
                                onSelect={handleSelectedSubCategory}
                            />
                        )}

                        {currentView === Views.SelectArea && (
                            <SearchArea
                                filterHistory={filterHistory}
                                onSelect={handleSelectedArea}
                            />
                        )}

                        {currentView === Views.SelectCategory && (
                            <SearchCategory
                                filterHistory={filterHistory}
                                onSelect={handleSelectedCategory}
                            />
                        )}

                        {currentView === Views.SelectSubCategory && (
                            <SearchSubCategory
                                filterHistory={filterHistory}
                                onSelect={handleSelectedSubCategory}
                            />
                        )}

                        {(currentView === Views.ShowThread
                            || currentView === Views.SelectArea
                            || currentView === Views.SelectSubCategory
                            || currentView === Views.SelectCategory
                        ) && (
                            <div
                                id="search-page-content-list"
                                onScroll={(event) => handleScroll(event)}>
                                {Array.isArray(threads) &&
                                    (threads.length === 0 ? (
                                        <div className="exception">
                                            <span className="no-result-thread">
                                                検索条件に該当する結果が見つかりませんでした。
                                            </span>
                                        </div>
                                    ) : (
                                        threads.map((thread, index) => {
                                            return (
                                                <>
                                                    {thread.user_id ===
                                                    userInfo.id ? (
                                                        <ThreadMyCreate
                                                            id={index}
                                                            thread={thread}
                                                        />
                                                    ) : (
                                                        <ThreadOtherCreate
                                                            id={index}
                                                            thread={thread}
                                                        />
                                                    )}
                                                </>
                                            );
                                        })
                                    ))}
                            </div>
                        )}

                        {currentView === Views.CreateThread && (
                            <CreateThreadScreen
                                onCreateSuccess={handleCreateThreadSuccess}
                            />
                        )}

                        {currentView === Views.CreateThreadSuccess && (
                            <CreateThreadSuccess
                                idThreadCreate={idThreadCreate}
                                handlerSearchResult={() =>
                                    setCurrentView(Views.ShowThread)
                                }
                            />
                        )}
                    </>
                )}
            </div>
            {currentView === Views.ShowThread && (
                <div
                    id="searchButtonFooter"
                    onClick={handleShowCreateThreadView}>
                    <h5>+ 自分の声を投稿する</h5>
                </div>
            )}
        </div>
    );
};

const _mapStateToProps = (state) => ({
    userInfo: state['cocoro/user'].userInfo,
    filterHistory: state['cocoro/user'].filterHistory,
});

export default connect(_mapStateToProps)(SearchScreen);
