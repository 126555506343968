import React, {useState, useEffect, useMemo} from 'react';
import clock from '../../../base/clock';
import './style/CountDownReplyListStyle.scss';

const CountDownReplyList = ({timeExpire}) => {
    const [time, setTime] = useState(() => timeExpire - clock.seconds());

    useEffect(() => {
        const itv = setInterval(() => {
            setTime(timeExpire - clock.seconds());
        }, 1000);
        return () => clearInterval(itv);
    }, []);

    const timeLeft = useMemo(() => {
        if (time > 0) {
            let m = Math.floor(time / 60) % 60;
            let h = Math.floor(time / 3600);
            if (time > 3600) {
                return `残り ${h}時間 ${m}分`;
            } else {
                if (time > 60) {
                    return `残り ${h}時間 ${m}分`;
                } else if (time == 60) {
                    return '残り 0時間 0分';
                } else {
                    let s = Math.floor(time % 3600) % 60;
                    return `残り ${s}秒`;
                }
            }
        } else {
            return '残り 0秒';
        }
    }, [time]);

    return (
        <>
            {time > 3600 ? (
                <div id="count-down-reply-list">
                    <div>{timeLeft}</div>
                </div>
            ) : (
                <div id="count-down-reply-list-red">
                    <div>{timeLeft}</div>
                </div>
            )}
        </>
    );
};

export default CountDownReplyList;
