import React from 'react';
import {View, ActivityIndicator, StyleSheet} from 'react-native';

export default function Loader() {
    return (
        <View style={styles.loader}>
            <ActivityIndicator size="large" color="#A6D836" />
        </View>
    );
}

const styles = StyleSheet.create({
    loader: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 375,
        height: 667,
        backgroundColor: '#000',
        zIndex: 8,
        opacity: 0.5,
        justifyContent: 'center',
    },
});
