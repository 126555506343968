import {db} from '../base/firebase/firebase';
import {
    SET_LOADING,
    SET_POPUP,
    SET_ACTION_POPUP,
    SET_CONNECT,
    SET_MODAL,
    SET_FCM_TOKEN,
    SET_VOIP_TOKEN,
    SET_MODAL_SPECIAL,
    SET_TOPIC_MODAL,
    SET_CREATE_TOPIC_MODAL,
    ADD_FRIEND_ID,
    CHECK_AUTH,
    CURRENT_APP_STATE,
    UPDATE_ROOM_DATA,
    UPDATE_LATEST_ROOMS,
    SET_NAVI_INDEX,
    CHANGE_HISTORY_TAB,
    CLOSE_CHAT_SCREEN,
    OPEN_PRESENT_SCREEN,
    CLOSE_PRESENT_SCREEN,
    OPEN_CHAT_SCREEN,
    CHANGE_ROUTE,
    SET_IS_FOCUS_SEARCH_FALSE,
    SET_IS_FOCUS_HOME_FALSE,
    TOGGLE_SEARCH_SCREEN,
    SET_IMG_URI,
    CLEAR_UNREAD_COUNT,
    SET_FROM_HOME,
    SET_AUDIO_PRESENT
} from './actionTypes';
import {
    dbCollections,
    INIT_ROOM_DATA_DEFAULT,
    MODAL_DETAIL_USER_HOME,
    realtimeTypes,
} from './constants';
import {httpRequest} from '../base/api/actions';
import {addFriendApi, rateRoomApi, reportRoomApi} from '../base/api';
import chatdb from '../chat/chatdb';
import {sortMessages} from '../chat/functions';
import {
    receiveNewFriendFromRealtime,
    removeFriend,
} from '../friendlist/actions';
import {deleteHistoryItem, deleteReplyItem} from '../history/redux/action';
import {updateRoomInfo} from '../chat/actions';


export function saveAudioPresent(data){
    return { 
        type: SET_AUDIO_PRESENT,
        data: data
     }
}

export function setFromHome(status) {
    return {
        type: SET_FROM_HOME,
        status,
    };
}

export function setCurrentAppState(currentState) {
    return {
        type: CURRENT_APP_STATE,
        currentState,
    };
}

export function changeRoute(route) {
    return {
        type: CHANGE_ROUTE,
        route,
    };
}

export function openChatScreen() {
    return {
        type: OPEN_CHAT_SCREEN,
    };
}

export function closeChatScreen() {
    return {
        type: CLOSE_CHAT_SCREEN,
    };
}

export function changeHistoryTab(historyTab) {
    return {
        type: CHANGE_HISTORY_TAB,
        historyTab,
    };
}

export function updateLatestRoomInfo(latestRooms) {
    return {
        type: UPDATE_LATEST_ROOMS,
        latestRooms,
    };
}

export function updateMessageRoomData(roomId, latestMessage, count, myId) {
    return {
        type: UPDATE_ROOM_DATA,
        roomId,
        latestMessage,
        count,
        myId,
    };
}

export function clearUnreadCount(roomId) {
    return {
        type: CLEAR_UNREAD_COUNT,
        roomId,
    };
}

export const addFriendId = (id) => {
    return {
        type: ADD_FRIEND_ID,
        id,
    };
};

export function setFcmToken(token) {
    return {
        type: SET_FCM_TOKEN,
        token,
    };
}

export function setVoipToken(token) {
    return {
        type: SET_VOIP_TOKEN,
        token,
    };
}

export function checkAuth(status) {
    return {type: CHECK_AUTH, status};
}

export function toogleSearchScreen() {
    return {
        type: TOGGLE_SEARCH_SCREEN,
    };
}

export function setNaviIndex(naviIndex) {
    return {
        type: SET_NAVI_INDEX,
        naviIndex,
    };
}

export function closeTopicModal() {
    return {
        type: SET_TOPIC_MODAL,
        topicModal: {status: false, data: ''},
    };
}

export function closePopup() {
    return {
        type: SET_POPUP,
        popup: {status: false, data: ''},
    };
}

export function closeActionPopup() {
    return {
        type: SET_ACTION_POPUP,
        popup: {status: false, data: ''},
    };
}

export function closeModalPage() {
    return {
        type: SET_MODAL,
        modal: {status: false, type: 0, data: {}},
    };
}

export function closeCreateTopicModalPage() {
    return {
        type: SET_CREATE_TOPIC_MODAL,
        modal: {status: false, type: 0, data: {}},
    };
}

export function closeModalPageSpecial() {
    return {
        type: SET_MODAL_SPECIAL,
        modalSpecial: {status: false, type: 0, data: {}},
    };
}

export function setConnect(connect) {
    return {
        type: SET_CONNECT,
        connect,
    };
}

export function openPopup(data) {
    return {
        type: SET_POPUP,
        popup: {status: true, data},
    };
}

export function openPopup1(data) {
    return {
        type: SET_POPUP,
        popup: {status: false, data},
    };
}

export function openActionPopup(data, action) {
    return {
        type: SET_ACTION_POPUP,
        popup: {status: true, data, action},
    };
}

export function openTopicModal(data, action) {
    return {
        type: SET_TOPIC_MODAL,
        topicModal: {status: true, data, action},
    };
}

export function openModalPage(data) {
    return {
        type: SET_MODAL,
        modal: {status: true, data},
    };
}

export function openCreateTopicModalPage(data) {
    return {
        type: SET_CREATE_TOPIC_MODAL,
        modal: {status: true, data},
    };
}

export function openModalPageSpecial(data) {
    return {
        type: SET_MODAL_SPECIAL,
        modalSpecial: {status: true, data},
    };
}

export function setIsFocusHomeFalse(naviIndex = 3) {
    return {
        type: SET_IS_FOCUS_HOME_FALSE,
        naviIndex,
    };
}
export function setIsFocusSearchFalse(naviIndex = 2) {
    return {
        type: SET_IS_FOCUS_SEARCH_FALSE,
        naviIndex,
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading,
    };
}

export function closeImageViewer() {
    return {type: SET_IMG_URI, img_uri: null};
}

export function openImageViewer(img_uri) {
    return {type: SET_IMG_URI, img_uri};
}

export const initApp = () => {
    return async function (dispatch, getState) {
        if (navigator.storage && navigator.storage.estimate) {
            const quota = await navigator.storage.estimate();
            // quota.usage -> Number of bytes used.
            // quota.quota -> Maximum number of bytes available.
            const percentageUsed = (quota.usage / quota.quota) * 100;
            console.log(
                `You've used ${percentageUsed}% of the available storage.`,
            );
            const remaining = quota.quota - quota.usage;
            console.log(`You can write up to ${remaining} more bytes.`);
        }
    };
};

export function subscribeRealtimeData(myId) {
    return function (dispatch, getState) {
        let ignoredFirstQuery = false;
        const handler = (docSnapshot) => {
            if (ignoredFirstQuery) {
                const data = docSnapshot.data();
                switch (data?.type) {
                    case realtimeTypes.ADD_FRIEND:
                        dispatch(receiveNewFriendFromRealtime(data.friend_id));
                        break;

                    case realtimeTypes.DELETE_FRIEND:
                        dispatch(removeFriend(data.user_id));
                        break;

                    case realtimeTypes.DELETE_HISTORY:
                        dispatch(deleteHistoryItem(data.history_id));
                        break;

                    case realtimeTypes.DELETE_ROOM_TOPIC: {
                        dispatch(deleteReplyItem(data.room_id));

                        const {roomInfo} = getState()['cocoro/chat'];
                        if (roomInfo?.id === data.room_id) {
                            dispatch(updateRoomInfo({deleted: true}));
                        }
                        break;
                    }
                    case realtimeTypes.DELETE_ROOM_FRIEND: {
                        const {roomInfo} = getState()['cocoro/chat'];
                        if (roomInfo?.id === data.room_id) {
                            dispatch(updateRoomInfo({deleted: true}));
                        }
                        break;
                    }
                }
            } else {
                ignoredFirstQuery = true;
            }
        };

        const unsubRealtime = db
            .collection(dbCollections.REALTIME)
            .doc(myId)
            .onSnapshot(handler);

        return () => {
            unsubRealtime();
        };
    };
}

export function addFriendTopic(userId, topicId, room_id) {
    return async function (dispatch, getState) {
        const result = await dispatch(
            httpRequest(
                addFriendApi({
                    user_id: userId,
                    topic_id: topicId,
                    room_id,
                }),
                true,
            ),
        );
        if (result.status === true) {
            dispatch(openPopup('ともだちを追加しました。'));

            const {modal} = getState()['cocoro/app'];
            dispatch(
                openModalPage({
                    type: MODAL_DETAIL_USER_HOME,
                    ...modal.data,
                    is_friend: true,
                }),
            );
        }
        return result;
    };
}

export function updateLatestRooms() {
    return async function (dispatch, getState) {
        const {userInfo: me} = getState()['cocoro/user'];
        try {
            if (me?.id) {
                const chats = await chatdb.getMyChats(me.id);
                const roomData = {};
                if (chats) {
                    const chatData = Object.values(chats);
                    chatData.forEach((room) => {
                        const room_id = room.room_id;
                        let unreadCount = 0;
                        let messageIds = [];
                        let latestMessage = INIT_ROOM_DATA_DEFAULT;
                        const messages = room?.messages
                            ? Object.values(room.messages)
                            : [];
                        if (messages.length > 0) {
                            sortMessages(messages);
                            latestMessage = messages[messages.length - 1];
                            messages.forEach((message) => {
                                if (
                                    message.user_id !== me.id &&
                                    !message.is_read
                                ) {
                                    unreadCount = unreadCount + 1;
                                    messageIds.push(message.id);
                                }
                            });
                        }
                        roomData[room_id] = {
                            unreadCount,
                            messageIds,
                            latestMessage,
                        };
                    });
                    dispatch(updateLatestRoomInfo(roomData));
                }
            }
        } catch (e) {
            console.log('updateLatestRooms e', e);
        }
    };
}

export function rateRoom(roomId, star, type) {
    return async function (dispatch, getState) {
        const result = await dispatch(
            httpRequest(rateRoomApi({room_id: roomId, star, type}), true),
        );
        return result;
    };
}

export function reportRoom(roomId, content, type) {
    return async function (dispatch, getState) {
        const newContent = content?.replace(/(?:\r\n|\r|\n)/g, '<br>');
        const result = await dispatch(
            httpRequest(
                reportRoomApi({room_id: roomId, content: newContent, type}),
                true,
            ),
        );
        return result;
    };
}
