import {setLoading, openPopup, openModalPage} from '../app/actions';
import {changePasswordApi} from './apis';
import {CHECK_AUTH} from '../app/actionTypes';
import {resetPassword} from '../resetpassword/actions';

export function changePassword(
    oldPassword,
    newPassword,
    confirmPassword,
    setOldPasswordError,
    setNewPasswordError,
    setConfirmPasswordError,
    callback = () => {},
) {
    return function (dispatch, getState) {
        dispatch(setLoading(true));
        let {access_token} = getState()['cocoro/user'].userInfo;
        changePasswordApi(
            access_token,
            oldPassword,
            newPassword,
            confirmPassword,
        ).then((resolve) => {
            dispatch(setLoading(false));
            try {
                dispatch({type: CHECK_AUTH, status: resolve.status});
                if (resolve.status === 200 || resolve.status === 201) {
                    dispatch(openPopup('パスワードを変更しました。'));
                    callback();
                } else if (resolve.status === 403) {
                    let errors = resolve.data.errors;
                    setOldPasswordError(errors.password ? errors.password : '');
                    setNewPasswordError(
                        errors.new_password ? errors.new_password : '',
                    );
                    setConfirmPasswordError(
                        errors.confirm_new_password
                            ? errors.confirm_new_password
                            : '',
                    );
                } else {
                    openPopup('想定しないエラーが発生しました。');
                }
            } catch (error) {
                openPopup('想定しないエラーが発生しました。');
            }
        });
    };
}
