import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {CallBtn} from '../../call';
import {openModalPage} from '../../app/actions';
import {getTopic} from '../../homescreen/actions';
import {
    MODAL_DETAIL_USER_CHAT,
    MODAL_RATE,
    TYPE_RATE_CHAT,
} from '../../app/constants';
import {roomTypes} from '../../../util/Constants';
import { updateRoomInfo } from "../actions";
import { callStates } from '../../call/constants';
import { HISTORY_PAGE_URL } from "../../app/constants";

const Header = ({roomInfo, history, userInfo, naviIndex, user_name, isSmall}) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState('-');

    const checkBeforeBack = async () => {
        if (
            roomInfo?.is_showed_rate_chat !== true &&
            roomInfo.paid === true &&
            roomInfo.topic &&
            roomInfo.topic.chat_price != 0 &&
            roomInfo.topic.user_id != userInfo.id
        ) {
            dispatch(
                openModalPage({
                    type: MODAL_RATE,
                    typeRate: TYPE_RATE_CHAT,
                    roomId: roomInfo.id,
                    username: title,
                }),
            );
        } else if(isSmall === false){
            localStorage.setItem(HISTORY_PAGE_URL, '');

            switch(naviIndex){
                case 'search':
                    history.push('/');
                    break;
                case 'home':
                    history.push('/search');
                    break;
                case 'history':
                    history.push('/history');
                    break;
                case 'friends':
                    history.push('/friendlist');
                    break;
                default :
                    history.push('/');
            }
            
            // if(history?.location?.state?.from === 'present')
            //     history.go(-2)
            // else
            //     history.goBack();
        }
    };

    const handleOpenDetailUser = async () => {
        if (roomInfo.topic_id != null) {
            const result = await dispatch(
                getTopic(roomInfo.topic_id, roomInfo.id),
            );
            if (result) {
                dispatch(
                    openModalPage({
                        type: MODAL_DETAIL_USER_CHAT,
                        ...roomInfo,
                        topicData: result,
                        is_friend: result.is_friend,
                    }),
                );
            }
        } else {
            dispatch(
                openModalPage({
                    type: MODAL_DETAIL_USER_CHAT,
                    ...roomInfo,
                    is_friend: true,
                }),
            );
        }
    };

    useEffect(() => {
        if (roomInfo.anonymous) {
            let otherId = roomInfo.participants_array.find(
                (item) => item !== userInfo.id,
            );
            setTitle(
                user_name + '---' + (roomInfo.userInfo?.user_name === undefined ? '' : roomInfo.userInfo?.user_name)
            );
        } else {
            if (roomInfo.topic) {
                if (userInfo.id === roomInfo.topic?.user_id) {
                    setTitle(
                        user_name + '---' + (roomInfo.userInfo?.user_name === undefined ? '' : roomInfo.userInfo?.user_name)
                    );
                } else {
                    setTitle(
                        user_name + '---' + (roomInfo.userInfo?.user_name === undefined ? '' : roomInfo.userInfo?.user_name)
                    );
                }
            } else {
                setTitle(
                    user_name + '---' + (roomInfo.userInfo?.user_name === undefined ? '' : roomInfo.userInfo?.user_name)
                );
            }
        }
    }, [roomInfo]);

    return (
        <header>
            <div className="back">
                {
                    isSmall === true ?
                    <img
                        alt=""
                        src=""
                    />
                    :
                    <img
                        alt=""
                        onClick={checkBeforeBack}
                        src="/images/icon-back-grey.png"
                    />
                }
            </div>
            <h3
                onClick={
                    roomInfo.userInfo?.user_name
                        ? () => handleOpenDetailUser()
                        : () => {}
                }>
                {title}
            </h3>
            <div className="call">
                {
                    roomInfo.userInfo &&
                        <img
                            style={{width: 30, height: 30, marginRight: 20, paddingTop: 5}}
                            src={require('../../../images/present.png')}
                            onClick={()=>{
                                dispatch(updateRoomInfo(roomInfo));
                                history.push('/present/'+roomInfo.id)
                            }}
                        />
                }
                {roomInfo.userInfo &&
                    (roomInfo.topic && roomInfo.topic.is_callable == false ? (
                        <CallBtn
                            userId={roomInfo.userInfo.id}
                            roomTypeId={
                                parseInt(roomInfo.room_type_id) ==
                                roomTypes.CHAT_FRIEND
                                    ? roomTypes.CALL_FRIEND
                                    : roomTypes.CALL_TOPIC
                            }
                            roomId={roomInfo.id}
                            topicInfo={roomInfo.topic}
                            disabled="true"
                            Component={() => (
                                <img
                                    className="request-call-disable"
                                    src="/images/ic_call_missing@4x.png"
                                    alt=""
                                />
                            )}
                            name = { roomInfo.userInfo?.user_name }
                            gender_name = {roomInfo.userInfo?.gender === '1' ? '男性（M）' : roomInfo.userInfo?.gender === '2' ? '女性（F）' : '不特定（X）'}
                            age_name = {roomInfo.userInfo?.age?.name}
                            area_name = { roomInfo.userInfo?.area?.name }
                            profile_image = {roomInfo.userInfo?.profile_image}
                        />
                    ) : (
                        <CallBtn
                            userId={roomInfo.userInfo.id}
                            isAnonymous={roomInfo.is_anonymous}
                            roomTypeId={
                                parseInt(roomInfo.room_type_id) ==
                                roomTypes.CHAT_FRIEND
                                    ? roomTypes.CALL_FRIEND
                                    : roomTypes.CALL_TOPIC
                            }
                            roomId={roomInfo.id}
                            topicInfo={roomInfo.topic}
                            name = { roomInfo.userInfo?.user_name }
                            gender_name = {roomInfo.userInfo?.gender === '1' ? '男性' : '女性'}
                            age_name = {roomInfo.userInfo?.age?.name}
                            area_name = { roomInfo.userInfo?.area?.name }
                            profile_image = {roomInfo.userInfo?.profile_image}
                            soundRecordNo = {
                                parseInt(roomInfo.room_type_id) == roomTypes.CHAT_FRIEND
                                    ? true
                                    : false
                            }
                        />
                    ))}
            </div>
        </header>
    );
};

function _mapStateToProps(state) {
    return {
        roomInfo: state['cocoro/chat'].roomInfo,
        userInfo: state['cocoro/user'].userInfo,
        naviIndex: state['cocoro/app'].naviIndex,
        isSmall: state['cocoro/call'].isSmall,
    };
}

export default connect(_mapStateToProps)(Header);
