import React, {useState, useEffect, useMemo, useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import '../friendstyles.scss';
import {Link, useHistory} from 'react-router-dom';
import {Modal, openModalPage} from '../../app';
import {setNaviIndex} from '../../app/actions';
import {ChatBtn} from '../../chat';
import {CallBtn} from '../../call';
import {
    ROOM_TYPE_CALL_FRIEND,
    ROOM_TYPE_CHAT_FRIEND,
} from '../../call/constants';
import {DEFAULT_LATEST_ROOM_DATA} from '../constants';
import {formatMessage} from '../functions';
import {
    getFriends,
    blockFriend,
    deleteFriend,
    getFriendInfo,
    detailFriend,
} from '../actions';
import {Constants} from '../../../util/Constants';
import {MODAL_DETAIL_FRIEND} from '../../app/constants';

function FriendList({friends, latestRoomsData, userInfo}) {
    const dispatch = useDispatch();
    const ids = [];
    const [isLoadMore, setLoadMore] = useState(false);
    const [isShowDeleteDialog, showDeleteDialog] = useState(false);
    const [isShowBlockDialog, showBlockDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const history = useHistory();
    const canLoadMore = useRef(true);
    const [lastDocId, setLastDocId] = useState(null);
    const isLoading = useRef(false);

    const handleScroll = (event) => {
        const scrollToBottom =
            event.target.scrollHeight - event.target.scrollTop <=
            event.target.clientHeight;
        if (scrollToBottom && !isLoading.current) {
            onLoadMore();
        }
    };

    const onLoadMore = async () => {
        if (canLoadMore.current) {
            setLoadMore(true);
            isLoading.current = true;
            const result = await dispatch(getFriends(lastDocId, false));
            setLoadMore(false);
            if (result?.last_doc_id) {
                setLastDocId(result?.last_doc_id);
            }
            if (result?.data.length === 0) {
                canLoadMore.current = false;
            }
            isLoading.current = false;
        }
    };

    const blockFriendFunc = (friendId) => {
        dispatch(blockFriend(friendId));
    };

    const deleteFriendFunc = (friendId) => {
        dispatch(deleteFriend(friendId));
    };

    const toAddFriend = () => {
        history.push('addfriend');
    };

    useEffect(() => {
        dispatch(setNaviIndex('friends'));
    }, []);

    useEffect(() => {
        const initFriendList = async () => {
            isLoading.current = true;
            const result = await dispatch(getFriends(lastDocId));
            if (result?.last_doc_id) {
                setLastDocId(result?.last_doc_id);
            }
            isLoading.current = false;
            canLoadMore.current = true;
        };
        initFriendList();
    }, []);

    const showUserDetailModal = async (friend) => {
        const result = await dispatch(getFriendInfo(friend?.user?.id));
        if (result) {
            dispatch(detailFriend(result, friend.topic));
            dispatch(
                openModalPage({
                    type: MODAL_DETAIL_FRIEND,
                    ...result,
                    topic: friend.topic,
                    isAnonymous: friend.isAnonymous,
                    topicId: friend.room.topic_id,
                    room: friend.room,
                    friend_flag: true,
                }),
            );
        }
    };

    const dataList = useMemo(() => {
        const _friends = friends.map((f) => {
            let isAnonymous = f?.room?.anonymous?.[f?.user?.id] || false;
            return {...f, isAnonymous};
        });

        // sort by latest message create at
        return _friends.sort(function (a, b) {
            const firstCreatedTime =
                latestRoomsData?.[a.room.id]?.latestMessage?.created_at || 0;
            const secondCreatedTime =
                latestRoomsData?.[b.room.id]?.latestMessage?.created_at || 0;
            return secondCreatedTime - firstCreatedTime;
        });
    }, [friends, latestRoomsData]);

    const chatComponent = (friend) => () => {
        const roomData = latestRoomsData?.[friend.room.id]
            ? latestRoomsData[friend.room.id]
            : DEFAULT_LATEST_ROOM_DATA;
        if (!roomData.latestMessage) {
            roomData = DEFAULT_LATEST_ROOM_DATA;
        }
        let latestMessage = roomData.latestMessage;
        let unreadCount = roomData.unreadCount;
        let displayMessage = formatMessage(
            latestMessage,
            userInfo.id,
            userInfo.user_name,
            friend?.user?.user_name || '',
            friend.isAnonymous,
        );
        return (
            <div className="friend_chat_content">
             {unreadCount !== undefined && unreadCount !== 0 && (
                    <div className="unreadBadge" style={{marginTop: 2, }}>
                        <span className="unreadCount">
                            {unreadCount < 100 ? unreadCount : '99+'}
                        </span>
                    </div>
                )}
                {!latestMessage.is_read ? (
                    <p className={'read_message'}>{(displayMessage.search("###sound") === -1 ? displayMessage : '音声メッセージ') }</p>
                ) : (
                    <p className={'unread_message'} style={{color: 'red'}}>{(displayMessage.search("###sound") === -1 ? displayMessage : '音声メッセージ') }</p>
                )}
           </div>
            // <div className="friend_chat_content">
            //     <h3>
            //         {friend.isAnonymous ? '匿名さん' : friend.user.user_name}
            //     </h3>
            //     {unreadCount !== undefined && unreadCount !== 0 && (
            //         <div className="unreadBadge" style={{marginTop: 20, }}>
            //             <span className="unreadCount">
            //                 {unreadCount < 100 ? unreadCount : '99+'}
            //             </span>
            //         </div>
            //     )}
            //     {latestMessage.is_read ? (
            //         <p className={'read_message'}>{displayMessage}</p>
            //     ) : (
            //         <p className={'unread_message'} style={{color: 'red'}}>{displayMessage}</p>
            //     )}
            // </div>
        );
    };

    const blockDialog = () => {
        return (
            <div id="confirmRemoveDialog">
                <span className="confirmTitle">
                    このともだち、本当にブロックしますか。
                </span>
                <div className="confirmContainer">
                    <div
                        className="buttonOk"
                        onClick={() => {
                            showBlockDialog(false);
                            blockFriendFunc(selectedId);
                        }}>
                        <span>はい</span>
                    </div>
                    <div
                        className="buttonCancel"
                        onClick={() => showBlockDialog(false)}>
                        <span>いいえ</span>
                    </div>
                </div>
            </div>
        );
    };

    const deleteDialog = () => {
        return (
            <div id="confirmRemoveDialog">
                <span className="confirmTitle">
                    このともだち、本当に削除しますか。
                </span>
                <div className="confirmContainer">
                    <div
                        className="buttonOk"
                        onClick={() => {
                            showDeleteDialog(false);
                            deleteFriendFunc(selectedId);
                        }}>
                        <span>はい</span>
                    </div>
                    <div
                        className="buttonCancel"
                        onClick={() => showDeleteDialog(false)}>
                        <span>いいえ</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div id="friendlist">
            {isShowBlockDialog && <Modal Component={blockDialog}/>}
            {isShowDeleteDialog && <Modal Component={deleteDialog}/>}
            <header>
                <div className="back"/>
                <h3>ともだち</h3>
                <div className="addfriend">
                    <img
                        alt=""
                        src="/images/add-friend-solid.svg"
                        className="ic_addfriend"
                        onClick={toAddFriend}
                    />
                </div>
            </header>
            <div className="mainContainer">
                <div
                    id="friend-content"
                    onScroll={(event) => handleScroll(event)}>
                    {dataList === undefined ? (
                        <div className="exception"/>
                    ) : dataList.length === 0 ? (
                        <div className="exception"/>
                    ) : (
                        dataList &&
                        dataList.map((friend, index) => {
                            if (ids.findIndex((id) => id == friend.id) == -1 && friend?.user?.id !== undefined) {
                                ids.push(friend.id);
                                return (
                                    <div key={index} className="item" style={{marginBottom: 0}}>
                                            {selectedId === friend.userId ? <span></span> : null}
                                            <div className={'avatarContainer'}>
                                                <img
                                                    className="avatar"
                                                    src={
                                                        friend.user?.profile_image ||
                                                        Constants.AVATAR_DEFAULT
                                                    }
                                                    alt=""
                                                    onClick={() =>
                                                        showUserDetailModal(friend)
                                                    }
                                                />
                                                {/* <ChatBtn
                                                    userId={friend.user.id}
                                                    Component={()=>{
                                                        return(
                                                            <img
                                                                className="avatar"
                                                                src={
                                                                    friend.user?.profile_image ||
                                                                    Constants.AVATAR_DEFAULT
                                                                }
                                                                alt=""
                                                                onClick={() =>
                                                                    showUserDetailModal(friend)
                                                                }
                                                            />
                                                        )
                                                    }}
                                                    roomTypeId={ROOM_TYPE_CHAT_FRIEND}
                                                    topicId={friend.room.topic_id}
                                                    roomInfo={friend.room}
                                                    isAnonymous={friend.isAnonymous}
                                                /> */}
                                                <h3>{friend?.user?.user_name}</h3>
                                            </div>
                                            <div className="functionContainer">
                                                <ChatBtn
                                                    userId={friend?.user?.id}
                                                    Component={chatComponent(friend)}
                                                    roomTypeId={ROOM_TYPE_CHAT_FRIEND}
                                                    topicId={friend.room.topic_id}
                                                    roomInfo={friend.room}
                                                    isAnonymous={friend.isAnonymous}
                                                />

                                                <div>
                                                    <img
                                                        className="item"
                                                        src="/images/ic_block_friend.svg"
                                                        style={{marginRight: 5}}
                                                        onClick={() => {
                                                            setSelectedId(
                                                                friend.user.id,
                                                            );
                                                            showBlockDialog(true);
                                                        }}
                                                    />
                                                    <img
                                                        className="item"
                                                        src="/images/ic_delete_friend.svg"
                                                        style={{marginRight: 5}}
                                                        onClick={() => {
                                                            setSelectedId(
                                                                friend.user.id,
                                                            );
                                                            showDeleteDialog(true);
                                                        }}
                                                    />
                                                    <CallBtn
                                                        userId={friend?.user?.id}
                                                        roomId={friend?.room?.id}
                                                        roomTypeId={ROOM_TYPE_CALL_FRIEND}
                                                        topicInfo={friend.topic}
                                                        disabled={false}
                                                        isAnonymous={friend.isAnonymous}
                                                        name = { friend?.user?.user_name }
                                                        gender_name = {friend?.user?.gender === '1' ? '男性（M）' : friend?.user?.gender === '2' ? '女性（F）' : '不特定（X）'}
                                                        age_name = {friend?.user?.age?.name}
                                                        area_name = { friend?.user?.area?.name }
                                                        profile_image = {friend?.user?.profile_image}
                                                        soundRecordNo = {true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                );
                            }
                        })
                    )}
                    {isLoadMore && (
                        <div id="friend-load-more">
                            <div className="friend-load-more"/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {
        friends: state['cocoro/friends'].friends,
        hasNextPage: state['cocoro/friends'].hasNextPage,
        latestRoomsData: state['cocoro/app'].latestRoomsData,
        userInfo: state['cocoro/user'].userInfo,
    };
}

export default connect(_mapStateToProps)(FriendList);
