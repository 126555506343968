/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import React, {useState, useEffect, useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {setNaviIndex, openModalPage, openPopup, setLoading} from '../../app';
import '../styles.scss';
import {Views} from '../../search/constants';
import FilterTopic from './FilterTopic1';
import ModalErrorCreateTopic from './ModalErrorCreateTopic';
import ModalCreateTopic from './ModalCreateTopic';
import ModalHaveTopic from './ModalHaveTopic';
import Toolbar from './Toolbar';
import {Constants, persistKeys} from '../../../util/Constants';
import {
    updateFistLogin,
    getTopicList,
    heartClick,
    exploreClick,
} from '../actions';
import {getMyFilter, getMyProfile, updateFilterHistory} from '../../user';
import {MODAL_DETAIL_FRIEND} from '../../app/constants';
import TimeTopic from '../../chat/components/TimeTopic.web';
import {useAsyncMemo} from '../../../util/hooks';
import {useWatchExpire, useWatchNewTopic} from '../hooks';
import $ from 'jquery';
import { getFriendInfo } from "../../friendlist/actions";
import { getAreaSearch, getCategorySearch, getSubCategorySearch, clearSubCategories, saveFilter, getThreadList } from "../../search/action";
import RadioItem from '../../search/components/RadioItem1.web';
import { updateCategory, updateCategoryId } from "../actions";

const HomeScreenMode = ({userInfo, filterHistory, areas, categories, subcategories, updateCategory, updateCategoryId, category, categoryId}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isBubbleStyle = true;

    const perPage = 30;
    const [isInitialized, setInitialized] = useState(false);
    const [topics, setTopics] = useState([]);
    const [lastDocId, setLastDocId] = useState([]);

    useEffect(() => {
        async function getInitData() {
            // await Promise.all([
            //     dispatch(getMyFilter()),
            //     // dispatch(getMyProfile()),
            // ]);
            setInitialized(true);
        }
        getInitData();
        dispatch(setNaviIndex('home'));
    }, []);

    useEffect(() => {
        if (isInitialized) {
            reloadTopicData(true, true);
        }
    }, [isInitialized]);

    const bubbleListJquerySelector = useRef(null);
    const currentViewOffset = useRef(0);
    const scrollInterval = useRef(null);
    const waitingInterval = useRef(null);
    const [isAutoScrollActive, setAutoScroll] = useState(false);

    useEffect(() => {
        dispatch(setNaviIndex('home'));
    }, []);

    useEffect(() => {
        if (isBubbleStyle) {
            bubbleListJquerySelector.current = $('.bubble-list').first();
            currentViewOffset.current = 0;
        }
    }, [isBubbleStyle]);

    useEffect(() => {
        if (isBubbleStyle) {
            clearInterval(scrollInterval.current);
            clearInterval(waitingInterval.current);
            waitingInterval.current = setInterval(() => {
                clearInterval(waitingInterval.current);
                setAutoScroll(true);
            }, 3000);
        }
    }, [topics, isBubbleStyle]);

    useEffect(() => {
        if (isBubbleStyle && isAutoScrollActive) {
            clearInterval(scrollInterval.current);
            scrollInterval.current = setInterval(() => {
                currentViewOffset.current += 2;
                if (bubbleListJquerySelector.current) {
                    bubbleListJquerySelector.current.animate(
                        {scrollTop: currentViewOffset.current},
                        50,
                        'swing',
                    );
                }
            }, 50);
        }
    }, [isAutoScrollActive, isBubbleStyle]);

    const onBubbleScroll = (e) => {
        const scrollToBottom =
            e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
        if (scrollToBottom) {
            clearInterval(scrollInterval.current);
            clearInterval(waitingInterval.current);
            setAutoScroll(false);
        }
        if (isBubbleStyle && !isAutoScrollActive) {
            currentViewOffset.current = e.target.scrollTop;
        }
    };

    const onMouseEnter = () => {
        clearInterval(scrollInterval.current);
        clearInterval(waitingInterval.current);
        setAutoScroll(false);
    };

    const onMouseLeave = () => {
        clearInterval(scrollInterval.current);
        clearInterval(waitingInterval.current);
        waitingInterval.current = setInterval(() => {
            setAutoScroll(true);
            clearInterval(waitingInterval.current);
        }, 3000);
    };

    const [modalVisible, setModalVisible] = useState(userInfo.is_first_login);
    const [isShowCreatTopic, setShowModalCreatTopic] = useState(false);
    const [isShowModalHaveTopic, setShowModalHaveTopic] = useState(false);
    const [isShowModalError, setShowModalError] = useState(false);

    const expiredTopicId = useWatchExpire(
        topics
            ? topics.map((topic) => {
                  return {
                      id: topic.id,
                      timeExpire: topic.expire_at._seconds,
                  };
              })
            : [],
    );

    useEffect(() => {
        if (expiredTopicId) {
            setTopics(topics.filter((topic) => topic.id !== expiredTopicId));
        }
    }, [expiredTopicId]);

    const reloadTopicData = async (loading = true, isFocus = false) => {
        const result = await dispatch(
            getTopicList(
                {
                    thread_id: null,
                    area_id: '1',
                    category_id: category?.id,
                    gender: '0',
                    age_id: '0',
                    user_status: '0',
                    last_doc_id: null,
                    per_page: perPage,
                },
                loading,
            ),
        );
        if (Array.isArray(result?.data)) {
            setTopics(result.data);
            setLastDocId(result.last_doc_id);

            if (isBubbleStyle && isFocus) {
                clearInterval(scrollInterval.current);
                clearInterval(waitingInterval.current);
                $('.bubble-list').animate({scrollTop: 0}, 500);
                waitingInterval.current = setInterval(() => {
                    setAutoScroll(true);
                    clearInterval(waitingInterval.current);
                }, 3000);
            }
        }
    };


    const showUserDetailModal = async (friend, filterHistory) => {
        const result = await dispatch(getFriendInfo(friend.user.id));
        if (result) {
            dispatch(
                openModalPage({
                    type: MODAL_DETAIL_FRIEND,
                    ...result,
                    topic: friend,
                    isAnonymous: friend.isAnonymous,
                    roomId: friend.id, //thread_id, //.room.topic_id,
                    room: friend.room,
                    userInfo: userInfo,
                    filterHistory: filterHistory,
                }),
            );
        }
    };

    const handleExploreClick = async (thread_id, author_id, index) =>{
        let a = topics;
        a[index].click_number = await a[index].click_number + 1;
        await setTopics(a);
        const result = await dispatch(exploreClick(thread_id, author_id));
        if(result?.message === "success"){
            
        }
    }

    const onSearchThread = () => {
        setShowModalError(false);
        if (!filterHistory.area) {
            history.push({
                pathname: '/search',
                state: {view: Views.SelectArea},
            });
            return;
        }
        if (!filterHistory.category) {
            history.push({
                pathname: '/search',
                state: {view: Views.SelectCategory},
            });
            return;
        }
        if (!filterHistory.sub_category) {
            history.push({
                pathname: '/search',
                state: {view: Views.SelectSubCategory},
            });
            return;
        }
        history.push({
            pathname: '/search',
            state: {view: Views.ShowThread},
        });
    };

    const handleScroll = (event) => {
        if (isBubbleStyle) {
            const scrollToBottom =
                event.target.scrollHeight - event.target.scrollTop <=
                event.target.clientHeight;
            if (scrollToBottom) {
                handleLoadMore();
            }
        } else {
            if (event.target.scrollTop === 0) {
                handleLoadMore();
            }
        }
    };

    const [hasAllTopics, setHasAllTopics] = useState(false);

    const handleLoadMore = async () => {
        if (hasAllTopics || !lastDocId) {
            return;
        }
        const result = await dispatch(
            getTopicList({
                thread_id: filterHistory.latest_joined_thread,
                area_id: '1',
                category_id: category?.id,
                sub_category_id: null,
                gender: '0',
                age_id: '0',
                user_status: '0',
                per_page: perPage,
                last_doc_id: lastDocId,
            }),
        );
        if (Array.isArray(result.data)) {
            if (result.data.length === 0) {
                setHasAllTopics(true);
            }
            setTopics(topics.concat(result.data));
            setLastDocId(result.last_doc_id);
        }
    };


    const [viewSearchCategory, setViewSearchCategory] = useState(false);

    useEffect(() => {
        if (areas === null) {
            dispatch(getAreaSearch());
        }
    }, []);

    useEffect(() => {
        if (subcategories === null && filterHistory.category?.id) {
            dispatch(getSubCategorySearch(filterHistory.category.id));
        }
    }, [filterHistory.category?.id, subcategories]);

    useEffect(() => {
        if (categories === null) {
            dispatch(getCategorySearch());
        }
    }, [categories]);

    const searchCategory = ()=>{
        setViewSearchCategory(true);
    }

    const handleSaveSelectedCategory = async (data) => {
        setViewSearchCategory(false);
        await dispatch(updateCategory(data));
        await dispatch(updateCategoryId(data?.id));
        setViewSearchCategory(false);
        const result1 =  await dispatch(
            getTopicList(
                {
                    thread_id: null,
                    area_id: '1',
                    category_id: data?.id,
                    sub_category_id: '',
                    gender: '0',
                    age_id: '0',
                    user_status: '0',
                    last_doc_id: null,
                    per_page: perPage,
                },true
            )
        );
        
        if (Array.isArray(result1?.data)) {
            setTopics(result1.data);
            setLastDocId(result1.last_doc_id);
        }
    };

    return (
        <div id="title">
            {isShowModalError && (
                <ModalErrorCreateTopic
                    onSearch={() => {
                        onSearchThread();
                    }}
                    onClose={() => setShowModalError(false)}
                />
            )}
            {isShowCreatTopic && (
                <ModalCreateTopic
                    // modalTitle={threadName ? threadName : 'ひまつぶし'}
                    modalTitle={''}
                    onClose={() => setShowModalCreatTopic(false)}
                    userInfo={{...userInfo, history: filterHistory}}
                    onCreateTopicSuccess={reloadTopicData}
                />
            )}

            {isShowModalHaveTopic && (
                <ModalHaveTopic
                    onCreateOke={() => {
                        setShowModalHaveTopic(false);
                        setShowModalCreatTopic(true);
                    }}
                    onClose={() => setShowModalHaveTopic(false)}
                />
            )}

            <Toolbar title={"最新投稿"} flag = { true } />

            <FilterTopic category={ category } searchCategory={searchCategory}/>

            {
                viewSearchCategory ? 
                    <div>
                        <div className={'modal'} onClick={()=>{
                            setViewSearchCategory(false);
                        }}/>
                        <div className={"modal-category"}>
                            {/* {
                                Array.isArray(categories) &&
                                    <RadioItem
                                        key={'all'}
                                        data={{name: 'すべて', id: ''}}
                                        isSelected={
                                            filterHistory.category?.id === ''
                                                ? true
                                                : false
                                        }
                                        setSelected={handleSaveSelectedCategory}
                                    />
                            } */}
                            {
                                Array.isArray(categories) &&
                                categories.map((item, index) => {
                                    if(item.id <6){
                                    return (
                                        
                                        <RadioItem                                            
                                            key={index}
                                            data={item}
                                            isSelected={
                                                filterHistory.category?.id === item.id
                                                    ? true
                                                    : false
                                            }
                                            // setSelected={setSelectedArea}
                                            setSelected={handleSaveSelectedCategory}
                                        />
                                    );
                                    }
                                })
                            }
                        </div>  
                    </div>
                    : null
            }


            <div id="chat-content" onScroll={(e) => handleScroll(e)}>
                {modalVisible && (
                    <div
                        id="Welcome"
                        onClick={() => {
                            dispatch(updateFistLogin());
                            userInfo.is_first_login = false;
                            localStorage.setItem(
                                persistKeys.USER_INFO,
                                JSON.stringify(userInfo),
                            );
                            setModalVisible(false);
                        }}>
                        <div className="welcome-icon">
                            <img
                                className="welcome-img"
                                src="/images/icon-message-welcome.svg"
                            />
                        </div>
                        <div className="welcome-title">
                            <span className="span-title">
                                COCOROへようこそ！
                            </span>
                        </div>
                        <div className="welcome-button">
                            <img
                                src="images/button-hide-welcome.svg"
                                onClick={() => {
                                    dispatch(updateFistLogin());
                                    userInfo.is_first_login = false;
                                    localStorage.setItem(
                                        persistKeys.USER_INFO,
                                        JSON.stringify(userInfo),
                                    );
                                    setModalVisible(false);
                                }}
                            />
                        </div>
                    </div>
                )}
                <div
                        className="bubble-list"
                        onScroll={(e) => onBubbleScroll(e)}
                        onMouseEnter={() => onMouseEnter()}
                        onMouseLeave={() => onMouseLeave()}
                        onWheel={() => onMouseEnter()}>
                        <div className="left1">
                            {Array.isArray(topics) &&
                                topics.map((topic, index) => {
                                    return (
                                        <React.Fragment
                                            key={`${topic.id}-left`}>
                                            {index % 2 === 0 && (
                                                <div
                                                    className="item"
                                                    onClick={() =>{
                                                            handleExploreClick(topic.thread_id, topic.user_id, index);
                                                            showUserDetailModal(topic, filterHistory)
                                                        }
                                                    }>
                                                    <h3>
                                                        <div className="style-text">
                                                            {' '}
                                                            {topic.title}
                                                        </div>
                                                    </h3>
                                                    <div className="style-image">
                                                        <img
                                                            className="style-image-show"
                                                            alt=""
                                                            src={
                                                                topic.user
                                                                    ?.profile_image
                                                                    ? topic.user
                                                                          ?.profile_image
                                                                    : Constants.AVATAR_DEFAULT
                                                            }
                                                        />
                                                    </div>
                                                    <div className={'item-name'}>
                                                        {topic.user?.user_name }
                                                    </div>
                                                    <div className={'item-detail'}>
                                                        {topic.user?.area?.name}/{topic.user?.gender === '1' ? '男性（M）' : topic.user?.gender === '2' ? '女性（F）' : '不特定（X）'}/{topic.user?.age?.name}
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                        <div className="right1">
                            {Array.isArray(topics) &&
                                topics.map((topic, index) => {
                                    return (
                                        <React.Fragment
                                            key={`${topic.id}-right`}>
                                            {index % 2 !== 0 && (
                                                <div
                                                    className="item"
                                                    onClick={() =>
                                                        // openDetailUser(topic)
                                                        showUserDetailModal(topic, filterHistory)
                                                    }>
                                                    <h3>
                                                        <div className="style-text">
                                                            {topic.title}
                                                        </div>
                                                    </h3>
                                                    <div className="style-image">
                                                        <img
                                                            className="style-image-show"
                                                            alt=""
                                                            src={
                                                                topic?.user
                                                                    ?.profile_image
                                                                    ? topic.user
                                                                          .profile_image
                                                                    : Constants.AVATAR_DEFAULT
                                                            }
                                                        />
                                                    </div>
                                                    <div className={'item-name'}>
                                                        {topic.user?.user_name }
                                                    </div>
                                                    <div className={'item-detail'}>
                                                        {topic.user?.area?.name}/{topic.user?.gender === "1" ? '男性（M）' : topic.user?.gender === "2" ? '女性（F）' : '不特定（X）'}/{topic.user?.age?.name}
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                    </div>
              
            </div>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    userInfo: state['cocoro/user'].userInfo,
    filterHistory: state['cocoro/user'].filterHistory,
    isBubbleStyle: state['cocoro/homescreen'].isBubbleStyle,
    categoryId: state['cocoro/homescreen'].categoryId,
    category: state['cocoro/homescreen'].category,
    areas: state['cocoro/search'].areas,
    categories: state['cocoro/search'].categories,
    subcategories: state['cocoro/search'].subcategories,
});

let _mapDispatchToProps = {
    updateCategory, updateCategoryId,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(HomeScreenMode);
