import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import '../styles.scss';
import {addFriend} from '../actions';
import {formatPrice} from '../functions';
import TimeTopic from './TimeTopic';
import {Constants} from '../../../util/Constants';

const DetailUserChat = ({data, userInfo}) => {
    const dispatch = useDispatch();
    const [canAddFriend, setCanAddFriend] = useState(false);

    useEffect(() => {
        var anonymous = Object.values(data.anonymous);
        setCanAddFriend(!anonymous[0] && !anonymous[1]);
    }, [data]);

    let handleAddFriend = async () => {
        const result = await dispatch(
            addFriend({
                user_id: data.userInfo.id,
                room_id: data.id || null,
                topic_id: data.topic_id || null,
            }),
        );
        if (result.status === true) {
            setCanAddFriend(false);
        }
    };

    let profile_image = null;
    let user_name = '';
    let share = false;
    if (data.topic) {
        if (userInfo.id == data.topic.user_id) {
            profile_image = data?.userInfo?.profile_image
                ? data.userInfo.profile_image
                : Constants.AVATAR_DEFAULT;
            user_name = data.is_anonymous
                ? data.userInfo?.user_name
                : data.userInfo.user_name;
            share = !data.is_anonymous;
        } else {
            profile_image = data?.userInfo?.profile_image
                ? data.userInfo.profile_image
                : Constants.AVATAR_DEFAULT;
            user_name = data.topic.is_anonymous
                ? data.userInfo?.user_name
                : data.userInfo.user_name;
            share = !data.topic.is_anonymous;
        }
    } else {
        profile_image = data?.userInfo?.profile_image
            ? data.userInfo.profile_image
            : Constants.AVATAR_DEFAULT;
        user_name = data.userInfo.user_name;
        share = true;
    }
    return (
        <div id="detail-user-chat">
            <div>
                <header>
                    <img src={profile_image} alt="" />
                    <div>
                        <p className="name">{user_name}</p>
                        <p className="detail">
                            {data.userInfo.area?.name} |{' '}
                            {parseInt(data?.userInfo.gender) == 1 ? '男性（M）' : parseInt(data?.userInfo.gender) == 2 ? '女性（F）' : '不特定（X）'} |{' '}
                            | {data.userInfo.age?.name}
                            
                        </p>
                    </div>
                    {!data.is_friend && canAddFriend && (
                        <div className="detail-user-chat-add-friend">
                            <img
                                className=""
                                src="/images/ic_add_friend@2x.png"
                                alt=""
                                onClick={handleAddFriend}
                            />
                            <br />
                            <span>ともだち追加</span>
                        </div>
                    )}
                </header>
                <div id="detail">
                    {data.topic &&
                        (data.topic.chat_price != 0 ||
                            data.topic.call_price != 0) && (
                            <div>
                                <div className="detail-price">
                                    <img src="/images/icon-take-charge.svg" />
                                </div>
                                <div className="detail-price-content">
                                    <p>
                                        {data.topic.chat_price != 0 &&
                                            'チャット課金（初回のみ）'}
                                    </p>
                                    <p>
                                        {data.topic.call_price != 0 &&
                                            '通話課金（1分ごとに）'}
                                    </p>
                                </div>
                                <div className="detail-pricex">
                                    <p>
                                        {data.topic.chat_price != 0 &&
                                            formatPrice(data.topic.chat_price) +
                                                'ポイント'}
                                    </p>
                                    <p>
                                        {data.topic.call_price != 0 &&
                                            formatPrice(data.topic.call_price) +
                                                'ポイント'}
                                    </p>
                                </div>
                            </div>
                        )}
                </div>
                <div className="comment">
                    <div>
                        <span className="x">一言コメント</span>
                        {share && data.userInfo.instagram && (
                            <a
                                target="_blank"
                                href={
                                    data.userInfo.instagram.includes(
                                        'https://',
                                    ) ||
                                    data.userInfo.instagram.includes('http://')
                                        ? data.userInfo.instagram
                                        : 'https://' + data.userInfo.instagram
                                }>
                                <img src="/images/ic_instagram@4x.png" alt="" />
                            </a>
                        )}
                        {share && data.userInfo.twitter && (
                            <a
                                target="_blank"
                                href={
                                    data.userInfo.twitter.includes(
                                        'https://',
                                    ) ||
                                    data.userInfo.twitter.includes('http://')
                                        ? data.userInfo.twitter
                                        : 'https://' + data.userInfo.twitter
                                }>
                                <img src="/images/ic_twitter@4x.png" alt="" />
                            </a>
                        )}
                        {share && data.userInfo.facebook && (
                            <a
                                target="_blank"
                                href={
                                    data.userInfo.facebook.includes(
                                        'https://',
                                    ) ||
                                    data.userInfo.facebook.includes('http://')
                                        ? data.userInfo.facebook
                                        : 'https://' + data.userInfo.facebook
                                }>
                                <img src="/images/ic_facebook@4x.png" alt="" />
                            </a>
                        )}
                    </div>
                    <p>{data.userInfo.comment}</p>
                </div>
                {data?.room_type_id == '2' &&
                    userInfo.id != data.topic.user_id && (
                        <div className="comment">
                            <div>
                                <span className="x">投稿内容</span>
                                <TimeTopic
                                    timeExpire={data.topic.expire_at._seconds}
                                />
                            </div>
                            <p>{data.topic.detail}</p>
                        </div>
                    )}
            </div>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    userInfo: state['cocoro/user'].userInfo,
});

export default connect(_mapStateToProps)(DetailUserChat);
