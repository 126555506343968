import firebase from 'firebase';
import {
    FIREBASE_API_KEY,
    AUTH_DOMAIN,
    DATABASE_URL,
    PROJECT_ID,
    STORAGE_BUCKET,
    MESSAGE_SENDER_ID,
    APP_ID,
    MEASUREMENT_ID,
} from '../../../env.json';

const config = {
    apiKey: FIREBASE_API_KEY,
    authDomain: AUTH_DOMAIN,
    databaseURL: DATABASE_URL,
    projectId: PROJECT_ID,
    storageBucket: STORAGE_BUCKET,
    messagingSenderId: MESSAGE_SENDER_ID,
    appId: APP_ID,
};

firebase.initializeApp(config);
firebase.firestore.setLogLevel('error');
firebase.firestore().settings({experimentalForceLongPolling: true});
export const database = firebase.database();
export const db = firebase.firestore();
export const auth = firebase.auth();
