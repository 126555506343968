import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {closeModalPage, openModalPage} from '../actions';
import {useDispatch} from 'react-redux';
import {MODAL_REPORT, TYPE_RATE_CALL, TYPE_RATE_CHAT} from '../constants';
import {rateRoom, openActionPopup} from '../actions';

function RateChat({data, roomInfo, userInfo}) {
    const [star, setStar] = useState(5);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleOpenReport = () => {
        dispatch(
            openModalPage({
                type: MODAL_REPORT,
                typeRate: data.typeRate,
                roomId: data.roomId,
                username: data.username || '匿名さん',
                onDone: data.onDone || null,
            }),
        );
    };

    const handleClose = async () => {
        // support data from call report
        const roomId = data.roomId || roomInfo.id;
        await dispatch(rateRoom(roomId, null, data.typeRate));

        dispatch(closeModalPage());
        if (parseInt(data.typeRate) === TYPE_RATE_CHAT) {
            history.goBack();
        }

        if (data.onDone) {
            data.onDone();
        }
    };

    const handleRate = async () => {
        // support data from call report
        const roomId = data.roomId || roomInfo.id;

        const result = await dispatch(rateRoom(roomId, star, data.typeRate));
        if (result) {
            dispatch(closeModalPage());
            dispatch(
                openActionPopup('レビューありがとうございます。', () => {
                    if (parseInt(data.typeRate) === TYPE_RATE_CHAT) {
                        history.goBack();
                    }
                }),
            );
        }

        if (data.onDone) {
            data.onDone();
        }
    };

    return (
        <div id="rating-chat" className="confirm-chat-price">
            {parseInt(data.typeRate) === TYPE_RATE_CHAT && (
                <p className="rate">
                    今回の課金には納得いただけましたか？
                    <br />
                    よろしければ評価をお願いします。
                </p>
            )}

            {parseInt(data.typeRate) === TYPE_RATE_CALL && (
                <p className="rate">
                    今回の有料トークには満足いただけましたか？
                    <br />
                    よろしければ評価をお願いします。
                </p>
            )}

            <div className="star-wrap">
                <img
                    src={
                        star >= 1
                            ? '/images/ic_rating_on.svg'
                            : '/images/ic_rating_off.svg'
                    }
                    onClick={() => setStar(1)}
                />
                <img
                    src={
                        star >= 2
                            ? '/images/ic_rating_on.svg'
                            : '/images/ic_rating_off.svg'
                    }
                    onClick={() => setStar(2)}
                />
                <img
                    src={
                        star >= 3
                            ? '/images/ic_rating_on.svg'
                            : '/images/ic_rating_off.svg'
                    }
                    onClick={() => setStar(3)}
                />
                <img
                    src={
                        star >= 4
                            ? '/images/ic_rating_on.svg'
                            : '/images/ic_rating_off.svg'
                    }
                    onClick={() => setStar(4)}
                />
                <img
                    src={
                        star >= 5
                            ? '/images/ic_rating_on.svg'
                            : '/images/ic_rating_off.svg'
                    }
                    onClick={() => setStar(5)}
                />
            </div>
            <div className="btnWrap">
                <span className="btn btn-chat" onClick={handleRate}>
                    評価する
                </span>
                <span className="btn btn-call" onClick={handleClose}>
                    しない
                </span>
            </div>
            <p className="report" onClick={handleOpenReport}>
                {parseInt(data.typeRate) === TYPE_RATE_CHAT &&
                    'このチャットを通報する'}
                {parseInt(data.typeRate) === TYPE_RATE_CALL &&
                    'この通話を通報する'}
            </p>
        </div>
    );
}
function _mapStateToProps(state) {
    return {
        roomInfo: state['cocoro/chat'].roomInfo,
        userInfo: state['cocoro/user'].userInfo,
    };
}

let _mapDispatchToProps = {};

export default connect(_mapStateToProps, _mapDispatchToProps)(RateChat);
