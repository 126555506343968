import {db} from '../base/firebase/firebase';
import {openPopup, closePopup, openActionPopup, closeModalPage, openModalPage} from '../app/actions';
import {requestPaidCallApi, confirmRequestPaidCallApi} from './apis';
import {
    ROOM_TYPE_CALL_FRIEND,
    REALTIME_TYPE_CALL,
    REALTIME_TYPE_CALL_ACTION_CALLER,
    REALTIME_TYPE_CALL_ACTION_CALLEE,
    REALTIME_TYPE_CALL_ACTION_AGREE,
    REALTIME_TYPE_CALL_ACTION_REFUSE,
    REALTIME_TYPE_CALL_ACTION_AUDIO,
    REALTIME_TYPE_CALL_ACTION_END,
    REALTIME_TYPE_CALL_BALANCE,
    REALTIME_TYPE_CALL_ACTION_END_BALANCE,
    REALTIME_TYPE_CALL_ACTION_REQUEST_PAID,
    REALTIME_TYPE_CALL_ACTION_CONFIRM_REQUEST_PAID,
    callStates,
} from './constants';
import {MODAL_PAID_CALL, MODAL_RATE, TYPE_RATE_CALL} from '../app/constants';
import * as webrtc from '../base/webrtc';
import {sleep} from '../../util/helpers';
import {CallManager} from './callmanager';
import {
    createRoomApi,
    saveAudioRecord,
    getRoomCallingDataApi,
    replyActionCallApi,
} from '../base/api';
import clock from '../base/clock';
import { SETTING_CALL_VOICE, SOUND_RECORD_DATA, } from "../app/constants";
import { setLoading } from "../app";
//-----------------------------------------------

var audio2 = new Audio();
audio2 = new Audio('/audio/ringtoneCall.mp3');

var audio1 = new Audio();
audio1 = new Audio('/audio/ringtone.mp3');

var audio = new Audio(localStorage.getItem(SOUND_RECORD_DATA));

export function setLocalStream(stream) {
    return {
        type: 'SET_LOCAL_STREAM',
        stream,
    };
}

export function updateRoomDetail(roomDetail) {
    return {
        type: 'UPDATE_ROOM_DETAIL',
        roomDetail,
    };
}

export function updateBalance(balance) {
    return {
        type: 'UPDATE_BALANCE',
        balance,
    };
}

export function updateUserinfo(userInfo) {
    return {
        type: 'UPDATE_USERINFO',
        userInfo,
    };
}

export function setRemoteStream(stream) {
    return {
        type: 'SET_REMOTE_STREAM',
        stream,
    };
}

export function updatePaidCall(paidCall) {
    return {
        type: 'UPDATE_PAID_CALL',
        paidCall,
    };
}

export function updateSmallStatus(isSmall) {
    return {
        type: 'UPDATE_SMALL_STATUS',
        isSmall,
    };
}

export function updateMyselfStatus(isMyself) {
    return {
        type: 'UPDATE_MYSELF_STATUS',
        isMyself,
    };
}

export function toggleSpeak() {
    return {
        type: 'TOGGLE_SPEAK',
    };
}

export const setCallState = (state, room_id) => {
    return {
        type: 'UPDATE_CALL_STATE',
        payload: state,
        room_id: room_id
    };
};

export function listener() {
    return function (dispatch, getState) {
        const {userInfo} = getState()['cocoro/user'];

        let ignoreFirstQuery = false;
        const handler = (docSnapshot) => {
            if (ignoreFirstQuery) {
                const data = docSnapshot.data();
                const {userInfo: me} = getState()['cocoro/user'];

                if (me.blocks?.[data.userInfo?.id]) {
                    console.log('receive from user is blocked');
                    return;
                }

                if (data.type === REALTIME_TYPE_CALL) {
                    if (data.action === REALTIME_TYPE_CALL_ACTION_CALLEE) {
                        console.log(
                            CallManager.isCalling,
                            CallManager.isAnswering,
                            CallManager.receivingId,
                        );
                        if (
                            CallManager.isCalling ||
                            CallManager.isAnswering ||
                            CallManager.receivingId
                        ) {
                            if (
                                CallManager.receivingId !==
                                data.userInfo.user_id
                            ) {
                                setTimeout(() => {
                                    CallManager.replyBusy(
                                        data.userInfo.user_id,
                                        data.room_id,
                                    );
                                }, 3000);
                            }
                        } else {
                            CallManager.receivingId = data.userInfo.user_id;
                            dispatch(updateUserinfo(data.userInfo));
                            dispatch(updateRoomDetail(data));
                            dispatch(updateBalance(0));
                            dispatch(setCallState(callStates.RECEIVING));
                        }
                    }

                    if (data.action === REALTIME_TYPE_CALL_ACTION_CALLER) {
                        dispatch(updateUserinfo(data.userInfo));
                        dispatch(updateRoomDetail(data));
                        dispatch(updateBalance(0));
                    }

                    if (
                        data.action === REALTIME_TYPE_CALL_ACTION_REQUEST_PAID
                    ) {
                        dispatch(
                            openModalPage({
                                type: MODAL_PAID_CALL,
                                price: data.price,
                            }),
                        );
                    }

                    if (
                        data.action ===
                        REALTIME_TYPE_CALL_ACTION_CONFIRM_REQUEST_PAID
                    ) {
                        if (data.status) {
                            dispatch(openPopup('課金を承認されました。'));
                            // call is connected
                            dispatch(updateBalanceRightAfterConnected(true));
                        } else {
                            dispatch(openPopup('課金を拒否されました。'));
                            dispatch(updatePaidCall(false));
                        }
                    }

                    if (data.action === REALTIME_TYPE_CALL_BALANCE) {
                        dispatch(changeBalance(data));
                    }

                    if (data.action === REALTIME_TYPE_CALL_ACTION_END_BALANCE) {
                        dispatch(openPopup('ポイントが不足です。'));
                        dispatch(endCall(data.callee_id, data.room_id));
                    }
                }
            } else {
                ignoreFirstQuery = true;
            }
        };

        const unsubRealtime = db
            .collection('real_time')
            .doc(userInfo.id)
            .onSnapshot(handler);

        return () => {
            unsubRealtime();
        };
    };
}

export function listenEndEvent(calleeId, signalId) {
    return function (dispatch, getState) {
        const subkey = 'callee_listen_end_event';
        CallManager.on(subkey, {
            [CallManager.State.IsEnded]: () => {
                dispatch(setCallState(callStates.NONE));
            },
        });
        CallManager.listenEndEvent({
            signalId,
            calleeId: calleeId,
            subkey,
        });
        return () => CallManager.unsubscribeListenEndEvent(subkey);
    };
}

// after receive call on native, sometime call data from realtime is missing (app killed)
export function getCallInfo() {
    return async function (dispatch, getState) {
        if (CallManager.peerConn?.signalId) {
            const roomId = CallManager.peerConn.signalId;
            const otherUserId = CallManager.peerConn.remotePeerId;
            const res = await getRoomCallingDataApi(roomId, otherUserId);
            if (res.data) {
                const data = res.data;
                dispatch(updateUserinfo(data.userInfo));
                dispatch(updateRoomDetail(data));
                dispatch(updateBalance(data.balance));
            }
        }
    };
}

export function requestCall(
    user_id,
    room_id,
    room_type_id = ROOM_TYPE_CALL_FRIEND,
    topic_id = null,
    is_anonymous = false,
    soundRecordNo,
) {
    return async function (dispatch, getState) {
        const {callState} = getState()['cocoro/call'];
        if (callState === callStates.NONE) {
            if (await webrtc.checkHasUserMedia()) {
                dispatch(updateUserinfo(null));
                dispatch(updateRoomDetail(null));
                dispatch(setCallState(callStates.CALLING, room_id));
                // if(is_anonymous)
                //     dispatch(openPopup('着信を待っています。'));
                audio2.loop = true;
                audio2.play();
                const res = await createRoomApi(
                    topic_id,
                    user_id,
                    room_id,
                    room_type_id,
                    is_anonymous,
                );

                if (res.status == 403) {
                    dispatch(openPopup('ポイントが不足です。'));
                    dispatch(setCallState(callStates.NONE));
                    audio2.pause();
                } else {
                    dispatch(makeCall(user_id, res?.data?.id, soundRecordNo));
                }
                dispatch(closeModalPage());
            }
        } else {
            dispatch(openPopup('トークできませんでした。すでにトーク中です。'));
        }
    };
}

export function saveRecordAudio(
    user_id,
    audioRecord,
) {
    return async function (dispatch, getState) {
        
        dispatch(setLoading(true));

        const res = await saveAudioRecord(
            user_id,
            audioRecord,
        );

        dispatch(setLoading(false));

        if (res.status == 403) {
            dispatch(openPopup('ポイントが不足です。'));
        } else {
         //Task   
        }
    }
}

export function updateBalanceRightAfterConnected(confirmed = false) {
    return function (dispatch, getState) {
        const {roomDetail} = getState()['cocoro/call'];
        const topicInfo = roomDetail?.topicInfo;
        let balance = 0;
        if (
            topicInfo &&
            (confirmed || topicInfo.purchase_on_start) &&
            topicInfo.call_price &&
            topicInfo.time_call_price
        ) {
            balance = topicInfo.call_price * topicInfo.time_call_price;
        }
        dispatch(updateBalance(balance));
    };
}

export function makeCall(calleeId, roomId, soundRecordNo) {
    return async function (dispatch, getState) {
        const {userInfo: myInfo} = getState()['cocoro/user'];
        

        if (!CallManager.isCalling) {
            CallManager.on('caller', {
                [CallManager.State.IsConnected]: async () => {
                    const {userInfo: caller1} = getState()['cocoro/call'];
                    audio.pause();
                    audio2.pause();
                    dispatch(closePopup());
                    
                    if (roomId && caller1?.id) {
                        const conn = webrtc.getConnection(
                            caller1.id,
                            roomId,
                        );
                        if (conn) {
                            const status = conn.toggleAudioTrack();
                        }
                    }

                    const {roomDetail, balance} = getState()['cocoro/call'];
                    if(roomDetail.userInfo?.is_anonymous){
                        await sleep(10);
                        audio1.play();
                        dispatch(openPopup('通話を開始してください。'));
                        await sleep(7000);
                        audio1.pause();
                        dispatch(closePopup());
                        dispatch(saveRecordAudio(myInfo.id, ''));
                        localStorage.setItem(SOUND_RECORD_DATA, '');
                    }

                    if (roomId && caller1?.id) {
                        const conn = webrtc.getConnection(
                            caller1.id,
                            roomId,
                        );
                        if (conn) {
                            const status = conn.toggleAudioTrack();
                        }
                    }
                    dispatch(updateSmallStatus(false));
                    dispatch(setCallState(callStates.CONNECTING));
                    dispatch(updateBalanceRightAfterConnected());
                },
                [CallManager.State.IsAudio]: async () => {
                    audio2.pause();
                    await sleep(100);
                    dispatch(openPopup('メッセージを再生しています。'));
                    
                    audio.loop = true;
                    audio.play();
                },
                [CallManager.State.IsDisconnected]: async () => {
                    dispatch(callEndApi(roomId));
                    dispatch(setCallState(callStates.DISCONNECT));
                    dispatch(openPopup('キャンセルされました。'));
                    dispatch(checkReviewOnPaidCall());
                    audio2.pause();
                },
                [CallManager.State.IsUnableConnect]: async () => {
                    dispatch(callEndApi(roomId, true));
                    dispatch(setCallState(callStates.UNABLE_CONNECT));
                    dispatch(openPopup('通話できません。'));
                    await sleep(1000);
                    audio2.pause();
                    dispatch(setCallState(callStates.NONE));
                },
                [CallManager.State.IsBusy]: async () => {
                    dispatch(setCallState(callStates.BUSY));
                    dispatch(openPopup('相手が話中です。'));
                    await sleep(1000);
                    audio2.pause();
                    dispatch(setCallState(callStates.NONE));
                },
                [CallManager.State.IsRefused]: async () => {
                    dispatch(setCallState(callStates.REFUSE));
                    dispatch(openPopup('切断されました。11'));
                    await sleep(3000);
                    audio2.pause();
                    dispatch(saveRecordAudio(myInfo.id, ''));
                    localStorage.setItem(SOUND_RECORD_DATA, '');
                    dispatch(setCallState(callStates.NONE));

                },
                [CallManager.State.IsEnded]: () => {
                    dispatch(openPopup('切断されました。22'));
                    audio2.pause();
                    dispatch(callEndApi(roomId));
                    dispatch(checkReviewOnPaidCall());
                },
            });

            await CallManager.call({
                calleeId,
                roomId,
                callerId: myInfo.id,
                callerName: myInfo.user_name,
            });
            if(localStorage.getItem(SETTING_CALL_VOICE) === 'true' && soundRecordNo !== true)
                dispatch(updateSmallStatus(false));
        }
    };
}

export function answerCall(callerId, roomId) {
    return async function (dispatch, getState) {

        dispatch(setCallState(callStates.WAITING_CONNECT));

        const {userInfo: myInfo} = getState()['cocoro/user'];
        const {userInfo: caller} = getState()['cocoro/call'];

        if (!CallManager.isAnswering) {
            CallManager.on('callee', {
                [CallManager.State.IsConnected]:async () => {
                    
                    const {userInfo: caller1} = getState()['cocoro/call'];
                    if (roomId && caller1?.id) {
                        const conn = webrtc.getConnection(
                            caller1.id,
                            roomId,
                        );
                        if (conn) {
                            const status = conn.toggleAudioTrack();
                        }
                    }
                    const {roomDetail, balance} = getState()['cocoro/call'];
                    if(roomDetail.userInfo?.is_anonymous){
                        dispatch(openPopup('通話を開始してください。'));
                        await sleep(100);
                        audio2.play();
                        audio2.loop = true;
                        await sleep(7000);
                        dispatch(closePopup());
                        audio2.pause();
                    }

                    if (roomId && caller1?.id) {
                        const conn = webrtc.getConnection(
                            caller1.id,
                            roomId,
                        );
                        if (conn) {
                            const status = conn.toggleAudioTrack();
                        }
                    }
                    dispatch(setCallState(callStates.CONNECTING));
                    dispatch(updateBalanceRightAfterConnected());
                },
                [CallManager.State.IsAudio]: () => {
                    dispatch(setCallState(callStates.CONNECTING));
                    dispatch(updateBalanceRightAfterConnected());
                },
                [CallManager.State.IsDisconnected]: async () => {
                    dispatch(openPopup('キャンセルされました。'));
                    dispatch(callEndApi(roomId));
                    dispatch(setCallState(callStates.NONE));
                    dispatch(updateBalance(0));
                    dispatch(updatePaidCall(false));
                    dispatch(updateSmallStatus(false));
                },
                [CallManager.State.IsUnableConnect]: async () => {
                    dispatch(openPopup('切断されました。33'));
                    dispatch(callEndApi(roomId, true));
                    dispatch(setCallState(callStates.UNABLE_CONNECT));
                    await sleep(3000);
                    dispatch(setCallState(callStates.NONE));
                    dispatch(updateBalance(0));
                    dispatch(updatePaidCall(false));
                    dispatch(updateSmallStatus(false));
                },
                [CallManager.State.IsEnded]: async () => {
                    dispatch(openPopup('切断されました。44'));
                    dispatch(callEndApi(roomId));
                    dispatch(setCallState(callStates.NONE));
                    dispatch(updateBalance(0));
                    dispatch(updatePaidCall(false));
                    dispatch(updateSmallStatus(false));
                },
            });

            await CallManager.receive({
                callerId,
                roomId,
                calleeId: myInfo.id,
                callerName: caller.user_name,
            });

            // keep previous flow
            await replyActionCallApi(roomId, REALTIME_TYPE_CALL_ACTION_AGREE);
            // await sleep(5000);
            // dispatch(openPopup('通話を開始してください。answerCall'));
            // audio2.play();
            // sleep(7000);
            
            // audio2.pause();
        }
    };
}

export function nativePressAnswer() {
    return async function (dispatch, getState) {
        CallManager.on('callee', {
            [CallManager.State.IsConnected]: () => {
                dispatch(setCallState(callStates.CONNECTING));
                dispatch(updateBalanceRightAfterConnected());
            },
            [CallManager.State.IsDisconnected]: async () => {
                if (CallManager.peerConn) {
                    const roomId = CallManager.peerConn.signalId;
                    dispatch(callEndApi(roomId));
                }
                dispatch(setCallState(callStates.NONE));
            },
            [CallManager.State.IsUnableConnect]: async () => {
                dispatch(setCallState(callStates.UNABLE_CONNECT));
                await sleep(3000);
                dispatch(setCallState(callStates.NONE));
            },
            [CallManager.State.IsEnded]: async () => {
                if (CallManager.peerConn) {
                    const roomId = CallManager.peerConn.signalId;
                    dispatch(callEndApi(roomId));
                }
                dispatch(setCallState(callStates.NONE));
            },
        });

        // keep previous flow
        const roomId = CallManager.peerConn?.signalId;
        await replyActionCallApi(roomId, REALTIME_TYPE_CALL_ACTION_AGREE);
    };
}

export function refuseCall(callerId, roomId) {
    return async function (dispatch, getState) {
        dispatch(setCallState(callStates.NONE));
        CallManager.refuse({callerId, signalId: roomId});
        // keep previous flow
        await replyActionCallApi(roomId, REALTIME_TYPE_CALL_ACTION_REFUSE);
    };
}

export function audioCall(callerId, roomId) {
    return async function (dispatch, getState) {
        CallManager.audio({callerId, signalId: roomId});
        // keep previous flow
        // await replyActionCallApi(roomId, REALTIME_TYPE_CALL_ACTION_AUDIO);
    };
}

export function endCall(peerId, roomId, callNotStart = false) {
    return function (dispatch, getState) {
        audio2.pause();
        dispatch(checkReviewOnPaidCall());

        CallManager.end({peerId, signalId: roomId});
        dispatch(callEndApi(roomId, callNotStart));
    };
}

export function checkReviewOnPaidCall() {
    return async function (dispatch, getState) {
        const {userInfo: myInfo} = getState()['cocoro/user'];
        const {roomDetail, balance} = getState()['cocoro/call'];
        const topicInfo = roomDetail?.topicInfo;
        const now = clock.now();
        if (
            balance > 0 &&
            myInfo.id !== topicInfo?.user_id &&
            roomDetail.user_id === myInfo.id &&
            topicInfo?.call_price &&
            now < topicInfo?.expire_at * 1000
        ) {
            dispatch(setCallState(callStates.WAITING_REVIEW));
            dispatch(
                openModalPage({
                    type: MODAL_RATE,
                    typeRate: TYPE_RATE_CALL,
                    roomId: roomDetail.room_id,
                    username: roomDetail.userInfo?.is_anonymous
                        ? null
                        : roomDetail.userInfo?.user_name,
                    onDone: () => {
                        dispatch(setCallState(callStates.NONE));
                    },
                }),
            );
        } else {
            dispatch(setCallState(callStates.NONE));
        }

        dispatch(updateBalance(0));
        dispatch(updatePaidCall(false));
        dispatch(updateSmallStatus(false));
    };
}

export function changeBalance(data) {
    return function (dispatch, getState) {
        let {roomDetail} = getState()['cocoro/call'];
        if (roomDetail.room_id && roomDetail.room_id == data.room_id) {
            dispatch(updateBalance(data.balance));
        }
    };
}

export function requestPaidCall() {
    return function (dispatch, getState) {
        let {userInfo} = getState()['cocoro/user'];
        let {room_id} = getState()['cocoro/call'].roomDetail;
        requestPaidCallApi(room_id, userInfo.access_token)
            .then((rs) => {
                if (rs.status === 200 || rs.status == 201) {
                    dispatch(updatePaidCall(true));
                    dispatch(openPopup('課金リクエストを送信しました。'));
                }
            })
            .catch((err) => {});
    };
}

export function confirmRequestPaidCall(status) {
    return function (dispatch, getState) {
        const {userInfo} = getState()['cocoro/user'];
        const {roomDetail} = getState()['cocoro/call'];
        confirmRequestPaidCallApi(
            roomDetail.room_id,
            userInfo.access_token,
            status,
        )
            .then((rs) => {
                dispatch(closeModalPage());
                if (rs.data) {
                    if (status) {
                        dispatch(updateBalanceRightAfterConnected(true));
                    }
                } else {
                    dispatch(openPopup('ポイントが不足です。'));
                }
            })
            .catch((err) => {});
    };
}

// keep previous flow
export function callEndApi(roomId, callNotStart) {
    return async function (dispatch, getState) {
        if (callNotStart) {
            await replyActionCallApi(roomId, REALTIME_TYPE_CALL_ACTION_REFUSE);
        } else {
            await replyActionCallApi(roomId, REALTIME_TYPE_CALL_ACTION_END);
        }
    };
}
