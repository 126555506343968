import React from 'react';
import {connect} from 'react-redux';
import {Redirect, useLocation} from 'react-router-dom';

const PrivateComponent = ({loggedIn, userInfo, children}) => {
    const location = useLocation();

    if (loggedIn == false) {
        let publicUrls = ['login'];
        let urls = window.location.href.split('/');
        let url = urls[urls.length - 1];
        if (publicUrls.indexOf(url) != -1) {
            return <Redirect to={{pathname: '/' + url}} />;
        } else {
            localStorage.removeItem('userRegisterInfo');
            localStorage.removeItem('userAvatarRegisterInfo');
            return <Redirect to={{pathname: '/splash'}} />;
        }
    }

    if (
        userInfo?.is_infomation == false &&
        location.pathname.indexOf('registration-information') === -1
    ) {
        return <Redirect to={{pathname: '/registration-information'}} />;
    }

    return <div id="wrapper">{children}</div>;
};

const _mapStateToPropsTop = (state) => ({
    loggedIn: state['cocoro/user'].loggedIn,
    userInfo: state['cocoro/user'].userInfo,
});

export default connect(_mapStateToPropsTop)(PrivateComponent);
