import React, {useState, useEffect} from 'react';
import {StyleSheet, Text, View, SafeAreaView} from 'react-native';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import CodeInput from '../input/CodeInput';
import Btn from '../Btn';
import Header from '../Header';
import {login} from '../../../user/actions';
import {verifyCode} from '../../actions';
import phoneBorderFaker from '../../../../util/style/phoneBorderFaker';
import fontStyle from '../fontStyle';

const CodeConfirm = ({email='', password='', setIsConfirm=''}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.removeItem('userRegisterInfo');
        localStorage.removeItem('userAvatarRegisterInfo');
    }, []);

    const [code, setCode] = useState('');
    const [err, setErr] = useState(null);

    const handleSubmitVerifyCode = async () => {
        setErr('');
        let result = await dispatch(verifyCode(email, code));
        if (result?.is_active) {
            result = await dispatch(login(email, password));
            history.push('/registration-information');
        } else if (result?.errors?.verify_code) {
            setErr(result?.errors?.verify_code);
        }
    };

    return (
        <SafeAreaView style={styles.container}>
            <div style={phoneBorderFaker}>
                <Header
                    txt=""
                    onPressBack={() => {
                        setIsConfirm(false);
                    }}
                />
                <div style={mainStyle}>
                    <View style={styles.txtWrapper}>
                        <Text style={styles.txt}>
                            届いたメールの内容に記載されている認証
                        </Text>
                        <Text style={styles.txt}>
                            コード（6桁の数字）を以下に入力して
                        </Text>
                        <Text style={styles.txt}>
                            認証ボタンをタップしてください。
                        </Text>
                    </View>
                    <View stye={styles.inputs}>
                        <CodeInput
                            txt="認証コード"
                            setValue={setCode}
                            err={err}
                            setErr={setErr}
                        />
                    </View>
                    <View style={styles.btn}>
                        <Btn txt="認証" onPress={handleSubmitVerifyCode} />
                    </View>
                </div>
            </div>
        </SafeAreaView>
    );
};

const width = 375;

const mainStyle = {
    paddingLeft: 27.5,
    paddingRight: 27.5,
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
    },
    txt: {
        ...fontStyle,
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    txtWrapper: {
        marginTop: width * 0.32,
        height: width * 0.3,
    },
    inputs: {
        marginTop: width * 0.1,
    },
    btn: {
        marginTop: width * 0.4,
        alignItems: 'center',
        width: '100%',
    },
});

export default CodeConfirm;
