import React, {useState, useEffect} from 'react';
import {
    View,
} from 'react-native';
import {openPopup} from '../../app/actions';
import {connect, useDispatch} from 'react-redux';
import { updateUser } from "../actions";
import '../blood.scss';

function SettingBlood1({navigation, route, openPopup, history}) {
    const dispatch = useDispatch();
    const [err, setErr] = useState('');

    let userInfoStorage = localStorage.getItem('user_info');
    try{
        userInfoStorage = JSON.parse(userInfoStorage);
        userInfoStorage.userBloodSettings = JSON.parse(userInfoStorage.userBloodSettings);
    }catch(e){
        userInfoStorage = {userBloodSettings: {}}
    }
    let bloodInitSelected = -1;
    switch (userInfoStorage.userBloodSettings.bloodType) {
        case 'A':
            bloodInitSelected = 0;
            break;
        case 'B':
            bloodInitSelected = 1;
            break;
        case 'O':
            bloodInitSelected = 2;
            break;
        case 'AB':
            bloodInitSelected = 3;
            break;
        default:
            bloodInitSelected = -1;
            break;
    }

    const personalityEnum = {'明るい': 0, 'ふつう': 1, '暗め': 2}

    const [bloodType, setBloodType] = useState(userInfoStorage.userBloodSettings.bloodType);
    const [selectedBloodType, setSelectedBloodType] = useState(bloodInitSelected);
    const [personality, setPersonality] = useState(userInfoStorage.userBloodSettings.personality);
    const [selectedPersonality, setSelectedPersonality] = useState(personalityEnum[userInfoStorage.userBloodSettings.personality]);
    const [sociability, setSociability] = useState(userInfoStorage.userBloodSettings.sociability);
    const [selectedSociability, setSelectedSociability] = useState(userInfoStorage.userBloodSettings.sociability-1);
    const [degree, setDegree] = useState(userInfoStorage.userBloodSettings.degree);
    const [selectedDegree, setSelectedDegree] = useState(userInfoStorage.userBloodSettings.degree-1);
    const [expression, setExpression] = useState(userInfoStorage.userBloodSettings.expression);
    const [selectedExpression, setSelectedExpression] = useState(userInfoStorage.userBloodSettings.expression-1);
    const [aggression, setAggression] = useState(userInfoStorage.userBloodSettings.aggression);
    const [selectedAggression, setSelectedAggression] = useState(userInfoStorage.userBloodSettings.aggression-1);
    const [fashion, setFashion] = useState(userInfoStorage.userBloodSettings.fashion);
    const [selectedFashion, setSelectedFashion] = useState(userInfoStorage.userBloodSettings.fashion-1);

    let handleClick = () => {

        userInfoStorage = JSON.parse(localStorage.getItem('user_info'));

        userInfoStorage.userBloodSettings = JSON.stringify({
            bloodType: bloodType,
            personality: personality,
            sociability: sociability,
            degree: degree,
            expression: expression,
            aggression: aggression,
            fashion: fashion
        });

        localStorage.setItem('user_info', JSON.stringify(userInfoStorage));

        let userInfo;
        try{
            userInfo = JSON.parse(userInfoStorage.userRegisterInfo);
        }catch(e){
            userInfo = {};
        }

        let avatar;
        try{
            avatar = JSON.parse(userInfoStorage.avatar);
        }catch(e){
            avatar = {}
        }
        try{
            dispatch(
                updateUser(
                    callbackSuccess,
                    userInfo.age_id,
                    1,
                    userInfo.area_id,
                    userInfo.gender,
                    avatar.type_image,
                    avatar.imageUri,
                    userInfo.user_name,
                    userInfoStorage.userAvatarRegisterInfo,
                    userInfoStorage.userBloodSettings,
                    userInfoStorage.userCharacterSettings,
                    userInfoStorage.userRegisterInfo,
                    userInfoStorage.userSoundSettings,
                    userInfoStorage.userCharacterInputWord,
                    userInfoStorage.userCharacterInputDetail,
                    userInfoStorage.userCoin,
                    setErr,
                )
            );
        }catch(e){

        }

    }

     let callbackSuccess = (data) => {
        history.push('/registration-information/character-setting-1');
    };

    return (
        <div style={styles.container} id={'blood-container'}>
            {/* <div className={"back-container"}> */}
                <img src={"/images/icon-back-grey.png"} className={'my-back'} alt = {"back"} onClick={()=> history.goBack()}/>
            {/* </div> */}
            <View style={styles.header}>
                <div style={styles.txt}>
                    なりたいキャラに
                </div>
                <div style={styles.txt}>
                    設定してください
                </div>
                <div style={styles.txt}>
                    (後から設定できます)
                </div>
            </View>

            <View style={{paddingHorizontal: 20, paddingTop: 10}}>
                <View>
                    <span style={styles.boldText}>
                        血液型
                    </span>

                    <View style={{...styles.row, marginTop: 5}}>
                        <button
                            style={{
                                ...styles.bloodType,
                                backgroundColor: selectedBloodType === 0 ? 'white' : 'transparent',
                                borderRadius: 100/2
                            }}
                            onClick={() => {
                                setBloodType('A');
                                setSelectedBloodType(0)
                            }}
                        >
                            <span>
                                A
                            </span>
                        </button>
                        <button
                            style={{
                                ...styles.bloodType,
                                backgroundColor: selectedBloodType === 1 ? 'white' : 'transparent',
                                borderRadius: 100/2
                            }}
                            onClick={() => {
                                setBloodType('B');
                                setSelectedBloodType(1)
                            }}
                        >
                            <span>
                                B
                            </span>
                        </button>
                        <button
                            style={{
                                ...styles.bloodType,
                                backgroundColor: selectedBloodType === 2 ? 'white' : 'transparent',
                                borderRadius: 100/2
                            }}
                            onClick={() => {
                                setBloodType('O');
                                setSelectedBloodType(2)
                            }}
                        >
                            <span>
                                O
                            </span>
                        </button>
                        <button
                            style={{
                                ...styles.bloodType,
                                backgroundColor: selectedBloodType === 3 ? 'white' : 'transparent',
                                borderRadius: 100/2
                            }}
                            onClick={() => {
                                setBloodType('AB');
                                setSelectedBloodType(3)
                            }}
                        >
                            <span>
                                AB
                            </span>
                        </button>
                    </View>
                </View>

                <View style={{marginTop: 5}}>
                    <span style={styles.boldText}>
                        性格
                    </span>

                    <View style={{...styles.row, marginTop: 5}}>
                        <button
                            style={{
                                ...styles.bloodType,
                                backgroundColor: selectedPersonality === 0 ? 'white' : 'transparent',
                                borderRadius: 100/2
                            }}
                            onClick={() => {
                                setPersonality('明るい');
                                setSelectedPersonality(0);
                            }}
                        >
                            <span>
                                明るい
                            </span>
                        </button>

                        <button
                            style={{
                                ...styles.bloodType,
                                backgroundColor: selectedPersonality === 1 ? 'white' : 'transparent',
                                borderRadius: 100/2
                            }}
                            onClick={() => {
                                setPersonality('ふつう');
                                setSelectedPersonality(1);
                            }}
                        >
                            <span>
                                ふつう
                            </span>
                        </button>

                        <button
                            style={{
                                ...styles.bloodType,
                                backgroundColor: selectedPersonality === 2 ? 'white' : 'transparent',
                                borderRadius: 100/2
                            }}
                            onClick={() => {
                                setPersonality('暗め');
                                setSelectedPersonality(2);
                            }}
                        >
                            <span>
                                暗め
                            </span>
                        </button>
                    </View>
                </View>

                <View style={{marginTop: 5}}>
                    <span style={styles.boldText}>
                        気質
                    </span>

                    <View style={styles.p10}>
                        <View style={styles.between}>
                            <span style={styles.txt1}>
                                社交性
                            </span>

                            <View style={{...styles.row, ...styles.option1to5}}>
                                {
                                    [0,1,2,3,4].map(val => {
                                        return (
                                            <button
                                                key={val}
                                                style={{
                                                    ...styles.option,
                                                    backgroundColor: selectedSociability === val ? 'white' : 'transparent',
                                                    borderRadius: 100/2
                                                }}
                                                onClick = {() => {
                                                    setSociability(val+1);
                                                    setSelectedSociability(val);
                                                }}
                                            >
                                                <span style={styles.txt1}>{val+1}</span>
                                            </button>
                                        )
                                    })
                                }
                            </View>
                        </View>

                        <View style={{...styles.between, marginTop: 5}}>
                            <span style={styles.txt1}>
                                おしゃべり度
                            </span>

                            <View style={{...styles.row, ...styles.option1to5}}>
                                {
                                    [0,1,2,3,4].map(val => {
                                        return (
                                            <button
                                                key={val}
                                                style={{
                                                    ...styles.option,
                                                    backgroundColor: selectedDegree === val ? 'white' : 'transparent',
                                                    borderRadius: 100/2
                                                }}
                                                onClick = {() => {
                                                    setDegree(val+1);
                                                    setSelectedDegree(val);
                                                }}
                                            >
                                                <span style={styles.txt1}>{val+1}</span>
                                            </button>
                                        )
                                    })
                                }
                            </View>
                        </View>

                        <View style={{...styles.between, marginTop: 5}}>
                            <span style={styles.txt1}>
                                感情表現
                            </span>

                            <View style={{...styles.row, ...styles.option1to5}}>
                                {
                                    [0,1,2,3,4].map(val => {
                                        return (
                                            <button
                                                key={val}
                                                style={{
                                                    ...styles.option,
                                                    backgroundColor: selectedExpression === val ? 'white' : 'transparent',
                                                    borderRadius: 100/2
                                                }}
                                                onClick = {() => {
                                                    setExpression(val+1);
                                                    setSelectedExpression(val);
                                                }}
                                            >
                                                <span style={styles.txt1}>{val+1}</span>
                                            </button>
                                        )
                                    })
                                }
                            </View>
                        </View>

                        <View style={{...styles.between, marginTop: 5}}>
                            <span style={styles.txt1}>
                                積極性
                            </span>

                            <View style={{...styles.row, ...styles.option1to5}}>
                                {
                                    [0,1,2,3,4].map(val => {
                                        return (
                                            <button
                                                key={val}
                                                style={{
                                                    ...styles.option,
                                                    backgroundColor: selectedAggression === val ? 'white' : 'transparent',
                                                    borderRadius: 100/2
                                                }}
                                                onClick = {() => {
                                                    setAggression(val+1);
                                                    setSelectedAggression(val);
                                                }}
                                            >
                                                <span style={styles.txt1}>{val+1}</span>
                                            </button>
                                        )
                                    })
                                }
                            </View>
                        </View>

                        <View style={{...styles.between, marginTop: 5}}>
                            <span style={styles.txt1}>
                                おしゃれ度
                            </span>

                            <View style={{...styles.row, ...styles.option1to5}}>
                                {
                                    [0,1,2,3,4].map(val => {
                                        return (
                                            <button
                                                key={val}
                                                style={{
                                                    ...styles.option,
                                                    backgroundColor: selectedFashion === val ? 'white' : 'transparent',
                                                    borderRadius: 100/2
                                                }}
                                                onClick = {() => {
                                                    setFashion(val+1);
                                                    setSelectedFashion(val);
                                                }}
                                            >
                                                <span style={styles.txt1}>{val+1}</span>
                                            </button>
                                        )
                                    })
                                }
                            </View>
                        </View>
                    </View>
                </View>
            </View>

            <View style={styles.warpbutton}>
                <button
                    onClick={handleClick}
                    style={styles.buttonSubmit}>
                    <span style={styles.textbutton}>つぎへ</span>
                </button>
            </View>
        </div>
    );
}

const styles = {
    container: {
        flex: 1,
        backgroundColor: '#d6f27e',
    },
    header: {
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 30,
    },
    txt: {
        color: '#2b2828',
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    txt1: {
        color: 'black',
        fontSize: 16,
        // fontWeight: 'bold',
        // alignSelf: 'center',
        // marginTop: 5
    },
    boldText: {
        color: 'black',
        fontSize: 18,
        fontWeight: 'bold',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    between: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    p10: {
        padding: 10
    },
    option1to5: {
        backgroundColor: '#40e8e5',
        padding: 5,
        borderRadius: 100/2
    },
    option: {
        backgroundColor: 'transparent',
        paddingHorizontal: 12,
        cursor: 'pointer'
    },
    bloodType: {
        backgroundColor: 'transparent',
        paddingHorizontal: 30,
        paddingVertical: 10,
        cursor:'pointer',
    },
    warpbutton: {
        width: '100%',
        marginTop: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '35px',
    },
    buttonSubmit: {
        width: '50%',
        padding: 5,
        fontWeight: 'bold',
        backgroundColor: '#FFE033',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#DBBD16',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 4,
        borderBottomColor: '#DBBD16',
        borderRadius: 10,
        height: 50,
        cursor: 'pointer'
    },
    textbutton: {
        fontSize: '18px',
        color: '#36383B',
        fontWeight: 'bold',
    },
};

function _mapStateToProps(state) {
    return {};
}

let _mapDispatchToProps = {
    openPopup,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(SettingBlood1);
