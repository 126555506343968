import React, {useEffect, useState} from 'react';
import '../createTopicStyle.scss';
import $ from 'jquery';
import {confirmAlert} from 'react-confirm-alert';
import {connect} from 'react-redux';
import {changeRoute} from '../../app';

function ModalErrorCreateTopic({onClose, onSearch, userInfo}) {
    return (
        <div id="rootContainerCreate">
            <div className="detailContainerError">
                <img
                    className="close"
                    onClick={() => onClose()}
                    src="/images/ic_close_modal.svg"
                    alt=""
                />
                <h3>
                    {' '}
                    自分の声を投稿することには、テーマ選択が必要となります。{' '}
                </h3>
                <div className="topic-title">
                    <div className="submit">
                        <span onClick={onSearch} className="button-submit">
                            {' '}
                            OK{' '}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function _mapStateToProps(state) {
    return {};
}

let _mapDispatchToProps = {
    changeRoute,
};

export default connect(
    _mapStateToProps,
    _mapDispatchToProps,
)(ModalErrorCreateTopic);
