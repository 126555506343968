import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {CallBtn} from '../../call';
import { ChatBtn } from '../../chat';
import {openModalPage} from '../../app/actions';
import {getTopic} from '../../homescreen/actions';
import {
    MODAL_DETAIL_USER_CHAT,
    MODAL_RATE,
    TYPE_RATE_CHAT,
} from '../../app/constants';
import {roomTypes} from '../../../util/Constants';
import {updateSmallStatus, endCall, requestPaidCall} from '../../call';
import {
    ROOM_TYPE_CHAT_FRIEND,
} from '../../call/constants';
import { updateRoomInfo } from "../actions";

const Header = ({roomInfo, history, userInfo, trumpetImage, setTrumpetImage, isSmall}) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');

    const checkBeforeBack = async () => {
        if(trumpetImage){
            setTrumpetImage(false);
        }else{
            history.goBack();
        }
    };

    const handleOpenDetailUser = async () => {
        if (roomInfo.topic_id != null) {
            const result = await dispatch(
                getTopic(roomInfo.topic_id, roomInfo.id),
            );
            if (result) {
                dispatch(
                    openModalPage({
                        type: MODAL_DETAIL_USER_CHAT,
                        ...roomInfo,
                        topicData: result,
                        is_friend: result.is_friend,
                    }),
                );
            }
        } else {
            dispatch(
                openModalPage({
                    type: MODAL_DETAIL_USER_CHAT,
                    ...roomInfo,
                    is_friend: true,
                }),
            );
        }
    };

    useEffect(() => {
        if (roomInfo.anonymous) {
            let otherId = roomInfo.participants_array.find(
                (item) => item !== userInfo.id,
            );
            setTitle(
                roomInfo.anonymous[otherId]
                    ? roomInfo.userInfo?.user_name
                    : roomInfo.userInfo?.user_name,
            );
        } else {
            if (roomInfo.topic) {
                if (userInfo.id === roomInfo.topic?.user_id) {
                    setTitle(
                        roomInfo.is_anonymous
                            ? roomInfo.userInfo?.user_name
                            : roomInfo.userInfo?.user_name,
                    );
                } else {
                    setTitle(
                        roomInfo.topic?.is_anonymous
                            ? roomInfo.userInfo?.user_name
                            : roomInfo.userInfo?.user_name,
                    );
                }
            } else {
                setTitle(roomInfo.userInfo?.user_name);
            }
        }
    }, [roomInfo]);

    const handleCallMessage = () => {
        dispatch(updateSmallStatus(true));
    }

    return (
        <header>
            <div className="back">
                {
                    isSmall === true ?
                    <img
                        alt=""
                        src=""
                    />
                    :
                    <img
                        alt=""
                        onClick={checkBeforeBack}
                        src="/images/back.png"
                        style={{width: 30, height: 30}}
                    />
                }
            </div>
            <h3
                onClick={
                    roomInfo.userInfo?.user_name
                        ? () => handleOpenDetailUser()
                        : () => {}
                }>
                {title}
            </h3>
            <div className="back" style={{display: 'flex', flexDirection: 'row-reverse', marginLeft: -10}}>
                {/* <ChatBtn
                    Component= { () =>{
                        return <img
                                    onClick={handleCallMessage}
                                    style={{width: 30, height: 30}}
                                    src={ require("../../../images/call_message.png") }
                                />
                    } }
                    userId={roomInfo?.userInfo?.id}
                    roomTypeId={ROOM_TYPE_CHAT_FRIEND}
                    topicId={roomInfo?.topic?.topicId}
                    roomInfo={roomInfo}
                    isAnonymous={roomInfo?.isAnonymous}
                /> */}
            </div>
            {/* <div className="call">
                {roomInfo.userInfo &&
                    (roomInfo.topic && roomInfo.topic.is_callable == false ? (
                        <CallBtn
                            userId={roomInfo.userInfo.id}
                            roomTypeId={
                                parseInt(roomInfo.room_type_id) ==
                                roomTypes.CHAT_FRIEND
                                    ? roomTypes.CALL_FRIEND
                                    : roomTypes.CALL_TOPIC
                            }
                            roomId={roomInfo.id}
                            topicInfo={roomInfo.topic}
                            disabled="true"
                            Component={() => (
                                <img
                                    className="request-call-disable"
                                    src="/images/ic_call_missing@4x.png"
                                    alt=""
                                />
                            )}
                        />
                    ) : (
                        <CallBtn
                            userId={roomInfo.userInfo.id}
                            isAnonymous={roomInfo.is_anonymous}
                            roomTypeId={
                                parseInt(roomInfo.room_type_id) ==
                                roomTypes.CHAT_FRIEND
                                    ? roomTypes.CALL_FRIEND
                                    : roomTypes.CALL_TOPIC
                            }
                            roomId={roomInfo.id}
                            topicInfo={roomInfo.topic}
                        />
                    ))}
            </div> */}
        </header>
    );
};

function _mapStateToProps(state) {
    return {
        roomInfo: state['cocoro/chat'].roomInfo,
        userInfo: state['cocoro/user'].userInfo,
        isSmall: state['cocoro/call'].isSmall,
    };
}

export default connect(_mapStateToProps)(Header);
