import React, {useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import '../styleCreateThread.scss';
import {createThread} from '../action';
import {openPopup} from '../../app/actions';
import {commonMessages} from '../../../util/Constants';

const CreateThreadScreen = ({filterHistory, onCreateSuccess}) => {
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [err, setErr] = useState(null);

    const onChangeValue = (value) => {
        setErr(null);
        setName(value);
    };

    const handleCreateThread = async () => {
        if (filterHistory.category?.id && filterHistory.sub_category?.id) {
            const result = await dispatch(
                createThread({
                    name,
                    area_id: filterHistory.area?.id,
                    category_id: filterHistory.category?.id,
                    sub_category_id: filterHistory.sub_category?.id,
                }),
            );
            if (result?.errors) {
                setErr(result.errors);
            } else if (result?.id) {
                onCreateSuccess(result.id);
            } else {
                dispatch(openPopup(commonMessages.SOMETHING_WRONG));
            }
        } else {
            dispatch(openPopup(commonMessages.SOMETHING_WRONG));
        }
    };

    return (
        <div class="wrapSearchThread">
            <div class="theme-text">テーマを作成しましょう！</div>
            <input
                id="detail"
                value={name}
                type="text"
                placeholder={'タイトルを入力してください'}
                onChange={(e) => {
                    onChangeValue(e.target.value);
                }}
            />
            {err && <span className="msg">{err.name}</span>}
            <div class="submit-button-wrap">
                <button
                    class="theme-submit-button "
                    onClick={handleCreateThread}>
                    テーマを登録
                </button>
            </div>
        </div>
    );
};

const _mapStateToProps = (state) => ({
    filterHistory: state['cocoro/user'].filterHistory,
});

export default connect(_mapStateToProps)(CreateThreadScreen);
