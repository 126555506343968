import {URL_API} from '../../env.json';
import axios from 'axios';
import apiConf from '../../util/apiConf';

export function signUp(email, confirm_email, password, confirm_password) {
    return new Promise(function (resolve, reject) {
        axios
            .post(
                URL_API + '/api/v1/users',
                {
                    email,
                    confirm_email,
                    password,
                    confirm_password,
                },
                apiConf,
            )
            .then(function (response) {
                resolve(response);
            })
            .catch(function ({response}) {
                if (response && response.status) {
                    resolve(response);
                } else {
                    if (response) {
                        resolve({
                            status: 404,
                            message: response.data ? response.data.message : '',
                        });
                    } else {
                        resolve({
                            status: 404,
                            message: '想定しないエラーが発生しました。',
                        });
                    }
                }
            });
    });
}
