import React, {useState} from 'react';
import inputStyle from './baseInputStyle';

export default function BaseInput(props) {
    const [inputTxt, setInputTxt] = useState('');

    function onChangeText(e) {
        try {
            let txt = e.target.validity.valid ? e.target.value : inputTxt;
            setInputTxt(txt);
            props.onChangeText(txt);
        } catch (e) {

        }
    }

    return (
        <input
            style={inputStyle}
            type="text"
            onInput={onChangeText}
            value={inputTxt}
            pattern="[0-9]*"
        />
    );
}
