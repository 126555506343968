import React from 'react';

export default function ActionPopup({closePopup, data, action}) {
    let onClose = () => {
        closePopup();
        action();
    };

    return (
        <div id="page-popup">
            <div className="page-popup-content">
                <p dangerouslySetInnerHTML={{__html: data}} />
                <button onClick={onClose}>OK</button>
            </div>
        </div>
    );
}
